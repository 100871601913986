import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { TextInput, Text, Modal, Services, Theme, Checkbox, i18n, tokens } from '@simplybenefits/sb-ui-library';

import { notifyAllEmployees, notifyEmployee } from '../../actions/admin.js';
import * as notifier from '../../actions/notifier.js';

class NotifyDialog extends PureComponent {
    state = {
        selectedMessage: null,
        selectedTypes: {
            email: false,
            push: false
        },
        customInput: {
            subject: '',
            message: ''
        },
        response: null,
        subjectError: false,
        messageError: false
    };

    _typeChange = (type) => {
        this.setState({
            selectedTypes: {
                ...this.state.selectedTypes,
                [type]: !this.state.selectedTypes[type]
            }
        });
    };

    _setSelectedMessage = (event) => {
        this.setState({
            selectedMessage: event.target.value
        });
    };

    _onBlur = async (type) => {
        this.setState({
            [`${ type }Error`]: await Services.validateInput(type, this.state.customInput[type])
        });
    };

    _handleCustomInputChange = (type) => (event) => {
        this.setState({
            customInput: {
                ...this.state.customInput,
                [type]: event.target.value
            }
        });
    };

    _sendNotification = async () => {
        const { selectedTypes, selectedMessage, customInput } = this.state;

        if (!selectedTypes.email && !selectedTypes.push) {
            this.props.dispatch(notifier.displayError({
                verbiage: i18n.t(tokens.labels.employees.selectNotificationType)
            }));

            return;
        }

        if (!selectedMessage) {
            this.props.dispatch(notifier.displayError({
                verbiage: i18n.t(tokens.labels.employees.selectNotificationMessage)
            }));

            return;
        }

        if (selectedMessage === 'custom' && customInput.subject.length < 1) {
            this.setState({
                subjectError: i18n.t(tokens.labels.employees.provideNotificationSubject)
            });

            return;
        }

        if (selectedMessage === 'custom' && customInput.message.length < 2) {
            this.props.dispatch(notifier.displayError({
                verbiage: i18n.t(tokens.labels.employees.provideCustomMessage)
            }));

            this.setState({
                messageError: i18n.t(tokens.labels.employees.provideMessageNotification)
            });

            return;
        }

        const data = {
            selectedMessage: this.state.selectedMessage,
            selectedTypes: this.state.selectedTypes,
            customInput: this.state.customInput
        };

        let response = null;

        if (this.props.notificationUser) {
            response = await this.props.dispatch(notifyEmployee(this.props.notificationUser, data));
        } else {
            response = await this.props.dispatch(notifyAllEmployees(data));
        }

        // clear the state
        this.setState({
            selectedMessage: null,
            selectedTypes: {
                email: false,
                push: false
            },
            customInput: {
                subject: '',
                message: ''
            },
            response,
            subjectError: false,
            messageError: false
        });

        this.props.toggleNotificationModal();
    };

    _handleResponseClose = () => {
        this.setState({
            response: null
        });
    };

    render() {
        const { notificationModalOpen, toggleNotificationModal } = this.props;
        const styles = {
            radio: {
                '&.MuiButtonBase-root:hover': {
                    backgroundColor: Theme.colors.hoverGrey
                }
            },
            label: {
                "& .MuiFormControlLabel-label": {
                    fontFamily: Theme.fonts.type.medium,
                    color: Theme.colors.mainTextGrey
                }
            }
        };

        if (this.props.adminMeta.blockEnrollments === 1) {
            return (
                <Modal
                    open={notificationModalOpen && !this.state.response}
                    onClose={toggleNotificationModal}
                    title={ i18n.t(tokens.labels.employees.notifyMembers) }
                    primaryButton={{
                        label: i18n.t(tokens.buttons.close),
                        onClick: toggleNotificationModal
                    }}
                >
                    <Text medium>
                        { i18n.t(tokens.labels.employees.accountPendingReview) }
                    </Text>
                </Modal>
            );
        }

        if (this.state.response) {
            return (
                <Modal
                    open={this.state.response}
                    onClose={this._handleResponseClose}
                    title={ i18n.t(tokens.labels.employees.notice) }
                >
                    <Text medium>
                        { `${ i18n.t(tokens.labels.employees.someNotificationsSentSuccessfully) }  ${ this.state.response }` }
                    </Text>
                </Modal>
            );
        }

        if (notificationModalOpen) {
            return (
                <Modal
                    open={notificationModalOpen && !this.state.response}
                    onClose={toggleNotificationModal}
                    title={ i18n.t(tokens.labels.employees.notifyMembers) }
                    primaryButton={{
                        label: i18n.t(tokens.buttons.send),
                        onClick: this._sendNotification
                    }}
                    loader={ this.props.loader }
                >
                    <Text medium light>
                        { i18n.t(tokens.labels.employees.selectNotificationTypeMessage) }
                    </Text>

                    <Text small light display="block" margin="20px 0 0 0">
                        { i18n.t(tokens.labels.employees.notificationType) }
                    </Text>

                    <FormGroup row>
                        <FormControlLabel
                            label={ i18n.t(tokens.labels.email) }
                            sx={styles.label}
                            control={ <Checkbox color="secondary"checked={this.state.selectedTypes.email} onChange={() => this._typeChange('email')} /> }
                        />

                        <FormControlLabel
                            label={ i18n.t(tokens.labels.employees.pushNotification) }
                            sx={styles.label}
                            control={ <Checkbox color="secondary" checked={this.state.selectedTypes.push} onChange={() => this._typeChange('push')} /> }
                        />
                    </FormGroup>

                    <Text small light display="block" margin="10px 0 0 0">
                        { i18n.t(tokens.labels.employees.messageType) }
                    </Text>

                    <RadioGroup row value={this.state.selectedMessage} onChange={this._setSelectedMessage}>
                        {
                            (this.props.currentAccountMeta.isPrivateLabel || this.props.currentAccountMeta.isMarketplaceOnly) ?
                                <FormControlLabel value="welcome" sx={styles.label} control={<Radio color="secondary" sx={styles.radio} />} label={ i18n.t(tokens.labels.employees.marketplaceEnrollment) } />
                                :
                                <FormControlLabel value="welcome" sx={styles.label} control={<Radio color="secondary" sx={styles.radio} />} label={ i18n.t(tokens.labels.employees.enrollment) } />
                        }

                        <FormControlLabel value="custom" sx={styles.label} control={<Radio color="secondary" sx={styles.radio} />} label={ i18n.t(tokens.labels.employees.custom) } />
                    </RadioGroup>

                    {
                        this.state.selectedMessage === 'custom' &&
                            <React.Fragment>
                                <TextInput
                                    label={ i18n.t(tokens.labels.employees.customSubject) }
                                    variant="outlined"
                                    value={this.state.customInput.subject}
                                    onBlur={() => this._onBlur('subject')}
                                    onChange={this._handleCustomInputChange('subject') }
                                    error={Boolean(this.state.subjectError)}
                                    helperText={this.state.subjectError}
                                    fullWidth
                                    autofocus
                                />

                                <TextInput
                                    label={ i18n.t(tokens.labels.employees.customMessage) }
                                    variant="outlined"
                                    value={this.state.customInput.message}
                                    onBlur={() => this._onBlur('message')}
                                    onChange={this._handleCustomInputChange('message')}
                                    error={Boolean(this.state.messageError)}
                                    helperText={this.state.messageError}
                                    fullWidth
                                    multiline={true}
                                    rowsMax={8}
                                    rows={8}
                                />
                            </React.Fragment>
                    }
                </Modal>
            );
        }

        return null;
    }
}

NotifyDialog.propTypes = {
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(NotifyDialog);
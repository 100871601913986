import React, { useState } from 'react';
import {
    Checkbox,
    DateInput,
    Modal,
    MessageCard, Services,
    Text,
    TextInput,
    Theme,
    i18n,
    tokens
} from "@simplybenefits/sb-ui-library";
import store from '../../../../redux/store.js';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import * as dates from "@simplybenefits/sb-dates";

/**
 * @param bankingDetails
 * @param showBottomBar
 * @returns {JSX.Element}
 * @private
 */
const _detailsBody = (bankingDetails, showBottomBar = true) => (
    <>
        <MessageCard
            title={ bankingDetails.institutionName }
            subtitle={ bankingDetails.institutionAddress }
        />

        <Text medium margin="10px 0">
            { i18n.t(tokens.labels.banking.branchTransitNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{ bankingDetails.transitNum }</span>
        </Text>

        <Text medium margin="10px 0">
            { i18n.t(tokens.labels.banking.financialInstitutionNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{ bankingDetails.institutionNum }</span>
        </Text>

        <Text medium margin="10px 0">
            { i18n.t(tokens.labels.banking.accountNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{ bankingDetails.accountNum }</span>
        </Text>

        {
            showBottomBar &&
                <div style={{ borderBottom: `1px solid ${ Theme.colors.lightGrey }`, marginTop: "30px" }}></div>
        }
    </>
);

/**
 * @param type
 * @param value
 * @param state
 * @param setState
 * @returns {null}
 * @private
 */
const _handleSignageChange = (type, value, state, setState) => {
    if (type === 'acceptedTerms' && value === true) {
        setState({
            ...state,
            signage: {
                ...state.signage,
                acceptedTerms: true,
                timestampOfAcceptance: dates.getUTC()
            }
        });

        return null;
    } else if (type === 'password') {
        setState({
            ...state,
            [type]: value
        });
    } else {
        setState({
            ...state,
            signage: {
                ...state.signage,
                [type]: value
            }
        });
    }

    return null;
};

/**
 * @param state
 * @param setState
 * @param saveBanking
 */
const validateSignage = async (state, setState, saveBanking) => {
    const { name, signature, datedOn, acceptedTerms } = state.signage;

    let signageNameError = await Services.validateInput('checkEmpty', name);
    const signageSignatureError = await Services.validateInput('checkEmpty', signature);
    const signageDatedOnError = await Services.validateInput('date', datedOn, i18n.locale);
    const passwordError = await Services.validateInput('checkEmpty', state.password);
    const signageAcceptedError = !acceptedTerms;

    if (name !== signature) {
        signageNameError = i18n.t(tokens.labels.signage.signageNameError);
    }

    if (signageAcceptedError) {
        alert(i18n.t(tokens.labels.signage.pleaseAcceptAndAcknowledgeChanges));
    }

    setState({
        ...state,
        signageNameError,
        signageSignatureError,
        signageDatedOnError,
        passwordError
    });

    if (
        signageNameError ||
        signageSignatureError ||
        signageDatedOnError ||
        signageAcceptedError ||
        passwordError
    ) {
        return false;
    }

    return saveBanking(state);
};

/**
 *
 * @returns
 */
const ConfirmBankingDetails = ({ 
    onBack, 
    saveBanking, 
    newBankingDetails, 
    close, 
    loader 
}) => {
    const useSameBankAccount = newBankingDetails.useSameBankAccount;

    const [ state, setState ] = useState({
        password: '',
        visibility: false,
        signage: {
            name: '',
            signature: '',
            acceptedTerms: false,
            timestampOfAcceptance: null,
            datedOn: null
        }
    });

    return (
        <Modal
            open={true}
            medium
            onBack={ onBack }
            onClose={ close }
            title={ i18n.t(tokens.titles.currentBankingDetails) }
            primaryButton={{
                label: i18n.t(tokens.buttons.saveBankingDetails),
                onClick: () => validateSignage(state, setState, saveBanking)
            }}
            loader={ loader }
        >
            <Text
                medium
                margin="10px 0"
                bold
                color={ store.getState().whitelabel.primaryColor || Theme.colors.skyBlue }
            >
                { i18n.t(tokens.labels.banking.pleaseEnsureYourInformationIsCorrect) }
            </Text>

            {
                useSameBankAccount ?
                    _detailsBody(newBankingDetails.default)
                    :
                    <>
                        <Text mediumLarge bold>
                            { i18n.t(tokens.titles.reimbursementAccount) }
                        </Text>

                        { _detailsBody(newBankingDetails.default) }

                        <Text mediumLarge bold margin="20px 0 0 0">
                            { i18n.t(tokens.titles.premiumPaymentAccount) }
                        </Text>

                        { _detailsBody(newBankingDetails.paymentsOnly) }
                    </>
            }

            <Text large bold margin="20px 0 10px 0">{ i18n.t(tokens.labels.confirmation) }</Text>

            <FormGroup row style={{ marginTop: 20 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={ state.signage.acceptedTerms }
                            onChange={ () => _handleSignageChange('acceptedTerms', !state.signage.acceptedTerms, state, setState) }
                            value="acceptedTerms"
                            color="secondary"
                        />
                    }
                    label={ i18n.t(tokens.labels.banking.haveVerifiedBankingInformation) }
                />
            </FormGroup>

            <TextInput
                label={ i18n.t(tokens.labels.yourName) }
                fullWidth
                value={state.signage.name}
                onChange={(e) => _handleSignageChange('name', e.target.value, state, setState)}
                error={ state.signageNameError }
            />

            <TextInput
                label={ i18n.t(tokens.labels.signature) }
                fullWidth 
                value={state.signage.signature}
                onChange={(e) => _handleSignageChange('signature', e.target.value, state, setState)}
                isSignature={true}
                error={ state.signageSignatureError }
            />

            <DateInput
                label={ i18n.t(tokens.labels.account.datedOnYYYYMMDD)}
                fullWidth
                value={state.signage.datedOn}
                onChange={(e) => _handleSignageChange('datedOn', e.target.value, state, setState)}
                error={ state.signageDatedOnError }
            />

            <TextInput
                label={ i18n.t(tokens.labels.banking.accountPassword) }
                value={state.password}
                inputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {
                                state.visibility ?
                                    <VisibilityIcon
                                        style={{ cursor: "pointer", color: Theme.colors.darkGrey, marginRight: 8 }}
                                        onClick={ () => setState({ ...state, visibility: !state.visibility }) }
                                    />
                                    :
                                    <VisibilityOffIcon
                                        style={{ cursor: "pointer", color: Theme.colors.darkGrey, marginRight: 8 }}
                                        onClick={ () => setState({ ...state, visibility: !state.visibility }) }
                                    />
                            }
                        </InputAdornment>
                    )
                }}
                onChange={(e) => _handleSignageChange('password', e.target.value, state, setState)}
                error={Boolean(state.passwordError)}
                helperText={state.passwordError}
                secure={!state.visibility}
                fullWidth
                autofocus
            />
        </Modal>
    );
};

export default ConfirmBankingDetails;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MessageCard, Loader, ContainerControlSite, Content, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';

import AccountList from '../AccountList.js';
import * as events from '../../../../services/events';


class pendingAccountsAccounts extends Component {
    state = {
        pendingAccounts: []
    };

    componentDidMount() {
        const pendingAccounts = [];

        this.props.accounts.map((account) => {
            if (account.status === 0) {
                pendingAccounts.push(account);
            }

            return null;
        });

        this.setState({
            pendingAccounts
        });

        events.record('view_pending_accounts');
    }

    render() {
        const { whitelabel } = this.props;

        return (
            <ContainerControlSite>
                <Content>
                    <Title title={ i18n.t(tokens.titles.pendingAccounts) } />

                    {
                        this.state.pendingAccounts.length === 0 ?
                            (
                                <MessageCard
                                    title={ i18n.t(tokens.labels.advisor.noPendingAccounts) }
                                    subtitle={ i18n.t(tokens.labels.advisor.youDoNotHavePendingAccounts) }
                                />
                            )
                            :
                            (
                                <AccountList accounts={this.state.pendingAccounts} />
                            )}
                </Content>

                {this.props.loading && <Loader whitelabel={ whitelabel } />}

                <span id="accounts-pending" />
            </ContainerControlSite>
        );
    }
}

pendingAccountsAccounts.propTypes = {
    loading: PropTypes.bool,
    accounts: PropTypes.array,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{accounts: Array, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.advisor.loading,
    accounts: state.advisor.accounts,
    whitelabel: state.whitelabel
});

export default (connect(mapStateToProps)(pendingAccountsAccounts));
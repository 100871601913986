import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ContainerControlSite, Content, Title, i18n, tokens, PlanOverview } from '@simplybenefits/sb-ui-library';
import { getAdminPdf } from '../../../actions/admin.js';
import { getAdvisorPdf } from '../../../actions/advisor.js';
import * as events from '../../../services/events';

class Overview extends PureComponent {
    downloadPlanDesign = () => {
        if (this.props.isAdvisor) {
            this.props.dispatch(getAdvisorPdf("Plan", i18n.t(tokens.labels.planDesignFileName), { accountID: this.props.accountID }));
        } else {
            this.props.dispatch(getAdminPdf("Plan", i18n.t(tokens.labels.planDesignFileName)));
        }
    };

    render() {
        const {
            isAdvisor,
            whitelabel,
            plan,
            accountID
        } = this.props;

        if (isAdvisor) {
            return (
                <React.Fragment>
                    <PlanOverview
                        whitelabel={ whitelabel }
                        plan={ plan }
                        meta={ this.props.advisorAccountMeta }
                        accountID={ accountID }
                        downloadPlanDesign={ this.downloadPlanDesign }
                        events={ events }
                        isAdvisor={ isAdvisor }
                        language={ this.props.advisorMeta.language }
                        specialityProductMetaConfigs={ this.props.specialityProductMetaConfigs }
                    />

                    <span id="plan-overview" />
                </React.Fragment>
            );
        }

        return (
            <ContainerControlSite>
                <Content>
                    <Title title={ i18n.t(tokens.labels.admin.planOverview) } />

                    <PlanOverview
                        whitelabel={ whitelabel }
                        plan={ plan }
                        meta={ this.props.adminAccountMeta }
                        accountID={ accountID }
                        downloadPlanDesign={ this.downloadPlanDesign }
                        events={ events }
                        isAdvisor={ isAdvisor }
                        language={ this.props.adminMeta.language }
                        specialityProductMetaConfigs={ this.props.specialityProductMetaConfigs }
                    />
                </Content>

                <span id="plan-overview" />
            </ContainerControlSite>
        );
    }
}

/**
 *
 * @param state
 * @returns {{accountID: *, meta, whitelabel, plan}}
 */
const mapStateToProps = (state) => ({
    whitelabel: state.whitelabel,
    plan: state.plan.data,
    accountID: state.advisor?.currentAccountMeta?.accountID,
    adminMeta: state.admin.meta,
    advisorMeta: state.advisor.meta,
    adminAccountMeta: state.admin.account,
    advisorAccountMeta: state.advisor.currentAccountMeta,
    specialityProductMetaConfigs: state.plan.specialityProductMetaConfigs
});

export default (connect(mapStateToProps)(Overview));
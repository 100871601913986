import events from '@simplybenefits/sb-events';
import store from '../redux/store.js';
import * as session from './session.js';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

/**
 * Record normal event
 * @param type
 * @param meta
 * @param identifier
 */
function record(type, identifier = null, meta = null) {

    // This turns off the event errors for now until we start using the events.log again
    if (events) {
        return null;
    }

    const isAdvisor = Number(session.getUserType()) === 1;
    const isMobile = false;
    const id = identifier || {};

    if (!session.getToken()) {
        return null;
    }

    if (!identifier) {
        if (isAdvisor && !store.getState().advisor.meta) {
            return null;
        }

        if (!isAdvisor && !store.getState().admin.meta) {
            return null;
        }

        if (isAdvisor) {
            id.advisorID = store.getState().advisor.meta.advisorID;
        } else {
            id.adminID = store.getState().admin.meta.adminID;
        }
    }

    try {
        events.log(type, id, meta, isMobile, isProduction);
    } catch (err) {
        console.log('Event Failed: ', err);
    }

    return null;
}

export {
    record
};
import request from './request.js';

/**
 * @returns {Promise<unknown>}
 */
function getWhiteLabel() {
    const options = {
        endpoint: `/admin/whitelabel`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * Login as an Admin
 * @param username
 * @param password
 * @returns {Promise<any>}
 */
function login(username, password, language) {
    const options = {
        endpoint: '/login',
        method: 'POST',
        skipAuth: true,
        body: {
            username,
            password,
            language
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function sendForgotPasswordEmail(body) {
    const options = {
        endpoint: '/admin/sendForgotPasswordEmail',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * Get all the account data for the admin
 */
function getAccount() {
    const options = {
        endpoint: '/admin',
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param {*} body
 */
function updateAccount(body) {
    const options = {
        endpoint: '/admin/updateAccountSettings',
        method: 'PUT',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param data
 * @param accountID
 * @param isAdvisor
 * @returns {Promise<any>}
 */
function create(data, accountID, isAdvisor) {
    const options = {
        endpoint: isAdvisor ? `/advisor/${ accountID }/admin` : '/admin',
        method: 'POST',
        body: {
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            selectedDivisions: data.selectedDivisions,
            isParent: data.isParent,
            language: data.language
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param adminID
 * @param isAdvisor
 * @returns {Promise<any>}
 */
function remove(adminID, isAdvisor) {
    const options = {
        endpoint: isAdvisor ? `/advisor/admin/${ adminID }` : `/admin/${ adminID }`,
        method: 'DELETE'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param data
 * @param adminID
 * @param isAdvisor
 * @returns {Promise<any>}
 */
function update(data, adminID, isAdvisor) {
    const options = {
        endpoint: isAdvisor ? `/advisor/admin/${ adminID }` : `/admin/${ adminID }`,
        method: 'PUT',
        body: {
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            selectedDivisions: data.selectedDivisions,
            isParent: data.isParent,
            language: data.language
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function updateAdminSettings(body) {
    const options = {
        endpoint: '/admin',
        method: 'PUT',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param password
 * @returns {Promise<any>}
 */
function changePassword(existing, password) {
    const options = {
        endpoint: `/admin/password`,
        method: 'PUT',
        body: {
            existing,
            password
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function notifyAllUsers(body) {
    const options = {
        method: 'POST',
        endpoint: `/admin/notify/users`,
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param userID
 * @param body
 * @returns {Promise<any>}
 */
function notifyUser(userID, body) {
    const options = {
        method: 'POST',
        endpoint: `/admin/notify/users/${ userID }`,
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function saveBankingDetails(body) {
    const options = {
        endpoint: '/admin/banking',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param userID
 * @param body
 * @returns {Promise<unknown>}
 */
function saveMemberBankingDetails(userID, body) {
    const options = {
        endpoint: `/admin/banking/member/${ userID }`,
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * 
 * @param {*} userID 
 * @returns 
 */
function getMemberBankingDetails(userID) {
    const options = {
        endpoint: `/admin/banking/member/${ userID }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * 
 * @param {*} accountID
 * @returns 
 */
function getAccountBankingDetails(accountID) {
    const options = {
        endpoint: `/admin/getAccountBankingDetails/${ accountID }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param id
 * @returns {Promise<any>}
 */
function removeNotification(id) {
    const options = {
        endpoint: `/notifications/${ id }`,
        method: 'DELETE'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param userID
 * @param accountID
 * @returns {Promise}
 */
function getEmployee(userID, accountID) {
    const options = {
        endpoint: `/admin/${ accountID }/employee/${ userID }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param startDate
 * @param endDate
 * @returns {Promise}
 */
function getChangelog() {
    const options = {
        endpoint: `/admin/changelog`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise}
 */
function downloadSalaryUpdateData() {
    const options = {
        endpoint: `/admin/massSalaryFile`,
        method: 'GET'
    };
    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * Add census form
 * @param body
 * @returns {Promise<any>}
 */
function uploadMassSalaryForm(body) {
    const options = {
        endpoint: `/admin/massSalaryFile`,
        method: 'POST',
        noJson: true,
        body
    };
    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param body
 * @returns {Promise<unknown>}
 */
function uploadMassUpdateForm(body) {
    const options = {
        endpoint: '/admin/massUpdate/upload',
        method: 'POST',
        noJson: true,
        body
    };
    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<unknown>}
 */
function getPendingChanges() {
    const options = {
        endpoint: `/admin/pendingChanges`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param changeID
 * @returns {Promise<unknown>}
 */
function getPendingChangeData(changeID) {
    const options = {
        endpoint: `/admin/pendingChanges/${ changeID }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param changeID
 * @returns {Promise<unknown>}
 */
function removePendingChange(changeID) {
    const options = {
        endpoint: `/admin/pendingChanges/${ changeID }`,
        method: 'DELETE'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<unknown>}
 */
function getLsaItems() {
    const options = {
        endpoint: `/admin/plan/lsaItems`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param institutionNum
 * @param transitNum
 * @returns {Promise<unknown>}
 */
function validateBankingDetails(institutionNum, transitNum) {
    const options = {
        endpoint: '/admin/branchDetails/validate',
        method: 'POST',
        body: {
            institutionNum,
            transitNum
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param password
 * @param token
 * @returns {Promise<unknown>}
 */
function resetPassword(password, token) {
    const options = {
        endpoint: '/auth/resetPassword',
        method: 'POST',
        skipAuth: true,
        body: {
            password,
            token
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    getAccount,
    updateAccount,
    create,
    update,
    updateAdminSettings,
    remove,
    login,
    changePassword,
    notifyAllUsers,
    notifyUser,
    sendForgotPasswordEmail,
    saveBankingDetails,
    removeNotification,
    getWhiteLabel,
    getEmployee,
    downloadSalaryUpdateData,
    uploadMassSalaryForm,
    uploadMassUpdateForm,
    getChangelog,
    getPendingChanges,
    getPendingChangeData,
    removePendingChange,
    saveMemberBankingDetails,
    getMemberBankingDetails,
    getAccountBankingDetails,
    getLsaItems,
    validateBankingDetails,
    resetPassword
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MessageCard, Loader, ContainerControlSite, Content, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';
import AccountList from '../AccountList.js';
import { getAccountsUnderAmendment } from '../../../../actions/advisor.js';

class UnderAmendment extends Component {
    state = {
        accountsUnderAmendment: null
    };

    async componentDidMount() {
        const accountsUnderAmendment = await this.props.dispatch(getAccountsUnderAmendment(this.props.advisorMeta.advisorID));

        if (accountsUnderAmendment) {
            this.setState({
                accountsUnderAmendment
            });
        }
    }

    render() {
        const { whitelabel } = this.props;

        if (!this.state.accountsUnderAmendment) {
            return <Loader whitelabel={ whitelabel } />;
        }

        return (
            <ContainerControlSite>
                <Content>
                    <Title title={ i18n.t(tokens.labels.advisor.accountsUnderAmendment)} />

                    {
                        this.state.accountsUnderAmendment.length === 0 ?
                            (
                                <MessageCard
                                    title={ i18n.t(tokens.labels.advisor.noAccountsUnderAmendment)}
                                    subtitle={ i18n.t(tokens.labels.advisor.noAccountsUnderAmendmentText)}
                                />
                            )
                            :
                            (
                                <AccountList accounts={this.state.accountsUnderAmendment} />
                            )}
                </Content>

                {this.props.loading && <Loader whitelabel={ whitelabel } />}

                <span id="accounts-amendment" />
            </ContainerControlSite>
        );
    }
}

UnderAmendment.propTypes = {
    loading: PropTypes.bool,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{accounts: Array, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.advisor.loading,
    whitelabel: state.whitelabel,
    advisorMeta: state.advisor.meta
});

export default connect(mapStateToProps)(UnderAmendment);
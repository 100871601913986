import * as changeRequests from '../xhr/changeRequests';
import * as loader from './loader.js';
import * as notifier from './notifier';
import { i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as session from '../services/session';

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'container') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}


/**
 * @param form
 * @param type
 * @returns {function(*=): Promise<boolean>}
 */
export function createChangeRequest(data) {
    return (dispatch) => {
        setLoader(dispatch, true, 'modal');

        return changeRequests
            .createChangeRequest(data)
            .then((changeRequestID) => {
                setLoader(dispatch, false);
                return changeRequestID;
            })
            .catch((error) => {
                setLoader(dispatch, false);

                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param form
 * @param type
 * @returns {function(*=): Promise<boolean>}
 */
export function submitChangeRequest(changeRequestID, data) {
    return (dispatch) => {
        setLoader(dispatch, true, 'modal');

        return changeRequests
            .submitChangeRequest(changeRequestID, data)
            .then(() => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.changeRequests.changeRequestSuccessfullySent)));
                return true;
            })
            .then(() => {
                window.location.href = '/memberChanges?success=true';
            })
            .catch((error) => {
                setLoader(dispatch, false);

                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param form
 * @param type
 * @returns {function(*=): Promise<boolean>}
 */
export function deleteChangeRequest(changeRequestUUID) {
    return (dispatch) => {
        setLoader(dispatch, true, 'modal');

        return changeRequests
            .deleteChangeRequest(changeRequestUUID)
            .then(() => {
                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);

                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 *
 * @param changeRequestID
 * @param changeRequest
 * @returns {function(*): Promise<*>}
 */
export function approveChangeRequest(changeRequestID, changeRequest) {
    return (dispatch) => {
        setLoader(dispatch, true, 'modal');
        return changeRequests.approveChangeRequest(changeRequestID, changeRequest)
            .then((data) => {
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.changeRequests.changeRequestSuccessfullyApproved)));
                setLoader(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 *
 * @param changeRequestID
 * @param changeRequest
 * @returns {function(*): Promise<*>}
 */
export function declineChangeRequest(changeRequestID, changeRequest) {
    return (dispatch) => {
        setLoader(dispatch, true, 'modal');
        return changeRequests.declineChangeRequest(changeRequestID, changeRequest)
            .then((data) => {
                setLoader(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 *
 * @param changeRequestID
 * @param id
 * @param reviewerID
 * @returns {function(*): Promise<*>}
 */
export function getChangeRequestDataReadOnly(changeRequestID, id, reviewerID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return changeRequests.getChangeRequestDataReadOnly(changeRequestID, id, reviewerID)
            .then((data) => {
                session.setToken(data.token);
                setLoader(dispatch, false);
                return data.changeRequest;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 *
 * @param filters
 * @param cursor
 * @returns {function(*): Promise<unknown>}
 */
export function getChangeRequests(cursor) {
    return (dispatch) => {
        setLoader(dispatch, true, 'modal');
        return changeRequests.getAllChangeRequests(cursor)
            .then((data) => {
                setLoader(dispatch, false, 'modal');
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'modal');
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * 
 * @param changeRequestID 
 * @returns 
 */
export function downloadChangeRequestPdf(changeRequestID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return changeRequests.downloadChangeRequestPdf(changeRequestID)
            .then((data) => {
                setLoader(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * 
 * @param changeRequestID 
 * @returns 
 */
export function resendChangeRequestLink(changeRequestID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return changeRequests.resendChangeRequestLink(changeRequestID)
            .then((data) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.changeRequests.emailSentForReview)));
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 *
 * @param changeRequestID
 * @returns {function(*): Promise<*>}
 */
export function getChangeRequestFile(changeRequestID) {
    return (dispatch) => {
        setLoader(dispatch, true, 'modal');
        return changeRequests.getFile(changeRequestID)
            .then((data) => {
                setLoader(dispatch, false, 'modal');
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'modal');
                dispatch(notifier.displayError(error));
            });
    };
}
import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import {
    DatePicker,
    Modal,
    TextInput,
    MonthPicker,
    Text,
    SearchBar,
    HorizonalTile,
    Theme,
    i18n,
    tokens,
    localizationMaps
} from "@simplybenefits/sb-ui-library";
import { helpers } from '@simplybenefits/sb-utils';
import { generateReport } from '../../actions/reports.js';
import Report from './Report.js';
import store from '../../redux/store.js';
import NumberInput from '@simplybenefits/sb-ui-library/dist/components/TextFields/NumberInput';
import advisorReportsData from './advisorReportsData.js';
import adminReportsData from './adminReportsData.js';
import { OpenInNew } from '@mui/icons-material';

class Overview extends Component {
    state = {
        modalOpen: false,
        reportType: null,
        filters: {},
        titleExpansionKeys: [],
        reportMeta: {},
        divisionOptions: [],
        memberOptions: [],
        dateRangeForReport: null,
        query: '',
        isDatePickerOpen: false
    };

    constructor(props) {
        super(props);
        this.scroll = React.createRef();
    }

    componentDidMount() {
        this.reportDiv = React.createRef();

        // set the divisionOptions
        this.setState({
            divisionOptions: this.props.isAdvisor || this.props.admin.meta.isParent ?
                [ i18n.t(tokens.labels.reports.allDivisions), ...helpers.sortObjByTitle(this.props.divisions).map((key) => this.props.divisions[key].title) ]
                :
                [ 'All Assigned Divisions', ...helpers.sortObjByTitle(this.props.divisions).map((key) => this.props.divisions[key].title) ],
            classOptions: [ i18n.t(tokens.labels.reports.allClasses), ...helpers.sortObjByTitle(this.props.censusClasses).map((key) => this.props.censusClasses[key].title) ],
            memberOptions: [ 'All Members', ...this.props.employees.map((employee) => `${ employee.firstname } ${ employee.lastname }`) ]
        });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyPress, false);
    }

    // Check if the Aso Report should be hidden
    _hideAso({ isAsoHealth, isAsoDental, asoType }) {
        const isBudgetedASO = (isAsoHealth || isAsoDental) && asoType === 'Budgeted';
        return isBudgetedASO !== true;
    }

    _setFilter = (type, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [type]: {
                    ...this.state.filters[type],
                    value
                }
            }
        });
    };

    /**
     * Used in the datepicker to set local state
     * @param startDate
     * @param endDate
     */
    _submitDate = (dateRange) => {
        this.setState({
            filters: {
                ...this.state.filters,
                dateRange: {
                    ...this.state.filters.dateRange,
                    ...dateRange
                }
            }
        });
    };

    _setIsDatePickerOpen = async (toggleValue) => {
        if (toggleValue === null) {
            await this.setState({
                isDatePickerOpen: false
            });
        }

        if (toggleValue !== null) {
            await this.setState({
                isDatePickerOpen: true
            });
        }
    };

    _toggleModal = (modalAction = 'close', reportType = false, filters = {}, titleExpansionKeys = []) => {
        if (modalAction === 'open') {
            // check to see if the filters has divisions
            if (Object.keys(filters).includes('division')) {
                // Change the value of divisions filter value to the first option in division options
                filters.division.value = this.state.divisionOptions[0];
            }

            this.setState({
                reportType,
                filters,
                titleExpansionKeys,
                modalOpen: true
            });
        } else {
            this.setState({
                filters,
                modalOpen: false
            });
        }

        document.removeEventListener("keydown", this._handleKeyPress, false);
    };

    // eslint-disable-next-line consistent-return
    _generateReport = async () => {

        if (this.state.filters?.dateRange?.error) {
            return null;
        }

        this._toggleModal('close');

        const filtersForReport = {};
 
        const filterKeys = Object.keys(this.state.filters);
        
        filterKeys.map((filterKey) => {
            if (filterKey === 'dateRange') {
                filtersForReport[filterKey] = {
                    startDate: this.state.filters.dateRange.startDate,
                    endDate: this.state.filters.dateRange.endDate
                };
                
                return true;
            }
            
            filtersForReport[filterKey] = this.state.filters[filterKey].value;
            
            return true;
        });
        
        const { isAdvisor, currentAccountMeta, account } = this.props;
        const { groupNum, company } = isAdvisor ? currentAccountMeta : account;
        
        this.setState({
            titleExpansionKeys: this.state.titleExpansionKeys,
            reportMeta: { groupNum, company, ...filtersForReport },
            dateRangeForReport: filterKeys.includes('dateRange') ?
                {
                    startDate: moment(this.state.filters.dateRange.startDate).format("ll"),
                    endDate: moment(this.state.filters.dateRange.endDate).format('ll')
                }
                : null
        });

        const reportTypeEnglish = localizationMaps.toEnglish("reportTypes", this.state.reportType);
        
        // we have to translate the all of the filters from whatever language it is stored in into english before we generate the report
        if (localizationMaps.toEnglish("divisions", filtersForReport.division) === "All Divisions") { 
            filtersForReport.division = "All Divisions";    
        }

        if (localizationMaps.toEnglish("classes", filtersForReport.class) === "All Classes") {
            filtersForReport.class = "All Classes";
        }

        if (filtersForReport.status) {
            filtersForReport.status = localizationMaps.toEnglish("memberStatuses", filtersForReport.status);
        }

        if (filtersForReport.type) {
            filtersForReport.type = localizationMaps.toEnglish("reportHeadings", filtersForReport.type);
        }

        if (filtersForReport.benefit) {
            filtersForReport.benefit = reportTypeEnglish === 'NEM Eligibility'
                ? localizationMaps.toEnglish("nemBenefitOptions", filtersForReport.benefit)
                : localizationMaps.toEnglish("healthDentalOptions", filtersForReport.benefit);
        }

        await this.props.dispatch(generateReport(reportTypeEnglish, filtersForReport, this.props.currentAccountMeta ? this.props.currentAccountMeta.accountID : null));
        
        this.scroll.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    
    _handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this._generateReport();
        }
    };

    _handleSearchChange = (value) => {
        this.setState({
            query: value
        });
    };

    render() {
        const { isAdvisor, report, plan } = this.props;
        const { modalOpen, divisionOptions, classOptions, memberOptions, filters } = this.state;
        const whitelabel = store.getState().whitelabel;
        const styles = {
            modalTall: {
                width: 500,
                height: this.state.isDatePickerOpen ? "575px" : "auto",
                flex: "none"
            },
            modalSmall: {
                width: 500,
                height: 'auto'
            },
            form: {
                display: 'flex',
                flexDirection: 'column'
            },
            reportTiles: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                maxWidth: '1000px',
                margin: '0 auto 24px'
            }
        };

        let hasSpendingAccounts = false;
        let hasHSA = false;
        let hasLSA = false;

        // Admin View
        if (!isAdvisor) {
            // Find out if the account has HSA or LSA
            if (plan?.data?.spendingAccounts) {
                plan.data.spendingAccounts.map((classSa) => {
                    if (classSa?.coverage && classSa.coverage.isSelected) {
                        if (classSa.coverage.pooledAmountAvailable && classSa.coverage.isPooled) {
                            hasSpendingAccounts = true;
                        }

                        if (classSa.coverage.hsaAmount && classSa.coverage.includeHsa) {
                            hasHSA = true;
                        }

                        if (classSa.coverage.lsaAmount && classSa.coverage.includeLsa) {
                            hasLSA = true;
                        }
                    }

                    return false;
                });
            }
        }

        const reportsData = isAdvisor ? advisorReportsData() : adminReportsData();

        // Get all the sections that will be shown so that we can let the user know if we can't find any reports from their search
        const allReportSections = Object.keys(reportsData).map((subSectionKey) => {
            const reports = reportsData[subSectionKey];
            // Get all reports that should be shown to see if we should even show this section
            const reportsToShow = Object.keys(reports).map((reportKey) => {
                const reportData = reports[reportKey];

                // filter out reports based on search
                if (this.state.query
                    && !reportKey.toLowerCase().replace(/\s/g, '').replace('report', '').includes(this.state.query.toLowerCase().replace(/\s/g, ''))
                    && (
                        !reportData.title
                        || !reportData.title.toLowerCase().replace(/\s/g, '').replace('report', '').includes(this.state.query.toLowerCase().replace(/\s/g, ''))
                    )
                    && (
                        !reportData.description
                        || !reportData.description.toLowerCase().replace(/\s/g, '').replace('report', '').includes(this.state.query.toLowerCase().replace(/\s/g, ''))
                    )
                ) {
                    return null;
                }

                // Filter out reports based on account and plan info
                if (reportKey === i18n.t(tokens.labels.reports.hsaBalance) && !hasSpendingAccounts && !hasHSA) {
                    return null;
                }

                if (reportKey === i18n.t(tokens.labels.reports.lsaBalance) && !hasSpendingAccounts && !hasLSA) {
                    return null;
                }

                // Aso report will not be displayed if asoHealth/asoDental are false and asoType is not Budgeted
                const hideAsoReport = isAdvisor ? this._hideAso(this.props.currentAccountMeta) : this._hideAso(this.props.account);

                if (reportKey === i18n.t(tokens.labels.reports.asoMonthlySummary) && hideAsoReport) {
                    return null;
                }

                return (
                    <HorizonalTile
                        key={reportKey}
                        testid={ reportData.testid }
                        title={ `${ reportData.title || reportKey } ${ i18n.t(tokens.labels.reports.report) }` }
                        description={ reportData.description }
                        icon={{
                            name: reportData.icon,
                            color: whitelabel.primaryColor || Theme.colors.skyBlue
                        }}
                        customStyle={{ width: '49%', marginBottom: 16 }}
                        onClick={ () => this._toggleModal('open', reportKey, reportData.filters, reportData.titleExpansionKeys) }
                    />
                );
            }).filter((tile) => tile !== null); // filter out tiles that won't be shown

            if (reportsToShow.length === 0) {
                return null;
            }

            return (
                <div key={subSectionKey}>
                    {
                        subSectionKey !== 'All' &&
                            <Text
                                margin="0 0 16px"
                                medium
                                bold
                            >
                                { subSectionKey }
                            </Text>
                    }

                    <div style={ styles.reportTiles }>
                        { reportsToShow }
                    </div>
                </div>
            );
        }).filter((tile) => tile !== null); // filter out sections that won't be shown;

        return (
            <div>
                <div style={{ maxWidth: '1000px', margin: '0 auto' }}>

                    <SearchBar
                        onChange={ (e) => this._handleSearchChange(e.target.value) }
                        search={ (e) => this._handleSearchChange(e) }
                        placeholder= { i18n.t(tokens.labels.search) }
                        iconColor={ Theme.colors.skyBlue }
                        customStyle={{
                            margin: '16px auto 32px',
                            width: '100%',
                            backgroundColor: Theme.colors.surface
                        }}
                    />

                    {
                        allReportSections.length === 0 ?
                            <Text
                                margin="0 0 16px"
                                medium
                                bold
                            >
                                { i18n.t(tokens.labels.claims.noResultsFound) }
                            </Text>
                            :
                            allReportSections
                    }
                </div>

                {
                    report &&
                        <Report
                            report={ report }
                            dateRange={ this.state.dateRangeForReport }
                            reportMeta={ this.state.reportMeta }
                            titleExpansionKeys={ this.state.titleExpansionKeys }
                            scrollRef={this.scroll}
                        />
                }

                <Modal
                    open={ modalOpen }
                    medium
                    onClose={ this._toggleModal }
                    title={ `${ this.state.reportType } ${ i18n.t(tokens.labels.reports.report) }` }
                    primaryButton={{
                        label: i18n.t(tokens.buttons.generate),
                        onClick: this._generateReport
                    }}
                >
                    {
                        Object.keys(filters).map((filterKey) => {
                            const filter = this.state.filters[filterKey];

                            if (filter.type === 'select') {
                                let options = filter.options;
                                let value = filters[filterKey].value || 'All';
                                let needsHelperText = false;
                                let parentAdminData = null;

                                switch (filterKey) {
                                    case 'division':
                                        options = divisionOptions;
                                        needsHelperText = !this.props.isAdvisor && !this.props.admin.meta.isParent;

                                        parentAdminData = needsHelperText ?
                                            this.props.admin.administrators[Object.keys(this.props.admin.administrators).find((key) => this.props.admin.administrators[key].isParent === 1)]
                                            :
                                            null;

                                        break;
                                    case 'class':
                                        options = classOptions;
                                        break;
                                    case 'member':
                                        options = memberOptions;
                                        break;
                                    default:
                                        options = filter.options;
                                        break;
                                }

                                return (
                                    <>
                                        <TextInput
                                            key={ filterKey }
                                            label={ filter.label }
                                            variant="outlined"
                                            value={ value }
                                            onChange={ (e) => this._setFilter(filterKey, e.target.value) }
                                            selectOptions={ options }
                                            select
                                            fullWidth
                                        />
                                        {
                                            needsHelperText ?
                                                <>
                                                    <Text margin="12px 0 0" medium>
                                                        {
                                                            `You only have access to data from divisions you have been assigned to by your Parent Admin. If you require data from additional divisions, contact the Parent Admin, ${ parentAdminData.firstname } ${ parentAdminData.lastname }.`
                                                        }
                                                    </Text>
                                                    <Text margin="12px 0 0" small>
                                                        Read more about{ ' ' }
                                                        <a
                                                            href="https://help.simplybenefits.ca/support/solutions/articles/47001127049-plan-administrators-permissions"
                                                            target="_blank"
                                                            style={{
                                                                textDecorationColor: whitelabel.primaryColor || Theme.colors.skyBlue,
                                                                color: whitelabel.primaryColor || Theme.colors.skyBlue
                                                            }}
                                                        >
                                                            plan administrators <OpenInNew style={{ fontSize: 18, verticalAlign: 'top' }} />
                                                        </a>
                                                    </Text>
                                                </>
                                                :
                                                null
                                        }
                                    </>
                                );
                            } else if (filter.type === 'number') {
                                return (
                                    <NumberInput
                                        key={ filterKey }
                                        label={ filter.label }
                                        value={ filters[filterKey].value }
                                        onChange={ (e) => this._setFilter(filterKey, e.target.value) }
                                        error={ Boolean(filters[filterKey].error) }
                                        helperText={ filter.isRequired ? filters[filterKey].error : 'Leave Blank For All' }
                                        fullWidth
                                        required={ filter.isRequired }
                                    />
                                );
                            } else if (filter.type === 'text') {
                                return (
                                    <TextInput
                                        key={ filterKey }
                                        label={ filter.label }
                                        variant="outlined"
                                        value={ this.state.filters[filterKey].value }
                                        onChange={ (e) => this._setFilter(filterKey, e.target.value) }
                                        error={ Boolean(filters[filterKey].error) }
                                        helperText={ filters[filterKey].error }
                                        fullWidth
                                    />
                                );
                            } else if (filter.type === 'date') {
                                return (
                                    <DatePicker
                                        key={ filterKey }
                                        startDate={ filters[filterKey].startDate }
                                        endDate={ filters[filterKey].endDate }
                                        submitDate={ this._submitDate }
                                        defaultSelectedValue={ localizationMaps.toEnglish("reportTypes", this.state.reportType) === 'Over Age Member Listing' ? 'Next 6 months' : "This Year" }
                                        futureDate={ localizationMaps.toEnglish("reportTypes", this.state.reportType) === 'Over Age Member Listing' }
                                        customStyle={{ marginTop: "16px" }}
                                        isPickerOpen={this._setIsDatePickerOpen}
                                    />
                                );
                            } else if (filter.type === 'month') {
                                if ([ 'from', 'to' ].includes(filterKey)) {
                                    return (
                                        <React.Fragment key={ filterKey }>
                                            <Text small margin="12px 0 0">{ filterKey === "to" ? i18n.t(tokens.labels.reports.to) : i18n.t(tokens.labels.reports.from) }</Text>
                                            <MonthPicker
                                                customStyle={{ marginTop: "8px" }}
                                                value={ filter.value }
                                                onChange={ (value) => this._setFilter(filterKey, value) }
                                            />
                                        </React.Fragment>
                                    );
                                }

                                return (
                                    <MonthPicker
                                        key={filterKey}
                                        value={ filter.value }
                                        onChange={ (value) => this._setFilter(filterKey, value)}
                                    />
                                );
                            }

                            return '';
                        })
                    }

                    <div id="reports-card" />
                </Modal>
            </div>
        );
    }
}

/**
 * @param state
 * @returns {{reportType: *, report: *}}
 */
const mapStateToProps = (state) => ({
    report: state.reports.current,
    reportType: state.reports.reportType,
    divisions: state.census.divisions,
    censusClasses: state.census.classes,
    employees: state.census.employees,
    plan: state.plan,
    account: state.admin.account,
    currentAccountMeta: state.advisor.currentAccountMeta,
    admin: state.admin
});

export default (connect(mapStateToProps)(Overview));
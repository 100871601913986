import request from './request.js';

/**
 * @param accountID
 * @returns {Promise<any>}
 */
function submitPlanDesigns(accountID, quoteID) {
    const options = {
        method: 'POST',
        endpoint: `/advisor/${ accountID }/plan/design/submit`,
        body: {
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function submitModuleBlueprint(accountID, quoteID) {
    const options = {
        method: 'POST',
        endpoint: `/advisor/${ accountID }/plan/modularFlex/submit`,
        body: {
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param classID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function createDesign(classID, quoteID) {
    const endpoint = `/advisor/plan/design/${ classID }`;
    const options = {
        method: 'POST',
        endpoint,
        body: {
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param designID
 * @param body
 * @param effectiveDate
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function saveDesign(designID, body, effectiveDate, quoteID) {
    const endpoint = `/advisor/plan/design/${ designID }`;
    const options = {
        method: 'PUT',
        endpoint,
        body: {
            ...body,
            quoteID: quoteID || null,
            effectiveDate: effectiveDate || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param designID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function cloneDesign(designID, quoteID) {
    const endpoint = `/advisor/plan/design/${ designID }/clone`;
    const options = {
        method: 'POST',
        endpoint,
        body: {
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param designID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function getPlanDesignRevisions(designID, quoteID) {
    const endpoint = quoteID ? `/advisor/plan/design/${ designID }/revisions?quoteID=${ quoteID }` : `/advisor/plan/design/${ designID }/revisions`;
    const options = {
        method: 'GET',
        endpoint
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param revisionID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function getSinglePlanDesignRevision(revisionID, quoteID) {
    const endpoint = quoteID ? `/advisor/plan/design/revisions/${ revisionID }?quoteID=${ quoteID }` : `/advisor/plan/design/revisions/${ revisionID }`;
    const options = {
        method: 'GET',
        endpoint
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param classID
 * @param body
 * @param quoteID
 * @param effectiveDate
 * @returns {Promise<unknown>}
 */
function saveModularBlueprint(classID, body, quoteID, effectiveDate) {
    const options = {
        method: 'PUT',
        endpoint: `/advisor/${ classID }/plan/modularFlex/blueprint`,
        body: {
            ...body,
            quoteID: quoteID || null,
            effectiveDate: effectiveDate || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<unknown>}
 */
function getLsaItems(accountID) {
    const options = {
        endpoint: `/advisor/${ accountID }/plan/lsaItems`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param advisorID
 */
function getSpecialityProductMetaConfigs(advisorID) {
    const options = {
        endpoint: `/advisor/${ advisorID }/plan/specialityProducts/configs`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function getSpecialityProductRates(accountID, quoteID) {
    const options = {
        endpoint: quoteID ? `/advisor/${ accountID }/plan/specialityProducts/rates?quoteID=${ quoteID }` : `/advisor/${ accountID }/plan/specialityProducts/rates`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param quoteID
 * @param rates
 * @param effectiveDate
 * @returns {Promise<unknown>}
 */
function saveSpecialityProductRates(accountID, quoteID, rates, effectiveDate = null) {
    const endpoint = `/advisor/${ accountID }/plan/specialityProducts/rates`;
    const options = {
        method: 'POST',
        endpoint,
        body: {
            quoteID: quoteID || null,
            rates,
            effectiveDate
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param quoteID
 * @param rates
 * @returns {Promise<unknown>}
 */
function submitSpecialityProductRates(accountID, quoteID, rates) {
    const endpoint = `/advisor/${ accountID }/plan/specialityProducts/rates/submit`;
    const options = {
        method: 'POST',
        endpoint,
        body: {
            quoteID: quoteID || null,
            rates
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    submitPlanDesigns,
    submitModuleBlueprint,
    createDesign,
    saveDesign,
    cloneDesign,
    getPlanDesignRevisions,
    getSinglePlanDesignRevision,
    saveModularBlueprint,
    getLsaItems,
    getSpecialityProductMetaConfigs,
    getSpecialityProductRates,
    saveSpecialityProductRates,
    submitSpecialityProductRates
};
export default {
    0: 'Pending',
    1: 'Active',
    2: 'Account Disabled',
    3: 'Parental Leave',
    4: 'Leave of Absence',
    5: 'Temporary Lay Off',
    6: 'Disability',
    7: 'Strike',
    8: 'Late Applicant',
    9: 'Terminated',
    10: 'Approved Leave',
    11: 'Survivor Benefits',
    12: 'Severance'
};
import * as advisor from '../xhr/advisor.js';
import * as session from '../services/session.js';
import * as types from './types/advisor.js';
import * as censusTypes from './types/census.js';
import * as planTypes from './types/plan.js';
import * as adminTypes from './types/admin.js';
import * as invoiceTypes from './types/invoices.js';
import * as quotesTypes from './types/quotes.js';
import * as notifier from './notifier.js';
import * as events from '../services/events';
import { generatePdf, generatePdfForQuote, generateAmendmentPDF } from '../xhr/generatePdf.js';
import * as loader from './loader.js';
import { i18n, tokens, localizationMaps } from '@simplybenefits/sb-ui-library';

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'full') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}

/**
 *
 * @param {*} dispatch
 * @param {*} state
 */
function setBankingDetailsVerifying(dispatch, state) {
    dispatch({
        type: types.BANKING_DETAILS_VERIFYING,
        state
    });
}

/**
 * @param username
 * @param password
 * @returns {function(*=): Promise<T | never>}
 */
export function login(username, password) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.login(username, password)
            .then((data) => {
                session.setAdvisorLanguage(data.language);
                session.setToken(data.token);
                session.setUserType(1);
                events.record('login', { advisorID: data.advisorID });

                setLoader(dispatch, false);
                window.location.href = '/advisor/dashboard';

                return true;
            })
            .catch((error) => {
                dispatch(notifier.displayError(error));
                setLoader(dispatch, false);

                return false;
            });
    };
}

/**
 * @param email
 */
export function forgotPassword(email) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor
            .sendForgotPasswordEmail(email)
            .then(() => {
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.passwordChangedSuccessfully)));
                setLoader(dispatch, false);
                events.record('send_temp_password');

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @returns {function(*=): Promise<T | never>}
 */
export function getAdvisor() {
    return (dispatch) => (
        advisor.get()
            .then((data) => {
                session.setToken(data.token);

                const advisorLanguage = data?.meta?.language || "en";
                i18n.setLocale(advisorLanguage);

                const storedLanguage = session.getAdvisorLanguage();
                if (storedLanguage !== advisorLanguage) {
                    session.setAdvisorLanguage(advisorLanguage);
                }  

                dispatch({
                    type: quotesTypes.GET_QUOTES,
                    quotes: data.quotes
                });

                delete data.quotes;

                dispatch({
                    type: types.GET_ADVISOR_SUCCESS,
                    data
                });

                if (data.notifications && data.notifications.length > 0) {
                    dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.youHaveNewNotifications)));
                }

                dispatch({
                    type: planTypes.SET_SPECIALITY_PRODUCT_CONFIGS,
                    data: data.specialityProductMetaConfigs
                });

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));

                return false;
            })
    );
}

/**
 * @param data
 * @returns {function(*=): Promise<T | never>}
 */
export function updateAdvisorSettings(data) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.updateAdvisorSettings(data)
            .then(() => {
                const advisorLanguage = data?.language || "en";
                i18n.setLocale(advisorLanguage);

                const storedLanguage = session.getAdvisorLanguage();
                if (storedLanguage !== advisorLanguage) {
                    session.setAdvisorLanguage(advisorLanguage);
                } 
                
                dispatch({
                    type: types.UPDATE_ADVISOR_SETTINGS_SUCCESS,
                    data
                });

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.updatedAdvisorSettings)));

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @returns {function(*=): Promise<T | never>}
 */
export function getAccount(accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.getAccount(accountID)
            .then((data) => {
                dispatch({
                    type: types.GET_ACCOUNT_SUCCESS,
                    meta: data.meta
                });

                dispatch({
                    type: censusTypes.GET_CENSUS_SUCCESS,
                    users: data.census
                });

                dispatch({
                    type: censusTypes.GET_CLASSES_SUCCESS,
                    classes: data.classes
                });

                dispatch({
                    type: censusTypes.GET_DIVISIONS_SUCCESS,
                    divisions: data.divisions
                });

                dispatch({
                    type: planTypes.GET_PLAN_SUCCESS,
                    plan: data.plan,
                    pricing: data.pricing,
                    designs: data.planDesigns,
                    modularBlueprint: data.modularBlueprint
                });

                dispatch({
                    type: adminTypes.GET_ADMIN_ACCOUNT_SUCCESS,
                    administrators: data.admins
                });

                setLoader(dispatch, false);
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param data
 * @param accountID
 * @returns {function(*=): Promise<T | never>}
 */
export function updateAccountSettings(data, accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.updateAccountSettings(data, accountID)
            .then(() => {
                dispatch({
                    type: types.UPDATE_ACCOUNT_SETTINGS_SUCCESS,
                    data
                });

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.successfullyUpdatedAccount)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param form
 * @param type
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<any>}
 */
export function uploadAccountHistory(form, type, accountID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true, 'accountHistory', Boolean(quoteID));

        return advisor.uploadAccountHistory(form, accountID, quoteID)
            .then(() => {
                setLoader(dispatch, false, 'accountHistory', Boolean(quoteID));
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.accountHistorySubmittedSuccessfully, { type: localizationMaps.fromEnglish("accountHistoryTypes", type) })));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'accountHistory', Boolean(quoteID));
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @returns {function(*=): Promise<any>}
 */
export function setMasterAppBack(accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.setMasterAppBack(accountID)
            .then(() => {
                setLoader(dispatch, false);

                dispatch({
                    type: types.SET_STATE,
                    state: 3,
                    accountID
                });

                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.masterAppSetBack)));
            })
            .catch((error) => {
                setLoader(dispatch, false);
                notifier.displayError(error.verbiage);
            });
    };
}

/**
 * @param existingPassword
 * @param newPassword
 * @returns {function(*=): Promise<boolean>}
 */
export function changeAdvisorPassword(existingPassword, newPassword) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor
            .changePassword(existingPassword, newPassword)
            .then(() => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.passwordChangedSuccessfully)));
                events.record('change_password');

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param effDate
 * @returns {function(*=): Promise<unknown>}
 */
export function makeAccountLive(accountID, effDate) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.makeAccountLive(accountID, effDate)
            .then(() => {
                dispatch({
                    type: types.SET_ACCOUNT_LIVE
                });

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.accountLive)));
                events.record('make_account_live');
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function getMasterApplicationData(accountID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true, 'masterApp', Boolean(quoteID));

        return advisor.getMasterApplicationData(accountID, quoteID)
            .then((data) => {
                setLoader(dispatch, false, 'masterApp', Boolean(quoteID));
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'masterApp', Boolean(quoteID));
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param key
 * @param quoteID
 * @returns {function(*=): Promise<any>}
 */
export function getMasterApplicationDataReadOnly(accountID, key, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, false);

        return advisor.getMasterApplicationDataReadOnly(accountID, key, quoteID)
            .then((data) => {
                setLoader(dispatch, false, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param data
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function sendMasterAppLink(accountID, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, false, Boolean(quoteID));

        return advisor.sendMasterAppLink(accountID, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.SET_STATE,
                        state: 7,
                        quoteID
                    });
                } else {
                    dispatch({
                        type: types.SET_STATE,
                        state: 8,
                        accountID
                    });
                }

                setLoader(dispatch, false, false, Boolean(quoteID));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, false, Boolean(quoteID));
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function sendReviewRequest(accountID, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, false, Boolean(quoteID));

        return advisor.sendReviewRequest(accountID, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.SET_STATE,
                        state: 4,
                        quoteID
                    });
                } else {
                    dispatch({
                        type: types.SET_STATE,
                        state: 5,
                        accountID
                    });
                }

                setLoader(dispatch, false, false, Boolean(quoteID));
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.requestForUnderwriting), 5000));

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, false, Boolean(quoteID));
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @param data
 * @param showLoader
 * @param showSuccess
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function updateMasterApp(accountID, data, showLoader, showSuccess, quoteID = false) {
    return (dispatch) => {
        if (showLoader) {
            setLoader(dispatch, true, 'masterApp', Boolean(quoteID));
        }

        return advisor.updateMasterApp(accountID, data, quoteID)
            .then(() => {
                setLoader(dispatch, false, 'masterApp', Boolean(quoteID));

                if (showLoader && showSuccess) {
                    dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.masterApplicationSavedSuccessfully)));
                }

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'masterApp', Boolean(quoteID));
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @param data
 * @param quoteID
 * @returns {function(*=)}
 */
export function submitMasterApp(accountID, data, quoteID = false) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.submitMasterApp(accountID, data, quoteID)
            .then(() => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.masterApplicationSubmittedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * This function sets the account to say they are done with the master app
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function completeMasterApp(accountID, quoteID = false) {
    return (dispatch) => {
        setLoader(dispatch, true, 'masterApp', Boolean(quoteID));

        return advisor.completeMasterApp(accountID, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.SET_STATE,
                        state: 3,
                        quoteID
                    });
                } else {
                    dispatch({
                        type: types.SET_STATE,
                        state: 4,
                        accountID
                    });
                }

                setLoader(dispatch, false, 'masterApp', Boolean(quoteID));
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.masterApplicationSubmittedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'masterApp', Boolean(quoteID));
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @param data
 * @returns {function(*=): Promise<boolean | never>}
 */
export function saveBankingDetails(accountID, data) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor
            .saveBankingDetails(accountID, data)
            .then(() => {
                dispatch({
                    type: types.PAYMENT_DETAILS_SUCCESS,
                    accountID
                });

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param id
 * @return
 */
export function removeAdvisorNotification(id) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor
            .removeNotification(id)
            .then(() => {
                dispatch({
                    type: types.REMOVE_NOTIFICATION_SUCCESS,
                    id
                });

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param assignedPlans
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function assignPlans(accountID, assignedPlans, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, 'assignPlans', Boolean(quoteID));

        return advisor
            .assignPlans(accountID, assignedPlans, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.SET_STATE,
                        state: 6,
                        quoteID
                    });
                } else {
                    dispatch({
                        type: types.SET_STATE,
                        state: 7,
                        accountID
                    });
                }

                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.planAssignedFinished)));
                setLoader(dispatch, false, 'assignPlans', Boolean(quoteID));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'assignPlans', Boolean(quoteID));
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @param reason
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function rejectPricing(accountID, reason, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, 'assignPlans', Boolean(quoteID));

        return advisor
            .rejectPricing(accountID, reason, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.SET_STATE,
                        state: 4,
                        quoteID
                    });
                } else {
                    dispatch({
                        type: types.SET_STATE,
                        state: 5,
                        accountID
                    });
                }

                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.pricingRejectedSuccessfully)));
                setLoader(dispatch, false, 'assignPlans', Boolean(quoteID));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'assignPlans', Boolean(quoteID));
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<unknown>}
 */
export function getCommissions(accountID, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, 'commissions', Boolean(quoteID));

        return advisor
            .getCommissions(accountID, quoteID)
            .then((commissions) => {
                setLoader(dispatch, false, 'commissions', Boolean(quoteID));
                return commissions;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'commissions', Boolean(quoteID));
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * Save the commissions for the advisor
 * @param accountID
 * @param commissions
 * @param quoteID
 * @param effectiveDate
 * @returns {function(*=): Promise<boolean>}
 */
export function saveCommissions(accountID, commissions, effectiveDate = null, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, 'commissions', Boolean(quoteID));

        return advisor
            .saveCommissions(accountID, commissions, quoteID, effectiveDate)
            .then(() => {
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.commissionsSavedSuccessfully)));
                setLoader(dispatch, false, 'commissions', Boolean(quoteID));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'commissions', Boolean(quoteID));
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * Submit the commission and move to next step
 * @param accountID
 * @param commissions
 * @param quoteID
 * @returns {function(*=): Q.Promise<>}
 */
export function submitCommissions(accountID, commissions, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, 'commissions', Boolean(quoteID));

        return advisor
            .submitCommission(accountID, commissions, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.SET_STATE,
                        state: 2,
                        quoteID
                    });
                } else {
                    dispatch({
                        type: types.SET_STATE,
                        state: 3,
                        accountID
                    });
                }

                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.commissionsSavedSuccessfully)));
                setLoader(dispatch, false, 'commissions', Boolean(quoteID));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'commissions', Boolean(quoteID));
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param filters
 * @returns {function(*=): Promise<boolean>}
 */
export function getCommissionsHistory(filters) {
    return (dispatch) => {
        setLoader(dispatch, true, 'commissions');

        return advisor
            .getCommissionsHistory(filters)
            .then((data) => {
                setLoader(dispatch, false, 'commissions');
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, 'commissions');
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @returns {Function}
 */
export function clearSelectedInvoice() {
    return (dispatch) => {
        dispatch({
            type: invoiceTypes.CLEAR_SELECTED_INVOICE
        });
    };
}

/**
 * @param accountID
 * @returns {function(*): *}
 */
export function getAccountAmendments(accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.getAccountAmendments(accountID)
            .then((data) => {
                setLoader(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @returns {function(*): *}
 */
export function createAmendment(accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.createAmendment(accountID)
            .then((amendmentID) => {
                setLoader(dispatch, false);
                return amendmentID;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @param amendmentID
 * @returns {function(*): Promise<* | boolean>}
 */
export function getAmendment(accountID, amendmentID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.getAmendment(accountID, amendmentID)
            .then((data) => {
                setLoader(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @param uniqueKey
 * @param amendmentID
 * @returns {function(*): Promise<* | boolean>}
 */
export function getAmendmentAuthorityView(accountID, uniqueKey, amendmentID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.getAmendmentAuthorityView(accountID, uniqueKey, amendmentID)
            .then((data) => {
                setLoader(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param signage
 * @param accountID
 * @param amendmentID
 * @returns {function(*): Promise<* | boolean>}
 */
export function completeAmendmentApproval(signage, accountID, amendmentID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.completeAmendmentApproval(signage, accountID, amendmentID)
            .then((data) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.amendmentApproved)));
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param advisorID
 * @returns {function(*): Promise<* | boolean>}
 */
export function getAccountsUnderAmendment(advisorID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return advisor.getAccountsUnderAmendment(advisorID)
            .then((data) => {
                setLoader(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * 
 * @param {} ComponentName 
 * @param {*} downloadParams 
 * @returns 
 */
export function getAdvisorPdf(ComponentName, fileName, downloadParams = {}) {    
    return (dispatch) => {
        if (ComponentName !== "Invoice") {
            setLoader(dispatch, true);
        }

        return generatePdf(ComponentName, downloadParams, true)
            .then((data) => {
                if (data.isSentViaEmail === true) {
                    return true;
                }

                const linkSource = `data:application/pdf;base64,${ data }`;
                const downloadLink = document.createElement("a");

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

                setLoader(dispatch, false);
                return data; 
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * 
 * 
 * @param {} ComponentName 
 * @param {*} downloadParams 
 * @returns 
 */
export function getAdvisorPdfForQuote(ComponentName, fileName, downloadParams = {}) {    
    return (dispatch) => {
        setLoader(dispatch, true);        
        return generatePdfForQuote(ComponentName, downloadParams, true)
            .then((data) => {
                const linkSource = `data:application/pdf;base64,${ data }`;
                const downloadLink = document.createElement("a");

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

                setLoader(dispatch, false);
                return data; 
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * 
 * 
 * @param {} ComponentName 
 * @param {*} downloadParams 
 * @returns 
 */
export function getAmendmentPDF(ComponentName, fileName, downloadParams = {}) {
    return (dispatch) => {
        setLoader(dispatch, true);        
        return generateAmendmentPDF(ComponentName, downloadParams, true)
            .then((data) => {
                const linkSource = `data:application/pdf;base64,${ data }`;
                const downloadLink = document.createElement("a");

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

                setLoader(dispatch, false);
                return data; 
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 *
 * @param institutionNum
 * @param transitNum
 * @returns {function(*): Promise<*>}
 */
export function validateBankingDetails(institutionNum, transitNum) {
    return (dispatch) => {
        setBankingDetailsVerifying(dispatch, true);

        return advisor.validateBankingDetails(institutionNum, transitNum)
            .then((data) => {
                setBankingDetailsVerifying(dispatch, false);
                return data;
            })
            .catch((error) => {
                setBankingDetailsVerifying(dispatch, false);
                dispatch(notifier.displayError(error));
                return error;
            });
    };
}
/* eslint-disable require-jsdoc */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Theme, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as dates from '@simplybenefits/sb-dates';
import { helpers } from '@simplybenefits/sb-utils';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import { useTheme } from '@mui/material/styles';
import { globalStyles } from '../../../theme/index.js';

const ClassAndDivisionDetailsModal = (props) => {
    const theme = useTheme();
    const { hideModal, showModal, census } = props;
    const styles = {
        table: globalStyles.table,
        tableHeading: {
            fontFamily: Theme.fonts.type.medium,
            color: "#FFFFFF",
            "&.MuiTableCell-root": {
                padding: "4px 30px"
            }
        },
        noPadding: {
            padding: 0,
            borderRadius: "0px"
        },
        cell: {
            "&.MuiTableCell-root": {
                padding: "4px 30px"
            },
            color: Theme.colors.mainTextGrey
        },
        row: {
            "&.MuiTableRow-root": {
                '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.whitelabel.buttonTextColor,
                    "&:hover": {
                        backgroundColor: Theme.colors.lightHoverGrey
                    }
                },
                backgroundColor: Theme.colors.backgroundGrey,
                "&:hover": {
                    backgroundColor: Theme.colors.lightHoverGrey
                }
            }
        }
    };

    return (
        <Modal
            open={showModal}
            onClose={hideModal}
            title={ i18n.t(tokens.labels.admin.classAndDivisionDetails) }
        >
        
            <Table padding="none">
                <TableHead>
                    <TableRow sx={{ backgroundColor: theme.palette.primary.main, borderTop: "2px solid #FFFFFF" }}>
                        <TableCell sx={styles.tableHeading}>
                            { i18n.t(tokens.labels.classesAndDivisions.className) }
                        </TableCell>
                        <TableCell sx={{ ...styles.tableHeading, width: "35%" }}>
                            { i18n.t(tokens.labels.classesAndDivisions.classDescription) }
                        </TableCell>
                        <TableCell padding="none" sx={{ ...styles.tableHeading, width: "35%" }} >
                            { i18n.t(tokens.labels.effectiveDate) }
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        Object.keys(census.classes).map((classID) => {
                            const classData = census.classes[classID];
                            return (
                                <TableRow
                                    key={classData.classID}
                                    sx={styles.row}
                                >
                                    <TableCell padding="none" sx={{ border: "none", ...styles.cell }}>
                                        { classData.title }
                                    </TableCell>
                                    <TableCell padding="none" sx={ { border: "none", ...styles.cell, width: "35%" } }>
                                        { classData.description }
                                    </TableCell>
                                    <TableCell padding="none" sx={ { border: "none", ...styles.cell, width: "35%" } }>
                                        { dates.getStartOfDay(classData.classEffectiveDate, "ll", i18n.locale) }
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>

            <div style={{ marginTop: "32px" }}>
                <Table padding="none">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: theme.palette.primary.main, borderTop: "2px solid #FFFFFF" }}>
                            <TableCell padding="none" sx={styles.tableHeading}>
                                { i18n.t(tokens.labels.classesAndDivisions.divisionName) }
                            </TableCell>
                            <TableCell padding="none" sx={{ ...styles.tableHeading, width: "35%" }} >
                                { i18n.t(tokens.labels.classesAndDivisions.divisionDescription) }
                            </TableCell>
                            <TableCell padding="none" sx={{ ...styles.tableHeading, width: "35%" }} >
                                { i18n.t(tokens.labels.effectiveDate) }
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            helpers.sortObjByTitle(census.divisions).map((divisionID) => {
                                const divisionData = census.divisions[divisionID];
                                return (
                                    <TableRow
                                        key={divisionData.divisionID}
                                        sx={styles.row}
                                    >
                                        <TableCell padding="none" sx={{ border: "none", ...styles.cell }}>
                                            { divisionData.title }
                                        </TableCell>
                                        <TableCell padding="none" sx={{ border: "none", ...styles.cell, width: "35%" }}>
                                            { divisionData.description }
                                        </TableCell>
                                        <TableCell padding="none" sx={ { border: "none", ...styles.cell, width: "35%" } }>
                                            { divisionData.eligibilityOverrideDate ? dates.getStartOfDay(divisionData.eligibilityOverrideDate, "ll", i18n.locale) : dates.getStartOfDay(divisionData.accountEffectiveDate, "ll", i18n.locale) }
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </div>

            <span id="classAndDivisionDetails" />
        </Modal>
    );
};

ClassAndDivisionDetailsModal.propTypes = {
    census: PropTypes.object
};

/**
 * @param state
 * @returns {{meta: null, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    census: state.census
});

export default connect(mapStateToProps)(ClassAndDivisionDetailsModal);
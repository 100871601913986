import config from '../config.js';
import * as session from '../services/session.js';

/**
 * @param req
 * @returns {Promise<any>}
 */
function request(req) {
    return new Promise((resolve, reject) => {
        if (!req.method) {
            console.error('method is required');
            reject(new Error('method is required'));
            return;
        }

        if (!req.endpoint) {
            console.error('endpoint is required');
            reject(new Error('endpoint is required'));
            return;
        }

        const token = session.getToken();
        const endpoint = `${ config.apiRequestUrl }${ req.endpoint }`;
        const options = {
            method: req.method.toUpperCase(),
            headers: {}
        };

        if (token && !req.skipAuth) {
            options.headers = {
                Authorization: `Bearer ${ token }`
            };
        }

        if (req.body) {
            if (req.noJson) {
                options.body = req.body;
            } else {
                options.body = JSON.stringify(req.body);
                options.headers['content-type'] = 'application/json';
            }
        }
        
        fetch(endpoint, options)
            .then((res) => res.json())
            .then((res) => {
                // if the route failed for some reason, lets catch it
                if (res.statusCode) {
                    const error = {
                        verbiage: res.message,
                        code: 'INTERNAL_ERROR'
                    };

                    if (Number(res.statusCode) === 404) {
                        error.verbiage = 'Request failed: request url was NOT_FOUND (404).';
                        error.code = 'NOT_FOUND';
                    }

                    reject(error);
                    return;
                }

                if (res.status === 'Fail') {
                    reject(res);

                    if (res.code === 'INTERNAL_ERROR' && process.env.REACT_APP_ENVIRONMENT !== 'local') {
                        window.location.href = '/error';
                        return;
                    }

                    if (res.code === 'FAILED_WHITELABEL_VALIDATION') {
                        window.location.href = '/login';
                    }
                } else {
                    resolve(res.data);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default request;
import React, { Component } from 'react';
import { ContainerControlSite, Content, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';
import { connect } from 'react-redux';
import Overview from './Overview.js';

class AdminManagement extends Component {
    render() {
        const { isAdvisor } = this.props;

        if (isAdvisor) {
            return (
                <React.Fragment>
                    <Overview isAdvisor={ true } />
                </React.Fragment>
            );
        }

        return (
            <ContainerControlSite>
                <Content>
                    <Title title={ i18n.t(tokens.labels.admin.administrators) } />
                    <Overview isAdvisor={ false } />
                </Content>
            </ContainerControlSite>
        );
    }
}

export default (connect()(AdminManagement));
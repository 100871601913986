import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import notifier from './notifier.js';
import admin from './admin.js';
import advisor from './advisor.js';
import census from './census.js';
import plan from './plan.js';
import invoices from './invoices.js';
import reports from './reports.js';
import whitelabel from './whitelabel.js';
import quotes from './quotes.js';
import loader from './loader.js';

/**
 * 
 * @param {*} history 
 * @returns 
 */
const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    notifier,
    admin,
    advisor,
    census,
    plan,
    reports,
    whitelabel,
    invoices,
    quotes,
    loader
//   ... // rest of your reducers
});

export default rootReducer;
import React, { Component } from 'react';
import { connect } from "react-redux";
import * as am4core from '@amcharts/amcharts4/core.js';
import * as am4charts from '@amcharts/amcharts4/charts.js';
import { Theme } from '@simplybenefits/sb-ui-library';

class Gauge extends Component {
    componentDidMount() {
        const chart = am4core.create(`chartdiv${ this.props.id }`, am4charts.GaugeChart);

        // ... chart code goes here ...
        chart.innerRadius = am4core.percent(82);

        /**
         * Normal axis
         */
        const axis = chart.xAxes.push(new am4charts.ValueAxis());
        axis.min = 0;
        axis.max = 100;
        axis.strictMinMax = true;
        axis.renderer.radius = am4core.percent(80);
        axis.renderer.inside = true;
        axis.renderer.line.strokeOpacity = 1;
        axis.renderer.ticks.template.strokeOpacity = 1;
        axis.renderer.ticks.template.length = 10;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.labels.template.radius = 40;
        axis.renderer.labels.template.adapter.add("text", (text) => `${ text }%`);

        /**
         * Axis for ranges
         */
        const axis2 = chart.xAxes.push(new am4charts.ValueAxis());
        axis2.min = 0;
        axis2.max = 100;
        axis2.renderer.innerRadius = 10;
        axis2.strictMinMax = true;
        axis2.renderer.labels.template.disabled = true;
        axis2.renderer.ticks.template.disabled = true;
        axis2.renderer.grid.template.disabled = true;

        const range0 = axis2.axisRanges.create();
        range0.value = 0;
        range0.endValue = this.props.startValue;
        range0.axisFill.fillOpacity = 1;
        range0.axisFill.fill = am4core.color(Theme.colors.mediumBlue);

        const range1 = axis2.axisRanges.create();
        range1.value = this.props.startValue;
        range1.endValue = 100;
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill = am4core.color(Theme.colors.mediumDarkBlue);


        /**
         * Label
         */
        const label = chart.radarContainer.createChild(am4core.Label);
        label.isMeasured = false;
        label.fontSize = 25;
        label.x = am4core.percent(50);
        label.y = am4core.percent(100);
        label.horizontalCenter = "middle";
        label.verticalCenter = "bottom";
        label.text = `${ this.props.startValue }%`;

        /**
         * Hand
         */
        const hand = chart.hands.push(new am4charts.ClockHand());
        hand.axis = axis2;
        hand.innerRadius = am4core.percent(20);
        hand.startWidth = 10;
        hand.pin.disabled = true;
        hand.value = this.props.startValue;

        hand.events.on("propertychanged", (ev) => {
            range0.endValue = ev.target.value;
            range1.value = ev.target.value;
            axis2.invalidate();
        });

        if (this.props.setInterval) {
            this.interval = setInterval(() => {
                const value = Math.floor(Math.random() * (100 - 50 + 1)) + 50; // random number between 50 and 100
                label.text = `${ value }%`;
                // eslint-disable-next-line
                const animation = new am4core.Animation(hand, {
                    property: "value",
                    to: value
                }, 1000, am4core.ease.cubicOut).start();
            }, 2000);
        }

        // ... chart code end ...

        this.chart = chart;
    }

    componentWillUnmount() {
        clearInterval(this.interval);

        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={`chartdiv${ this.props.id }`} style={{ width: "100%", height: '100%' }} />
        );
    }
}

export default connect()(Gauge);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as dates from '@simplybenefits/sb-dates';
import { Loader, AmendmentDisplay, MessageCard, Title, Container, Content, ContentBlock, i18n, tokens } from '@simplybenefits/sb-ui-library';
import { getAccount, getAmendment, getAdvisorPdf } from '../../../actions/advisor.js';

class Amendment extends Component {
    state = {
        amendment: null
    };

    async componentDidMount() {
        if (!this.props.currentAccountMeta) {
            await this.props.dispatch(getAccount(this.props.match.params.accountID));
        }

        const amendment = await this.props.dispatch(getAmendment(this.props.match.params.accountID, this.props.match.params.amendmentID));
        amendment.effectiveDate = dates.getUTC(amendment.effectiveDate, 'YYYY/MM/DD');

        if (amendment) {
            this.setState({
                amendment
            });
        }
    }

    _downloadPDF = () => {
        const accountID = this.props.match.params.accountID;
        const amendmentID = this.props.match.params.amendmentID;
        const fileName = `amendment_${ this.props.match.params.amendmentID }.pdf`;
        return this.props.dispatch(getAdvisorPdf("Amendment", fileName, { accountID, amendmentID }));
    };

    render() {
        const { whitelabel } = this.props;

        if (!this.props.currentAccountMeta || !this.state.amendment) {
            return <Loader whitelabel={ whitelabel } />;
        }

        const amendmentStatus = this.state.amendment.status.toUpperCase();

        return (
            <Container id="account-container" >
                <Content>
                    <Title
                        title={ i18n.t(tokens.labels.amendments.amendment) }
                        subheader={ i18n.t(tokens.labels.amendments.amendment) }
                        breadcrumbs={[
                            {
                                title: i18n.t(tokens.titles.dashboard),
                                link: `/advisor/dashboard`
                            },
                            {
                                title: i18n.t(tokens.titles.activeAccounts),
                                link: `/advisor/accounts/active`
                            },
                            {
                                title: this.props.currentAccountMeta.company,
                                link: `/advisor/account/${ this.props.currentAccountMeta.accountID }`
                            },
                            {
                                title: i18n.t(tokens.titles.amendments),
                                link: `/advisor/account/${ this.props.currentAccountMeta.accountID }#amendments`
                            }
                        ]}
                    />

                    {
                        amendmentStatus === 'IN PROGRESS' &&
                            <MessageCard
                                title={ i18n.t(tokens.labels.amendments.inProgress) }
                                subtitle={ i18n.t(tokens.labels.amendments.amendmentCurrentlyInProgress)}
                            />
                    }

                    {
                        amendmentStatus === 'UNDER REVIEW' &&
                            <MessageCard
                                title={ i18n.t(tokens.labels.amendments.amendmentFormUnderReview) }
                                subtitle={ i18n.t(tokens.labels.amendments.amendmentSentToSigningAuth) }
                            />
                    }

                    {
                        amendmentStatus === 'APPROVED' &&
                            <MessageCard
                                title={ i18n.t(tokens.labels.amendments.amendmentFormApproved) }
                                subtitle={ i18n.t(tokens.labels.amendments.amendmentFormApprovedBySigningAuth) }
                                btn
                                btnName={ i18n.t(tokens.buttons.downloadPdf) }
                                btnOnClick={this._downloadPDF}
                            />
                    }

                    {
                        amendmentStatus === 'UNDER REVISION' &&
                            <MessageCard
                                title={ i18n.t(tokens.labels.amendments.amendmentFormUnderRevision) }
                                subtitle={ i18n.t(tokens.labels.amendments.amendmentCurrentlyUnderRevisions) }
                                btn
                                btnName={ i18n.t(tokens.buttons.downloadPdf) }
                                btnOnClick={this._downloadPDF}
                            />
                    }

                    {
                        amendmentStatus === 'PENDING' &&
                            <MessageCard
                                title={ i18n.t(tokens.labels.amendments.amendmentPending) }
                                subtitle={ i18n.t(tokens.labels.amendments.amendmentHasBeenApprovedChangesPending, { effectiveDate: dates.getUTC(this.state.amendment.effectiveDate, 'll', i18n.locale) }) }
                                btn
                                btnName={ i18n.t(tokens.buttons.downloadPdf) }
                                btnOnClick={this._downloadPDF}
                            />
                    }

                    {
                        amendmentStatus === 'COMPLETE' &&
                            <MessageCard
                                title={ i18n.t(tokens.labels.amendments.amendmentCompleted) }
                                subtitle={ i18n.t(tokens.labels.amendments.amendmentApproved) }
                                btn
                                btnName={ i18n.t(tokens.buttons.downloadPdf) }
                                btnOnClick={this._downloadPDF}
                            />
                    }

                    <ContentBlock>
                        <AmendmentDisplay
                            amendment={ this.state.amendment }
                            whitelabel={ this.props.whitelabel }
                            classData={ this.props.classData }
                            isAuthorityView={ false }
                        />
                    </ContentBlock>
                </Content>

                { this.props.loading && <Loader whitelabel={ whitelabel } /> }

                <span id="accounts-single" />
            </Container>
        );
    }
}

Amendment.propTypes = {
    loading: PropTypes.bool,
    currentAccountMeta: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{currentAccountMeta: null, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.advisor.loading,
    currentAccountMeta: state.advisor.currentAccountMeta,
    classData: state.census.classes,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(Amendment);
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import store from './redux/store.js';
import CacheBuster from 'react-cache-buster';
import version from '../package.json';
import * as am4core from '@amcharts/amcharts4/core';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Theme, Loader } from '@simplybenefits/sb-ui-library';
import { GlobalStyles } from '@mui/material';

import PrivateRoute from './PrivateRoute.js';
import ForgotPassword from './containers/ForgotPassword/index.js';
import AdminLogin from './containers/Login/AdminLogin.js';
import AdvisorLogin from './containers/Login/AdvisorLogin.js';
import Advisor from './containers/Advisor/index.js';
import AdminDashboard from './containers/Admin/Dashboard/index.js';
import Reports from './containers/Reports/index.js';
import ActiveAccounts from './containers/Advisor/Accounts/Active/index.js';
import AccountsUnderAmendment from './containers/Advisor/Accounts/UnderAmendment/index.js';
import Quotes from './containers/Advisor/Quotes/index.js';
import PendingAccounts from './containers/Advisor/Accounts/Pending/index.js';
import LostAccounts from './containers/Advisor/Accounts/Lost/index.js';
import Account from './containers/Advisor/Accounts/Account.js';
import Quote from './containers/Advisor/Quotes/Quote.js';
import Administrators from './containers/Admin/Management/index.js';
import AdminResources from './containers/Admin/Resources/index.js';
import AdvisorResources from './containers/Advisor/Resources/index.js';
import Metrics from './containers/Admin/Metrics/index.js';
import PlanOverview from './containers/Admin/Plan/Overview.js';
import Members from './containers/Members/index.js';
import Member from './containers/Members/Member/index.js';
import Invoices from './containers/Invoices/index.js';
import AsoReports from './containers/Invoices/AsoReports/index.js';
import AdvisorSettings from './containers/Advisor/Settings/index.js';
import AdminSettings from './containers/Admin/Settings/index.js';
import AdminMemberChanges from './containers/Admin/MemberChanges/index.js';
import ChangePassword from './containers/ChangePassword/index.js';
import MasterApplicationAuthorityView from './containers/Advisor/MasterApplication/MasterApplicationAuthorityView.js';
import ChangeRequestDetailsView from './containers/ChangeRequests/ChangeRequestDetailsView.js';
import NotFound from './containers/NotFound/index.js';
import InternalError from './containers/InternalError/index.js';
import Notifier from './components/Notifier.js';
import Notifications from './containers/Notifications/index.js';
import Files from './containers/Files';
import Commissions from './containers/Advisor/Commissions/index.js';
import Amendment from './containers/Advisor/Amendments/Amendment.js';
import AmendmentAuthorityView from './containers/Advisor/Amendments/AmendmentAuthorityView.js';
import { getAccount } from './actions/admin.js';
import { getWhiteLabel, getLocalStorageWhitelabel } from './actions/whitelabel.js';
import { getAdvisor } from './actions/advisor.js';
import * as session from './services/session.js';
import * as events from './services/events.js';

am4core.useTheme(am4themesAnimated);

class App extends Component {
    state = {
        loading: true,
        error: false,
        theme: false,
        whitelabel: false
    };

    componentDidMount = async () => {
        const token = await session.getToken();
        let whitelabel = null;

        // if we are logged in, let's get the whitelabel using the members actual data and not the domain or the locally stored stuff
        if (token) {
            whitelabel = await store.dispatch(getWhiteLabel(true));
            await this._pullData(token);
        } else {
            // try and get whitelabel data by using the url (no auth required)
            whitelabel = await store.dispatch(getWhiteLabel());

            // if we can't find any whitelabel by domain then look for locally stored whitelabel (they might be using the simply domain)
            if (!whitelabel) {
                whitelabel = await store.dispatch(getLocalStorageWhitelabel());
            }
        }

        const theme = Theme.themeWithWhitelabel(whitelabel);

        this.setState({
            loading: false,
            theme,
            whitelabel
        });
    };

    _pullData = async (token) => {
        const userType = await session.getUserType();

        if (token) {
            // its an advisor
            if (Number(userType) === 1) {
                // check if we have the advisor data pulled already, if not get it
                if (store.getState().advisor.meta === null) {
                    await store.dispatch(getAdvisor());
                }
            } else if (store.getState().admin.meta === null) {
                // check if we have the account data pulled already, if not get it
                await store.dispatch(getAccount());
            }

            events.record('web_load');
        }
    };

    render() {
        const globalStyles = {
            '*::-webkit-scrollbar': {
                height: 15,
                width: 15
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: Theme.colors.surface,
                borderRadius: 10,
                boxShadow: 'inset 0 0 2px rgba(0, 0, 0, .5)'
            },
            '*::-webkit-scrollbar-track': {
                backgroundColor: Theme.colors.backgroundGrey,
                borderRadius: 10,
                boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.2)'
            },
            '.MuiDrawer-paper, .MuiPaper-root': {
                zIndex: 'auto !important'
            }
        };

        const styles = {
            main: {
                height: 'auto',
                margin: 0,
                minHeight: '100vh',
                padding: 0,
                position: 'relative'
            }
        };

        // if no theme is loaded than we are waiting for whitelabel so return nothing
        if (!this.state.theme) {
            return null;
        }

        if (this.state.loading) { 
            return <Loader isContainer/>;
        }

        return (
            <CacheBuster
                currentVersion={ version.version }
                isEnabled={ true } // If false, the library is disabled.
                loadingComponent={ <Loader isContainer/> } // If not pass, nothing appears at the time of new version check.
            >
                <ThemeProvider theme={this.state.theme}>
                    <CssBaseline />
                    <GlobalStyles styles={globalStyles}/>
                    <main style={styles.main}>
                        <Switch>
                            <Route exact path="/login" component={(props) => <AdminLogin {...props}/>} />
                            <Route exact path="/forgotPassword/:token" component={ (props) => <ForgotPassword {...props}/> } />
                            <Route exact path="/admin/login" component={(props) => <AdminLogin {...props}/>} />
                            <Route exact path="/advisor/login" component={ (props) => <AdvisorLogin {...props}/>} />
                            <Route exact path="/application/master/:id/:uniqueKey/:isQuote" component={(props) => <MasterApplicationAuthorityView {...props}/>} />
                            <Route exact path="/changeRequests/:changeRequestID/:id/Administrator/:reviewerID" component={(props) => <ChangeRequestDetailsView {...props}/>} />
                            <Route exact path="/accounts/:accountID/amendments/:amendmentID/:uniqueKey" component={(props) => <AmendmentAuthorityView {...props}/>} />
                            <PrivateRoute exact path="/advisor" component={Advisor} />
                            <PrivateRoute exact path="/advisor/dashboard" component={Advisor} />
                            <PrivateRoute exact path="/advisor/reports" component={Reports} />
                            <PrivateRoute exact path="/advisor/commissions" component={Commissions} />
                            <PrivateRoute exact path="/advisor/accounts/active" component={ActiveAccounts} />
                            <PrivateRoute exact path="/advisor/accounts/amendment" component={AccountsUnderAmendment} />
                            <PrivateRoute exact path="/advisor/quotes" component={Quotes} />
                            <PrivateRoute exact path="/advisor/accounts/pending" component={PendingAccounts} />
                            <PrivateRoute exact path="/advisor/accounts/lost" component={LostAccounts} />
                            <PrivateRoute exact path="/advisor/settings" component={AdvisorSettings} />
                            <PrivateRoute exact path="/advisor/account/:accountID" component={Account} />
                            <PrivateRoute exact path="/advisor/quotes/:quoteID" component={Quote} />
                            <PrivateRoute exact path="/advisor/files" component={Files} />
                            <PrivateRoute exact path="/advisor/account/:accountID/amendments/:amendmentID" component={Amendment} />
                            <PrivateRoute exact path="/" component={AdminDashboard} />
                            <PrivateRoute exact path="/plan" component={PlanOverview} />
                            <PrivateRoute exact path="/files" component={Files} />
                            <PrivateRoute exact path="/members" component={Members} />
                            <PrivateRoute exact path="/member/:accountID/:userID" component={Member} />
                            <PrivateRoute exact path="/metrics" component={Metrics} />
                            <PrivateRoute exact path="/invoices" component={Invoices} />
                            <PrivateRoute exact path="/aso" component={AsoReports} />
                            <PrivateRoute exact path="/settings" component={AdminSettings} />
                            <PrivateRoute exact path="/memberChanges" component={AdminMemberChanges} />
                            <PrivateRoute exact path="/memberChanges/:changeRequestID" component={(props) => <ChangeRequestDetailsView isPrivateView={false} {...props}/>} />
                            <PrivateRoute exact path="/reports" component={Reports} />
                            <PrivateRoute exact path="/resources" component={AdminResources} />
                            <PrivateRoute exact path="/advisor/resources" component={AdvisorResources} />
                            <PrivateRoute exact path="/administrators" component={Administrators} />
                            <PrivateRoute exact path="/settings/security" component={ChangePassword} />
                            <PrivateRoute exact path="/notifications" component={Notifications} />
                            <Route path="/error" component={ (props) => <InternalError {...props}/> }/>
                            <Route component={ (props) => <NotFound {...props}/> } />
                        </Switch>
                        <Notifier />
                    </main>
                </ThemeProvider>
            </CacheBuster>
        );
    }
}

export default (App);
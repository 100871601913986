export const GET_PLAN_SUCCESS = 'plan/GET_PLAN_SUCCESS';
export const UPDATE_PLAN_CLASS_RECORD = 'plan/UPDATE_PLAN_CLASS_RECORD';
export const GET_PRICING_SUCCESS = 'plan/GET_PRICING_SUCCESS';
export const ADD_DESIGN_FOR_NEW_CLASS = 'plan/ADD_DESIGN_FOR_NEW_CLASS';

export const CREATE_NEW_PLAN_DESIGN = 'plan/CREATE_NEW_PLAN_DESIGN';
export const SAVE_PLAN_DESIGN_FIELD = 'plan/SAVE_PLAN_DESIGN_FIELD';
export const SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA = 'plan/SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA';
export const REVERT_FROM_REVISION = 'plan/REVERT_FROM_REVISION';

export const SAVE_MODULAR_BENEFIT_INIT_DATA = 'plan/SAVE_MODULAR_BENEFIT_INIT_DATA';
export const SAVE_MODULAR_FIELD = 'plan/SAVE_MODULAR_FIELD';

export const SET_SPECIALITY_PRODUCT_CONFIGS = 'plan/SET_SPECIALITY_PRODUCT_CONFIGS';
export const GET_QUOTE_SUCCESS = 'quotes/GET_QUOTE_SUCCESS';
export const GET_QUOTES = 'quotes/GET_QUOTES';
export const SET_PLAN_TYPE = 'quote/SET_PLAN_TYPE';
export const CREATE_NEW_PLAN_DESIGN = 'quote/CREATE_NEW_PLAN_DESIGN';
export const SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA = 'quote/SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA';
export const SAVE_PLAN_DESIGN_FIELD = 'quote/SAVE_PLAN_DESIGN_FIELD';
export const REVERT_FROM_REVISION = 'quote/REVERT_FROM_REVISION';
export const SAVE_MODULAR_BENEFIT_INIT_DATA = 'quote/SAVE_MODULAR_BENEFIT_INIT_DATA';
export const SAVE_MODULAR_FIELD = 'quote/SAVE_MODULAR_FIELD';
export const SET_STATE = 'quote/SET_STATE';
export const ADD_CLASS_SUCCESS = 'quote/ADD_CLASS_SUCCESS';
export const EDIT_CLASS_SUCCESS = 'quote/EDIT_CLASS_SUCCESS';
export const ADD_DIVISION_SUCCESS = 'quote/ADD_DIVISION_SUCCESS';
export const EDIT_DIVISION_SUCCESS = 'quote/EDIT_DIVISION_SUCCESS';
export const ADD_DESIGN_FOR_NEW_CLASS = 'quote/ADD_DESIGN_FOR_NEW_CLASS';
export const REMOVE_CLASS_SUCCESS = 'quote/REMOVE_CLASS_SUCCESS';
export const REMOVE_DIVISION_SUCCESS = 'quote/REMOVE_DIVISION_SUCCESS';
import initialState from '../initialState.js';
import {
    GET_CENSUS_SUCCESS,
    ADD_CENSUS_USER_SUCCESS,
    REMOVE_CENSUS_USER_SUCCESS,
    GET_USERS_SUCCESS,
    UPDATE_CENSUS_USER_SUCCESS,
    GET_CLASSES_SUCCESS,
    GET_DIVISIONS_SUCCESS,
    ADD_CLASS_SUCCESS,
    EDIT_CLASS_SUCCESS,
    ADD_DIVISION_SUCCESS,
    EDIT_DIVISION_SUCCESS
} from '../../actions/types/census';

export default (state = initialState.census, action) => {
    switch (action.type) {
        case GET_CENSUS_SUCCESS: {
            return {
                ...state,
                employees: action.users
            };
        }

        case ADD_CENSUS_USER_SUCCESS: {
            return {
                ...state,
                employees: [ ...state.employees, action.data ]
            };
        }

        case UPDATE_CENSUS_USER_SUCCESS: {
            return {
                ...state,
                employees: state.employees.map((user) => {
                    if (user.userID === action.data.userID) {
                        return {
                            ...user,
                            ...action.data
                        };
                    }

                    return user;
                })
            };
        }

        case REMOVE_CENSUS_USER_SUCCESS: {
            return {
                ...state,
                employees: state.employees.filter((item) => item.userID !== action.userID)
            };
        }

        case GET_USERS_SUCCESS: {
            return {
                ...state,
                employees: action.users
            };
        }

        case GET_CLASSES_SUCCESS: {
            return {
                ...state,
                classes: action.classes
            };
        }

        case GET_DIVISIONS_SUCCESS: {
            return {
                ...state,
                divisions: action.divisions
            };
        }

        case ADD_CLASS_SUCCESS: {
            return {
                ...state,
                classes: {
                    ...state.classes,
                    [action.data.classID]: action.data
                }
            };
        }

        case EDIT_CLASS_SUCCESS: {
            return {
                ...state,
                classes: {
                    ...state.classes,
                    [action.data.classID]: {
                        ...state.classes[action.data.classID],
                        title: action.data.title,
                        description: action.data.description
                    }
                }
            };
        }

        case ADD_DIVISION_SUCCESS: {
            return {
                ...state,
                divisions: {
                    ...state.divisions,
                    [action.data.divisionID]: action.data
                }
            };
        }

        case EDIT_DIVISION_SUCCESS: {
            return {
                ...state,
                divisions: {
                    ...state.divisions,
                    [action.data.divisionID]: {
                        ...state.divisions[action.data.divisionID],
                        title: action.data.title,
                        description: action.data.description
                    }
                }
            };
        }

        default:
            return state;
    }
};
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Overview from './Overview.js';
import { Loader, Content, ContainerControlSite, Title, i18n, tokens } from "@simplybenefits/sb-ui-library";
import store from '../../redux/store.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const Reports = (props) => {
    const { isAdvisor, currentAccountMeta } = props;
    const whitelabel = store.getState().whitelabel;

    if (isAdvisor) {
        return (
            <React.Fragment>
                <div id="content">
                    <Overview isAdvisor={ true } currentAccountMeta={ currentAccountMeta } />
                </div>

                <span id="reports" />
            </React.Fragment>
        );
    }

    return (
        <ContainerControlSite>
            <Content id="content">
                <Title title={ i18n.t(tokens.labels.admin.reports) } />
                <Overview isAdvisor={ false } currentAccountMeta={ null } />
            </Content>

            <span id="reports" />

            {props.loading && <Loader whitelabel={ whitelabel } />}
        </ContainerControlSite>
    );
};

Reports.propTypes = {
    loading: PropTypes.bool
};

/**
 * @param state
 * @returns {{loading: *}}
 */
const mapStateToProps = (state) => ({
    loading: state.reports.loading
});

export default (connect(mapStateToProps)(Reports));
import React from 'react';
import { Modal, MessageCard, Text, Theme, i18n, tokens } from "@simplybenefits/sb-ui-library";

/**
 * @param bankingDetails
 * @param showBottomBar
 * @returns {JSX.Element}
 * @private
 */
const _detailsBody = (bankingDetails, showBottomBar = true) => (
    <>
        <MessageCard
            title={ bankingDetails.institutionName }
            subtitle={ bankingDetails.institutionAddress }
        />

        <Text medium margin="10px 0">
            { i18n.t(tokens.labels.banking.branchTransitNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{ bankingDetails.transitNum }</span>
        </Text>

        <Text medium margin="10px 0">
            { i18n.t(tokens.labels.banking.financialInstitutionNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{ bankingDetails.institutionNum }</span>
        </Text>

        <Text medium margin="10px 0">
            { i18n.t(tokens.labels.banking.accountNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{ bankingDetails.accountNum }</span>
        </Text>

        {
            showBottomBar &&
                <div style={{ borderBottom: `1px solid ${ Theme.colors.lightGrey }`, marginTop: "30px" }}></div>
        }
    </>
);

/**
 *
 * @returns
 */
const CurrentBankingDetailsModal = ({ 
    close, 
    showModal, 
    currentBankingDetails,
    loader
}) => {
    const hasDualBankAccountSetup = currentBankingDetails.default && currentBankingDetails?.paymentsOnly?.accountNum && currentBankingDetails?.paymentsOnly?.accountNum.length > 0;

    return (
        <Modal
            medium
            open={true}
            onClose={close}
            title={ i18n.t(tokens.titles.currentBankingDetails) }
            primaryButton={{
                label: i18n.t(tokens.buttons.editBankingDetails),
                onClick: () => showModal('editBankingDetails')
            }}
            loader={loader}
        >
            {
                !hasDualBankAccountSetup ?
                    _detailsBody(currentBankingDetails.default)
                    :
                    <>
                        <Text mediumLarge bold>
                            { i18n.t(tokens.titles.reimbursementAccount) }
                        </Text>

                        { _detailsBody(currentBankingDetails.default) }

                        <Text mediumLarge bold margin="20px 0 0 0">
                            { i18n.t(tokens.titles.premiumPaymentAccount) }
                        </Text>

                        { _detailsBody(currentBankingDetails.paymentsOnly, false) }
                    </>
            }
        </Modal>
    );
};

export default CurrentBankingDetailsModal;
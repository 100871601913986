import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TextInput, Text, Modal, Services, Tiles, BubbleSelectBlock, i18n, tokens, localizationMaps } from '@simplybenefits/sb-ui-library';
import { helpers } from '@simplybenefits/sb-utils';
import { getUserType } from '../../../services/session.js';
import { createAdmin, updateAdmin, removeAdmin } from '../../../actions/admin.js';

class Overview extends Component {
    state = {
        firstname: '',
        lastname: '',
        email: '',
        language: '',
        showAdminForm: false,
        selectedAdminID: false,
        isEdit: false,
        isParent: 0,
        isPlanSponsor: 0,
        divisionsById: {},
        confirmDialog: false
    };

    componentDidMount() {
        // if its a non Parent Admin user, do not allow access
        const isAdvisor = Number(getUserType()) === 1;

        if (!isAdvisor && Number(this.props.admin.isParent) === 0) {
            window.location.href = '/';
            return;
        }

        document.addEventListener("keydown", this._handleKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyPress, false);
    }

    _hideForm = () => {
        this.setState({
            showAdminForm: false,
            confirmDialog: false,
            isEdit: false
        });
    };

    _onBlur = async (type) => {
        this.setState({
            [`${ type }Error`]: await Services.validateInput(type, this.state[type])
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    _showNewAdmin = () => {
        this.setState({
            firstname: '',
            lastname: '',
            email: '',
            language: '',
            showAdminForm: true,
            isEdit: false,
            selectedAdminID: false,
            isParent: 0,
            isPlanSponsor: 0,
            divisionsById: {}
        });
    };

    _showEdit = (admin) => {
        const divisionsArr = Array.isArray(admin.divisions) ? admin.divisions : Object.values(admin.divisions);
        const divisionEntries = divisionsArr.map(({ divisionID, access }) => [ divisionID, { divisionID, access }]);

        this.setState({
            firstname: admin ? admin.firstname : '',
            lastname: admin ? admin.lastname : '',
            email: admin ? admin.email : '',
            language: admin ? admin.language === "fr" ? i18n.t(tokens.dropdowns.languages.french) : i18n.t(tokens.dropdowns.languages.english) : '',
            isEdit: true,
            showAdminForm: true,
            selectedAdminID: admin.adminID,
            isParent: admin.isParent,
            isPlanSponsor: admin.isPlanSponsor,
            divisionsById: Object.fromEntries(divisionEntries)
        });
    };

    /**
     * @returns {Promise<void>}
     * @private
     */
    _editAdmin = async () => {
        const firstnameError = await Services.validateInput('firstname', this.state.firstname);
        const lastnameError = await Services.validateInput('lastname', this.state.lastname);
        const emailError = await Services.validateInput('email', this.state.email);

        this.setState({
            firstnameError,
            lastnameError,
            emailError
        });

        if (
            firstnameError ||
            lastnameError ||
            emailError
        ) {
            return false;
        }

        const language = localizationMaps.toEnglish("languages", this.state.language) === "French" ? "fr" : "en";

        const success = await this.props.dispatch(updateAdmin({
            firstname: this.state.firstname.trim(),
            lastname: this.state.lastname.trim(),
            email: this.state.email.trim(),
            language,
            isParent: this.state.isParent || 0,
            selectedDivisions: Object.values(this.state.divisionsById)
        }, this.state.selectedAdminID));

        if (success) {
            this._hideForm();
            document.removeEventListener("keydown", this._handleKeyPress, false);
        }

        return null;
    };

    /**
     * @returns {Promise<void>}
     * @private
     */
    _createAdmin = async () => {
        const firstnameError = await Services.validateInput('firstname', this.state.firstname);
        const lastnameError = await Services.validateInput('lastname', this.state.lastname);
        const emailError = await Services.validateInput('email', this.state.email);

        this.setState({
            firstnameError,
            lastnameError,
            emailError
        });

        if (
            firstnameError ||
            lastnameError ||
            emailError
        ) {
            return false;
        }

        const language = localizationMaps.toEnglish("languages", this.state.language) === "French" ? "fr" : "en";

        const success = await this.props.dispatch(createAdmin({
            firstname: this.state.firstname.trim(),
            lastname: this.state.lastname.trim(),
            email: this.state.email.trim(),
            isParent: this.state.isParent || 0,
            selectedDivisions: Object.values(this.state.divisionsById),
            language
        }, this.props.accountID));

        if (success) {
            this._hideForm();
            document.removeEventListener("keydown", this._handleKeyPress, false);
        }

        return null;
    };

    _confirm = () => {
        this.setState({
            confirmDialog: true
        });
    };

    /**
     * @private
     */
    _removeAdmin = async () => {
        this.setState({
            confirmDialog: false
        });

        const success = await this.props.dispatch(removeAdmin(this.state.selectedAdminID));

        if (success) {
            this._hideForm();
            document.removeEventListener("keydown", this._handleKeyPress, false);
        }
    };

    _handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (this.state.email !== '' && this.state.name !== '' && Object.keys(this.state.divisionsById).length > 0) {
                this.state.isEdit ? this.state.confirmDialog ? this._removeAdmin() : this._editAdmin() : this._createAdmin();
            }
        }
    };

    _getTiles = () => {
        const { administrators } = this.props;
        const planSponsorID = Object.keys(administrators).find((a) => administrators[a].isPlanSponsor === 1);
        const planSponsor = administrators[planSponsorID];

        const tiles = [
            {
                title: i18n.t(tokens.labels.admin.createAdmin),
                description: i18n.t(tokens.labels.admin.addAdminToAccount),
                onClick: this._showNewAdmin,
                testid: 'create-or-edit-administrators'
            }
        ];

        // Show parent Admin First
        tiles.push({
            testid: "edit-existing-admin-tile",
            title: `${ planSponsor.firstname } ${ planSponsor.lastname }`,
            description: planSponsor.email,
            subtitle: i18n.t(tokens.labels.admin.planSponsor),
            icon: 'Plan Sponsor',
            onClick: () => this._showEdit(planSponsor)
        });

        Object.keys(administrators).sort((a, b) => administrators[a].firstname.localeCompare(administrators[b].firstname)).map((adminID) => {
            if (adminID === planSponsorID) {
                return null;
            }

            const admin = administrators[adminID];

            tiles.push({
                title: `${ admin.firstname } ${ admin.lastname }`,
                description: admin.email,
                subtitle: admin.isParent === 1 ? i18n.t(tokens.labels.admin.parentAdmin) : null,
                icon: admin.isParent === 1 ? 'Parent Admin' : 'Person',
                onClick: () => this._showEdit(admin)
            });

            return null;
        });

        return tiles;
    };

    _handleDivisionSelection(divisionID, access) {
        const { divisionsById } = this.state;

        if (divisionID in divisionsById) {
            if (divisionsById[divisionID].access === access) {
                delete divisionsById[divisionID];
            } else {
                divisionsById[divisionID] = { access, divisionID };
            }
        } else {
            divisionsById[divisionID] = { access, divisionID };
        }

        this.setState({ divisionsById });
    }

    render() {
        const { divisions } = this.props;
        const tiles = this._getTiles();
        const styles = {
            list: {
                width: '100%',
                padding: 0,
                margin: '10px 0 0 0'
            },
            listItem: {
                paddingLeft: 1.25, // 10px Mui scaling
                paddingRight: 0
            }
        };

        return (
            <React.Fragment>
                <Tiles tiles={tiles} />

                {
                    this.state.confirmDialog ?
                        <Modal
                            open={this.state.confirmDialog}
                            onClose={this._hideForm}
                            title={ i18n.t(tokens.labels.admin.removeAdmin)}
                            primaryButton={{
                                label: i18n.t(tokens.buttons.remove),
                                onClick: this._removeAdmin
                            }}
                            loader={this.props.loader}
                        >

                            <Text medium light>
                                { i18n.t(tokens.labels.admin.removeAdminConfirmation) }
                            </Text>
                        </Modal>
                        :
                        <Modal
                            testid="create-admin-modal"
                            loader={this.props.loader}
                            open={this.state.showAdminForm}
                            onClose={this._hideForm}
                            title={this.state.isEdit ? i18n.t(tokens.labels.admin.editAdmin) : i18n.t(tokens.labels.admin.createAdmin) }
                            primaryButton={{
                                label: this.state.confirmDialog && i18n.t(tokens.buttons.remove),
                                onClick: this.state.isEdit ? this.state.confirmDialog ? this._removeAdmin : this._editAdmin : this._createAdmin
                            }}
                            secondaryButton={ this.state.isPlanSponsor === 0
                                ? {
                                    label: i18n.t(tokens.buttons.remove),
                                    onClick: Number(this.state.isPlanSponsor) === 0 && this.state.isEdit && !this.state.confirmDialog ? this._confirm : false
                                }
                                : null 
                            }
                        >
                            <TextInput
                                label={ i18n.t(tokens.labels.admin.adminFirstName) }
                                value={this.state.firstname}
                                onBlur={() => this._onBlur('firstname')}
                                onChange={(value) => this._handleInputChange('firstname', value)}
                                error={Boolean(this.state.firstnameError)}
                                helperText={this.state.firstnameError}
                                fullWidth
                                autoFocus
                            />

                            <TextInput
                                label={ i18n.t(tokens.labels.admin.adminLastName) }
                                value={this.state.lastname}
                                onBlur={() => this._onBlur('lastname')}
                                onChange={(value) => this._handleInputChange('lastname', value)}
                                error={Boolean(this.state.lastnameError)}
                                helperText={this.state.lastnameError}
                                fullWidth
                            />

                            <TextInput 
                                label={ i18n.t(tokens.labels.admin.adminEmail) }
                                value={this.state.email}
                                onBlur={() => this._onBlur('email')}
                                onChange={(value) => this._handleInputChange('email', value)}
                                error={Boolean(this.state.emailError)}
                                helperText={this.state.emailError}
                                fullWidth
                            />

                            <TextInput
                                label={ i18n.t(tokens.labels.quotes.adminLanguage) }
                                value={ this.state.language }
                                onBlur={() => this._onBlur('language')}
                                onChange={(value) => this._handleInputChange('language', value)}
                                select
                                selectOptions={ Services.selectFieldOptions.languageOptions() }
                                fullWidth
                            />

                            {
                                this.state.isPlanSponsor === 0 &&
                                    <>
                                        <Text mediumLarge bold margin="20px 0 10px 0">
                                            { i18n.t(tokens.labels.admin.adminPermissions) }
                                        </Text>

                                        {
                                            (this.props.isAdvisor || this.props.admin.isPlanSponsor === 1) &&
                                                <>
                                                    <Text small>
                                                        { i18n.t(tokens.labels.admin.giveThisAdmin) } <b>{ i18n.t(tokens.labels.admin.parentAdmin) }</b> { i18n.t(tokens.labels.admin.parentAdminsFullAccess) }
                                                    </Text>

                                                    <BubbleSelectBlock
                                                        first={{
                                                            title: i18n.t(tokens.buttons.yes),
                                                            selected: this.state.isParent === 1,
                                                            field: 'Yes'
                                                        }}
                                                        second={{
                                                            title: i18n.t(tokens.buttons.no),
                                                            selected: this.state.isParent === 0,
                                                            field: 'No'
                                                        }}
                                                        handleChange={(field) => {
                                                            if (field === 'Yes') {
                                                                this.setState({ isParent: 1 });
                                                            } else {
                                                                this.setState({ isParent: 0 });
                                                            }
                                                        }}
                                                    />
                                                </>
                                        }

                                        {
                                            Number(this.state.isParent) === 0 &&
                                                <React.Fragment>
                                                    <Text
                                                        small
                                                        display="block"
                                                        margin="10px 0 10px 0"
                                                    >
                                                        { i18n.t(tokens.labels.admin.forEachDivision, { type: this.state.isEdit ? this.state.firstname : i18n.t(tokens.labels.admin.theAdmin) }) }
                                                    </Text>

                                                    <div style={ styles.container }>
                                                        <Text small bold>{ i18n.t(tokens.labels.admin.noneSelected) }</Text>
                                                        <Text tiny margin="5px 0">
                                                            { i18n.t(tokens.labels.admin.adminWillNotBeAbleToView) }
                                                        </Text>
                                                    </div>

                                                    <div style={ styles.container }>
                                                        <Text small bold>{ i18n.t(tokens.labels.admin.readOnly) }</Text>
                                                        <Text tiny margin="5px 0">
                                                            { i18n.t(tokens.labels.admin.adminCanOnlyView) }
                                                        </Text>
                                                    </div>

                                                    <div style={ styles.container }>
                                                        <Text small bold>{ i18n.t(tokens.labels.admin.readAndWrite) }</Text>
                                                        <Text tiny margin="5px 0">
                                                            { i18n.t(tokens.labels.admin.adminCanViewAndManage) }
                                                        </Text>
                                                    </div>

                                                    <Text mediumLarge bold margin="10px 0 10px 0">
                                                        { i18n.t(tokens.labels.admin.divisions) }
                                                    </Text>

                                                    <div style={styles.list}>
                                                        {
                                                            helpers.sortObjByTitle(divisions).map((divisionID) => {
                                                                const division = divisions[divisionID];

                                                                return (
                                                                    <div key={divisionID} style={styles.listItem}>
                                                                        <Text avMedium>{ division.title }</Text>
                                                                        <BubbleSelectBlock
                                                                            allowToggleOff={true}
                                                                            first={{
                                                                                title: i18n.t(tokens.labels.admin.readOnly),
                                                                                selected: this.state.divisionsById[divisionID]?.access === 'Read',
                                                                                field: 'Read'
                                                                            }}
                                                                            second={{
                                                                                title: i18n.t(tokens.labels.admin.readAndWrite),
                                                                                selected: this.state.divisionsById[divisionID]?.access === 'Write',
                                                                                field: 'Write'
                                                                            }}
                                                                            handleChange={ (access) => {
                                                                                this._handleDivisionSelection(divisionID, access);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </React.Fragment>
                                        }
                                    </>
                            }
                        </Modal>
                }
            </React.Fragment>
        );
    }
}

Overview.propTypes = {
    loading: PropTypes.bool,
    administrators: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
    divisions: PropTypes.object,
    admin: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{admin: null, loading: boolean, administrators: Array, divisions: (default.census.divisions|{})}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    administrators: state.admin.administrators,
    admin: state.admin.meta,
    divisions: state.census.divisions,
    accountID: Number(getUserType()) === 1 ? state.advisor.currentAccountMeta.accountID : state.admin.account.accountID,
    whitelabel: state.whitelabel
});

export default (connect(mapStateToProps)(Overview));
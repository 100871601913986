import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Button, Loader, Invoice, InvoiceHistory, ContainerControlSite, Content, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as dates from '@simplybenefits/sb-dates';
import { getInvoices, getInvoice, clearSelectedInvoice, getCurrentInvoice } from '../../actions/invoices.js';
import store from '../../redux/store.js';
import { getUserType } from '../../services/session.js';
import { getAdminPdf } from '../../actions/admin.js';
import { getAdvisorPdf } from '../../actions/advisor.js';

class Invoices extends PureComponent {
    state = {
        selectedSection: 'invoices-history',
        isCurrent: false,
        invoiceMeta: null
    };
    
    componentDidMount() {
        const isAdvisor = Number(getUserType()) === 1;

        if (!this.props.currentInvoice && !this.props.selectedInvoice) {
            if (isAdvisor) {
                this.props.dispatch(getInvoices(this.props.accountID));
            } else {
                this.props.dispatch(getInvoices());
            }
        }
    }

    _selectHistoryHeader = () => {
        this.setState({
            selectedSection: 'invoices-history',
            isCurrent: false,
            invoiceMeta: null
        });

        this.props.dispatch(clearSelectedInvoice());
    };

    _selectOverview = async () => {
        await this.props.dispatch(clearSelectedInvoice());

        this.setState({
            selectedSection: 'invoices-overview',
            isCurrent: true
        });
    };

    _selectInvoice = async (invoiceMeta) => {
        const isAdvisor = Number(getUserType()) === 1;
        
        if (isAdvisor) {
            this.props.dispatch(getInvoice(invoiceMeta.invoiceNum, this.props.accountID));
        } else {
            this.props.dispatch(getInvoice(invoiceMeta.invoiceNum));
        }

        await this.setState({
            isCurrent: false,
            selectedSection: 'invoices-overview',
            invoiceMeta
        });
    };
    
    _getCurrentInvoice = async () => {
        await this.props.dispatch(getCurrentInvoice(this.props.accountID));

        await this.setState({
            isCurrent: true,
            selectedSection: 'invoices-overview'
        });
    };
    
    _downloadInvoice = async (isDivisionSummary, selectedDivisionID) => {
        const isAdvisor = Number(getUserType()) === 1;
        const fileName = `${ i18n.t(tokens.labels.invoiceFileName, { date: dates.getLocalDisplay(this.state.invoiceMeta?.dateAdded, 'MMMM_YYYY', i18n.locale) }) }`;
    
        if (isAdvisor) {
            return await this.props.dispatch(getAdvisorPdf("Invoice", fileName, { invoiceNum: this.state.invoiceMeta?.invoiceNum, accountID: this.props.accountID, isDivisionSummary: isDivisionSummary, selectedDivisionID }));
        }
    
        return await this.props.dispatch(getAdminPdf("Invoice", fileName, { invoiceNum: this.state.invoiceMeta?.invoiceNum, isDivisionSummary: isDivisionSummary, selectedDivisionID }));
    };

    _renderContent = (isAdvisor, invoice, whitelabel) => (
        <>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <Title title={ isAdvisor ? '' : 'Invoices' } customStyle={{ flex: 1, minHieght: "90px" }} />

                {
                    this.state.selectedSection === 'invoices-overview' ?
                        <Button
                            id="view-history"
                            color="primary"
                            customStyle={{
                                height: 50,
                                width: 180,
                                textTransform: "capitalize",
                                fontSize: 16
                            }}
                            onClick={this._selectHistoryHeader}
                        >
                            View History
                        </Button>
                        :
                        null
                }

            </div>

            {
                this.state.selectedSection === 'invoices-history' ?
                    <InvoiceHistory
                        history={this.props.history}
                        selectInvoice={this._selectInvoice}
                        getCurrentInvoice={this._getCurrentInvoice}
                        isChildAdmin={!this.props.admin.meta.isParent} // we need to know if the admin is a parent or child so that we can display the total on the invoice tile or not
                    />
                    :
                    !invoice || this.props.loader?.isLoading ?
                        <Loader/>
                        :
                        <Invoice
                            invoice={{
                                ...invoice,
                                meta: this.state.invoiceMeta
                            }}
                            isCurrent={ this.state.isCurrent }
                            whitelabel={ whitelabel }
                            account={ isAdvisor ? this.props.currentAccountMeta : this.props.account }
                            accountClasses={ this.props.accountClasses }
                            divisions={ (isAdvisor || this.props.admin.meta.isParent) ? this.props.divisions : this.props.admin.administrators[this.props.admin.meta.adminID].divisions }
                            hideOverview= { isAdvisor ? false : !this.props.admin.meta.isParent }
                            loading={ this.props.loading }
                            downloadInvoice={ this._downloadInvoice }
                        />
            }
        </>
    );

    render() {
        const isAdvisor = Number(getUserType()) === 1;
        const whitelabel = store.getState().whitelabel;
        const invoice = this.props.selectedInvoice || this.props.currentInvoice;

        if (isAdvisor) {
            return this._renderContent(isAdvisor, invoice, whitelabel);
        }

        return (
            <ContainerControlSite>
                <Content>
                    { this._renderContent(isAdvisor, invoice, whitelabel) }
                </Content>

                <span id="invoices" />
            </ContainerControlSite>
        );
    }
}

Invoices.propTypes = {
    loading: PropTypes.bool,
    history: PropTypes.array,
    currentInvoice: PropTypes.object,
    selectedInvoice: PropTypes.object
};

/**
 * @param state
 * @returns {{selectedBill: null, admin: null, history: Array, loading: boolean, currentBill: null}}
 * the meta state is only here so that if the advisor switches language while viewing an invoice, this component will rerender and display in the newly selected language
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    history: state.invoices.history,
    currentInvoice: state.invoices.current,
    selectedInvoice: state.invoices.selected,
    divisions: state.invoices.divisions,
    accountClasses: state.census.classes,
    account: state.admin.account,
    admin: state.admin,
    currentAccountMeta: state.advisor.currentAccountMeta,
    meta: state.advisor.meta
});

export default connect(mapStateToProps)(Invoices);
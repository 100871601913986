import * as invoicesXhr from '../xhr/invoices.js';
import * as types from './types/invoices.js';
import * as notifier from './notifier.js';
import * as loader from './loader.js';

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'full') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}

/**
 * @returns {function(*=): Promise<T | never>}
 */
export function getInvoices(accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return invoicesXhr.getInvoices(accountID)
            .then((invoices) => {
                dispatch({
                    type: types.GET_INVOICES_SUCCESS,
                    invoices
                });

                setLoader(dispatch, false);
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param invoiceNum
 * @param accountID
 * @returns {function(*=): Promise<any>}
 */
export function getInvoice(invoiceNum = false, accountID = false) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return invoicesXhr.getInvoice(invoiceNum, accountID)
            .then((data) => {
                dispatch({
                    type: types.GET_INVOICE_SUCCESS,
                    invoice: data.invoice
                });

                setLoader(dispatch, false);
                return data.invoice;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param invoiceNum
 * @param accountID
 * @returns {function(*=): Promise<any>}
 */
export function getCurrentInvoice(accountID = false) {

    return (dispatch) => {
        setLoader(dispatch, true);
        return invoicesXhr.getCurrentInvoice(accountID)
            .then((currentInvoice) => {
                dispatch({
                    type: types.GET_CURRENT_INVOICE_SUCCESS,
                    invoice: currentInvoice
                });

                setLoader(dispatch, false);
                return currentInvoice;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @returns {Function}
 */
export function clearSelectedInvoice() {
    return (dispatch) => {
        dispatch({
            type: types.CLEAR_SELECTED_INVOICE
        });
    };
}

/**
 * @param asoReportID
 * @returns {function(*=): Promise<T | never>}
 */
export function getAsoReport(asoReportID = false) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return invoicesXhr.getAsoReport(asoReportID)
            .then((data) => {
                setLoader(dispatch, false);
                return data.report;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as dates from '@simplybenefits/sb-dates';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import TrashIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Theme, Button, Text, i18n, tokens } from "@simplybenefits/sb-ui-library";
import { getPendingChangeData } from '../../../actions/admin';

/**
 * 
 * @param records
 * @param removeChange
 * @param dispatch 
 * @returns 
 */
const PendingChangesTable = ({ records, removeChange }) => {
    const [ pendingState, setPendingState ] = useState({
        selectedChangeID: null,
        pendingChanges: null
    });
    const dispatch = useDispatch();
    const divisions = useSelector((state) => state.census.divisions);
    const allowPendingChangeRemoval = Object.values(divisions).some((division) => division.access !== 'Read');

    /**
     * 
     * @param {*} changeID 
     * @returns 
     */
    const _selectChange = async (changeID) => {
        if (pendingState.selectedChangeID === changeID) {
            setPendingState({
                selectedChangeID: null,
                pendingChanges: null
            });

            return;
        }

        const pendingChanges = await dispatch(getPendingChangeData(changeID));

        setPendingState({
            selectedChangeID: changeID,
            pendingChanges
        });
    };

    const styles = {
        tableHeading: {
            whiteSpace: "nowrap",
            fontSize: Theme.fonts.size.medium,
            fontFamily: Theme.fonts.type.heading,
            backgroundColor: Theme.colors.surface,
            paddingLeft: "2vw",
            color: Theme.colors.mainTextGrey,
            height: '62px',
            border: 'none'
        },
        tableCell: {
            height: '62px',
            fontFamily: Theme.fonts.type.light,
            whiteSpace: "nowrap",
            paddingLeft: "2vw",
            color: Theme.colors.mainTextGrey,
            backgroundColor: Theme.colors.surface,
            borderTop: `1px solid ${ Theme.colors.lightGrey }`,
            borderBottom: 'none' 

        },
        tableContainer: {
            maxHeight: '620px', 
            boxShadow: `0 0 3px 0 rgba(151, 151, 151, 0.5)`,
            overflowY: 'auto'
        },
        icon: {
            color: Theme.colors.mediumGrey
        }
    };

    if (!records || records.length === 0) {
        return (
            <Text medium light align="left">
                { i18n.t(tokens.labels.admin.noPendingChanges) }
            </Text>
        );
    }

    return (
        <TableContainer style={ styles.tableContainer }>
            <Table stickyHeader>
                <TableHead >
                    <TableRow>
                        <TableCell style={ styles.tableHeading }>
                            { i18n.t(tokens.labels.type) }
                        </TableCell>

                        <TableCell style={ styles.tableHeading }>
                            { i18n.t(tokens.labels.admin.member) }
                        </TableCell>

                        <TableCell style={ styles.tableHeading }>
                            { i18n.t(tokens.labels.admin.profileCertificate) }
                        </TableCell>

                        <TableCell style={ styles.tableHeading }>
                            { i18n.t(tokens.labels.admin.changedBy) }
                        </TableCell>

                        <TableCell style={ styles.tableHeading }>
                            { i18n.t(tokens.labels.admin.processed) }
                        </TableCell>

                        <TableCell style={ styles.tableHeading }>
                            { i18n.t(tokens.labels.effectiveDate) }
                        </TableCell>

                        <TableCell style={styles.tableHeading} />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        records.map((item) => (
                            <React.Fragment key={item.changeID}>
                                <TableRow>
                                    <TableCell style={{ ...styles.tableCell, fontFamily: Theme.fonts.type.medium }}>
                                        { item.type }
                                    </TableCell>

                                    <TableCell style={ styles.tableCell}>
                                        { item.label }
                                    </TableCell>

                                    <TableCell style={ styles.tableCell}>
                                        { item.profile }
                                    </TableCell>

                                    <TableCell style={ styles.tableCell}>
                                        { item.changedBy }
                                    </TableCell>

                                    <TableCell style={ styles.tableCell}>
                                        { dates.getLocalDisplay(item.occurred, 'lll', i18n.locale) }
                                    </TableCell>

                                    <TableCell style={ styles.tableCell}>
                                        { dates.getStartOfDay(item.effectiveDate, 'll', i18n.locale) }
                                    </TableCell>

                                    <TableCell style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', ...styles.tableCell }}>
                                        {
                                            allowPendingChangeRemoval &&
                                                <Button customStyle={ styles.icon } transparent onClick={ () => removeChange(item.changeID) }>
                                                    <TrashIcon />
                                                </Button>
                                        }
                                       
                                        {
                                            [ i18n.t(tokens.labels.employees.memberUpdate), i18n.t(tokens.labels.employees.dependentUpdate), i18n.t(tokens.labels.employees.beneficiaryUpdate), i18n.t(tokens.labels.employees.massSalaryUploaded) ].indexOf(item.type) !== -1 &&
                                                    <Button customStyle={styles.icon} transparent onClick={ () => _selectChange(item.changeID) }>
                                                        {
                                                            pendingState.selectedChangeID === item.changeID ?
                                                                <KeyboardArrowUpIcon /> 
                                                                :
                                                                <KeyboardArrowDownIcon />
                                                        }
                                                    </Button>
                                        }
                                    </TableCell>
                                </TableRow>

                                {
                                    pendingState.selectedChangeID === item.changeID &&
                                        <TableRow>
                                            <TableCell style={{ ...styles.tableCell, borderTop: 'none' }} colSpan={8}>
                                                {
                                                    pendingState.pendingChanges.map((change, changeIndex) => (
                                                        <Text key={changeIndex} small margin="5px 0">{ change }</Text>
                                                    ))
                                                }
                                            </TableCell>
                                        </TableRow>
                                }
                            </React.Fragment>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

PendingChangesTable.propTypes = {
    records: PropTypes.array
};

export default PendingChangesTable;
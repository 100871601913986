import React, { PureComponent } from 'react';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { Text, Theme, i18n, tokens } from '@simplybenefits/sb-ui-library';
import { styled } from '@mui/system';
import { connect } from 'react-redux';

const BlockRow = styled('div')({
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: 60,
    marginBottom: 5,
    // borderLeft: selected && `5px solid ${ Theme.colors.skyBlue }`,
    boxShadow: "0 1px 2px rgb(0 0 0 / 15%)",
    '&:hover': {
        filter: "brightness(97%)"
    }
});

const NameBlock = styled('div')({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
    paddingLeft: "10px",
    backgroundColor: Theme.colors.lightBlue
});


class BookletRow extends PureComponent {
    state = {
        anchorEl: null,
        employee: null
    };

    _handleMenuClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    _handleCloseMenu = () => {
        this.setState({ anchorEl: false });
    };

    render() {
        const { classDetails, isHeader, employee, accountID } = this.props;

        const styles = {
            headerBlockRow: {
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                flexDirection: 'row',
                height: 30,
                marginBottom: 5
            },
            blockRowBody: {
                display: 'flex',
                flex: 3,
                alignItems: 'center',
                flexDirection: 'row',
                height: '100%',
                paddingLeft: "10px"
            },
            blockRowBodyTitle: {
                display: 'flex',
                flex: 3,
                alignItems: 'center',
                flexDirection: 'row',
                height: '100%'
            },
            blockRowSelected: {
                borderLeft: `5px solid ${ Theme.colors.skyBlue }`
            },
            nameBlock: {
                flex: 1,
                display: 'flex',
                alignItems: 'flex-start',
                backgroundColor: Theme.colors.lightBlue,
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                marginLeft: "10px"
            },
            data: {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'end',
                marginLeft: 'auto'
            }
        };

        if (isHeader) {
            return (
                <div style={styles.headerBlockRow}>
                    <div style={{ ...styles.nameBlock, backgroundColor: Theme.colors.backgroundGrey }}>
                        <Text small bold>{ i18n.t(tokens.labels.class) }</Text>
                    </div>

                    <div style={ styles.blockRowBodyTitle }>
                        <Text small bold>{ i18n.t(tokens.labels.classDescription) }</Text>
                        <div style={{ width: 50 }} />
                    </div>
                </div>
            );
        }

        return (
            <BlockRow > 
                <NameBlock>
                    <Text medium>{ classDetails.title }</Text>
                </NameBlock>

                <div style={ styles.blockRowBody }>
                    <Text medium light>
                        { classDetails.description }
                    </Text>
                </div>

                <div>
                    <IconButton aria-label="Edit" onClick={this._handleMenuClick} style={{ width: 48, height: 48, color: Theme.colors.mainTextGrey }}>
                        <MoreVertIcon style={{ color: Theme.colors.mainTextGrey }}/>
                    </IconButton>

                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this._handleCloseMenu}
                        disableAutoFocusItem={true}
                        style={styles.menu}
                    >
                        <MenuItem
                            onClick={ () => {
                                this._handleCloseMenu();
                                this.props.download(classDetails, employee, accountID);
                            }}
                        >
                            <Text color="inherit" medium display="inline">
                                { i18n.t(tokens.buttons.downloadClassBooklet) }
                            </Text>
                        </MenuItem>
                    </Menu>
                </div>
            </BlockRow>
        );
    }
}

export default (connect()(BookletRow));
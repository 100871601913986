import * as quotes from '../xhr/quotes.js';
import * as types from './types/quotes.js';
import * as notifier from './notifier.js';
import * as loader from './loader.js';
import { i18n, tokens } from '@simplybenefits/sb-ui-library';

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'full') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}

/**
 * @param body
 * @returns {function(*=): Promise<boolean | never>}
 */
export function createQuote(body) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return quotes.createQuote(body)
            .then((data) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.quoteCreatedSuccessfully)));

                setTimeout(() => {
                    window.location = `/advisor/quotes/${ data.quoteID }`;
                }, 500);

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param quoteID
 * @param body
 * @returns {function(*=): (Promise<any> | * | undefined | Promise<void>)}
 */
export function editQuote(quoteID, body) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return quotes.editQuote(quoteID, body)
            .then(() => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.quoteEditedSuccessfully)));

                setTimeout(() => {
                    window.location.reload();
                }, 500);

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param quoteID
 * @returns {function(*): *}
 */
export function convertQuote(quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return quotes.convertQuote(quoteID)
            .then((accountID) => {
                dispatch(notifier.displayMessage(`Quote successfully converted!`));
                window.location.href = `/advisor/account/${ accountID }`;

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function getQuote(quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return quotes.getQuote(quoteID)
            .then((data) => {
                dispatch({
                    type: types.GET_QUOTE_SUCCESS,
                    data
                });

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param planType
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function savePlanType(planType, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return quotes.savePlanType(planType, quoteID)
            .then(() => {
                dispatch({
                    type: types.SET_PLAN_TYPE,
                    planType,
                    quoteID
                });

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param classID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function removeClass(classID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return quotes
            .removeClass(classID, quoteID)
            .then(() => {
                dispatch({
                    type: types.REMOVE_CLASS_SUCCESS,
                    data: {
                        quoteID,
                        classID
                    }
                });

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.classRemovedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param divisionID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function removeDivision(divisionID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return quotes
            .removeDivision(divisionID, quoteID)
            .then(() => {
                dispatch({
                    type: types.REMOVE_DIVISION_SUCCESS,
                    data: {
                        quoteID,
                        divisionID
                    }
                });

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.divisionRemovedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import * as admin from '../xhr/admin.js';
import * as session from '../services/session.js';
import * as types from './types/admin.js';
import * as censusTypes from './types/census.js';
import * as planTypes from './types/plan.js';
import * as notifier from './notifier.js';
import * as events from '../services/events';
import { history } from '../redux/store.js';
import { generatePdf } from '../xhr/generatePdf.js';
import * as loader from './loader.js';
import { i18n, tokens } from '@simplybenefits/sb-ui-library';

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'full') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}

/**
 *
 * @param {*} dispatch
 * @param {*} state
 */
function setBankingDetailsVerifying(dispatch, state) {
    dispatch({
        type: types.BANKING_DETAILS_VERIFYING,
        state
    });
}

/**
 * Login as an Admin
 * @param username
 * @param password
 */
export function login(username, password, language = "en") {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin.login(username, password, language)
            .then((data) => {
                session.setAdminLanguage(data.language);
                session.setToken(data.token);
                session.setUserType(0);
                events.record('login', { adminID: data.adminID });
                setLoader(dispatch, false);
                window.location.href = '/';

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param email
 * @param language
 * @returns {function(*): Promise<boolean>}
 */
export function forgotPassword(email, language = "en") {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin
            .sendForgotPasswordEmail(email, language)
            .then(() => {
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.checkInboxForPasswordRecoveryEmail)));
                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @returns {function(*): Promise<T | never>}
 */
export function getAccount() {
    return (dispatch) => (
        admin
            .getAccount()
            .then((data) => {
                session.setToken(data.token);
                i18n.setLocale(data.meta.language);

                dispatch({
                    type: types.GET_ADMIN_ACCOUNT_SUCCESS,
                    meta: data.meta,
                    administrators: data.admins,
                    notifications: data.notifications,
                    account: data.account,
                    changelog: data.changelog,
                    pendingChanges: data.pendingChanges
                });

                if (data.notifications && data.notifications.length > 0) {
                    dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.youHaveNewNotifications)));
                }

                dispatch({
                    type: censusTypes.GET_CENSUS_SUCCESS,
                    users: data.census
                });

                dispatch({
                    type: censusTypes.GET_CLASSES_SUCCESS,
                    classes: data.classes
                });

                dispatch({
                    type: censusTypes.GET_DIVISIONS_SUCCESS,
                    divisions: data.divisions
                });

                dispatch({
                    type: planTypes.GET_PLAN_SUCCESS,
                    plan: data.plan
                });

                dispatch({
                    type: planTypes.SET_SPECIALITY_PRODUCT_CONFIGS,
                    data: data.specialityProductMetaConfigs
                });

                return true;
            })
            .catch((error) => {
                dispatch(notifier.displayError(error));

                return false;
            })
    );
}

/**
 *
 * @param {*} accountID
 * @param {*} body
 */
export function editAccount(accountID, body) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin
            .updateAccount(body)
            .then(() => {
                dispatch({
                    type: types.UPDATE_ADMIN_ACCOUNT_SETTINGS_SUCCESS,
                    accountID,
                    data: body
                });

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.accountUpdatedSuccessfully)));

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param data
 * @param accountID
 * @returns {function(*=): Promise<boolean | never>}
 */
export function createAdmin(data, accountID) {
    const isAdvisor = Number(session.getUserType()) === 1;

    return (dispatch) => {
        setLoader(dispatch, true, isAdvisor);

        return admin.create(data, accountID, isAdvisor)
            .then((response) => {
                dispatch({
                    type: types.CREATE_ADMIN_SUCCESS,
                    data: {
                        ...data,
                        adminID: response.adminID,
                        accountID: accountID,
                        isParent: data.isParent,
                        isPlanSponsor: 0
                    }
                });

                setLoader(dispatch, false, isAdvisor);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.adminCreatedSuccessfully)));
                events.record('create_admin');
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, isAdvisor);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param adminID
 * @returns {function(*): Promise<boolean | never>}
 */
export function removeAdmin(adminID) {
    const isAdvisor = Number(session.getUserType()) === 1;
    return (dispatch) => (
        admin.remove(adminID, isAdvisor)
            .then(() => {
                dispatch({
                    type: types.REMOVE_ADMIN_SUCCESS,
                    adminID
                });

                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.adminRemovedSuccessfully)));
                events.record('remove_admin');
                return true;
            })
            .catch((error) => {
                dispatch(notifier.displayError(error));
            })
    );
}

/**
 * @param data
 * @param adminID
 * @returns {function(*=): Promise<boolean | never>}
 */
export function updateAdmin(data, adminID) {
    const isAdvisor = Number(session.getUserType()) === 1;
    return (dispatch) => (
        admin.update(data, adminID, isAdvisor)
            .then(() => {
                dispatch({
                    type: types.UPDATE_ADMIN_SUCCESS,
                    data: {
                        ...data,
                        adminID
                    }
                });

                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.adminUpdatedSuccessfully)));
                events.record('edit_admin');
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            })
    );
}

/**
 * @param data
 * @returns {function(*=): Promise<T | never>}
 */
export function updateAdminSettings(data) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin.updateAdminSettings(data)
            .then(() => {
                const adminLanguage = data?.language || "en";
                i18n.setLocale(adminLanguage);

                const storedLanguage = session.getAdminLanguage();
                if (storedLanguage !== adminLanguage) {
                    session.setAdminLanguage(adminLanguage);
                }

                dispatch({
                    type: types.UPDATE_ADMIN_SETTINGS_SUCCESS,
                    data
                });

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.updatedAdminSettings)));

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param existingPassword
 * @param newPassword
 * @returns {function(*=): Promise<boolean>}
 */
export function changeAdminPassword(existingPassword, newPassword) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin
            .changePassword(existingPassword, newPassword)
            .then(() => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.passwordChangedSuccessfully)));
                events.record('change_password');

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @returns {Function}
 */
export function logout() {
    const userType = session.getUserType();
    session.removeToken();
    session.removeUserType();

    events.record('logout');

    if (Number(userType) === 1) {
        history.push('/advisor/login');
    } else {
        history.push('/login');
    }

    return (dispatch) => {
        dispatch({
            type: types.LOGOUT
        });
    };
}

/**
 * @param data
 * @returns {function(*=): Promise<T | never>}
 */
export function notifyAllEmployees(data) {
    return (dispatch) => {
        setLoader(dispatch, true, false);
        return admin.notifyAllUsers(data)
            .then(() => {
                setLoader(dispatch, false, false);
                events.record('notify_all_employees');
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.notificationsSentSuccessfully)));
                return null;
            })
            .catch((error) => {
                setLoader(dispatch, false, false);

                if (error.code === 'CREDENTIALS_FAILED') {
                    return error.verbiage;
                }

                dispatch(notifier.displayError(error));
                return null;
            });
    };
}

/**
 * @param userID
 * @param data
 * @returns {function(*=): Promise<T | never>}
 */
export function notifyEmployee(userID, data) {
    return (dispatch) => {
        setLoader(dispatch, true, false);
        return admin.notifyUser(userID, data)
            .then(() => {
                setLoader(dispatch, false, false);
                events.record('notify_employee');
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.notificationsSentSuccessfully)));
                return null;
            })
            .catch((error) => {
                setLoader(dispatch, false, false);

                if (error.code === 'CREDENTIALS_FAILED') {
                    return error.verbiage;
                }

                dispatch(notifier.displayError(error));
                return null;
            });
    };
}

/**
 * @param data
 * @returns {function(*=): Promise<boolean | never>}
 */
export function saveBankingDetails(data) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin
            .saveBankingDetails(data)
            .then(() => {
                dispatch({
                    type: types.PAYMENT_DETAILS_SUCCESS
                });

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.savedPaymentDetails)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param userID
 * @param data
 */
export function saveMemberBankingDetails(userID, data) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin
            .saveMemberBankingDetails(userID, data)
            .then(() => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.savedPaymentDetails)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getMemberBankingDetails(userID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return admin
            .getMemberBankingDetails(userID)
            .then((data) => {
                setLoader(dispatch, false);
                return data;

            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export function getAccountBankingDetails(accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return admin
            .getAccountBankingDetails(accountID)
            .then((data) => {
                setLoader(dispatch, false);
                return data;

            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param id
 * @returns
 */
export function removeAdminNotification(id) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin
            .removeNotification(id)
            .then(() => {
                dispatch({
                    type: types.REMOVE_NOTIFICATION_SUCCESS,
                    id
                });

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param userID
 * @param accountID
 * @returns {function(*=): Promise<any>}
 */
export function getEmployee(userID, accountID) {
    return (dispatch) => {
        setLoader(dispatch, true, false);
        return admin.getEmployee(userID, accountID)
            .then((data) => {
                setLoader(dispatch, false, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param startDate
 * @param endDate
 * @returns {function(*): (Q.Promise<boolean> | * | undefined | Promise<any>)}
 */
export function getChangelog(startDate, endDate) {
    return (dispatch) => {
        setLoader(dispatch, true, false);

        return admin.getChangelog(startDate, endDate)
            .then((data) => {
                dispatch({
                    type: types.GET_CHANGELOG,
                    changelog: data
                });

                setLoader(dispatch, false, false);

                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @returns {function(*): (Q.Promise<boolean> | * | undefined | Promise<any>)}
 */
export function getPendingChanges() {
    return (dispatch) => {
        setLoader(dispatch, true, false);

        return admin.getPendingChanges()
            .then((data) => {
                dispatch({
                    type: types.GET_CHANGELOG,
                    pendingChanges: data
                });

                setLoader(dispatch, false, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @returns {function(*): (Q.Promise<boolean> | * | undefined | Promise<any>)}
 */
export function getPendingChangeData(changeID) {
    return (dispatch) => {
        setLoader(dispatch, true, false);

        return admin.getPendingChangeData(changeID)
            .then((data) => {
                setLoader(dispatch, false, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param changeID
 * @returns {function(*=): Promise<unknown | boolean>}
 */
export function removePendingChange(changeID) {
    return (dispatch) => {
        setLoader(dispatch, true, false);

        return admin.removePendingChange(changeID)
            .then(() => {
                setLoader(dispatch, false, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @returns {function(*=): Promise<any>}
 */
export function downloadSalaryUpdateData() {
    return (dispatch) => {
        setLoader(dispatch, true, false);
        return admin.downloadSalaryUpdateData()
            .then((data) => {
                const date = new Date().toLocaleDateString();
                const options = {
                    fieldSeparator: ',',
                    filename: i18n.t(tokens.labels.massSalaryDownloadFileName, { date: date }),
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    showLabels: true,
                    showTitle: false,
                    title: `Member Salary and Wage Data - ${ date }`,
                    useTextFile: false,
                    useBom: true,
                    useKeysAsHeaders: true
                };

                const csvExporter = new ExportToCsv(options);
                csvExporter.generateCsv(data);

                setLoader(dispatch, false, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param form
 * @returns {function(*=): Promise<boolean | never>}
 */
export function uploadMassSalaryForm(form) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return admin.uploadMassSalaryForm(form)
            .then(() => {
                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.usersAddedSuccessfully)));

                setTimeout(() => {
                    window.location.reload();
                }, 1000);

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 *
 * @param body
 * @returns {function(*): Promise<boolean>}
 */
export function uploadMassUpdateForm(body) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return admin.uploadMassUpdateForm(body)
            .then((result) => {
                setLoader(dispatch, false);
                if (result.isSuccessful === true) {
                    dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.uploadedMassUpdateSuccessfully)));
                }

                if ('errors' in result) {
                    dispatch(notifier.displayError('There were some errors while uploading the file'));
                }

                return result;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @returns {function(*): Promise<*>}
 */
export function getLsaItems() {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin.getLsaItems()
            .then((items) => {
                setLoader(dispatch, false);
                return items;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * 
 * @param {*} ComponentName 
 * @param {*} downloadParams 
 * @returns 
 */
export function getAdminPdf(ComponentName, fileName, downloadParams = {}, setLoaderState = false) { 
    return (dispatch) => {

        if (setLoaderState) {
            setLoader(dispatch, true);
        }

        return generatePdf(ComponentName, downloadParams, false)
            .then((data) => {
                if (data.isSentViaEmail === true) {
                    return true;
                }

                const linkSource = `data:application/pdf;base64,${ data }`;
                const downloadLink = document.createElement("a");

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

                if (setLoaderState) {
                    setLoader(dispatch, false);
                }

                return data; 
            })
            .catch((error) => {

                if (setLoaderState) {
                    setLoader(dispatch, false);
                }

                dispatch(notifier.displayError(error));
            });
    };
}

/**
 *
 * @param institutionNum
 * @param transitNum
 * @returns {function(*): Promise<*>}
 */
export function validateBankingDetails(institutionNum, transitNum) {
    return (dispatch) => {
        setBankingDetailsVerifying(dispatch, true);

        return admin.validateBankingDetails(institutionNum, transitNum)
            .then((data) => {
                setBankingDetailsVerifying(dispatch, false);
                return data;
            })
            .catch((error) => {
                setBankingDetailsVerifying(dispatch, false);
                dispatch(notifier.displayError(error));
                return error;
            });
    };
}

/**
 * @param password
 * @param token
 */
export function resetPassword(password, token) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return admin
            .resetPassword(password, token)
            .then(() => {
                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MessageCard, QuoteCard, Button, MetaModal, ContainerControlSite, Content, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';
import store from '../../../redux/store.js';
import { createQuote } from '../../../actions/quotes.js';

class Quotes extends Component {
    state = {
        showModal: false
    };

    _showModal = () => {
        this.setState({
            showModal: true
        });
    };

    _hideModal = () => {
        this.setState({
            showModal: false
        });
    };

    render() {
        const whitelabel = store.getState().whitelabel;
        const styles = {
            titleBlock: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center'
            }
        };

        return (
            <ContainerControlSite>
                <Content>
                    <div style={ styles.titleBlock }>
                        <Title title={ i18n.t(tokens.titles.quotes) } customStyle={{ flex: 1 }} />

                        {
                            this.props.quotes.length > 0 &&
                                <Button
                                    id="view-history"
                                    color="primary"
                                    customStyle={{
                                        height: 50,
                                        width: 180,
                                        textTransform: "capitalize",
                                        fontSize: 16
                                    }}
                                    onClick={ this._showModal }
                                >
                                    { i18n.t(tokens.buttons.createQuote) }
                                </Button>
                        }
                    </div>

                    {
                        this.props.quotes.length === 0 ?
                            <MessageCard
                                title={i18n.t(tokens.labels.quotes.noQuotes)}
                                subtitle={i18n.t(tokens.labels.quotes.youDoNotHaveAnyQuotes)}
                                btn
                                btnName={ i18n.t(tokens.buttons.createQuote) }
                                btnOnClick={this._showModal}
                            />
                            :
                            this.props.quotes.map((quote) => (
                                <QuoteCard
                                    key={ quote.quoteID }
                                    quote={quote}
                                    whitelabel={whitelabel}
                                    link={
                                        quote.status.toUpperCase() === 'SOLD' ?
                                            `/advisor/account/${ quote.accountID }`
                                            :
                                            `/advisor/quotes/${ quote.quoteID }`
                                    }
                                />
                            ))
                    }

                    <MetaModal
                        hideModal={this._hideModal}
                        showModal={this.state.showModal}
                        loading={this.props.loading}
                        create={ (...params) => this.props.dispatch(createQuote(...params)) }
                        isMasterSite={ false }
                        advisorMeta={ this.props.advisor }
                        whitelabel={ whitelabel }
                    />
                </Content>
                <span id="quotes" />
            </ContainerControlSite>
        );
    }
}

Quotes.propTypes = {
    quotes: PropTypes.array
};

/**
 * @param state
 * @returns {{accounts: Array, loading: boolean}}
 * The Advisor object is required here because when we change the advisors language, we need the entire component to rerender
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    quotes: state.quotes.all,
    advisor: state.advisor.meta
});

export default (connect(mapStateToProps)(Quotes));
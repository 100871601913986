import React from 'react';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import PropTypes from 'prop-types';
import { Theme } from "@simplybenefits/sb-ui-library";
import { useTheme } from '@mui/material/styles';

/**
 * @param x
 * @returns {string}
 */
function _numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * @param column
 * @param item
 * @returns {*}
 * @private
 */
function _getValue(column, item) {
    if (column.type === "dollar") {
        return `$${ _numberWithCommas(item[column.title].toFixed(2)) }`;
    }

    return item[column.title] || 'N/A';
}

/**
 * @param report
 * @param classes
 * @returns {*}
 * @constructor
 */
const ReportTable = ({ report }) => {
    const theme = useTheme();
    const styles = {
        table: {
            display: 'block',
            overflowX: 'auto',
            paddingBottom: '20px',
            width: '100%'
        },
        scrollbar: {
            '&::WebkitScrollbar': {
                height: '15px',
                width: '15px'
            },
            '&::WebkitScrollbarThumb': {
                backgroundColor: Theme.colors.surface,
                borderRadius: '10px',
                boxShadow: 'inset 0 0 2px rgba(0, 0, 0, .5)'
            },
            '&::WebkitScrollbarTrack': {
                backgroundColor: Theme.colors.backgroundGrey,
                borderRadius: '10px',
                boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.2)'
            }
        },
        tableHeading: {
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingRight: "2vw",
            paddingLeft: "2vw",
            fontFamily: Theme.fonts.type.medium,
            color: Theme.colors.surface,
            whiteSpace: "nowrap",
            borderBottom: "none"
        },
        tableCell: {
            paddingTop: "4px",
            paddingBottom: "4px",
            fontFamily: Theme.fonts.type.base,
            whiteSpace: "nowrap",
            paddingRight: "2vw",
            paddingLeft: "2vw",
            color: Theme.colors.mainTextGrey
        }
    };
    
    return (
        <div style={{ overflow: "auto", maxHeight: 600, ...styles.scrollbar }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: theme.palette.primary.main, borderTop: "2px solid #FFFFFF" }}>
                        {
                            report.columns.map((column) => (
                                <TableCell key={column.title} sx={styles.tableHeading}>
                                    { column.title }
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        report.items.map((item, index) => {
                            // TODO: fix key mapped to index
                            let backgroundObject = { border: "none" };
                            if (index % 2 !== 0) {
                                backgroundObject = { backgroundColor: Theme.colors.backgroundGrey, border: "none" };
                            }

                            return (
                                <TableRow key={index}>
                                    {
                                        report.columns.map((column, i) => (
                                            // TODO: fix key mapped to index incorrectly 
                                            <TableCell key={i} sx={{ ...styles.tableCell, ...backgroundObject }}>
                                                { _getValue(column, item) }
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </div>
    );
};

ReportTable.propTypes = {
    report: PropTypes.object
};

export default (ReportTable);
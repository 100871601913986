import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AmendmentDisplay, Loader, MessageCard, Text, Theme, ContainerControlSite, Content, Logo, ContentBlock, i18n, tokens, Button } from '@simplybenefits/sb-ui-library';
import { getAmendmentAuthorityView, completeAmendmentApproval, getAmendmentPDF } from '../../../actions/advisor.js';

class AmendmentAuthorityView extends Component {
    state = {
        amendmentData: null,
        errors: {},
        language: 'en'
    };

    async componentDidMount() {
        const amendmentID = this.props.match.params.amendmentID;
        const accountID = this.props.match.params.accountID;
        const uniqueKey = this.props.match.params.uniqueKey;

        const amendmentData = await this.props.dispatch(getAmendmentAuthorityView(accountID, uniqueKey, amendmentID));

        if (amendmentData) {
            this.setState({
                amendmentData
            });
        }
    }

    _completeApproval = (...params) => {
        this.props.dispatch(completeAmendmentApproval(...params, this.props.match.params.accountID, this.props.match.params.amendmentID));
    };

    _downloadAmendmentPDF = async () => {
        const accountID = this.props.match.params.accountID;
        const amendmentID = this.props.match.params.amendmentID;
        const fileName = `amendment_${ this.props.match.params.amendmentID }.pdf`;
        await this.props.dispatch(getAmendmentPDF("Amendment", fileName, { accountID, amendmentID, language: this.state.language }));
    };

    render() {
        const { whitelabel } = this.props;

        if (!this.state.amendmentData) {
            return <Loader/>;
        }

        return (
            <ContainerControlSite background="true" id="content">
                <Logo whitelabel={ whitelabel } customStyle={{ paddingTop: 20 }} />

                <Content>

                    <Button
                        onClick={ () => {
                            i18n.toggleLocale();
                            this.setState({ language: this.state.language === "en" ? "fr" : "en" });
                        }}
                    >
                        { this.state.language === "en" ? "Français" : "English" }
                    </Button>

                    {
                        this.state.amendmentData.amendment.status.toUpperCase() === 'APPROVED' ?
                            <MessageCard
                                title={ i18n.t(tokens.labels.amendments.amendmentApprovedMemo) }
                                subtitle={ i18n.t(tokens.labels.amendments.amendmentYouAlreadySigned) }
                                btn
                                btnName={ i18n.t(tokens.buttons.downloadPdf) }
                                btnOnClick={this._downloadAmendmentPDF}
                            />
                            :
                            <MessageCard
                                title={ i18n.t(tokens.labels.amendments.amendmentFormUnderReview) }
                                subtitle={ i18n.t(tokens.labels.amendments.amendmentPleaseReview) }
                                btn
                                btnName={ i18n.t(tokens.buttons.downloadPdf)}
                                btnOnClick={this._downloadAmendmentPDF}
                            />
                    }

                    <ContentBlock>
                        <div style={{ padding: "30px 0 10px 30px" }}>
                            <Text
                                extraLarge
                                veryBold
                                color={ Theme.colors.mainTextGrey }
                                customStyle={{ flex: '1' }}
                            >
                                { i18n.t(tokens.labels.amendments.amendmentForm) }
                            </Text>
                        </div>

                        <AmendmentDisplay
                            whitelabel={ this.props.whitelabel }
                            amendment={ this.state.amendmentData.amendment }
                            classData={ this.state.amendmentData.classData }
                            isAuthorityView={ true }
                            completeApproval={ this._completeApproval }
                        />
                    </ContentBlock>
                </Content>
            </ContainerControlSite>
        );
    }
}


AmendmentAuthorityView.propTypes = {
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(AmendmentAuthorityView);
import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { Button, Text, Theme, i18n, tokens, localizationMaps } from "@simplybenefits/sb-ui-library";
import LossRatio from './Layouts/LossRatio.js';
import ReportTable from './Layouts/ReportTable.js';
import * as dates from "@simplybenefits/sb-dates";
import store from '../../redux/store.js';
import LTDReport from './Layouts/LTDReport.js';
import { getAdminPdf } from '../../actions/admin.js';
import { getAdvisorPdf } from '../../actions/advisor.js';

class Report extends PureComponent {
    componentDidMount() {
        const content = document.querySelector('#content');

        content.scroll({ top: 900, left: 0, behavior: 'smooth' });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.report !== this.props.report) {
            const content = document.querySelector('#content');

            content.scroll({ top: 900, left: 0, behavior: 'smooth' });
        }
    }

    // eslint-disable-next-line require-await
    _downloadCSV = (report) => {
        // format the rows to show real dates instead of unix timestamps
        const items = report.items.map((item) => {
            if (item['Birth Date']) {
                item['Birth Date'] = dates.getStartOfDay(item['Birth Date'], 'll', i18n.locale);
            }

            if (item['Hire Date']) {
                item['Hire Date'] = dates.getStartOfDay(item['Hire Date'], 'll', i18n.locale);
            }

            return item;
        });

        const reportType = report.reportType;
        let title = `${ localizationMaps.fromEnglish("reportTypes", reportType) } ${ i18n.t(tokens.labels.reports.report) } `;
        if (this.props.dateRange) {
            title += `(${ dates.getStartOfDay(this.props.dateRange.startDate, 'll', i18n.locale) } - ${ dates.getStartOfDay(this.props.dateRange.endDate, 'll', i18n.locale) })`;
        }

        const { reportMeta, titleExpansionKeys } = this.props;

        if (Array.isArray(titleExpansionKeys) && titleExpansionKeys.length > 0) {
            const titleExpansion = titleExpansionKeys
                .filter((key) => (key in reportMeta))
                .map((key) => `(${ localizationMaps.fromEnglish("reportTitleExpansionKeys", key) }: ${ reportMeta[key] })`)
                .join(' ');

            title += `/ ${ titleExpansion }`;
        }

        const options = {
            title,
            fieldSeparator: ',',
            filename: `${ localizationMaps.fromEnglish("reportTypes", reportType).replace(/ /g, '-') }-${ i18n.t(tokens.labels.reports.report) }-${ dates.getLocalDisplay(new Date(), 'YYYY-MM-DD', i18n.locale) }`,
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(items);
    };

    _downloadLtdReport = async (report, isAdvisor, accountID, adminID) => {
        if (isAdvisor) {
            // need to send extra info for advisor because data is not sent in req.admin
            await this.props.dispatch(getAdvisorPdf("LtdReport", i18n.t(tokens.labels.ltdFileName), { filters: report.filters, groupNum: report.groupNum, accountID, adminID }));
        } else {
            await this.props.dispatch(getAdminPdf("LtdReport", i18n.t(tokens.labels.ltdFileName), { filters: report.filters, groupNum: report.groupNum }));
        }
    };

    _downloadLossRatioReport = async (report, accountID, company) => { 
        await this.props.dispatch(getAdvisorPdf("LossRatio", i18n.t(tokens.labels.lossRatioFileName), { divisionFilter: report.divisionFilter, dateRangeFilter: report.dateRangeFilter, accountID, company, groupNum: report.groupNum }));
    };

    render() {
        const { report, dateRange, scrollRef } = this.props;
        const { advisor } = store.getState();
        const styles = {
            contentBlock: {
                backgroundColor: Theme.colors.surface,
                boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
                display: 'block',
                margin: 'auto auto 30px auto',
                padding: '30px',
                position: 'relative',
                zIndex: Theme.zIndex.container,
                marginTop: 30
            },
            titleBlock: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center'
            }
        };

        return (
            <div style={ styles.contentBlock } ref={scrollRef}>
                <div style={ styles.titleBlock }>
                    <div style={{ flex: 1 }}>
                        <Text
                            largest
                            bold
                            color={ Theme.colors.mainTextGrey }
                            margin="0"
                        >
                            { localizationMaps.fromEnglish("reportTypes", report.reportType) }
                        </Text>

                        {
                            dateRange &&
                                <Text
                                    small
                                    bold
                                    color={ Theme.colors.mainTextGrey }
                                    margin="0 0 20px 0"
                                    customStyle={{ flex: 1 }}
                                >
                                    {
                                        `${ dateRange.startDate } - ${ dateRange.endDate }`
                                    }
                                </Text>
                        }
                    </div>

                    {
                        (report.items && report.items !== {} && report.items.length !== 0)
                        && [ 'HSA Balance', 'LSA Balance', 'Spending Account Usages', 'Dependent Listing', 'Overage Dependent Listing', 'Taxable Benefit', 'ASO Monthly Summary', 'Certificate Listing', 'NEM Eligibility' ].indexOf(report.reportType) > -1 &&
                            <Button transparent onClick={() => this._downloadCSV(report)}>
                                { i18n.t(tokens.buttons.download) }
                            </Button>
                    }

                    {
                        (report.items && report.items !== {} && report.items.length !== 0)
                        && report.reportType === 'Loss Ratio' &&
                            <Button transparent onClick={() => this._downloadLossRatioReport(report, advisor.currentAccountMeta.accountID, advisor.currentAccountMeta.company)}>
                                { i18n.t(tokens.buttons.download) }
                            </Button>
                    }

                    {
                        (report.items && report.items !== {} && report.items.length !== 0)
                        && report.reportType === 'LTD Report' &&
                            <Button transparent onClick={() => this._downloadLtdReport(report, advisor.currentAccountMeta !== null, advisor.currentAccountMeta?.accountID, advisor.currentAccountMeta?.adminID)}>
                                { i18n.t(tokens.buttons.download) }
                            </Button>
                    }
                </div>

                {
                    (!report.items || report.items === {} || report.items.length === 0) ?
                        <Text large>{ i18n.t(tokens.labels.reports.noRecords) }</Text>
                        :
                        <React.Fragment>
                            {
                                report.reportType === 'Loss Ratio' &&
                                    <LossRatio report={ report.items } />
                            }

                            {
                                report.reportType === 'LTD Report' &&
                                    <LTDReport report={ report.items } />
                            }

                            {
                                [ 'HSA Balance', 'LSA Balance', 'Spending Account Usages', 'Dependent Listing', 'Overage Dependent Listing', 'Taxable Benefit', 'Over Age Member Listing', 'ASO Monthly Summary', 'Certificate Listing', 'NEM Eligibility' ].indexOf(report.reportType) > -1 &&
                                    <ReportTable report={report} />
                            }
                        </React.Fragment>
                }
            </div>
        );
    }
}


export default (connect()(Report));
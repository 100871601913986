import request from './request.js';

/**
 * Add a user to the census
 * @param body
 * @param accountID
 * @param isAdvisor
 * @returns {Promise<any>}
 */
function addUser(body, accountID, isAdvisor = false) {
    const endpoint = isAdvisor ? `/advisor/${ accountID }/census/user` : '/census/user';
    const options = {
        method: 'POST',
        endpoint,
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * Add census form
 * @param body
 * @param accountID
 * @returns {Promise<any>}
 */
function uploadCensus(body, accountID) {
    const options = {
        endpoint: `/advisor/${ accountID }/census/upload`,
        method: 'POST',
        noJson: true,
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * Update a user in the census
 * @param body
 * @param userID
 * @param isAdvisor
 * @returns {Promise<any>}
 */
function updateUser(body, userID, isAdvisor = false) {
    const endpoint = isAdvisor ? `/advisor/census/user/${ userID }` : `/census/user/${ userID }`;
    const options = {
        method: 'PUT',
        endpoint,
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * Remove a user from the census
 * @param userID
 * @param accountID
 * @param effectiveDate
 * @param isAdvisor
 * @returns {Promise<any>}
 */
function removeUser(userID, accountID, effectiveDate, isAdvisor = false) {
    const endpoint = isAdvisor ? `/advisor/${ accountID }/census/${ userID }` : `/census/${ userID }`;
    const options = {
        method: 'DELETE',
        endpoint,
        body: {
            effectiveDate
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * Remove a user from the census
 * @param userID
 * @param accountID
 * @param effectiveDate
 * @returns {Promise<any>}
 */
function getUsers(accountID, filters) {
    const endpoint = `/getUsers`;
    const options = {
        method: 'POST',
        endpoint,
        body: {
            classes: filters.classes,
            divisions: filters.divisions,
            status: filters.status,
            hireDate: filters.hireDate,
            studyStartDate: filters.studyStartDate,
            waivedStatus: filters.waivedStatus
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param title
 * @param description
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function addClass(title, description, isRetiree, accountID, quoteID) {
    const endpoint = `/advisor/${ accountID }/class`;
    const options = {
        method: 'POST',
        endpoint,
        body: {
            title,
            description,
            isRetiree,
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param title
 * @param description
 * @param classID
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function editClass(title, description, isRetiree, classID, accountID, quoteID) {
    const endpoint = `/advisor/${ accountID }/class/${ classID }`;

    const options = {
        method: 'PUT',
        endpoint,
        body: {
            title,
            description,
            isRetiree,
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param title
 * @param description
 * @param address
 * @param bankingDetails
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function addDivision(title, description, address, bankingDetails, accountID, quoteID = null) {
    const endpoint = `/advisor/${ accountID }/division`;
    const options = {
        method: 'POST',
        endpoint,
        body: {
            title,
            description,
            address,
            bankingDetails,
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param title
 * @param description
 * @param address
 * @param bankingDetails
 * @param accountID
 * @param divisionID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function editDivision(title, description, address, bankingDetails, accountID, divisionID, quoteID) {
    const endpoint = `/advisor/${ accountID }/division/${ divisionID }`;
    const options = {
        method: 'PUT',
        endpoint,
        body: {
            title,
            description,
            address,
            bankingDetails,
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @returns {Promise<any>}
 */
function submitCensus(accountID) {
    const options = {
        method: 'POST',
        endpoint: `/advisor/census/${ accountID }/complete`
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    addUser,
    uploadCensus,
    updateUser,
    removeUser,
    getUsers,
    addClass,
    editClass,
    addDivision,
    editDivision,
    submitCensus
};
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import { saveAs } from 'file-saver';
import {
    AttachMoney as AttachMoneyIcon, 
    Person as PersonIcon, 
    Send as SendIcon, 
    ManageSearch as ManageSearchIcon,
    Update as UpdateIcon
} from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import {
    Button,
    MessageCard,
    SearchBar,
    MemberList,
    Content,
    Title,
    i18n,
    tokens,
    Text,
    Theme,
    EmployeeModalHandlerAdminSite,
    TerminateEmployeeModal,
    MassUpdate,
    Services
} from '@simplybenefits/sb-ui-library';

import FilterForm from './FilterForm.js';
import NotifyDialog from './NotifyDialog.js';
import MassSalaryUpdate from './MassSalaryUpdate.js';
import { addUser, getUsers, removeUser, updateUser } from '../../actions/census.js';
import MenuItem from '@mui/material/MenuItem';
import { uploadMassUpdateForm } from '../../actions/admin.js';
import { createChangeRequest, submitChangeRequest, deleteChangeRequest } from '../../actions/changeRequests.js';
import { createFolder, downloadFile, listFiles, removeFile, uploadFile } from '../../actions/files.js';

class Management extends PureComponent {
    state = {
        showNewEmployee: false,
        showEditEmployee: false,
        currentEmployee: null,
        selectedClass: 'all',
        accountList: this.props.census,
        notificationModalOpen: false,
        notificationUser: null,
        showMassSalaryUpdate: false,
        showMassUpdateMenu: false,
        openMassUpdateModal: false,
        massUpdateType: null,
        anchorEl: null,
        query: ''
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.census !== this.state.accountList) {
            this.setState({
                accountList: nextProps.census
            });

            // Run search with new filtered list of employees to make sure search is still in effect
            this._search(this.state.query, nextProps.census);
        }
    }

    _editEmployee = (employee) => {
        this.setState({
            showEditEmployee: true,
            showNewEmployee: false,
            currentEmployee: { ...employee }
        });
    };

    _hideShowEmployee = () => {
        this.setState({
            showNewEmployee: false,
            showEditEmployee: false,
            currentEmployee: null
        });
    };

    _showNewEmployee = () => {
        this.setState({
            showNewEmployee: true,
            showEditEmployee: false,
            currentEmployee: null
        });
    };

    _openChangeRequestModal = (changeRequestData) => {
        this.setState({
            showEditEmployee: false,
            showChangeRequestModal: true,
            changeRequestData
        });
    };

    _toggleMassSalaryUpdate = () => {
        this.setState({
            showMassSalaryUpdate: !this.state.showMassSalaryUpdate
        });
    };

    _toggleMassUpdateMenu = (event) => {
        this.setState({
            anchorEl: !this.state.showMassUpdateMenu ? event.currentTarget : null,
            showMassUpdateMenu: !this.state.showMassUpdateMenu
        });
    };

    _toggleMassUpdateModal = (type = null) => {
        this.setState({
            anchorEl: null,
            showMassUpdateMenu: false,
            massUpdateType: type,
            openMassUpdateModal: !this.state.openMassUpdateModal
        });
    };

    _downloadTemplate = (selectedType) => {
        saveAs(selectedType.templateDownloadLink, `${ selectedType.label }.csv`);
    };

    _toggleNotificationModal = (userID) => {
        this.setState({
            notificationModalOpen: !this.state.notificationModalOpen,
            notificationUser: userID
        });
    };

    _search = async (query, members = null) => {
        const membersList = members ? members : this.props.census;
        const accountList = [];
        const searchText = query?.toLowerCase().trim();

        if (query !== '') {
            membersList.map((member) => {
                if (`${ member.firstname } ${ member.lastname }`.toLowerCase().trim()
                    .includes(searchText) ||
                    member.email.toLowerCase().trim()
                        .includes(searchText) ||
                    member.cert === searchText
                ) {
                    return accountList.push(member);
                }

                return null;
            });
        } else {
            membersList.map((member) => accountList.push(member));
        }

        await this.setState({
            accountList: [ ...accountList ]
        });
    };

    _searchInput = (event) => {
        this.setState({
            query: event.target.value
        });
    };

    _applyFilters = (filters) => {
        this.props.dispatch(getUsers(this.props.currentAccountMeta.accountID, filters));
    };

    _terminateEmployee = (employee) => {
        this.setState({
            showTerminateMemberModal: true,
            currentEmployee: { ...employee }
        });
    };


    render() {
        const { whitelabel, currentAccountMeta, adminMeta, divisions } = this.props;
        const isParentAdmin = adminMeta.isParent === 1;
        const isStudentGroup = Boolean(currentAccountMeta.isStudentGroup);
        const showNewMemberButton = Object.values(divisions).some((division) => division.access !== 'Read');
        const styles = {
            createNewEmployeeBtn: {
                float: 'right'
            }
        };

        return (
            <Content>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                    <Title title={ i18n.t(tokens.titles.members) } />

                    <SearchBar
                        onChange={(e) => this._searchInput(e)}
                        search={(e) => this._search(e)}
                        customStyle={{ alignSelf: 'center', marginLeft: 'auto', backgroundColor: '#FFF' }}
                    />
                </div>

                {
                    (this.props.adminMeta.blockEnrollments === 1 && !this.props.currentAccountMeta.isStudentGroup) &&
                        <MessageCard
                            title={ i18n.t(tokens.labels.employees.accountPending) }
                            subtitle={ i18n.t(tokens.labels.employees.accountPendingMessage) }
                        />
                }

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ marginTop: 20, marginBottom: 10 }}
                >
                    <Button
                        onClick={() => {
                            window.location.href = '/memberChanges';
                        }}
                        transparent
                    >
                        <ManageSearchIcon />
                        <Text bold small color={ Theme.colors.darkBlue } uppercase>{ i18n.t(tokens.buttons.viewMemberChanges) }</Text>
                    </Button>

                    <div style={{ marginLeft: 'auto' }}>
                        {
                            isParentAdmin &&
                            <>
                                <Button color="primary" transparent onClick={() => this._toggleNotificationModal(null)} >
                                    <SendIcon style={{ fontSize: 17, lineHeight: '14px', color: 'inherit', marginRight: '5px' }} />
                                    <Text bold small color={ Theme.colors.darkBlue } uppercase>{ i18n.t(tokens.labels.employees.notifyMembers) }</Text>

                                </Button>
                    
                                {
                                    !isStudentGroup ?
                                        <Button
                                            onClick={this._toggleMassSalaryUpdate}
                                            transparent
                                        >
                                            <AttachMoneyIcon/>
                                            <Text bold small color={ Theme.colors.darkBlue } uppercase>{ i18n.t(tokens.labels.employees.salaryUpdate) }</Text>
                                        </Button>
                                        : null
                                }

                                {
                                    isStudentGroup ?
                                        <Button
                                            onClick={this._toggleMassUpdateMenu}
                                            transparent
                                        >
                                            <UpdateIcon/>&nbsp;
                                            <Text bold small color={ Theme.colors.darkBlue } uppercase>{ i18n.t(tokens.labels.employees.massUpdate) }</Text>
                                        </Button>
                                        : null
                                }


                            </>
                        }

                        {
                            showNewMemberButton &&
                                <Button
                                    transparent
                                    style={ styles.createNewEmployeeBtn }
                                    onClick={this._showNewEmployee}
                                >
                                    <PersonIcon />
                                    <Text bold small color={ Theme.colors.darkBlue } uppercase>{ i18n.t(tokens.labels.employees.newMember) }</Text>

                                </Button>
                        }
                    </div>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
                        <FilterForm
                            classData={ this.props.classData }
                            divisions={ this.props.divisions }
                            applyFilters= { this._applyFilters }
                            isStudentGroup={ isStudentGroup }
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
                        {
                            this.state.accountList.length === 0 ?
                                <div
                                    style={{
                                        fontWeight: 600,
                                        textAlign: 'center',
                                        padding: 30,
                                        minWidth: '100%'
                                    }}
                                >
                                    { i18n.t(tokens.labels.employees.noEmployees) }
                                </div>
                                :
                                <MemberList
                                    employees={ this.state.accountList }
                                    divisions={ this.props.divisions }
                                    classesData={ this.props.classData }
                                    toggleNotificationModal={ this._toggleNotificationModal }
                                    editEmployee={ this._editEmployee }
                                    terminateEmployee={ this._terminateEmployee }
                                    whitelabel={ this.props.whitelabel }
                                />
                        }
                        
                    </Grid>
                </Grid>

                <NotifyDialog
                    notificationModalOpen={this.state.notificationModalOpen}
                    toggleNotificationModal={() => this._toggleNotificationModal(null)}
                    notificationUser={this.state.notificationUser}
                    adminMeta={ this.props.adminMeta }
                    currentAccountMeta={ this.props.currentAccountMeta }
                    whitelabel={ whitelabel }
                />

                {
                    this.state.showMassSalaryUpdate &&
                        <MassSalaryUpdate close={ this._toggleMassSalaryUpdate } />
                }

                <EmployeeModalHandlerAdminSite
                    open={ this.state.showNewEmployee || this.state.showEditEmployee}
                    isEdit={this.state.showEditEmployee}
                    close={this._hideShowEmployee}
                    currentEmployee={this.state.currentEmployee}
                    classData={this.props.classData}
                    divisions={this.props.divisions}
                    addUser={ (employeeDetails) => this.props.dispatch(addUser(employeeDetails, this.props.adminMeta.accountID)) }
                    updateUser={(...params) => this.props.dispatch(updateUser(...params))}
                    currentAccountMeta={currentAccountMeta}

                    createChangeRequest={(params) => this.props.dispatch(createChangeRequest({ ...params, userID: this.state.currentEmployee.userID }))}
                    deleteChangeRequest={(...params) => this.props.dispatch(deleteChangeRequest(...params))}
                    submitChangeRequest={ submitChangeRequest }

                    id={ this.state.currentEmployee?.userID }
                    dispatch={ this.props.dispatch }
                    listFiles={ listFiles }
                    createFolder={ createFolder }
                    uploadFile={ uploadFile }
                    downloadFile={ downloadFile }
                    removeFile={ removeFile }
                    loader={this.props.loader}
                />

                <TerminateEmployeeModal
                    open={this.state.showTerminateMemberModal}
                    currentEmployee={this.state.currentEmployee}
                    terminate={(...params) => this.props.dispatch(removeUser(...params))}
                    close={ () => this.setState({ showTerminateMemberModal: false })}
                    loader={this.props.loader}
                />

                {
                    this.state.showMassUpdateMenu ?
                        <Menu
                            anchorEl={this.state.anchorEl}
                            open={this.state.showMassUpdateMenu}
                            onClose={this._toggleMassUpdateMenu}
                            disableAutoFocusItem={true}
                            anchorOrigin={ { vertical: "top", horizontal: "left" } }
                        >

                            {
                                Object.entries(Services.massUpdateTypes).map(([ type, { label }]) => (
                                    <MenuItem
                                        key={ type }
                                        style={ styles.navDropMenuItem }
                                        onClick={() => this._toggleMassUpdateModal(type)}
                                    >
                                        <Text
                                            align="center"
                                            medium
                                            display="block"
                                            customLinkStyle={{ textDecoration: 'none' }}
                                        >
                                            { label }
                                        </Text>
                                    </MenuItem>
                                ))
                            }
                        </Menu>
                        : null
                }

                {
                    this.state.openMassUpdateModal ?
                        <MassUpdate
                            type={ this.state.massUpdateType }
                            close={ this._toggleMassUpdateModal }
                            upload={ uploadMassUpdateForm }
                            download={ this._downloadTemplate }
                        />
                        : null
                }
            </Content>
        );
    }
}

Management.propTypes = {
    census: PropTypes.array,
    classData: PropTypes.object,
    divisions: PropTypes.object,
    adminMeta: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{classData: (default.census.classes|{}), census: Array, divisions: (default.census.divisions|{}), adminMeta: null}}
 */
const mapStateToProps = (state) => ({
    census: state.census.employees,
    classData: state.census.classes,
    divisions: state.census.divisions,
    adminMeta: state.admin.meta,
    currentAccountMeta: state.admin.account,
    whitelabel: state.whitelabel,
    loader: state.loader
});

export default connect(mapStateToProps)(Management);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { TextInput, Text, Button, Theme, Services, Modal, Container, Logo, Form, i18n, tokens } from '@simplybenefits/sb-ui-library';
import { login, forgotPassword } from '../../actions/advisor.js';
import * as notifier from '../../actions/notifier.js';

class AdvisorLogin extends Component {
    state = {
        email: '',
        emailError: false,
        password: '',
        passwordError: false,
        visibility: false,
        forgotPassword: false,
        language: ''
    };

    componentDidMount() {
        const storedLocale = localStorage.getItem("sb-advisor-language") || i18n.locale;

        i18n.setLocale(storedLocale);
        this.setState({ language: storedLocale });

        if (window.document.documentMode) {
            this.props.dispatch(notifier.displayBrowserMessage(i18n.t(tokens.labels.notifications.simplyBenefitsIsBestUsed)));
        }

        if (window.location.href.indexOf('invalid-token') > -1) {
            this.props.dispatch(notifier.displayError({
                verbiage: i18n.t(tokens.labels.notifications.youDoNotBelongToThisOrganization)
            }));
        }

        document.addEventListener("keydown", this._handleKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyPress, false);
    }

    _toggle = (type) => {
        this.setState({
            [type]: !this.state[type]
        });
    };
    
    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value.trim()
        });
    };

    _login = async () => {
        const emailError = await Services.validateInput('email', this.state.email);
        const passwordError = await Services.validateInput('checkEmpty', this.state.password);

        this.setState({
            emailError,
            passwordError
        });

        if (emailError || passwordError) {
            return;
        }

        await this.props.dispatch(login(this.state.email, this.state.password));
    };

    _forgotPassword = async () => {
        const emailError = await Services.validateInput('email', this.state.email);
        this.setState({ emailError });

        if (!emailError) {
            await this.props.dispatch(forgotPassword({ email: this.state.email }));
        }

        this._toggle('forgotPassword');

        return null;
    };

    _handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            return this._login();
        }

        return false;
    };

    render() {
        const { whitelabel } = this.props;
        const styles = {
            noDecor: {
                color: Theme.colors.mainTextGrey,
                textDecoration: 'none',
                textDecorationColor: 'none',
                letterSpacing: '.01071em'
            },
            subtext: {
                color: Theme.colors.mainTextGrey,
                cursor: 'pointer',
                display: 'block',
                fontSize: '12px',
                marginBottom: '16px',
                marginTop: '16px',
                textAlign: 'center'
            },
            forgotBtn: {
                color: Theme.colors.mainTextGrey,
                fontFamily: Theme.fonts.type.medium,
                fontSize: '12px',
                paddingBottom: '1px',
                paddingLeft: '0',
                paddingRight: '0',
                paddingTop: '0',
                textTransform: 'capitalize',
                letterSpacing: '0.02857em'

            }
        };

        return (
            <Container background={"true"}>
                <div style={{ paddingTop: 20 }}>
                    <Logo customLogoContainerStyle={{ padding: 0 }} whitelabel={whitelabel}/>
                </div>

                <Form
                    customStyle={{
                        width: '450px',
                        paddingTop: 5,
                        paddingBottom: 10,
                        paddingRight: 40,
                        paddingLeft: 40
                    }}
                >
                    <Text
                        mediumLarge
                        bold
                        display="block"
                        align="left"
                        margin="20px 0"
                        customLinkStyle={{ lineHeight: '40px' }}
                    >
                        { i18n.t(tokens.labels.dashboard.advisorLogin) }
                    </Text>

                    <TextInput
                        id="login"
                        label={ i18n.t(tokens.labels.advisor.advisorEmail) }
                        onChange={(value) => this._handleInputChange('email', value)}
                        error={Boolean(this.state.emailError)}
                        helperText={this.state.emailError}
                        fullWidth
                        autofocus
                    />

                    <TextInput
                        id="password"
                        label={ i18n.t(tokens.labels.password) }
                        value={this.state.password}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{ marginRight: 1 }}>
                                    {
                                        this.state.visibility ?
                                            <VisibilityIcon
                                                style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                                onClick={() => this._toggle('visibility')}
                                            />
                                            :
                                            <VisibilityOffIcon
                                                style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                                onClick={() => this._toggle('visibility')}
                                            />
                                    }
                                </InputAdornment>
                            )
                        }}
                        onChange={(value) => this._handleInputChange('password', value)}
                        error={Boolean(this.state.passwordError)}
                        helperText={this.state.passwordError}
                        secure={!this.state.visibility}
                        fullWidth
                    />

                    <Button
                        onClick={this._login}
                        color="primary"
                        marginTop={2.5}
                        fullWidth
                    >
                        { i18n.t(tokens.buttons.signIn) }
                    </Button>

                    <span style={styles.subtext}>
                        <Button
                            onClick={() => this._toggle('forgotPassword')}
                            transparent
                            customStyle={{ ...styles.forgotBtn, '&:hover': { backgroundColor: 'lightgrey' } }}
                        >
                            { i18n.t(tokens.labels.dashboard.forgotPassword) }
                        </Button>
                        &nbsp;&nbsp; | &nbsp;&nbsp;
                        <Link to={`/admin/login`} style={styles.noDecor}>
                            { i18n.t(tokens.labels.dashboard.areYouAnAdminLogin) }
                        </Link>
                    </span>
                </Form>

                <Modal
                    open={this.state.forgotPassword}
                    onClose={() => this._toggle('forgotPassword') }
                    title={ i18n.t(tokens.titles.resetPassword) }
                    primaryButton={{
                        label: i18n.t(tokens.buttons.continue),
                        onClick: this._forgotPassword
                    }}
                    loader={ this.props.loader }
                >
                    <Text medium light>
                        { i18n.t(tokens.labels.dashboard.resetPasswordConfirmation) }
                    </Text>
                </Modal>
            </Container>
        );
    }
}

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    whitelabel: state.whitelabel
});

export default (connect(mapStateToProps)(AdvisorLogin));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MessageCard, Loader, ContainerControlSite, Content, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';

import AccountList from '../AccountList.js';
import * as events from '../../../../services/events';
import store from '../../../../redux/store.js';

class LostAccounts extends Component {
    state = {
        lostAccounts: []
    };

    componentDidMount() {
        const lostAccounts = [];

        this.props.accounts.map((account) => {
            if (account.status === 2) {
                lostAccounts.push(account);
            }

            return null;
        });

        this.setState({
            lostAccounts
        });

        events.record('view_lost_accounts');
    }

    render() {
        const whitelabel = store.getState().whitelabel;

        return (
            <ContainerControlSite>
                <Content>
                    <Title title={ i18n.t(tokens.labels.advisor.lostOpportunities)} />

                    {
                        this.state.lostAccounts.length === 0 ?
                            (
                                <MessageCard
                                    title={ i18n.t(tokens.labels.advisor.noLostOpportunities)}
                                    subtitle={ i18n.t(tokens.labels.advisor.noLostOpportunitiesText)}
                                />
                            )
                            :
                            (
                                <AccountList accounts={this.state.lostAccounts} />
                            )
                    }
                </Content>

                {this.props.loading && <Loader whitelabel={ whitelabel } />}

                <span id="accounts-lost" />
            </ContainerControlSite>
        );
    }
}

LostAccounts.propTypes = {
    loading: PropTypes.bool,
    accounts: PropTypes.array
};

/**
 * @param state
 * @returns {{accounts: Array, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.advisor.loading,
    accounts: state.advisor.accounts
});

export default (connect(mapStateToProps)(LostAccounts));
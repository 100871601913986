import request from './request.js';

/**
 * 
 * @param {*} ComponentName 
 * @param {*} downloadParams 
 * @returns 
 */
function generatePdf(ComponentName, downloadParams, isAdvisor) {
    const options = {
        endpoint: isAdvisor ? `/advisor/generatePdf` : `/admin/generatePdf`,
        method: 'POST',
        body: {
            componentName: ComponentName,
            downloadParams
        }
    };
    
    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}
 
/**
 * 
 * @param {*} ComponentName 
 * @param {*} downloadParams 
 * @returns 
 */
function generatePdfForQuote(ComponentName, downloadParams) {
    const options = {
        endpoint: '/advisor/quote/generatePdf',
        method: 'POST',
        body: {
            componentName: ComponentName,
            downloadParams
        }
    };
    
    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * 
 * @param {*} ComponentName 
 * @param {*} downloadParams 
 * @returns 
 */
function generateAmendmentPDF(ComponentName, downloadParams) {
    const options = {
        endpoint: '/advisor/amendment/generatePdf',
        method: 'POST',
        body: {
            componentName: ComponentName,
            downloadParams
        }
    };
    
    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    generatePdf, 
    generatePdfForQuote,
    generateAmendmentPDF
};
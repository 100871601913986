import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import { Text, Theme, Button, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as dates from '@simplybenefits/sb-dates';
import { getAdminPdf } from '../../../actions/admin.js';

import { globalStyles } from '../../../theme/index.js';
import * as helpers from '../../../services/helpers.js';
import { styled } from '@mui/system';
import { connect } from 'react-redux';

const HeaderBar = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 10,
    padding: 8,
    backgroundColor: theme.palette.primary.main
}));

const StyledLine = styled('div')(({ theme }) => ({
    width: '98%',
    height: 2,
    opacity: 0.5,
    backgroundColor: theme.palette.primary.main,
    margin: "10px auto"
}));

class DivisionBreakdown extends Component {

    downloadAsoReport = (asoReportID, selectedDivisionID, clientName, divisionTitle, timeStamp, groupNum, period, accountID) => {
        this.props.dispatch(getAdminPdf("AsoDivision", i18n.t(tokens.labels.asoFileName, { date: dates.getLocalDisplay(this.props.date, 'MMMM_YYYY', i18n.locale) }), { asoReportID, selectedDivisionID, clientName, divisionTitle, timeStamp, groupNum, period, accountID }));
    };

    render() {
        const { data, divisionTitle, account, period, asoReportID, selectedDivisionID } = this.props;
        const styles = {
            contentBlock: globalStyles.contentBlock,
            block: {
                display: 'flex',
                flex: 1,
                flexDirection: 'column'
            },
            row: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                padding: "5px 30px 0 50px"
            },
            rowCondensed: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                padding: 8
            }
        };

        return (
            <div style={styles.contentBlock}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={8}>
                        <Text largest veryBold>{i18n.t(tokens.labels.aso.asoAccountSummary)}</Text>
                    </Grid>
                    <Grid item xs={4} >
                        <Grid container justifyContent="flex-end">
                            <Grid item style={{ padding: 0 }} >
                                <Button 
                                    transparent 
                                    customStyle={{ marginLeft: "30px", '&:hover': { backgroundColor: 'lightgrey' } }}
                                    onClick={() => this.downloadAsoReport(asoReportID, selectedDivisionID, this.props.clientName, divisionTitle, this.props.timeStamp, account.groupNum, period, account.accountID)}>
                                    {i18n.t(tokens.buttons.downloadDivisionSummary)}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Text large>{ divisionTitle }</Text>

                <div style={ styles.block } >
                    <HeaderBar>
                        <Text medium bold color={ Theme.colors.surface } customStyle={{ flex: 2 }} />
                        <Text medium bold color={ Theme.colors.surface } customStyle={{ flex: 1, textAlign: 'center' }}>{i18n.t(tokens.titles.health)}</Text>
                        <Text medium bold color={ Theme.colors.surface } customStyle={{ flex: 1, textAlign: 'center' }}>{i18n.t(tokens.titles.dental)}</Text>
                        <Text medium bold color={ Theme.colors.surface } customStyle={{ flex: 1, textAlign: 'center' }}>{i18n.t(tokens.labels.total)}</Text>
                    </HeaderBar>

                    <div style={ styles.rowCondensed }>
                        <Text mediumLarge bold customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.priorBalance)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.startingBalance) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.asoBalanceAdjustment)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.asoAdjustments) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.asoStopLossAdjustment)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.asoStopLossAdjustments) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.numLivesSolo)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.numberWithCommas(data.total.numLives.solo) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.numLivesDuo)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.numberWithCommas(data.total.numLives.duo) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.numLivesFamily)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.numberWithCommas(data.total.numLives.family) }</Text>
                    </div>

                    <StyledLine />

                    <div style={ styles.rowCondensed }>
                        <Text mediumLarge bold customStyle={{ flex: 1 }}>{i18n.t(tokens.labels.aso.revenue)}</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.asoBalanceAdjustment)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.asoAdjustments) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.asoStopLossAdjustment)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.asoStopLossAdjustments) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.benefitAdjustment)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.health.adjustments) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.dental.adjustments) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.adjustments) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.netPremiumBilled)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.health.netPremium) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.dental.netPremium) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.netPremium) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.grossPremiumBilled)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.health.grossPremium) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.dental.grossPremium) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.grossPremium) }</Text>
                    </div>

                    <StyledLine/>

                    <div style={ styles.rowCondensed }>
                        <Text mediumLarge bold customStyle={{ flex: 1 }}>{i18n.t(tokens.labels.aso.expenses)}</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.claimPaidUnderAso)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.health.claimsPaid) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.dental.claimsPaid) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.claimsPaid) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.managementFee)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.health.managementFee) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.dental.managementFee) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.managementFee) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.adminFee)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.health.adminFee) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.dental.adminFee) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.adminFee) }</Text>
                    </div>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.appTaxes)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.health.GST + data.health.PST + (data.health.RST || 0) + data.health.HST) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.dental.GST + data.dental.PST + (data.dental.RST || 0) + data.dental.HST) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.GST + data.total.PST + (data.total.RST || 0) + data.total.HST) }</Text>
                    </div>

                    <StyledLine/>

                    <div style={ styles.rowCondensed }>
                        <Text mediumLarge bold customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.totalExpenses)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.health.claimsPaid + data.health.managementFee + data.health.adminFee + data.health.GST + data.health.PST + (data.health.RST || 0) + data.health.HST) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.dental.claimsPaid + data.dental.managementFee + data.dental.adminFee + data.dental.GST + data.dental.PST + (data.dental.RST || 0) + data.dental.HST) }</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.claimsPaid + data.total.managementFee + data.total.adminFee + data.total.GST + data.total.PST + (data.total.RST || 0) + data.total.HST) }</Text>
                    </div>

                    <StyledLine/>

                    <div style={ styles.rowCondensed }>
                        <Text medium customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.gainLoss)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.closingBalance - data.total.startingBalance) }</Text>
                    </div>

                    <StyledLine/>

                    <div style={ styles.rowCondensed }>
                        <Text mediumLarge bold customStyle={{ flex: 2 }}>{i18n.t(tokens.labels.aso.balanceAsoAccount)}</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text medium customStyle={{ flex: 1, textAlign: 'center' }}>-</Text>
                        <Text mediumLarge bold customStyle={{ flex: 1, textAlign: 'center' }}>{ helpers.formatNumber(data.total.closingBalance) }</Text>
                    </div>

                    <ul>
                        <li>{ `${ i18n.t(tokens.labels.aso.feeDental) } ${ data.dental.managementFeePercentage }%` }</li>
                        <li>{ `${ i18n.t(tokens.labels.aso.feeHealth) } ${ data.health.managementFeePercentage }%` }</li>
                        <li>{ `${ i18n.t(tokens.labels.aso.adminFeeDental) } ${ data.dental.adminFeePercentage }%` }</li>
                        <li>{ `${ i18n.t(tokens.labels.aso.adminFeeHealth) } ${ data.health.adminFeePercentage }%` }</li>
                        <li>{ `${ i18n.t(tokens.labels.aso.hstGst) } #76557 5311`}</li>
                        <li>{`${ i18n.t(tokens.labels.aso.balanceInAso) }.`}</li>
                    </ul>
                </div>

                <span id="aso-report" />
            </div>
        );
    }
}

DivisionBreakdown.propTypes = {
    report: PropTypes.object
};

export default connect()(DivisionBreakdown);
import * as types from './types/loader';

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export function setLoader(data) {
    return (dispatch) => {
        dispatch({
            type: types.SET_LOADER,
            data
        });
    };
}
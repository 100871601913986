import * as files from '../xhr/files';
import * as loader from './loader.js';
import * as notifier from "./notifier.js";

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'container') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param path
 * @returns {function(*): *}
 */
export function listFiles(type, id, parentFolder, path = null) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return files.listFiles(type, id, parentFolder, path)
            .then((data) => {
                setLoader(dispatch, false);
                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param folderName
 * @param path
 * @returns {function(*): *}
 */
export function createFolder(type, id, parentFolder, folderName, path = null) {
    return (dispatch) => files.createFolder(type, id, parentFolder, folderName, path)
        .then(() => true)
        .catch((error) => {
            dispatch(notifier.displayError(error));
            return false;
        });
}

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param file
 * @param path
 * @returns {function(*): Promise<boolean>}
 */
export function uploadFile(type, id, parentFolder, file, path = null, skipNotification = false) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return files.uploadFile(type, id, parentFolder, file, path, skipNotification)
            .then(() => {
                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param path
 * @returns {function(*): *}
 */
export function downloadFile(type, id, parentFolder, path) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return files.downloadFile(type, id, parentFolder, path)
            .then((url) => {
                setLoader(dispatch, false);
                return url;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param path
 * @returns {(function(*): (*|undefined))|*}
 */
export function removeFile(type, id, parentFolder, path) {
    return (dispatch) => files.removeFile(type, id, parentFolder, path)
        .then(() => true)
        .catch((error) => {

            dispatch(notifier.displayError(error));
            return false;
        });
}
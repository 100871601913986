import initialState from '../initialState.js';
import {
    GET_PLAN_SUCCESS,
    UPDATE_PLAN_CLASS_RECORD,
    GET_PRICING_SUCCESS,
    ADD_DESIGN_FOR_NEW_CLASS,
    CREATE_NEW_PLAN_DESIGN,
    SAVE_PLAN_DESIGN_FIELD,
    SAVE_MODULAR_FIELD,
    SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA,
    SAVE_MODULAR_BENEFIT_INIT_DATA,
    REVERT_FROM_REVISION,
    SET_SPECIALITY_PRODUCT_CONFIGS
} from '../../actions/types/plan';

export default (state = initialState.plan, action) => {
    switch (action.type) {
        case SET_SPECIALITY_PRODUCT_CONFIGS: {
            return {
                ...state,
                specialityProductMetaConfigs: action.data
            };
        }

        case GET_PLAN_SUCCESS: {
            return {
                ...state,
                data: action.plan,
                designs: action.designs,
                pricing: action.pricing,
                modularBlueprint: action.modularBlueprint || state.modularBlueprint
            };
        }

        case ADD_DESIGN_FOR_NEW_CLASS: {
            return {
                ...state,
                designs: {
                    ...state.designs,
                    [action.data.classID]: {}
                }
            };
        }

        case UPDATE_PLAN_CLASS_RECORD: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.classID]: {
                        ...state.data[action.data.classID],
                        [action.data.key]: {
                            ...state.data[action.data.classID][action.data.key],
                            [action.data.type]: Number(action.data.value)
                        }
                    }
                }
            };
        }

        case GET_PRICING_SUCCESS: {
            return {
                ...state,
                pricing: action.pricing
            };
        }

        case CREATE_NEW_PLAN_DESIGN: {
            return {
                ...state,
                designs: {
                    ...state.designs,
                    [action.classID]: {
                        ...state.designs[action.classID],
                        [action.data.designID]: {
                            ...action.data
                        }
                    }
                }
            };
        }

        case SAVE_PLAN_DESIGN_FIELD: {
            return {
                ...state,
                designs: {
                    ...state.designs,
                    [action.classID]: {
                        ...state.designs[action.classID],
                        [action.designID]: {
                            ...state.designs[action.classID][action.designID],
                            [action.benefit]: {
                                ...state.designs[action.classID][action.designID][action.benefit],
                                [action.field]: action.value
                            }
                        }
                    }
                }
            };
        }

        case SAVE_MODULAR_FIELD: {
            if (!action.tier) {
                return {
                    ...state,
                    modularBlueprints: {
                        ...state.modularBlueprints,
                        [action.classID]: {
                            ...state.modularBlueprints[action.classID],
                            [action.benefit]: {
                                ...state.modularBlueprints[action.classID][action.benefit],
                                [action.field]: action.value
                            }
                        }
                    }
                };
            }

            if (action.tierBenefit === 'dental') {
                return {
                    ...state,
                    modularBlueprints: {
                        ...state.modularBlueprints,
                        [action.classID]: {
                            ...state.modularBlueprints[action.classID],
                            dental: {
                                ...state.modularBlueprints[action.classID].dental,
                                [action.tier]: {
                                    ...state.modularBlueprints[action.classID].dental[action.tier],
                                    [action.field]: action.value
                                }
                            }
                        }
                    }
                };
            }

            return {
                ...state,
                modularBlueprints: {
                    ...state.modularBlueprints,
                    [action.classID]: {
                        ...state.modularBlueprints[action.classID],
                        health: {
                            ...state.modularBlueprints[action.classID].health,
                            [action.tier]: {
                                ...state.modularBlueprints[action.classID].health[action.tier],
                                [action.benefit]: {
                                    ...state.modularBlueprints[action.classID].health[action.tier][action.benefit],
                                    [action.field]: action.value
                                }
                            }
                        }
                    }
                }
            };
        }

        case SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA: {
            return {
                ...state,
                designs: {
                    ...state.designs,
                    [action.classID]: {
                        ...state.designs[action.classID],
                        [action.designID]: {
                            ...state.designs[action.classID][action.designID],
                            [action.benefit]: {
                                ...action.data
                            }
                        }
                    }
                }
            };
        }

        case SAVE_MODULAR_BENEFIT_INIT_DATA: {
            if (!action.tier) {
                return {
                    ...state,
                    currentAccount: {
                        ...state.currentAccount,
                        modularBlueprints: {
                            ...state.currentAccount.modularBlueprints,
                            [action.classID]: {
                                ...state.currentAccount.modularBlueprints[action.classID],
                                [action.benefit]: {
                                    ...action.data
                                }
                            }
                        }
                    }
                };
            }

            if (action.tierBenefit === 'dental') {
                return {
                    ...state,
                    modularBlueprints: {
                        ...state.modularBlueprints,
                        [action.classID]: {
                            ...state.modularBlueprints[action.classID],
                            dental: {
                                ...state.modularBlueprints[action.classID].dental,
                                [action.tier]: {
                                    ...action.data
                                }
                            }
                        }
                    }
                };
            }

            return {
                ...state,
                modularBlueprints: {
                    ...state.modularBlueprints,
                    [action.classID]: {
                        ...state.modularBlueprints[action.classID],
                        health: {
                            ...state.modularBlueprints[action.classID].health,
                            [action.tier]: {
                                ...state.modularBlueprints[action.classID].health[action.tier],
                                [action.benefit]: {
                                    ...action.data
                                }
                            }
                        }
                    }
                }
            };
        }

        case REVERT_FROM_REVISION: {
            return {
                ...state,
                designs: {
                    ...state.designs,
                    [action.classID]: {
                        ...state.designs[action.classID],
                        [action.designID]: {
                            ...state.designs[action.classID][action.designID],
                            ...action.data
                        }
                    }
                }
            };
        }

        default:
            return state;
    }
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tiles, Text, Modal, ContainerControlSite, Content, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as events from '../../../services/events';
import { saveAs } from "file-saver";


class Resources extends Component {
    state = {
        showConfirmation: false
    };

    componentDidMount() {
        events.record('view_resources');
    }

    _openForm = () => {
        this.setState({
            showConfirmation: true
        });
    };

    _closeForm = () => {
        this.setState({
            showConfirmation: false
        });
    };

    _downloadPDF = (item) => {
        saveAs(`https://d6hvupc5g7lnm.cloudfront.net/files/${ item }`, item);
        this._openForm();
    };

    render() {
        const styles = {
            tiles: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: 'auto'
            },
            list: {
                width: '100%',
                padding: 0
            },
            listItem: {
                paddingLeft: 10,
                paddingRight: 0
            }
        };

        const tiles = [
            {
                title: i18n.t(tokens.labels.resources.maternityPaternityLeaveForm),
                description: i18n.t(tokens.labels.resources.forMemberCommencingMaternity),
                onClick: () => this._downloadPDF('Simply Benefits Maternity and Paternity Leave Temporary Refusal of Benefits.pdf'),
                icon: 'Resources'
             
            },
            {
                title: i18n.t(tokens.labels.resources.extendedHealthClaimsSubmissionForm),
                description: i18n.t(tokens.labels.resources.forMedicalExpensesAndServices),
                onClick: () => this._downloadPDF('Simply Benefits Extended Health Care Claims Submission.pdf'),
                icon: 'Resources'
            },
            {
                title: i18n.t(tokens.labels.resources.dentalClaimsSubmissionForm),
                description: i18n.t(tokens.labels.resources.forDentalExpensesAndServices),
                onClick: () => this._downloadPDF('Simply Benefits Dental Claims Submission.pdf'),
                icon: 'Resources'
            },
            {
                title: i18n.t(tokens.labels.resources.disabledChildDependentForm),
                description: i18n.t(tokens.labels.resources.forMembersWithDisabledChild),
                onClick: () => this._downloadPDF('Simply Benefits Disabled Child Dependent.pdf'),
                icon: 'Resources'
            },
            {
                title: i18n.t(tokens.labels.resources.studentDeclarationForm),
                description: i18n.t(tokens.labels.resources.forMembersWithDepsOver21),
                onClick: () => this._downloadPDF('Simply Benefits Student Declaration.pdf'),
                icon: 'Resources'
            },
            {
                title: i18n.t(tokens.labels.resources.noticeOfAbsenceForm),
                description: i18n.t(tokens.labels.resources.forMembersOffWorkMoreThan10Days),
                onClick: () => this._downloadPDF('Simply Benefits Notice of Absence.pdf'),
                icon: 'Resources'
            },
            {
                title: i18n.t(tokens.labels.resources.groupChangeForm),
                description: i18n.t(tokens.labels.resources.forInsuredMemberChanges),
                onClick: () => this._downloadPDF('Simply Benefits Group Change.pdf'),
                icon: 'Resources'
            }
        ];

        return (
            <ContainerControlSite>
                <Content>
                    <Title title="Resources" />

                    <div style={styles.tiles}>
                        <Tiles tiles={ tiles }>
                            {!tiles.length &&
                                <Text
                                    small
                                    margin="10px 0 5px 0"
                                    customStyle={{ maxWidth: 600 }}
                                >
                                    { i18n.t(tokens.labels.resources.whenFormsAndDocumentsAreAvailable) }
                                </Text>
                            }
                        </Tiles>
                    </div>
                </Content>
                {
                    this.state.showConfirmation ?
                        <Modal
                            open={this.state.showConfirmation}
                            onSave={this._closeForm}
                            primaryButton={{
                                label: "Confirm",
                                onClick: this._closeForm
                            }}
                        >
                            <Text medium light>
                                { i18n.t(tokens.labels.resources.onceFormsAreCompletedAndSigned) }
                            </Text>
                        </Modal>
                        :
                        null
                }

                <span id="resources" />
            </ContainerControlSite>
        );
    }
}

/**
 * @param state
 * @returns {loading: boolean}
 */
const mapStateToProps = (state) => ({
    loader: state.loader
});

export default (connect(mapStateToProps)(Resources));
import initialState from '../initialState';
import {
    SET_LOADER
} from '../../actions/types/loader';

export default (state = initialState.loader, action) => {
    switch (action.type) {
        case SET_LOADER: {
            return {
                ...state,
                isLoading: action.data.isLoading,
                type: action.data.type || 'full'
            };
        }

        default:
            return state;
    }
};
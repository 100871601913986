import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Content,
    Title,
    i18n,
    tokens,
    Files as SbFiles, 
    Loader
} from '@simplybenefits/sb-ui-library';
import { createFolder, downloadFile, listFiles, removeFile, uploadFile } from "../../actions/files.js";
import { getUserType } from "../../services/session.js";

class Files extends PureComponent {
    render() {
        const isAdvisor = Number(getUserType()) === 1;

        return (
            <Container>
                <Content>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                        <Title title={ i18n.t(tokens.titles.files) } customStyle={{ flex: 1 }} />
                    </div>

                    <SbFiles
                        type={ isAdvisor ? 'advisors' : 'accounts' }
                        id={ isAdvisor ? this.props.advisorMeta.advisorID : this.props.adminMeta.accountID }
                        parentFolder="files/Public" // only show them the public folder
                        dispatch={ this.props.dispatch }
                        listFiles={ listFiles }
                        createFolder={ createFolder }
                        uploadFile={ uploadFile }
                        downloadFile={ downloadFile }
                        removeFile={ removeFile }
                        loader={ this.props.loader }
                    />
                </Content>

                {this.props.loading && <Loader whitelabel={ this.props.whitelabel } />}
            </Container>
        );
    }
}

/**
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    advisorMeta: state.advisor.meta,
    adminMeta: state.admin.meta,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(Files);
import React, { Component } from 'react';
import { connect } from "react-redux";
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Theme } from '@simplybenefits/sb-ui-library';

class Bar extends Component {
    componentDidMount() {
        const chart = am4core.create(`barChartDiv${ this.props.id }`, am4charts.XYChart);

        // ... chart code goes here ...

        // Add data
        chart.data = [{
            "type": "Healthcare",
            "claims": 501.9,
            "units": 250
        }, {
            "type": "Dental",
            "claims": 301.9,
            "units": 222
        }, {
            "type": "Drugs",
            "claims": 201.1,
            "units": 170
        }, {
            "type": "Dependent Life",
            "claims": 165.8,
            "units": 122
        }, {
            "type": "Life Insurance",
            "claims": 139.9,
            "units": 99
        }, {
            "type": "Accidental D&D",
            "claims": 128.3,
            "units": 85
        }];

        // Create axes
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "type";
        categoryAxis.title.text = "Claim Type";

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Number Claims";

        // Create series
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "claims";
        series.dataFields.categoryX = "type";
        series.name = "Sales";
        series.columns.template.tooltipText = "Series: {name}\nCategory: {categoryX}\nValue: {valueY}";
        series.columns.template.fill = am4core.color(Theme.colors.mediumBlue);

        const series2 = chart.series.push(new am4charts.LineSeries());
        series2.name = "Units";
        series2.stroke = am4core.color(Theme.colors.mediumDarkBlue);
        series2.strokeWidth = 3;
        series2.dataFields.valueY = "units";
        series2.dataFields.categoryX = "type";
        // ... chart code end ...

        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={`barChartDiv${ this.props.id }`} style={{ width: "100%", height: '100%' }} />
        );
    }
}

export default connect()(Bar);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as addressTools from '@simplybenefits/sb-address';
import {
    TextInput,
    PhoneInput,
    Services,
    Modal,
    Text,
    BubbleSelectBlock,
    i18n,
    tokens,
    localizationMaps
} from '@simplybenefits/sb-ui-library';

import * as notifier from '../../../actions/notifier.js';
import { editAccount } from '../../../actions/admin.js';
import FormControl from "@mui/material/FormControl";

const statusMapString = {
    0: 'In Progress',
    1: 'Active',
    2: 'Disabled',
    9: 'Cancelled'
};

const statusMapInt = {
    "In Progress": 0,
    Active: 1,
    Disabled: 2,
    Cancelled: 9
};

class AccountSettings extends PureComponent {
    state = {
        suite: '',
        firstLine: '',
        city: '',
        province: '',
        postalCode: '',
        country: 'Canada',
        companyName: '',
        contactEmail: '',
        phone: '',
        status: '',
        authorityName: '',
        authorityEmail: '',
        asoType: '',
        isAsoDental: '',
        isAsoHealth: ''
    };

    async componentDidMount() {
        const { account } = this.props;
        let address = '';

        if (account.address) {
            address = addressTools.parseAddress(account.address);
        }

        await this.setState({
            suite: address.suite || '',
            firstLine: address.firstLine || '',
            city: address.city || '',
            province: localizationMaps.fromEnglish("provinces", address.province) || '',
            postalCode: address.postalCode || '',

            companyName: account.company,
            contactEmail: account.contactEmail,
            phone: account.phone,
            status: statusMapString[account.status || 2],
            authorityName: account.authorityName,
            authorityEmail: account.authorityEmail,
            asoType: account.asoType,
            isAsoDental: account.isAsoDental,
            isAsoHealth: account.isAsoHealth
        });

        document.addEventListener("keydown", this._handleKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyPress, false);
    }

    _onBlur = async (type, event) => {        
        this.setState({
            [`${ type }Error`]: await Services.validateInput(type, this.state[type])
        });

        if (event) {
            this.setState({ [type]: event.target.value });
        }
    };

    _onAddressBlur = async (type) => {
        if (type !== 'suite') {
            await this.setState({
                [`${ type }Error`]: await Services.validateInput(type, this.state[type])
            });
        }

        const { firstLine, city, province, postalCode } = this.state;

        if (firstLine && city && province && postalCode) {
            await addressTools.generateAddress(this.state);
        }

        return null;
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    _handleAddressChange = (type, event) => {
        this.setState({
            [type]: event.target.value.replace(/,/g, '')
        });
    };

    _validateForm = async () => {
        const companyNameError = await Services.validateInput('companyName', this.state.companyName);
        const statusError = await Services.validateInput('status', this.state.status);
        const phoneError = await Services.validateInput('phone', this.state.phone);
        const contactEmailError = await Services.validateInput('contactEmail', this.state.contactEmail);
        const authorityNameError = await Services.validateInput('authorityName', this.state.authorityName);
        const authorityEmailError = await Services.validateInput('authorityEmail', this.state.authorityEmail);

        const firstLineError = await Services.validateInput('firstLine', this.state.firstLine);
        const cityError = await Services.validateInput('city', this.state.city);
        const provinceError = await Services.validateInput('province', this.state.province);
        const postalCodeError = await Services.validateInput('postalCode', this.state.postalCode);

        this.setState({
            companyNameError,
            phoneError,
            contactEmailError,
            authorityNameError,
            authorityEmailError,
            statusError,

            firstLineError,
            cityError,
            provinceError,
            postalCodeError
        });

        if (
            companyNameError ||
            phoneError ||
            contactEmailError ||
            authorityNameError ||
            authorityEmailError ||
            statusError ||

            firstLineError ||
            cityError ||
            provinceError ||
            postalCodeError
        ) {
            this.props.dispatch(notifier.displayError({ verbiage: i18n.t(tokens.labels.pleaseConfirmDetailsError) }));

            return false;
        }

        return true;
    };

    _saveAccount = async () => {
        const isFormValid = await this._validateForm();

        if (!isFormValid) {
            return false;
        }

        const {
            suite,
            firstLine,
            city,
            province,
            postalCode,
            country,
            companyName,
            contactEmail,
            phone,
            status,
            authorityName,
            authorityEmail
        } = this.state;

        const generatedAddress = addressTools.generateAddress({
            suite,
            firstLine,
            city,
            province: localizationMaps.toEnglish("provinces", province),
            postalCode,
            country
        });

        const body = {
            company: companyName.trim(),
            address: generatedAddress.trim(),
            phone: phone.trim(),
            contactEmail: contactEmail.trim(),
            authorityName: authorityName.trim(),
            authorityEmail: authorityEmail.trim(),
            status: statusMapInt[status]
        };

        const success = this.props.dispatch(editAccount(this.props.account.accountID, body));

        if (success) {
            this.props.hideModal();
            document.removeEventListener("keydown", this._handleKeyPress, false);
        }

        return true;
    };

    _handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            return this._saveAccount();
        }

        return false;
    };

    render() {
        const { hideModal } = this.props;
        const {
            suite,
            firstLine,
            city,
            province,
            postalCode
        } = this.state;
        
        const styles = {
            form: {
                display: 'flex',
                flexDirection: 'column'
            },
            row: {
                display: 'flex',
                flexDirection: 'row'
            },
            divider: {
                width: 40
            }
        };

        return (
            <Modal
                testid="account-settings-modal"
                open={ true }
                onClose={ hideModal }
                title={ i18n.t(tokens.labels.account.modifyDetails) }
                primaryButton={{
                    onClick: this._saveAccount
                }}
                loader={this.props.loader}
            >
                <TextInput
                    label={ i18n.t(tokens.labels.account.companyName) }
                    value={ this.state.companyName }
                    disabled
                    fullWidth
                    autofocus
                />

                <TextInput
                    label={ i18n.t(tokens.labels.streetAddress) }
                    autocomplete="shipping street-address"
                    value={ firstLine }
                    onBlur={ () => this._onAddressBlur('firstLine') }
                    onChange={ (value) => this._handleAddressChange('firstLine', value) }
                    error={ Boolean(this.state.firstLineError) }
                    helperText={ this.state.firstLineError }
                    fullWidth
                />

                <div style={ styles.row }>
                    <TextInput
                        label={ i18n.t(tokens.labels.account.suiteUnit) }
                        value={ suite }
                        onBlur={ () => this._onAddressBlur('suite') }
                        onChange={ (value) => this._handleAddressChange('suite', value) }
                        fullWidth
                    />

                    <div style={ styles.divider } />

                    <TextInput
                        label={ i18n.t(tokens.labels.account.cityTown) }
                        autocomplete="shipping locality"
                        value={ city }
                        onBlur={ () => this._onAddressBlur('city') }
                        onChange={ (value) => this._handleAddressChange('city', value) }
                        error={ Boolean(this.state.cityError) }
                        helperText={ this.state.cityError }
                        fullWidth
                    />
                </div>


                <div style={ styles.row }>
                    <TextInput
                        label={ i18n.t(tokens.labels.province) }
                        autocomplete="shipping region"
                        value={ province }
                        onBlur={() => this._onAddressBlur('province')}
                        onChange={ (value) => this._handleInputChange('province', value) }
                        select
                        selectOptions={ Services.selectFieldOptions.provinceOptions() }
                        fullWidth
                    />

                    <div style={ styles.divider } />

                    <TextInput
                        label={ i18n.t(tokens.labels.postalCode) }
                        autocomplete="shipping postal-code"
                        value={ postalCode }
                        onBlur={ () => this._onAddressBlur('postalCode') }
                        onChange={ (value) => this._handleAddressChange('postalCode', value) }
                        error={ Boolean(this.state.postalCodeError) }
                        helperText={ this.state.postalCodeError }
                        fullWidth
                    />
                </div>

                <PhoneInput
                    label={ i18n.t(tokens.labels.account.companyContactNumber) }
                    value={ this.state.phone }
                    onBlur={ () => this._onBlur('phone') }
                    onChange={ (value) => this._handleInputChange('phone', value) }
                    error={ Boolean(this.state.phoneError) }
                    helperText={ this.state.phoneError }
                    fullWidth
                />

                <TextInput
                    label={ i18n.t(tokens.labels.account.companyEmail) }
                    value={ this.state.contactEmail }
                    onBlur={ () => this._onBlur('contactEmail') }
                    onChange={ (value) => this._handleInputChange('contactEmail', value) }
                    error={ Boolean(this.state.contactEmailError) }
                    helperText={ this.state.contactEmailError }
                    fullWidth
                />

                <TextInput
                    label={ i18n.t(tokens.labels.account.signingAuthorityName) }
                    value={ this.state.authorityName }
                    onBlur={ () => this._onBlur('authorityName') }
                    onChange={ (value) => this._handleInputChange('authorityName', value) }
                    error={ Boolean(this.state.authorityNameError) }
                    helperText={ this.state.authorityNameError }
                    fullWidth
                />

                <TextInput
                    label={ i18n.t(tokens.labels.account.signingAuthorityEmail) }
                    value={ this.state.authorityEmail }
                    onBlur={ () => this._onBlur('authorityEmail') }
                    onChange={ (value) => this._handleInputChange('authorityEmail', value) }
                    error={ Boolean(this.state.authorityEmailError) }
                    helperText={ this.state.authorityEmailError }
                    fullWidth
                />

                {
                    (!this.props.account.isPrivateLabel && this.state.asoType !== null) &&
                    <>
                        <Text
                            medium
                            light
                            margin="10px 0 10px 0"
                        >
                            { i18n.t(tokens.labels.account.asoSettingsContact) }
                        </Text>

                        <FormControl component="fieldset">
                            <Text
                                small
                                bold
                                margin="10px 0 0 0"
                            >
                                { i18n.t(tokens.labels.account.asoType) }
                            </Text>

                            <BubbleSelectBlock
                                first={{
                                    title: i18n.t(tokens.labels.account.budgeted),
                                    selected: this.state.asoType === 'Budgeted',
                                    field: 'Budgeted'
                                }}
                                second={{
                                    title: i18n.t(tokens.labels.account.inArrears),
                                    selected: this.state.asoType === 'Arrears',
                                    field: 'Arrears'
                                }}
                                disabled={ true }
                            />
                        </FormControl>

                        <FormControl component="fieldset">
                            <Text
                                medium
                                bold
                                margin="10px 0 10px 0"
                            >
                                { i18n.t(tokens.labels.account.asoDental) }
                            </Text>

                            <BubbleSelectBlock
                                first={{
                                    title: i18n.t(tokens.buttons.yes),
                                    selected: Boolean(this.state.isAsoDental) === true,
                                    field: 'isAsoDentalYes'
                                }}
                                second={{
                                    title: i18n.t(tokens.buttons.no),
                                    selected: Boolean(this.state.isAsoDental) === false,
                                    field: 'isAsoDentalNo'
                                }}
                                disabled={ true }
                            />
                        </FormControl>

                        <FormControl component="fieldset">
                            <Text
                                medium
                                bold
                                margin="10px 0 10px 0"
                            >
                                { i18n.t(tokens.labels.account.asoHealth) }
                            </Text>

                            <BubbleSelectBlock
                                first={{
                                    title: i18n.t(tokens.buttons.yes),
                                    selected: Boolean(this.state.isAsoHealth) === true,
                                    field: 'isAsoHealthYes'
                                }}
                                second={{
                                    title: i18n.t(tokens.buttons.no),
                                    selected: Boolean(this.state.isAsoHealth) === false,
                                    field: 'isAsoHealthNo'
                                }}
                                disabled={ true }
                            />
                        </FormControl>
                    </>
                }
            </Modal>
        );
    }
}


AccountSettings.propTypes = {
    account: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    account: state.admin.account,
    whitelabel: state.whitelabel
});

export default (connect(mapStateToProps)(AccountSettings));
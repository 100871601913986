import moment from 'moment';
import { i18n, tokens, Services } from "@simplybenefits/sb-ui-library";

const now = new Date();
const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

/**
 * Generates data for admin reports.
 * @returns {Object} Admin reports data.
 */
const adminReportsData = () => ({ 
    [i18n.t(tokens.labels.reports.membersAndUsage)]: {
        [i18n.t(tokens.labels.reports.overAgeMemberListing)]: {
            title: i18n.t(tokens.labels.reports.overAgeMemberAnniversary),
            icon: 'elderly',
            description: i18n.t(tokens.labels.reports.viewAllMembersWhoWillBecomeOlder),
            filters: {
                dateRange: {
                    type: 'date',
                    startDate: moment(),
                    endDate: moment().add(6, 'month')
                },
                age: {
                    type: 'number',
                    label: i18n.t(tokens.labels.reports.age),
                    isRequired: true,
                    value: 65
                },
                division: {
                    type: 'select',
                    label: i18n.t(tokens.titles.division),
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                }
            }
        },
        [i18n.t(tokens.labels.reports.overAgeDependentListing)]: {
            title: i18n.t(tokens.labels.reports.overageDependent),
            icon: 'cake',
            description: i18n.t(tokens.labels.reports.viewAllCurrentDependentsOverage),
            testid: 'over-age-dependents',
            titleExpansionKeys: [ 'groupNum', 'company', 'division' ],
            filters: {
                division: {
                    type: 'select',
                    label: i18n.t(tokens.titles.division),
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                },
                status: {
                    type: 'select',
                    label: i18n.t(tokens.labels.employees.status),
                    options: [
                        i18n.t(tokens.labels.reports.all),
                        i18n.t(tokens.labels.employees.active),
                        i18n.t(tokens.labels.employees.accountDisabled)
                    ],
                    value: i18n.t(tokens.labels.reports.all)
                }
            }
        },
        [i18n.t(tokens.labels.reports.dependentListing)]: {
            icon: 'groups',
            description: i18n.t(tokens.labels.reports.viewAllCurrentDependents),
            testid: 'dependent-listing',
            titleExpansionKeys: [ 'groupNum', 'company', 'division' ],
            filters: {
                division: {
                    type: 'select',
                    label: i18n.t(tokens.titles.division),
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                },
                status: {
                    type: 'select',
                    label: i18n.t(tokens.labels.employees.status),
                    options: [
                        i18n.t(tokens.labels.reports.all),
                        i18n.t(tokens.labels.employees.active),
                        i18n.t(tokens.labels.employees.accountDisabled)
                    ],
                    value: i18n.t(tokens.labels.reports.all)
                }
            }
        },
        [i18n.t(tokens.labels.reports.spendingAccountUsages)]: {
            title: i18n.t(tokens.labels.reports.hsaLsaUsages),
            icon: 'receipt',
            description: i18n.t(tokens.labels.reports.viewSpendingAccountPremiums),
            titleExpansionKeys: [ 'groupNum', 'company', 'division' ],
            filters: {
                dateRange: {
                    type: 'date',
                    startDate: moment().startOf('year'),
                    endDate: moment()
                },
                division: {
                    type: 'select',
                    label: 'Division',
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                },
                member: true,
                type: {
                    type: 'select',
                    label: i18n.t(tokens.labels.coverage.benefit),
                    options: Services.selectFieldOptions.spendingAccountOptions(),
                    value: i18n.t(tokens.labels.reports.all)
                }
            }
        },
        [i18n.t(tokens.labels.reports.hsaBalance)]: {
            icon: 'healthAndSafety',
            description: i18n.t(tokens.labels.reports.viewCurrentHsaBalancesPerMember),
            testid: 'hsa-balance',
            titleExpansionKeys: [ 'groupNum', 'company', 'division' ],
            filters: {
                division: {
                    type: 'select',
                    label: i18n.t(tokens.titles.division),
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                },
                status: {
                    type: 'select',
                    label: i18n.t(tokens.labels.employees.status),
                    options: Services.selectFieldOptions.memberStatusOptions(),
                    value: i18n.t(tokens.labels.reports.all)
                }
            }
        },
        [i18n.t(tokens.labels.reports.lsaBalance)]: {
            icon: 'shoppingCart',
            description: i18n.t(tokens.labels.reports.viewCurrentLsaBalancesPerMember),
            titleExpansionKeys: [ 'groupNum', 'company', 'division' ],
            filters: {
                division: {
                    type: 'select',
                    label: i18n.t(tokens.titles.division),
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                },
                status: {
                    type: 'select',
                    label: i18n.t(tokens.labels.employees.status),
                    options: Services.selectFieldOptions.memberStatusOptions(),
                    value: i18n.t(tokens.labels.reports.all)
                }
            }
        },
        [i18n.t(tokens.labels.reports.certificateListing)]: {
            icon: 'assignment',
            description: i18n.t(tokens.labels.reports.getDataOnEachMember),
            testid: 'certificate-listing',
            titleExpansionKeys: [ 'groupNum', 'company', 'division' ],
            filters: {
                division: {
                    type: 'select',
                    label: i18n.t(tokens.titles.division),
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                },
                status: {
                    type: 'select',
                    label: i18n.t(tokens.labels.employees.status),
                    options: Services.selectFieldOptions.memberStatusOptions(),
                    value: i18n.t(tokens.labels.reports.all)
                }
            }
        },
        [i18n.t(tokens.labels.reports.nemEligibilityReportTitle)]: {
            title: i18n.t(tokens.labels.reports.nemEligibilityReportTitle),
            icon: 'grading',
            description: i18n.t(tokens.labels.reports.nemEligibilityReportDesc),
            testid: 'nem-eligibility-report',
            filters: {
                class: {
                    type: 'select',
                    label: i18n.t(tokens.titles.class),
                    options: [ i18n.t(tokens.labels.reports.allClasses) ],
                    value: i18n.t(tokens.labels.reports.allClasses),
                    isRequired: true
                },
                division: {
                    type: 'select',
                    label: i18n.t(tokens.titles.division),
                    options: [ i18n.t(tokens.labels.reports.allDivisions) ],
                    value: i18n.t(tokens.labels.reports.allDivisions),
                    isRequired: true
                },
                benefit: {
                    type: 'select',
                    label: i18n.t(tokens.titles.benefit),
                    options: Services.selectFieldOptions.nemBenefitOptions(),
                    value: i18n.t(tokens.labels.reports.all),
                    isRequired: true
                },
                status: {
                    type: 'select',
                    label: i18n.t(tokens.titles.status),
                    options: Services.selectFieldOptions.memberStatusOptions(),
                    value: i18n.t(tokens.labels.reports.all),
                    isRequired: true
                }
            }
        }
    },
    [i18n.t(tokens.labels.reports.financial)]: {
        [i18n.t(tokens.labels.reports.asoMonthlySummary)]: {
            icon: 'adminPanelSettings',
            description: i18n.t(tokens.labels.reports.viewAsoMonthlySummary),
            titleExpansionKeys: [ 'groupNum', 'company', 'division' ],
            filters: {
                from: {
                    type: 'month',
                    value: firstOfMonth
                },
                to: {
                    type: 'month',
                    value: firstOfMonth
                },
                division: {
                    type: 'select',
                    label: i18n.t(tokens.titles.division),
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                },
                benefit: {
                    type: 'select',
                    label: i18n.t(tokens.labels.coverage.benefit),
                    options: Services.selectFieldOptions.healthDentalOptions(),
                    value: i18n.t(tokens.dropdowns.healthDentalOptions.both)
                }
            }
        },
        [i18n.t(tokens.labels.reports.taxableBenefit)]: {
            icon: 'monetizationOn',
            description: i18n.t(tokens.labels.reports.viewYearlyPremium),
            testid: 'taxable-benefit',
            titleExpansionKeys: [ 'groupNum', 'company', 'division' ],
            filters: {
                dateRange: {
                    type: 'date',
                    startDate: moment().startOf('year'),
                    endDate: moment()
                },
                division: {
                    type: 'select',
                    label: 'Division',
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                },
                // Members select with member options
                status: {
                    type: 'select',
                    label: i18n.t(tokens.labels.employees.status),
                    options: Services.selectFieldOptions.memberStatusOptions(),
                    value: i18n.t(tokens.labels.reports.all)
                }
            }
        },
        [i18n.t(tokens.labels.reports.ltdReport)]: {
            title: i18n.t(tokens.labels.reports.ltd),
            icon: 'dateRange',
            description: i18n.t(tokens.labels.reports.viewLtdDisabilityReport),
            testid: 'ltd-report',
            filters: {
                division: {
                    type: 'select',
                    label: 'Division',
                    options: [ 'All Divisions' ],
                    value: 'All Divisions'
                },
                class: {
                    type: 'select',
                    label: 'Class',
                    options: [ 'All Classes' ],
                    value: 'All Classes'
                }
            }
        }
    }
});

export default adminReportsData;
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveMemberBankingDetails, getMemberBankingDetails, validateBankingDetails } from '../../../../actions/admin.js';
import CurrentBankingDetailsModal from './CurrentBankingDetailsModal.js';
import ConfirmBankingDetails from './ConfirmBankingDetails.js';
import { Loader, Services, EditBankingDetails as EditBankingDetailsModal } from "@simplybenefits/sb-ui-library";

class Banking extends PureComponent {
    state = {
        currentBankingDetails: null,
        modalType: null, // currentBankingDetails, editBankingDetails, confirmDetails
        newBankingDetails: null
    };
    
    async componentDidMount() {
        const currentBankingDetails = await this.props.dispatch(getMemberBankingDetails(this.props.employee.meta.userID));

        this.setState({
            modalType: this.props.employee.meta.paymentsSetup ? 'currentBankingDetails' : 'editBankingDetails',
            currentBankingDetails
        });
    }

    _showModal = (modalType) => {
        this.setState({
            modalType
        });
    };

    _saveBankingDetails = async (params) => {
        const data = {
            password: params.password,
            signage: {
                ...params.signage,
                datedOn: Services.dates.formatOutgoingDate(params.signage.datedOn)
            },
            default: this.state.newBankingDetails.default
        };

        if (this.state.newBankingDetails.useSameBankAccount === false) {
            data.paymentsOnly = this.state.newBankingDetails.paymentsOnly;
        }

        // if the member is set to CC pay then don't save a payment only account, only save reimbursement account
        if (this.props.employee.meta.paymentType === 'CC') {
            data.paymentsOnly = null;
        }

        // make call to backend to update banking details
        const success = await this.props.dispatch(saveMemberBankingDetails(this.props.employee.meta.userID, data));

        if (success) {
            window.location.reload();
            return true;
        }

        return false;
    };

    render() {
        if (this.state.modalType === 'currentBankingDetails') {
            return (
                <CurrentBankingDetailsModal
                    currentBankingDetails={ this.state.currentBankingDetails }
                    showModal={ this._showModal }
                    close={ this.props.hideModal }
                    loader={this.props.loader}
                />
            );
        }

        if (this.state.modalType === 'editBankingDetails') {
            return (
                <EditBankingDetailsModal
                    meta={ this.props.employee.meta }
                    currentBankingDetails={ this.state.currentBankingDetails }
                    showModal={ this._showModal }
                    onBack={ () => this._showModal('currentBankingDetails') }
                    close={ this.props.hideModal }
                    validateBankingDetails={ (...params) => this.props.dispatch(validateBankingDetails(...params)) }
                    bankingDetailsVerifying={ this.props.bankingDetailsVerifying }
                    saveNewBankingDetails={ (newBankingDetails) => {
                        this.setState({
                            newBankingDetails
                        });
                    }}
                    whitelabel={ this.props.whitelabel }
                    loader={this.props.loader}
                />
            );
        }

        if (this.state.modalType === 'confirmDetails') {
            return (
                <ConfirmBankingDetails
                    newBankingDetails={ this.state.newBankingDetails }
                    onBack={ () => this._showModal('editBankingDetails') }
                    close={ this.props.hideModal }
                    saveBanking={ (params) => this._saveBankingDetails(params) }
                />
            );
        }

        return <Loader/>;
    }
}

Banking.propTypes = {
    loading: PropTypes.bool,
    meta: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    bankingDetailsVerifying: state.admin.bankingDetailsVerifying,
    currentAccountMeta: state.advisor.currentAccountMeta,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(Banking);
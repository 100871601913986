export default {
    loader: {
        isLoading: false,
        type: 'full' // 'full', 'container', 'modal'
    },
    admin: {
        meta: null,
        administrators: [],
        notifications: [],
        account: null,
        changelog: null,
        pendingChanges: null
    },
    advisor: {
        meta: null,
        accounts: [],
        currentAccountMeta: null,
        notifications: [],
        loaders: {
            commissions: false,
            accountHistory: false,
            masterApp: false,
            assignPlans: false
        }
    },
    quotes: {
        all: [],
        currentQuote: null
    },
    census: {
        employees: [],
        classes: {},
        divisions: {}
    },
    plan: {
        data: null,
        pricing: null,
        designs: {}, // object of classIDs and design data
        modularBlueprints: {},
        specialityProductMetaConfigs: {}
    },
    invoices: {
        current: null,
        selected: null,
        history: [],
        asoReports: {
            history: null,
            balances: null
        }
    },
    reports: {
        prebuilt: [],
        current: false // the currently generated report
    },
    whitelabel: {
        name: null,
        logo: null,
        logoSmall: null,
        primaryColor: null,
        secondaryColor: null,
        lightColor: null,
        buttonTextColor: null,
        watermarkWhite: null,
        watermarkColor: null
    },
    notifier: {
        showing: false,
        verbiage: 'Something failed, please try again',
        type: 'error'
    }
};
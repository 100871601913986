import React from 'react';
import { Text, Theme, Services, i18n, tokens, localizationMaps } from '@simplybenefits/sb-ui-library';
import { styled } from '@mui/system';
import memberStatusMap from '../../../common/memberStatusMap.js';

const HeaderBar = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 10,
    padding: 8,
    backgroundColor: theme.palette.primary.main
}));

const HeaderBarNoFill = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 10,
    padding: 8,
    borderTop: `1.5px solid ${ theme.palette.primary.main }`,
    borderBottom: `1.5px solid ${ theme.palette.primary.main }`
}));

/**
 * @param transaction
 * @param classes
 * @returns {*}
 * @constructor
 */
const LTDReport = ({ report, testid }) => {
    const styles = {
        block: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginBottom: 20
        },
        rowCondensed: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            padding: "3px 8px"
        }
    };

    return (
        <div style={ styles.block }>
            { 
                report.map((items, index) => (
                    // TODO: fix key mapped to index
                    <React.Fragment key={index}>
                        <HeaderBar>
                            <Text medium bold color={ Theme.colors.surface } customStyle={{ flex: 1 }}>{ `${ items[0].lastname }, ${ items[0].firstname }` }</Text>
                            <Text medium bold color={ Theme.colors.surface } customStyle={{ flex: 1, textAlign: 'right' }}>{i18n.t(tokens.labels.certificate)}: { items[0].cert }</Text>
                        </HeaderBar>

                        <div style={ styles.rowCondensed }>
                            <Text medium bold customStyle={{ flex: 1 }}>{i18n.t(tokens.labels.status)}</Text>
                            <Text medium bold customStyle={{ flex: 1 }}>{i18n.t(tokens.labels.class)}</Text>
                            <Text medium bold customStyle={{ flex: 1 }}>{i18n.t(tokens.labels.division)}</Text>
                            <Text medium bold customStyle={{ flex: 1 }}>{i18n.t(tokens.labels.province)}</Text>
                        </div>

                        <div style={ styles.rowCondensed }>
                            <Text medium customStyle={{ flex: 1 }}>{ localizationMaps.fromEnglish("memberStatuses", memberStatusMap[items[0].status]) }</Text>
                            <Text medium customStyle={{ flex: 1 }}>{ items[0].class }</Text>
                            <Text medium customStyle={{ flex: 1 }}>{ items[0].division }</Text>
                            <Text medium customStyle={{ flex: 1 }}>{ localizationMaps.fromEnglish("provinces", items[0].province) }</Text>
                        </div>

                        <HeaderBarNoFill>
                            <Text medium bold customStyle={{ flex: 1 }}>{i18n.t(tokens.labels.effectiveDate)}</Text>
                            <Text medium bold customStyle={{ flex: 1, textAlign: 'center' }}>{i18n.t(tokens.labels.reports.salary)}</Text>
                            <Text medium bold customStyle={{ flex: 1, textAlign: 'center' }}>{i18n.t(tokens.labels.reports.volume)}</Text>
                            <Text medium bold customStyle={{ flex: 2, textAlign: 'center' }}>{i18n.t(tokens.labels.reports.approvedVolume)}</Text>
                            <Text medium bold customStyle={{ flex: 1, textAlign: 'center' }}>{i18n.t(tokens.labels.reports.premium)}</Text>
                        </HeaderBarNoFill>

                        {
                            items.map((item) => (
                                <div style={ styles.rowCondensed } key={item.userID}>
                                    <Text medium bold customStyle={{ flex: 1 }}>{ Services.dates.formatIncomingDate(item.effectiveDate) || 'N/A' }</Text>
                                    <Text medium bold customStyle={{ flex: 1, textAlign: 'center' }}>{ item.earnings || 'N/A' }</Text>
                                    <Text medium bold customStyle={{ flex: 1, textAlign: 'center' }}>{ item.volume || 'N/A' }</Text>
                                    <Text medium bold customStyle={{ flex: 2, textAlign: 'center' }}>{ item.approvedVolume || 'N/A' }</Text>
                                    <Text medium bold customStyle={{ flex: 1, textAlign: 'center' }}>{ item.premium || 'N/A' }</Text>
                                </div>
                            ))
                        }
                    </React.Fragment>
                ))
            }
        
            <span id={testid} />
        </div>
    );
};

export default (LTDReport);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Close as CloseIcon } from '@mui/icons-material';
import { Text, Theme } from '@simplybenefits/sb-ui-library';
import { hideNotification } from '../actions/notifier.js';
import { styled } from '@mui/system';

const StyledNotifier = styled('div')(({ theme, customStyle }) => ({
    color: Theme.colors.surface,
    height: 'auto',
    minHeight: 20,
    padding: 20,
    position: 'fixed',
    top: '2%',
    left: 'calc(50% - 290px)',
    width: 580,
    maxWidth: '90%',
    zIndex: '9999',
    [theme.breakpoints.down(600)]: {
        left: 'calc(50% - 150px)',
        width: 300
    },
    ...customStyle
}));

class Notifier extends Component {
    state = {
        hide: false
    };

    _close = () => {
        this.props.dispatch(hideNotification());
    };

    render() {
        const styles = {
            errorNotifier: {
                backgroundColor: Theme.colors.red
            },
            messageNotifier: {
                backgroundColor: Theme.colors.brandGreen
            },
            browserNotifier: {
                backgroundColor: Theme.colors.mediumDarkBlue,
                opacity: 0.7
            },
            closeModalIcon: {
                cursor: 'pointer',
                display: 'inline',
                marginTop: -15,
                marginRight: -15,
                fontSize: 22,
                float: 'right',
                color: Theme.colors.surface
            },
            noDecor: {
                textDecorationColor: Theme.colors.surface
            }
        };
        if (!this.props.showing) {
            return null;
        }

        let messageStyle = {};

        switch (this.props.notifierType) {
            case 'error':
                messageStyle = styles.errorNotifier;
                break;
            case 'browser':
                messageStyle = styles.browserNotifier;
                break;
            default:
                messageStyle = styles.messageNotifier;
        }

        return (
            <StyledNotifier customStyle={messageStyle}>
                <CloseIcon
                    style={styles.closeModalIcon}
                    onClick={this._close}
                />

                <Text
                    align="center"
                    color={Theme.colors.surface}
                    medium
                    display="block"
                >
                    {this.props.verbiage}
                </Text>

                <a href={this.props.link} style={styles.noDecor}>
                    <Text
                        align="center"
                        color={Theme.colors.surface}
                        medium
                        display="block"
                    >
                        {this.props.link}
                    </Text>
                </a>
            </StyledNotifier>
        );
    }
}


Notifier.propTypes = {
    dispatch: PropTypes.func,
    verbiage: PropTypes.string,
    notifierType: PropTypes.string,
    link: PropTypes.string,
    showing: PropTypes.bool
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    verbiage: state.notifier.verbiage,
    notifierType: state.notifier.type,
    link: state.notifier.link,
    showing: state.notifier.showing
});

export default (connect(mapStateToProps)(Notifier));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tiles, Theme, Text, Content, Container, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';
import ChangePassword from '../ChangePassword/index.js';

class Advisor extends Component {
    state = {
        changePasswordModal: false,
        welcomeMessage: 'Good Morning'
    };

    setWelcomeMessage = () => {
        const today = new Date();
        const curHr = today.getHours();

        if (curHr < 12) {
            this.setState({
                welcomeMessage: i18n.t(tokens.labels.dashboard.goodMorning)
            });
        } else if (curHr < 18) {
            this.setState({
                welcomeMessage: i18n.t(tokens.labels.dashboard.goodAfternoon)
            });
        } else {
            this.setState({
                welcomeMessage: i18n.t(tokens.labels.dashboard.goodEvening)
            });
        }
    };

    componentDidMount() {
        this.setWelcomeMessage();
    }
    
    // make sure that the welcome message changes language when the advisor switches their language
    componentDidUpdate (prevProps) {
        if (prevProps.meta.language !== this.props.meta.language) {
            this.setWelcomeMessage();
        }
    }

    _toggleChangePasswordModal = () => {
        this.setState({
            changePasswordModal: !this.state.changePasswordModal
        });
    };

    render() {
        const { whitelabel } = this.props;

        const tiles = [
            {
                title: i18n.t(tokens.titles.quotes),
                description: i18n.t(tokens.labels.advisor.continueQuotingProcess),
                link: '/advisor/quotes'
            },
            {
                title: i18n.t(tokens.titles.pendingAccounts),
                description: i18n.t(tokens.labels.advisor.soldAccounts),
                link: '/advisor/accounts/pending'
            },
            {
                title: i18n.t(tokens.titles.activeAccounts),
                description: i18n.t(tokens.labels.advisor.viewManageActiveAccounts),
                link: '/advisor/accounts/active'
            },
            {
                title: i18n.t(tokens.titles.lostOpportunities),
                description: i18n.t(tokens.labels.advisor.viewLostAccounts),
                link: '/advisor/accounts/lost'
            },
            {
                title: i18n.t(tokens.titles.underAmendment),
                description: i18n.t(tokens.labels.advisor.viewAmendedAccounts),
                link: '/advisor/accounts/amendment'
            },
            {
                title: i18n.t(tokens.titles.commissions),
                description: i18n.t(tokens.labels.advisor.viewCommissions),
                link: '/advisor/commissions'
            }
        ];

        return (
            <Container>
                <Content>
                    <Title title={ i18n.t(tokens.titles.dashboard) } />

                    <span>
                        <Text light extraLarge display="inline">{ this.state.welcomeMessage }, </Text>
                        <Text bold extraLarge display="inline" color={ whitelabel.primaryColor || Theme.colors.skyBlue }>{ this.props.meta.firstname }</Text>
                        <Text light extraLarge display="inline">.</Text>
                    </span>

                    <Text light medium margin="5px 0 40px 0">
                        { i18n.t(tokens.labels.advisor.healthDashboardWelcome) }
                    </Text>

                    <Tiles tiles={tiles} />
                </Content>

                {
                    this.state.changePasswordModal &&
                        <ChangePassword isForgotPassword hideModal={this._toggleChangePasswordModal}/>
                }

                <span id="dashboard" />
            </Container>
        );
    }
}

Advisor.propTypes = {
    loading: PropTypes.bool,
    accounts: PropTypes.array,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{accounts: Array, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.advisor.loading,
    accounts: state.advisor.accounts,
    meta: state.advisor.meta,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(Advisor);
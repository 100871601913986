import React, { Component } from 'react';
import { connect } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import { Payment as PaymentIcon, ListAlt as ListAltIcon, Add as AddIcon } from '@mui/icons-material';

import * as dates from '@simplybenefits/sb-dates';
import store from '../../../redux/store.js';
import {
    Button,
    Loader,
    Text,
    Theme,
    BookletDownload,
    ContainerControlSite,
    Content,
    Title,
    i18n,
    tokens,
    localizationMaps,
    DependentModal,
    RemoveDependentModal,
    TrusteeModal,
    BeneficiaryModal,
    RemoveBeneficiaryModal,
    ChangeRequestModal,
    WaiveCoverageModal,
    ReinstateCoverageModal,
    TerminateEmployeeModal,
    MemberActionsMenu,
    EmployeeModalHandlerAdminSite,
    PersonalInfoBlock
} from '@simplybenefits/sb-ui-library';
import EmployeeBankingModal from './Banking/index.js';
import JourneyBlock from './JourneyBlock.js';
import JourneyTable from './JourneyTable.js';
import NameTag from './NameTag.js';
import { getUserType } from '../../../services/session.js';
import { getEmployee, getAdminPdf } from '../../../actions/admin.js';
import { addUser, removeUser, updateUser } from '../../../actions/census.js';
import { createChangeRequest, submitChangeRequest, deleteChangeRequest } from '../../../actions/changeRequests.js';
import { createFolder, downloadFile, listFiles, removeFile, uploadFile } from '../../../actions/files.js';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main
}));

class Member extends Component {
    state = {
        employee: null,
        viewAllJourney: false,
        showEditMember: false,
        loading: false,
        actionsDialogOpen: false,
        showUpdateBankingDetails: false,
        showDependentModal: false, // Adding or Editing Dependent
        showRemoveDependentModal: false,
        dependentChangeType: null,
        dependentData: null,
        showTrusteeModal: false,
        trusteeData: null,
        trusteeChangeType: null,
        showBeneficiaryModal: false, // Adding or Editing Beneficiary
        showRemoveBeneficiaryModal: false,
        beneficiaryData: null,
        beneficiaryChangeType: null, // Add or Edit or Remove
        showChangeRequestModal: false,
        changeRequestData: null,
        anchorEl: null
    };

    async componentDidMount() {
        const employee = await this.props.dispatch(getEmployee(this.props.match.params.userID, this.props.match.params.accountID));

        if (employee) {
            this.setState({
                employee
            });
        }
    }

    _fetchMember = async () => {
        const employee = await this.props.dispatch(getEmployee(this.props.match.params.userID, this.props.match.params.accountID));

        if (employee) {
            this.setState({
                employee
            });
        }
    };

    _viewAllJourney = () => {
        this.setState({
            viewAllJourney: !this.state.viewAllJourney
        });
    };

    _toggleEditMember = (state) => {
        this.setState({
            showEditMember: state
        });
    };

    _downloadProofOfInsurance = async (employee) => {
        // Proof of insurance uses members language
        const fileName = employee.meta.language === 'fr' ? `Preuve_Assurance_Simply_Benefits_${ employee.meta.firstname }_${ employee.meta.lastname }.pdf` : `Simply_Benefits_Insurance_Proof_${ employee.meta.firstname }_${ employee.meta.lastname }.pdf`;
        await this.props.dispatch(getAdminPdf("ProofOfInsurance", fileName, { userID: employee.meta.userID }));
    };

    _downloadBenefitsCard = async (employee) => {
        // Benefits card uses members langauge
        const fileName = employee.meta.language === 'fr' ? `Carte_d'avantages.pdf` : `Benefits_Card.pdf`;
        await this.props.dispatch(getAdminPdf("MyCard", fileName, { userID: employee.meta.userID }));
    };

    _closeDialog = () => {
        this.setState({
            actionsDialogOpen: false
        });
    };

    /**
     * Create and open a change request form
     * @param {Object} changeRequestData - Data for creating the change request
     * @returns {Promise<boolean>}
     */
    _openChangeRequestModal = (changeRequestData) => {
        this.setState({
            showEditDependentModal: false,
            showChangeRequestModal: true,
            showEditMember: false,
            showWaiveCoverageModal: false,
            showReinstateCoverageModal: false,
            changeRequestData: changeRequestData
        });
    };

    render() {
        const { adminMeta, currentAccountMeta, divisions } = this.props;
        const whitelabel = store.getState().whitelabel;
        const employee = this.state.employee;
        const isAdvisor = Number(getUserType()) === 1;
        const showUpdateBankingButton = typeof employee?.meta?.divisionID === 'string'
            ? divisions[employee.meta.divisionID].access === 'Write' && !isAdvisor
            : false;

        const styles = {
            contentMargin: {
                marginTop: '30px'
            },
            top: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                margin: "0 0 20px 0",
                flexWrap: 'wrap'
            },
            items: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row'
            },
            titleBlock: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center'
            },
            downloadBtnContainer: {
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
                marginBottom: 10
            }
        };

        let dependentColorIndex = 1;
        let beneficiaryColorIndex = 1;

        if (!employee || this.state.loader?.isLoading) {
            return <Loader/>;
        }

        employee.meta.company = adminMeta.company; // save the company name in the member data for the booklet

        let statusColor = Theme.colors.brandGreen;

        if (!employee.meta.benefitsActive) {
            statusColor = Theme.colors.red;
        }

        // Add,Edit,Remove Dependents,Beneficiaries,Trustee
        const canWrite = (divisions[employee.meta.divisionID].access === 'Write' && !isAdvisor) || adminMeta.isParent;

        return (
            <ContainerControlSite>
                <Content>
                    {
                        [ 'PENDING', 'ACTIVE', 'TERMINATED' ].includes(employee.meta.status.toUpperCase()) ?
                            <Text
                                mediumLarge
                                bold
                                color={statusColor}
                                margin="0 5px 0 0"
                                align="right"
                            >
                                { localizationMaps.fromEnglish("memberStatuses", employee.meta.status) }
                            </Text>
                            :
                            <Text
                                mediumLarge
                                bold
                                color={statusColor}
                                margin="0 5px 0 0"
                                align="right"
                            >
                                { `${ localizationMaps.fromEnglish("memberStatuses", employee.meta.status) } - ${ !employee.meta.benefitsActive ? i18n.t(tokens.labels.employees.inactive) : i18n.t(tokens.labels.employees.active) }` }
                            </Text>
                    }

                    {
                        employee.meta.activeStatusRecord &&
                            <Text
                                align="right"
                                light
                            >
                                { i18n.t(tokens.labels.employees.expectedReturnDate, { returnDate: dates.getLocalDisplay(employee.meta.activeStatusRecord.returnDate) })}
                            </Text>
                    }

                    <div style={ styles.titleBlock }>
                        <Title
                            title={ `${ employee.meta.firstname } ${ employee.meta.lastname }` }
                            subheader={ i18n.t(tokens.labels.employees.member) }
                            customStyle={{ flex: 1 }}
                            breadcrumbs={[
                                {
                                    title: i18n.t(tokens.labels.employees.members),
                                    link: '/members'
                                }
                            ]}
                        />

                        <div style={ styles.downloadBtnContainer }>
                            {
                                canWrite ?
                                    <MemberActionsMenu
                                        waiveCoverage={() => this.setState({ showWaiveCoverageModal: true })}
                                        reinstateCoverage={() => this.setState({ showReinstateCoverageModal: true })}
                                        showReinstateCoverageOption={ employee.meta.waiveFor || employee.meta.hasWaived }
                                        terminate={() => this.setState({ showTerminateMemberModal: true })}
                                    /> :
                                    null
                            }
                            

                            {
                                Boolean(employee.meta.cert) &&
                                    <>
                                        {
                                            showUpdateBankingButton &&
                                                <Button
                                                    color="white"
                                                    customStyle={{
                                                        height: 50,
                                                        marginLeft: "10px",
                                                        textTransform: "capitalize",
                                                        fontSize: 16,
                                                        alignSelf: 'flex-end',
                                                        marginBottom: "20px"
                                                    }}
                                                    noHover
                                                    onClick={() => {
                                                        this.setState({
                                                            showUpdateBankingDetails: true
                                                        });
                                                    }}
                                                >
                                                    { i18n.t(tokens.buttons.updateBankingDetails) }
                                                </Button>
                                        }

                                        <Button
                                            color="primary"
                                            customStyle={{
                                                height: 50,
                                                width: 220,
                                                marginLeft: "10px",
                                                textTransform: "capitalize",
                                                fontSize: 16,
                                                alignSelf: 'flex-end',
                                                marginBottom: "20px"
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    actionsDialogOpen: true
                                                });
                                            }}
                                        >
                                            { i18n.t(tokens.buttons.downloadResources) }
                                        </Button>
                                    </>
                            }
                        </div>
                    </div>

                    <div style={ styles.top }>
                        <PersonalInfoBlock
                            meta={ employee.meta }
                            coverage={ employee.coverage }
                            classData={this.props.classData}
                            divisions={this.props.divisions}
                            onEdit={() => this._toggleEditMember(true)}
                            isAdminSite={true}
                        />

                        <JourneyBlock
                            journey={ employee.journey }
                            meta={ employee.meta }
                            onViewAll={ this._viewAllJourney }
                            viewAllJourney={ this.state.viewAllJourney }
                        />
                    </div>

                    {
                        this.state.viewAllJourney &&
                            <div style={{ marginBottom: 20 }}>
                                <Text
                                    extraLarge
                                    veryBold
                                    color={ Theme.colors.mainTextGrey }
                                    margin="0 0 10px 0"
                                >
                                    { i18n.t(tokens.labels.employees.journey) }
                                </Text>

                                <JourneyTable journey={ employee.journey } meta={ employee.meta } />
                            </div>
                    }

                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text
                                extraLarge
                                veryBold
                                color={ Theme.colors.mainTextGrey }
                                margin="0 14px 10px 0" 
                                
                            >
                                { i18n.t(tokens.titles.dependents) }
                            </Text>

                            {
                                canWrite ?
                                    <IconButton 
                                        aria-label="addDependent"
                                        onClick={() => {
                                            this.setState({ showDependentModal: true, dependentData: employee.dependent, dependentChangeType: 'Add' });
                                        }}
                                        sx={{ color: Theme.colors.mainTextGrey,
                                            marginBottom: '10px',
                                            '&:hover': {
                                                backgroundColor: Theme.colors.hoverGrey
                                            } }}
                                    >
                                        <AddIcon/>
                                    </IconButton>
                                    :
                                    null
                            }
                        </div>

                        {
                            employee.dependents.length === 0 ?
                                <Text
                                    small
                                    bold
                                    color={ Theme.colors.mainTextGrey }
                                    margin="20px 14px 10px 0"
                                >
                                    { i18n.t(tokens.labels.employees.memberNotProvidedDependents) }
                                </Text>
                                :
                                <div style={ styles.items }>
                                    {
                                        employee.dependents.map((dependent) => {
                                            if (dependent.status.toUpperCase() === 'TERMINATED') {
                                                return null;
                                            }

                                            let type = 'light';

                                            if (dependentColorIndex === 1) {
                                                type = 'light';
                                                dependentColorIndex = 2;
                                            } else if (dependentColorIndex === 2) {
                                                type = 'medium';
                                                dependentColorIndex = 3;
                                            } else if (dependentColorIndex === 3) {
                                                type = 'dark';
                                                dependentColorIndex = 1;
                                            }

                                            return (
                                                <NameTag 
                                                    key={ dependent.dependentID } 
                                                    item={ dependent } 
                                                    type={type}
                                                    edit={() => this.setState({ showDependentModal: true, dependentData: dependent, dependentChangeType: 'Edit' }) }
                                                    remove={() => this.setState({ showRemoveDependentModal: true, dependentData: dependent, beneficiaryChangeType: 'Remove' })}
                                                    writeAccess={ canWrite }
                                                />
                                            );
                                        })
                                    }
                                </div>
                        }
                    </div>

                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text
                                extraLarge
                                veryBold
                                color={ Theme.colors.mainTextGrey }
                                margin="20px 14px 10px 0"
                            >
                                { i18n.t(tokens.titles.beneficiaries) }
                            </Text>

                            {
                                canWrite ?
                                    <IconButton 
                                        aria-label="addBeneficiary"
                                        onClick={() => {
                                            this.setState({ showBeneficiaryModal: true, beneficiaryData: employee.beneficiary, beneficiaryChangeType: 'Add' });
                                        }}
                                        sx={{ color: Theme.colors.mainTextGrey,
                                            margin: "20px 0 10px 0",
                                            '&:hover': {
                                                backgroundColor: Theme.colors.hoverGrey
                                            } }}
                                    >
                                        <AddIcon/>
                                    </IconButton>
                                    :
                                    null
                            }
                        </div>

                        {
                            employee.beneficiaries.primary.length === 0 ?
                                <Text
                                    small
                                    bold
                                    color={ Theme.colors.mainTextGrey }
                                    margin="20px 0 10px 0"
                                >
                                    { i18n.t(tokens.labels.employees.memberNotProvidedBeneficiaries) }
                                </Text>
                                :
                                <div style={ styles.items }>
                                    {
                                        employee.beneficiaries.primary.map((beneficiary) => {
                                            let type = 'medium';

                                            if (beneficiaryColorIndex === 1) {
                                                type = 'medium';
                                                beneficiaryColorIndex = 2;
                                            } else if (beneficiaryColorIndex === 2) {
                                                type = 'dark';
                                                beneficiaryColorIndex = 3;
                                            } else if (beneficiaryColorIndex === 3) {
                                                type = 'light';
                                                beneficiaryColorIndex = 1;
                                            }

                                            return (
                                                <NameTag 
                                                    key={ beneficiary.beneficiaryID } 
                                                    item={ beneficiary } 
                                                    type={type}
                                                    edit={() => this.setState({ showBeneficiaryModal: true, beneficiaryData: beneficiary, beneficiaryChangeType: 'Edit' }) }
                                                    remove={() => this.setState({ showRemoveBeneficiaryModal: true, beneficiaryData: beneficiary, beneficiaryChangeType: 'Remove' })}
                                                    writeAccess={ canWrite }
                                                />
                                                        
                                            );
                                        })
                                    }
                                </div>
                        }
                    </div>

                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text
                                extraLarge
                                veryBold
                                color={ Theme.colors.mainTextGrey }
                                margin="20px 14px 10px 0"
                            >
                                { i18n.t(tokens.titles.trustee) }
                            </Text>

                            {
                                canWrite ?
                                    <IconButton 
                                        aria-label="addTrustee"
                                        onClick={() => {
                                            this.setState({ showTrusteeModal: true, trusteeData: employee.trustee, trusteeChangeType: 'Add' });
                                        }}
                                        sx={{ color: Theme.colors.mainTextGrey,
                                            margin: "20px 0 10px 0",
                                            '&:hover': {
                                                backgroundColor: Theme.colors.hoverGrey
                                            } }}
                                    >
                                        <AddIcon/>
                                    </IconButton>
                                    :
                                    null
                            }
                        </div>

                        {
                            employee.trustee ?
                                <NameTag 
                                    item={ employee.trustee } 
                                    type="dark" 
                                    edit={() => this.setState({ showTrusteeModal: true, trusteeData: employee.trustee, trusteeChangeType: 'Edit' }) }
                                    remove={() => this.setState({ showTrusteeModal: true, trusteeData: employee.trustee, trusteeChangeType: 'Remove' })}
                                    writeAccess={ canWrite }
                                />
                                :
                                <Text
                                    small
                                    bold
                                    color={ Theme.colors.mainTextGrey }
                                    margin="20px 0 10px 0"
                                >
                                    { i18n.t(tokens.labels.employees.memberNotProvidedTrustee) }
                                </Text>

                        }
                    </div>
                </Content>

                {
                    this.state.actionsDialogOpen &&
                        <Dialog sx={{ "& .MuiPaper-rounded": { borderRadius: "8px", minWidth: "320px" } }} onClose={this._closeDialog} aria-labelledby="simple-dialog-title" open={Boolean(open)}>
                            <DialogTitle id="simple-dialog-title" style={{ paddingBottom: 5, fontFamily: Theme.fonts.type.heading }}>{ i18n.t(tokens.labels.employees.selectDownload) }</DialogTitle>
                            <List>
                                <BookletDownload
                                    employee={ employee }
                                    whitelabel={ whitelabel }
                                    isListItem={ true }
                                    loading={ this.props.loading }
                                    getAdminPdf={ (...params) => this.props.dispatch(getAdminPdf(...params)) }
                                />

                                <ListItem button onClick={() => this._downloadProofOfInsurance(employee)} sx={{ padding: "15px 25px" }}>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <ListAltIcon />
                                        </StyledAvatar>
                                    </ListItemAvatar>

                                    <ListItemText primary={ i18n.t(tokens.buttons.downloadProofOfInsurance)} />
                                </ListItem>

                                <ListItem button onClick={() => this._downloadBenefitsCard(employee)} sx={{ padding: "15px 25px" }}>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <PaymentIcon />
                                        </StyledAvatar>
                                    </ListItemAvatar>

                                    <ListItemText primary={ i18n.t(tokens.buttons.downloadBenefitsCard) }/>
                                </ListItem>

                            </List>
                        </Dialog>
                }

                {
                    (this.state.showUpdateBankingDetails && this.state.employee) &&
                        <EmployeeBankingModal
                            hideModal={() => this.setState({ showUpdateBankingDetails: false })}
                            employee={ this.state.employee }
                        />
                }

                {/* Modals */}
                <EmployeeModalHandlerAdminSite
                    open={ this.state.showEditMember }
                    isEdit={this.state.showEditMember}
                    close={() => this._toggleEditMember(false)}
                    currentEmployee={ employee.meta }
                    classData={this.props.classData}
                    divisions={this.props.divisions}
                    addUser={ (employeeDetails) => this.props.dispatch(addUser(employeeDetails, employee.meta.accountID)) }
                    updateUser={(...params) => this.props.dispatch(updateUser(...params))}
                    currentAccountMeta={ currentAccountMeta }

                    createChangeRequest={(params) => this.props.dispatch(createChangeRequest({ ...params, userID: employee.meta.userID }))}
                    deleteChangeRequest={(...params) => this.props.dispatch(deleteChangeRequest(...params))}
                    submitChangeRequest={ submitChangeRequest }

                    id={ employee.meta.userID }
                    dispatch={ this.props.dispatch }
                    listFiles={ listFiles }
                    createFolder={ createFolder }
                    uploadFile={ uploadFile }
                    downloadFile={ downloadFile }
                    removeFile={ removeFile }
                    loader={this.props.loader}
                />

                <TerminateEmployeeModal
                    open={this.state.showTerminateMemberModal}
                    currentEmployee={employee.meta}
                    terminate={(...params) => this.props.dispatch(removeUser(...params))}
                    close={ () => this.setState({ showTerminateMemberModal: false })}
                    loader={this.props.loader}
                />

                <DependentModal
                    open={this.state.showDependentModal}
                    dependentData={this.state.dependentData}
                    goBack={() => this.setState({ showDependentModal: false, dependentData: null })}
                    openChangeRequestModal={(changeRequestData) => this.setState({ showDependentModal: false, showChangeRequestModal: true, changeRequestData })}
                    isEdit={ this.state.dependentChangeType === 'Edit'}
                />

                <RemoveDependentModal
                    open={ this.state.showRemoveDependentModal }
                    dependentInfo={ this.state.dependentData }
                    goBack={() => this.setState({ showRemoveDependentModal: false, dependentData: null })}
                    openChangeRequestModal={ (changeRequestData) => this.setState({ showRemoveDependentModal: false, showChangeRequestModal: true, changeRequestData }) }
                />
                
                <BeneficiaryModal
                    open={ this.state.showBeneficiaryModal }
                    onClose={ () => this.setState({ showBeneficiaryModal: false, beneficiaryData: null, beneficiaryChangeType: null })}
                    beneficiaryData={ this.state.beneficiaryData }
                    isEdit={ this.state.beneficiaryChangeType === 'Edit'}
                    isAdmin={ true }
                    isContingent={ false }
                    trustee={ employee.trustee }
                    primaryBeneficiaries={ employee.beneficiaries.primary }
                    contingentBeneficiaries={employee.beneficiaries.contingent}
                    loader={ this.props.loader }
                    openChangeRequestForm={(changeRequestData) => this.setState({ showBeneficiaryModal: false, showChangeRequestModal: true, changeRequestData })}
                />

                <RemoveBeneficiaryModal
                    open={ this.state.showRemoveBeneficiaryModal }
                    onClose={ () => this.setState({ showRemoveBeneficiaryModal: false, beneficiaryData: null, beneficiaryChangeType: null })}
                    beneficiaryData={ this.state.beneficiaryData }
                    isContingent={ false }
                    primaryBeneficiaries={ employee.beneficiaries.primary }
                    contingentBeneficiaries={employee.beneficiaries.contingent}
                    isAdmin={ true }
                    openChangeRequestForm={(changeRequestData) => this.setState({ showRemoveBeneficiaryModal: false, showChangeRequestModal: true, changeRequestData })}
                    loader={ this.props.loader }
                />

                <TrusteeModal
                    open={ this.state.showTrusteeModal }
                    onClose={ () => this.setState({ showTrusteeModal: false, trusteeData: null })}
                    trusteeData={ this.state.trusteeData }
                    isEdit={ this.state.trusteeChangeType === 'Edit' }
                    isRemoveTrustee={ this.state.trusteeChangeType === 'Remove'}
                    isAdmin={ true }
                    openChangeRequestModal={(changeRequestData) => this.setState({ showTrusteeModal: false, showChangeRequestModal: true, changeRequestData })}
                />

                <WaiveCoverageModal 
                    open={this.state.showWaiveCoverageModal}
                    close={() => this.setState({ showWaiveCoverageModal: false }) }
                    openChangeRequestModal={ this._openChangeRequestModal }
                />

                <ReinstateCoverageModal
                    open={this.state.showReinstateCoverageModal}
                    close={() => this.setState({ showReinstateCoverageModal: false }) }
                    openChangeRequestModal={ this._openChangeRequestModal }
                    meta={employee.meta}
                /> 

                <ChangeRequestModal
                    open={ this.state.showChangeRequestModal }
                    onClose={ () => this.setState({ showChangeRequestModal: false, editDependentData: null, changeRequestData: null })}
                    createChangeRequest={(params) => this.props.dispatch(createChangeRequest({ ...params, userID: this.state.employee.meta.userID }))}
                    deleteChangeRequest={(...params) => this.props.dispatch(deleteChangeRequest(...params))}
                    submitChangeRequest={(changeRequestID, changeRequestUpdateData) => this.props.dispatch(submitChangeRequest(changeRequestID, { userID: this.state.employee.meta.userID, ...changeRequestUpdateData })) }
                    changeRequestData={this.state.changeRequestData}
                    loader={this.props.loader}

                    // Files Props
                    type="users"
                    id={ this.state.employee.meta.userID }
                    dispatch={ this.props.dispatch }
                    listFiles={ listFiles }
                    createFolder={ createFolder }
                    uploadFile={ uploadFile }
                    downloadFile={ downloadFile }
                    removeFile={ removeFile }
                />

                <span id="employee" />
            </ContainerControlSite>
        );
    }
}

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    census: state.census.employees,
    classData: state.census.classes,
    divisions: state.census.divisions,
    adminMeta: state.admin.meta,
    whitelabel: state.whitelabel,
    currentAccountMeta: state.admin.account
});

export default (connect(mapStateToProps)(Member));
import React from 'react';
import Avatar from 'react-avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Text, Theme, i18n, tokens } from '@simplybenefits/sb-ui-library';

import store from '../redux/store.js';


/**
 *
 * @param {*} param0
 */
const MetaMenu = ({ open, onClose, isAdvisor, isParent, hasSpendingAccounts, anchorEl, toggleModal, handleCloseMenu, logout, name, email, testid }) => {
    const styles = {
        menuItemBorder: {
            borderBottom: `${ Theme.colors.surface } solid 1px`
        },
        navDropMenuItem: {
            height: 'auto',
            justifyContent: 'center',
            minWidth: '100%',
            padding: '5px',
            paddingTop: '10px'
        },
        navDropMenuItemMain: {
            padding: '30px',
            paddingTop: '20px',
            textAlign: 'center'
        }   
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            disableAutoFocusItem={true}
            anchorOrigin={ { vertical: "top", horizontal: "left" } }
        >
            <div style={styles.menuItemBorder} onClick={handleCloseMenu}>
                <div style={styles.navDropMenuItemMain}>
                    <Avatar
                        name={name}
                        size="55"
                        textSizeRatio={2.2}
                        round={false}
                        color={store.getState().whitelabel.primaryColor || Theme.colors.skyBlue}
                        style={{ marginBottom: 20 }}
                    />
                    <Text
                        testid={testid}
                        align={'center'}
                        medium
                        bold
                        display="block"
                    >
                        {name}
                    </Text>
                    <Text
                        align={'center'}
                        medium
                        light
                        display="block"
                    >
                        {email}
                    </Text>

                </div>
            </div>

            <MenuItem
                style={styles.navDropMenuItem}
                onClick={() => toggleModal(isAdvisor ? 'showAdvisorSettingsModal' : 'showAdminSettingsModal')}
            >
                <Text
                    align="center"
                    medium
                    display="block"
                    customLinkStyle={{ textDecoration: 'none' }}
                >
                    { i18n.t(tokens.labels.admin.settings) }
                </Text>
            </MenuItem>

            <MenuItem
                style={styles.navDropMenuItem}
                onClick={() => toggleModal('showPasswordModal')}
            >
                <Text
                    align="center"
                    medium
                    display="block"
                    customLinkStyle={{ textDecoration: 'none' }}
                >
                    { i18n.t(tokens.labels.password) }
                </Text>
            </MenuItem>

            {
                !isAdvisor &&
                    <div>
                        {
                            Boolean(isParent) &&
                                <MenuItem
                                    style={styles.navDropMenuItem}
                                    onClick={() => toggleModal('showBankingDetailsModal')}
                                >
                                    <Text
                                        align="center"
                                        medium
                                        display="block"
                                        customLinkStyle={{ textDecoration: 'none' }}
                                    >
                                        { i18n.t(tokens.titles.bankingDetails) }
                                    </Text>
                                </MenuItem>
                        }

                        <MenuItem style={styles.navDropMenuItem} onClick={handleCloseMenu}>
                            <Text
                                medium
                                margin="0 10px"
                                display="block"
                                link="/invoices"
                                customLinkStyle={{ textDecoration: 'none' }}
                            >
                                { i18n.t(tokens.titles.invoices) }
                            </Text>
                        </MenuItem>

                        {
                            Boolean(isParent) &&
                                <MenuItem style={styles.navDropMenuItem} onClick={handleCloseMenu}>
                                    <Text
                                        medium
                                        margin="0 10px"
                                        display="block"
                                        link="/administrators"
                                        customLinkStyle={{ textDecoration: 'none' }}
                                    >
                                        { i18n.t(tokens.labels.admin.administrators) }
                                    </Text>
                                </MenuItem>
                        }
                        <MenuItem
                            style={styles.navDropMenuItem}
                            onClick={() => toggleModal('showClassAndDivisionDetailsModal')}
                        >
                            <Text
                                align="center"
                                medium
                                display="block"
                                customLinkStyle={{ textDecoration: 'none' }}
                            >
                                { i18n.t(tokens.labels.admin.classAndDivisionDetails) }
                            </Text>
                        </MenuItem>
                    </div>
            }

            {
                Boolean(hasSpendingAccounts) &&
                            <MenuItem
                                style={ styles.navDropMenuItem }
                                onClick={() => toggleModal('showLsaItemsModal')}
                            >
                                <Text
                                    align="center"
                                    medium
                                    display="block"
                                    customLinkStyle={{ textDecoration: 'none' }}
                                >
                                    { i18n.t(tokens.labels.spendingAccounts.lsaCategories) }
                                </Text>
                            </MenuItem>
            }

            <MenuItem style={styles.navDropMenuItem} onClick={handleCloseMenu}>
                <Text
                    medium
                    margin="0 10px"
                    display="block"
                    link={isAdvisor ? "/advisor/resources" : "/resources"}
                    customLinkStyle={{ textDecoration: 'none' }}
                >
                    { i18n.t(tokens.titles.resources) }
                </Text>
            </MenuItem>

            <MenuItem style={styles.navDropMenuItem} onClick={logout} >
                <Text
                    color={Theme.colors.red}
                    align="center"
                    medium
                    display="block"
                >
                    { i18n.t(tokens.titles.logout) }
                </Text>
            </MenuItem>
        </Menu>
    );
};

export default (MetaMenu);
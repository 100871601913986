import React from 'react';
import { Services } from '@simplybenefits/sb-ui-library';

import AccountCard from '../../../components/Cards/AccountCard.js';

/**
 * @param accounts
 * @returns {*}
 * @constructor
 */
const AccountList = ({ accounts }) => (
    <div id="accountListBlock">
        {
            accounts.map((account) => (
                <AccountCard key={Services.concatTokens(account.company, account.accountID)} account={account} />
            ))
        }
    </div>
);

export default AccountList;
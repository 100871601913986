import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from '@simplybenefits/sb-ui-library';

import Management from './Management.js';
import * as events from '../../services/events.js';

class Members extends Component {
    state = {
        selectedSection: 'my-team'
    };

    componentDidMount() {
        events.record('view_employees');
    }

    render() {

        return (
            <Container>
                <Management />

                <span id="employees" />
            </Container>
        );
    }
}

export default connect()(Members);
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Loader, Notification, ContainerControlSite, Content, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';
import List from '@mui/material/List';
import { removeAdminNotification } from '../../actions/admin.js';
import { removeAdvisorNotification } from '../../actions/advisor.js';
import * as session from '../../services/session.js';
import store from '../../redux/store.js';

class Notifications extends PureComponent {
    _removeAdminNotification = (notification) => {
        this.props.dispatch(removeAdminNotification(notification.id));
    };

    _removeAdvisorNotification = (notification) => {
        this.props.dispatch(removeAdvisorNotification(notification.id));
    };

    render() {
        const whitelabel = store.getState().whitelabel;

        let notifications = [];
        const userType = session.getUserType();
        const isAdvisor = Number(userType) === 1;

        if (isAdvisor) {
            if (!this.props.advisorNotifications) {
                return <Loader whitelabel={ whitelabel } />;
            }

            notifications = this.props.advisorNotifications;
        } else {
            if (!this.props.adminNotifications) {
                return <Loader whitelabel={ whitelabel } />;
            }

            notifications = this.props.adminNotifications;
        }

        return (
            <ContainerControlSite>
                <Content>
                    <Title title={ i18n.t(tokens.titles.notifications) } />

                    <List>
                        {
                            notifications.map((notification) => (
                                <Notification
                                    key={notification.id}
                                    notification={ notification }
                                    onClick={ isAdvisor ? this._removeAdvisorNotification : this._removeAdminNotification }
                                    customDeleteIcon={{ padding: "12px" }}
                                />
                            ))
                        }
                    </List>

                    {
                        notifications.length === 0 &&
                            <Text light medium align="center">{ i18n.t(tokens.labels.account.youHaveNoNotifications) }</Text>
                    }
                </Content>

                {(this.props.adminLoading || this.props.advisorLoading) && <Loader whitelabel={ whitelabel } />}

                <span id="notifications" />
            </ContainerControlSite>
        );
    }
}

Notifications.propTypes = {
    adminLoading: PropTypes.bool,
    advisorLoading: PropTypes.bool,
    adminNotifications: PropTypes.array,
    advisorNotifications: PropTypes.array
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    adminLoading: state.admin.loading,
    advisorLoading: state.advisor.loading,
    adminNotifications: state.admin.notifications,
    advisorNotifications: state.advisor.notifications
});

export default (connect(mapStateToProps)(Notifications));
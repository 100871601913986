import request from './request.js';

/**
 * @param type
 * @param filters
 * @param accountID
 * @param isAdvisor
 * @returns {Promise<any>}
 */
function generate(type, filters, accountID, isAdvisor) {
    const options = {
        endpoint: isAdvisor ? `/advisor/${ accountID }/report/run/${ type }` : `/report/run/${ type }`,
        method: 'POST',
        body: {
            filters
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    generate
};
import initialState from '../initialState.js';
import {
    GET_REPORT_SUCCESS,
    GENERATE_REPORT_SUCCESS,
    CLEAR_REPORT
} from '../../actions/types/reports';

export default (state = initialState.reports, action) => {
    switch (action.type) {
        case CLEAR_REPORT: {
            return {
                ...state,
                current: false
            };
        }

        case GET_REPORT_SUCCESS: {
            return {
                ...state,
                presets: [ ...action.data.presets ]
            };
        }

        case GENERATE_REPORT_SUCCESS: {
            return {
                ...state,
                current: action.data
            };
        }

        default:
            return state;
    }
};
import React from 'react';
import { Text, Theme, i18n, tokens } from '@simplybenefits/sb-ui-library';
import { styled } from '@mui/system';

const StyledHeaderBar = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: 8,
    backgroundColor: theme.palette.primary.main,
    marginTop: 10,
    marginBottom: 10
}));

const StyledLine = styled('div')(({ theme }) => ({
    width: '100%',
    height: 2,
    opacity: 0.5,
    backgroundColor: theme.palette.primary.main,
    margin: "10px auto"
}));

/**
 * @param number
 * @returns {string}
 * @private
 */
function _formatNumber(number) {
    return `$${ number.toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') }`;
}

/**
 * @param x
 * @private
 */
function _numberWithCommas(x) {
    return x.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * @param classes
 * @returns {*}
 */
const HeaderRow = () => (
    <StyledHeaderBar>
        <div style={{ flex: 2 }} />
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Text medium bold color={ Theme.colors.surface }>{i18n.t(tokens.labels.reports.claims)}</Text>
        </div>

        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Text medium bold color={ Theme.colors.surface }>{i18n.t(tokens.labels.reports.amount)}</Text>
        </div>
    </StyledHeaderBar>
);

/**
 * @param report
 * @param classes
 * @returns {*}
 * @constructor
 */
const LossRatio = ({ report }) => {
    const styles = {
        container: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        },
        block: {
            display: 'flex',
            flex: 1,
            flexDirection: 'space-between',
            marginBottom: 30
        },
        leftColumn: {
            flex: 1,
            marginRight: 20
        },
        rightColumn: {
            flex: 1,
            marginLeft: 20
        },
        row: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            padding: '5px 0'
        },
        label: {
            display: 'flex',
            flex: 2,
            alignItems: 'center'
        },
        claims: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
        },
        amount: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
        }
    };
    return (
        <div style={ styles.container }>
            <div style={ styles.block }>
                <div style={ styles.leftColumn }>
                    <Text mediumLarge veryBold>{i18n.t(tokens.labels.reports.healthcareInformation)}</Text>
                    <HeaderRow/>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.titles.drugs)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.ehc.drugs.numClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.ehc.drugs.amountPaid) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.titles.health)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.ehc.health.numClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.ehc.health.amountPaid) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.titles.paramedicals)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.ehc.paramedicals.numClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.ehc.paramedicals.amountPaid) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.titles.vision)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.ehc.vision.numClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.ehc.vision.amountPaid) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.titles.outOfCountry)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.ehc.outOfCountry.numClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.ehc.outOfCountry.amountPaid) }</Text>
                        </div>
                    </div>

                    <StyledLine/>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.labels.total)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.ehc.totalClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.ehc.totalClaimsPaid) }</Text>
                        </div>
                    </div>

                    <StyledLine/>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text mediumLarge bold>{i18n.t(tokens.labels.reports.totalPremium)}</Text>
                        </div>
                        <div style={ styles.claims } />
                        <div style={ styles.amount }>
                            <Text mediumLarge bold>{ _formatNumber(report.ehc.totalPremium) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text mediumLarge bold>{i18n.t(tokens.labels.reports.totalClaimsPaid)}</Text>
                        </div>
                        <div style={ styles.claims } />
                        <div style={ styles.amount }>
                            <Text mediumLarge bold>{ _formatNumber(report.ehc.totalClaimsPaid) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text mediumLarge bold>{i18n.t(tokens.labels.reports.lossRatio)}</Text>
                        </div>
                        <div style={ styles.claims } />
                        <div style={ styles.amount }>
                            <Text mediumLarge bold>{ (Number(report.ehc.lossRatio) * 100).toFixed(2) }%</Text>
                        </div>
                    </div>

                    <StyledLine />
                </div>

                <div style={ styles.rightColumn }>
                    <Text mediumLarge veryBold>{i18n.t(tokens.labels.reports.dentalInformation)}</Text>
                    <HeaderRow />

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.labels.reports.basic)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.dental.basic.numClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.dental.basic.amountPaid) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.labels.reports.major)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.dental.major.numClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.dental.major.amountPaid) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.labels.reports.orthodontics)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.dental.orthodontics.numClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.dental.orthodontics.amountPaid) }</Text>
                        </div>
                    </div>

                    <StyledLine/>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text medium bold>{i18n.t(tokens.labels.total)}</Text>
                        </div>
                        <div style={ styles.claims }>
                            <Text medium bold>{ _numberWithCommas(report.dental.totalClaims) }</Text>
                        </div>
                        <div style={ styles.amount }>
                            <Text medium bold>{ _formatNumber(report.dental.totalClaimsPaid) }</Text>
                        </div>
                    </div>

                    <StyledLine/>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text mediumLarge bold>{i18n.t(tokens.labels.reports.totalPremium)}</Text>
                        </div>
                        <div style={ styles.claims } />
                        <div style={ styles.amount }>
                            <Text mediumLarge bold>{ _formatNumber(report.dental.totalPremium) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text mediumLarge bold>{i18n.t(tokens.labels.reports.totalClaimsPaid)}</Text>
                        </div>
                        <div style={ styles.claims } />
                        <div style={ styles.amount }>
                            <Text mediumLarge bold>{ _formatNumber(report.dental.totalClaimsPaid) }</Text>
                        </div>
                    </div>

                    <div style={ styles.row }>
                        <div style={ styles.label }>
                            <Text mediumLarge bold>{i18n.t(tokens.labels.reports.lossRatio)}</Text>
                        </div>
                        <div style={ styles.claims } />
                        <div style={ styles.amount }>
                            <Text mediumLarge bold>{ (Number(report.dental.lossRatio) * 100).toFixed(2) }%</Text>
                        </div>
                    </div>

                    <StyledLine/>
                </div>
            </div>
        </div>
    );
};

export default (LossRatio);
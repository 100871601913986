import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getUserType } from '../../../services/session.js';

import { MessageCard, InvoiceTile, MonthTile, i18n, tokens } from "@simplybenefits/sb-ui-library";


class AsoHistory extends PureComponent {
    render() {
        const { history, whitelabel } = this.props;
        const isAdvisor = Number(getUserType()) === 1;
        const styles = {
            historyContainer: {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row'
            }
        };

        return (
            <div style={styles.historyContainer}>
                {
                    !history || history.length === 0 ?
                        <MessageCard
                            title={i18n.t(tokens.labels.admin.noAsoHistory)}
                            subtitle={i18n.t(tokens.labels.admin.accountHasNoASOHistory)}
                        />
                        :
                        this.props.history.map((report, i) => (
                            isAdvisor || this.props.admin.meta.isParent ?
                                <InvoiceTile
                                    testid={i === 0 ? 'aso-report-tile' : undefined}
                                    key={report.dateAdded}
                                    date={report.dateAdded}
                                    total={ report.closingBalance }
                                    status="Balance"
                                    onClick={() => this.props.selectReport(report)}
                                    whitelabel={ whitelabel }
                                />
                                :
                                <MonthTile
                                    testid={i === 0 ? 'aso-report-tile' : undefined}
                                    key={report.dateAdded}
                                    date={report.dateAdded}
                                    onClick={() => this.props.selectReport(report)}
                                    whitelabel={ whitelabel }
                                />
                        ))
                }
            </div>
        );
    }
}

AsoHistory.propTypes = {
    history: PropTypes.array
};

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.invoices.loading,
    admin: state.admin,
    whitelabel: state.whitelabel
});

export default (connect(mapStateToProps)(AsoHistory));
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAccountAmendments, createAmendment } from '../../../actions/advisor.js';
import { Loader, MessageCard, AmendmentList, Container, i18n, tokens } from '@simplybenefits/sb-ui-library';

class Amendments extends PureComponent {
    state = {
        amendments: null
    };

    async componentDidMount() {
        const amendments = await this.props.dispatch(getAccountAmendments(this.props.accountID));

        this.setState({
            amendments
        });
    }

    _createAmendment = async () => {
        const amendmentID = await this.props.dispatch(createAmendment(this.props.accountID));

        // on a create new amendment, we will create a blank amendment and open the edit form view
        if (amendmentID) {
            window.location.href = `/accounts/${ this.props.accountID }/amendments/${ amendmentID }`;
        }
    };

    render() {
        if (!this.state.amendments) {
            return <Loader />;
        }

        return (
            <Container>
                <MessageCard
                    title={ i18n.t(tokens.labels.advisor.groupAmendments) }
                    subtitle={ i18n.t(tokens.labels.advisor.viewAllGroupAmendments) }
                    btn={ this.props.currentAccountMeta.isPrivateLabel }
                    btnName="Create Amendment"
                    btnOnClick={this._createAmendment}
                />

                <AmendmentList
                    isMasterSite={ false }
                    amendments={ this.state.amendments }
                    whitelabel={ this.props.whitelabel }
                />
            </Container>
        );
    }
}

Amendments.propTypes = {
    loading: PropTypes.bool,
    currentAccountMeta: PropTypes.object
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.advisor.loading,
    currentAccountMeta: state.advisor.currentAccountMeta,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(Amendments);
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import {
    TextInput,
    DateInput,
    Text,
    Services,
    Modal,
    Button,
    Theme,
    StepCompletionNotif,
    MessageCard,
    Loader,
    Checkbox,
    i18n,
    tokens,
    EditBankingDetails
} from '@simplybenefits/sb-ui-library';
import * as dates from '@simplybenefits/sb-dates';

import * as notifier from '../../actions/notifier.js';
import {
    saveBankingDetails as saveBankingDetailsAdmin,
    getAccountBankingDetails,
    validateBankingDetails as validateBankingDetailsAdmin
} from '../../actions/admin';
import store from '../../redux/store.js';

class Banking extends PureComponent {
    state = {
        eftDetails: null,
        password: '',
        modalType: null, // currentBankingDetails, editBankingDetails, confirmDetails
        visibility: false,
        successDialogOpen: false,
        isBankingAlreadySet: false,
        bankingModalOpen: true,
        signage: {
            name: '',
            signature: '',
            acceptedTerms: false,
            timestampOfAcceptance: null,
            datedOn: null
        },
        currentBankingDetails: null,
        divisionView: false
    };

    async componentDidMount() {
        const isBankingAlreadySet = (this.props.currentAccountMeta && this.props.currentAccountMeta.paymentsSetup === 1)
            ||
            (this.props.adminMeta && this.props.adminMeta.paymentsSetup === 1);

        this.setState({
            isBankingAlreadySet
        });

        let currentDetails = null;
        if (isBankingAlreadySet) {
            currentDetails = await this.props.dispatch(getAccountBankingDetails(this.props.adminMeta.accountID));
        }

        this.setState({
            currentBankingDetails: currentDetails,
            modalType: "currentBankingDetails"
        });
    }

    _onBlur = async (type) => {
        this.setState({
            [`${ type }Error`]: await Services.validateInput('checkEmpty', this.state[type])
        });
    };

    _handleSignageChange = (type, value) => {
        if (type === 'acceptedTerms' && value === true) {
            this.setState({
                signage: {
                    ...this.state.signage,
                    acceptedTerms: true,
                    timestampOfAcceptance: dates.getUTC()
                }
            });

            return null;
        }

        this.setState({
            signage: {
                ...this.state.signage,
                [type]: value
            }
        });

        return null;
    };

    _closeSuccessDialog = () => {
        this.props.hideModal();
    };

    _goToNextStep = () => {
        this._closeSuccessDialog();
        this.props.setSelectedSection('advisor-master-application');
    };

    _handleInputChange = (type, value) => {
        if (type === 'accepted' && value === true) {
            this.setState({
                accepted: value,
                acceptedError: false,
                timestampOfAcceptance: dates.getUTC()
            });
        } else {
            this.setState({
                [type]: value
            });
        }
    };

    _isSignageCompleted = async () => {
        const { name, signature, datedOn, acceptedTerms } = this.state.signage;

        let signageNameError = await Services.validateInput('checkEmpty', name);
        const signageSignatureError = await Services.validateInput('checkEmpty', signature);
        const signageDatedOnError = await Services.validateInput('date', datedOn, i18n.locale);
        const signageAcceptedError = !acceptedTerms;

        if (name !== signature) {
            signageNameError = i18n.t(tokens.labels.signage.signageNameError);
        }

        if (signageAcceptedError) {
            alert(i18n.t(tokens.labels.signage.pleaseAcceptAndAcknowledgeChanges));
        }

        this.setState({
            signageNameError,
            signageSignatureError,
            signageDatedOnError
        });

        if (
            signageNameError ||
            signageSignatureError ||
            signageDatedOnError ||
            signageAcceptedError
        ) {
            return false;
        }

        return true;
    };

    _toggleVisibility = () => {
        this.setState({
            visibility: !this.state.visibility
        });
    };

    _showConfirmDialog = () => {
        this.setState({
            confirmModalOpen: !this.state.confirmModalOpen
        });
    };

    _save = async () => {
        const passwordError = await Services.validateInput('password', this.state.password);
        this.setState({ passwordError });

        if (passwordError) {
            this.props.dispatch(notifier.displayError({
                verbiage: i18n.t(tokens.labels.banking.enterSimplyBenefitsPassword)
            }));

            return false;
        }

        const signageComplete = await this._isSignageCompleted();

        if (!signageComplete) {
            this.props.dispatch(notifier.displayError({
                verbiage: i18n.t(tokens.labels.banking.provideSignatureToContinue)
            }));

            return false;
        }

        let success = false;

        const data = {
            accountHolder: this.state.eftDetails.default.accountHolder,
            institutionName: this.state.eftDetails.default.institutionName,
            institutionAddress: this.state.eftDetails.default.institutionAddress,
            institutionNum: `${ this.state.eftDetails.default.institutionNum }`,
            transitNum: `${ this.state.eftDetails.default.transitNum }`,
            accountNum: `${ this.state.eftDetails.default.accountNum }`,
            accepted: this.state.eftDetails.default.accepted,
            timestampOfAcceptance: Number(this.state.eftDetails.default.timestampOfAcceptance),
            password: this.state.password,
            signage: {
                name: this.state.signage.name.trim(),
                signature: this.state.signage.signature.trim(),
                acceptedTerms: this.state.signage.acceptedTerms,
                timestampOfAcceptance: this.state.signage.timestampOfAcceptance,
                datedOn: Services.dates.formatOutgoingDate(this.state.signage.datedOn)
            }
        };
        
        success = await this.props.dispatch(saveBankingDetailsAdmin(data));

        if (!success) {
            return false;
        }

        if (this.state.isBankingAlreadySet) {
            this.props.hideModal();
            return true;
        }

        return true;
    };

    /**
     * Toggles to Edit mode
     */
    _showModal = (modalType) => {
        this.setState({
            modalType
        });
    };

    _toggleDivisionBankingModal = () => {
        this.setState({
            divisionView: !this.state.divisionView
        });
    };


    _saveNewBankingDetails = (newBankingDetails) => {
        this.setState({
            eftDetails: {
                ...newBankingDetails
            },
            modalType: "confirmDetails"
        });
    };


    render() {
        const whitelabel = store.getState().whitelabel;
        const styles = {
            row: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '100%'
            },
            column: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                marginTop: 20
            },
            goBack: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                "&:hover": {
                    backgroundColor: "#f5f5f5"
                }
            },
            spacer: {
                height: 15
            },
            label: {
                "& .MuiFormControlLabel-label": {
                    fontFamily: Theme.fonts.type.medium,
                    color: Theme.colors.mainTextGrey
                }
            },
            signature: {
                fontFamily: 'AntaraDistance',
                fontWeight: 'bold',
                fontSize: 20,
                paddingBottom: 0
            }
        };
        
        if (this.state.modalType === 'editBankingDetails') {
            return (
                <EditBankingDetails
                    validateBankingDetails={ (...params) => this.props.dispatch(validateBankingDetailsAdmin(...params)) }
                    bankingDetailsVerifying={ this.props.bankingDetailsVerifying }
                    saveNewBankingDetails={ this._saveNewBankingDetails }
                    onBack={ () => this._showModal('currentBankingDetails') }
                    showModal={ this._showModal }
                    close={ this.props.hideModal }
                    accountLevelBankingDetailsView={ true }
                    loader={ this.props.loader }
                />
            );
        }

        if (this.state.modalType === "confirmDetails") {
            return (
                <>
                    <StepCompletionNotif
                        dialog={i18n.t(tokens.labels.banking.successfullySubmittedBankingDetails)}
                        type={i18n.t(tokens.titles.banking)}
                        open={this.state.successDialogOpen}
                        handleClose={this._closeSuccessDialog}
                        handleNext={this._goToNextStep}
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true} 
                    />
                    <Modal
                        medium
                        testid="confirm-details-modal"
                        open={this.state.modalType === "confirmDetails"}
                        onClose={ this.props.hideModal }
                        title={i18n.t(tokens.buttons.bankingDetails)}
                        primaryButton={{
                            label: i18n.t(tokens.buttons.finish),
                            onClick: this._save
                        }}
                        loader={this.props.loader}
                    >
                        <Text
                            medium
                            margin="20px 0"
                        >
                            {i18n.t(tokens.labels.banking.youHaveProvidedTheFollowing)}
                        </Text>

                        <MessageCard
                            title={this.state.eftDetails?.default?.institutionName}
                            subtitle={this.state.eftDetails?.default?.institutionAddress} />

                        <Text
                            medium
                            margin="5px 0"
                        >
                            {i18n.t(tokens.labels.masterApplication.name)}: <span style={{ color: Theme.colors.black, fontWeight: 'bold' }}>{this.state.eftDetails?.default?.accountHolder}</span>
                        </Text>

                        <Text
                            medium
                            margin="5px 0"
                        >
                            {i18n.t(tokens.labels.banking.financialInstitutionNumber)}: <span style={{ color: Theme.colors.black, fontWeight: 'bold' }}>{this.state.eftDetails.default?.institutionNum}</span>
                        </Text>

                        <Text
                            medium
                            margin="5px 0"
                        >
                            {i18n.t(tokens.labels.banking.branchTransitNumber)}: <span style={{ color: Theme.colors.black, fontWeight: 'bold' }}>{this.state.eftDetails.default?.transitNum}</span>
                        </Text>

                        <Text
                            medium
                            margin="5px 0 20px 0"
                        >
                            {i18n.t(tokens.labels.banking.accountNumber)}: <span style={{ color: Theme.colors.black, fontWeight: 'bold' }}>{this.state.eftDetails.default?.accountNum}</span>
                        </Text>

                        <Text
                            onClick={this._showConfirmDialog}
                            medium
                            margin="10px 0"
                            bold
                            color={whitelabel.primaryColor || Theme.colors.skyBlue}
                        >
                            {i18n.t(tokens.labels.banking.ensureInformationCorrectDeposit)}
                        </Text>

                        <Text large bold margin="20px 0 10px 0">{i18n.t(tokens.labels.confirmation)}</Text>

                        <FormGroup row style={{ marginTop: 20 }}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={this.state.signage.acceptedTerms}
                                    onChange={() => this._handleSignageChange('acceptedTerms', !this.state.signage.acceptedTerms)}
                                    value="acceptedTerms"
                                    color="secondary" />}
                                label={i18n.t(tokens.labels.banking.haveVerifiedBankingInformation)} />
                        </FormGroup>

                        <Button
                            aria-label={i18n.t(tokens.buttons.backToEdit)}
                            variant="text"
                            onClick={ () => this._showModal("editBankingDetails")}
                            style={styles.goBack}
                            fullWidth
                        >
                            <Text
                                small
                                margin="10px 0"
                                bold
                            >
                                {i18n.t(tokens.labels.banking.notCorrect)} <span style={{ textDecoration: 'underline' }}>{i18n.t(tokens.labels.banking.editThisInformation)}</span>
                            </Text>
                        </Button>

                        <TextInput
                            label={i18n.t(tokens.labels.yourName)}
                            fullWidth
                            value={this.state.signage.name}
                            onChange={(e) => this._handleSignageChange('name', e.target.value)}
                            error={this.state.signageNameError} />

                        <TextInput
                            label={i18n.t(tokens.labels.signature)}
                            fullWidth
                            value={this.state.signage.signature}
                            onChange={(e) => this._handleSignageChange('signature', e.target.value)}
                            inputProps={{ inputProps: { style: { ...styles.signature } } }}
                            error={this.state.signageSignatureError} />

                        <DateInput
                            label={i18n.t(tokens.labels.account.datedOnYYYYMMDD)}
                            fullWidth
                            value={this.state.signage.datedOn}
                            onChange={(e) => this._handleSignageChange('datedOn', e.target.value)}
                            error={this.state.signageDatedOnError} />

                        <TextInput
                            label={i18n.t(tokens.labels.password)}
                            value={this.state.password}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {this.state.visibility ?
                                            <VisibilityIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={this._toggleVisibility} />
                                            :
                                            <VisibilityOffIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={this._toggleVisibility} />}
                                    </InputAdornment>
                                )
                            }}
                            onBlur={() => this._onBlur('password')}
                            onChange={(e) => this._handleInputChange('password', e.target.value)}
                            error={Boolean(this.state.passwordError)}
                            helperText={this.state.passwordError}
                            secure={!this.state.visibility}
                            fullWidth
                            autofocus />
                    </Modal>
                </>
            );
        
        }

        return (
            <Modal
                medium
                open={ true }
                onClose={this.props.hideModal}
                title={ i18n.t(tokens.titles.currentBankingDetails) }
                primaryButton={{
                    label: i18n.t(tokens.buttons.editBankingDetails),
                    onClick: () => this._showModal("editBankingDetails")
                }}
                loader={this.props.loader}
            >
                { 
                    this.state.currentBankingDetails === null ?
                        <div style={{ height: "325px" }}>
                            <Loader/>
                        </div>
                        :
                        <>
                        
                            <MessageCard
                                title={this.state.currentBankingDetails?.institutionName}
                                subtitle={this.state.currentBankingDetails?.institutionAddress}
                            />

                            <Text medium margin="10px 0">
                                { i18n.t(tokens.labels.banking.branchTransitNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{this.state.currentBankingDetails?.transitNum}</span>
                            </Text>

                            <Text medium margin="10px 0">
                                { i18n.t(tokens.labels.banking.financialInstitutionNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{this.state.currentBankingDetails?.institutionNum}</span>
                            </Text>

                            <Text medium margin="10px 0">
                                { i18n.t(tokens.labels.banking.accountNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{this.state.currentBankingDetails?.accountNum}</span>
                            </Text>

                            { // If divisions with division pay exist
                                this.state.currentBankingDetails?.divisionArray.length > 0 &&
                                <Button 
                                    transparent 
                                    onClick={this._toggleDivisionBankingModal} 
                                    customStyle={{ display: "block", margin: '0 auto' }}
                                >
                                    { i18n.t(tokens.labels.banking.viewDivisionBankingDetails) }
                                </Button>
                            }


                            { // If divisions with division pay exist
                                this.state.currentBankingDetails?.divisionArray && this.state.divisionView &&
                                    <Modal
                                        open={this.state.divisionView}
                                        onClose={this._toggleDivisionBankingModal}
                                        title={ i18n.t(tokens.titles.divisionBankingDetails) }
                                    >   
                                        {
                                            this.state.currentBankingDetails.divisionArray.map((div, index) => (
                                                <div key={index}>
                                                    <Text large bold margin="10px 0px 0px 0px">{div.title}</Text>
                                                    <MessageCard
                                                        title={div.institutionName}
                                                        subtitle={div.institutionAddress}
                                                    />
                                                    <Text medium margin="10px 0">
                                                        { i18n.t(tokens.labels.banking.branchTransitNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{div.transitNum}</span>
                                                    </Text>

                                                    <Text medium margin="10px 0">
                                                        { i18n.t(tokens.labels.banking.financialInstitutionNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{div.institutionNum}</span>
                                                    </Text>

                                                    <Text medium margin="10px 0">
                                                        { i18n.t(tokens.labels.banking.accountNumber) }: <span style={{ color: Theme.colors.black, fontWeight: 'bold', marginLeft: "15px" }}>{div.accountNum}</span>
                                                    </Text>

                                                    <div style={{ borderBottom: `1px solid ${ Theme.colors.lightGrey }`, marginTop: "15px" }}></div>
                                                </div>
                                            ))
                                        }
                                    </Modal>
                            }

                            <div style={{ borderBottom: `1px solid ${ Theme.colors.lightGrey }`, marginTop: "15px" }}></div>
                        </>
                }
            </Modal>
            
        );
    }
}

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    currentAccountMeta: state.advisor.currentAccountMeta,
    adminMeta: state.admin.meta,
    loader: state.loader,
    bankingDetailsVerifying: state.admin.bankingDetailsVerifying,
    divisions: state.census.divisions
});

export default (connect(mapStateToProps)(Banking));
import initialState from '../initialState.js';
import {
    BANKING_DETAILS_VERIFYING,
    GET_ADVISOR_SUCCESS,
    GET_ACCOUNT_SUCCESS,
    UPDATE_ADVISOR_SETTINGS_SUCCESS,
    UPDATE_ACCOUNT_SETTINGS_SUCCESS,
    SET_ACCOUNT_LIVE,
    PAYMENT_DETAILS_SUCCESS,
    REMOVE_NOTIFICATION_SUCCESS,
    SET_STATE,
    SET_PLAN_TYPE
} from '../../actions/types/advisor';

export default (state = initialState.advisor, action) => {
    switch (action.type) {
        case BANKING_DETAILS_VERIFYING: {
            return {
                ...state,
                bankingDetailsVerifying: action.state
            };
        }

        case SET_STATE: {
            return {
                ...state,
                currentAccountMeta: {
                    ...state.currentAccountMeta,
                    state: action.state
                },
                accounts: state.accounts.map((account) => {
                    if (account.accountID === action.accountID) {
                        return {
                            ...account,
                            state: action.state
                        };
                    }

                    return account;
                })
            };
        }

        case SET_PLAN_TYPE: {
            return {
                ...state,
                currentAccountMeta: {
                    ...state.currentAccountMeta,
                    planType: action.planType
                },
                accounts: state.accounts.map((account) => {
                    if (account.accountID === action.accountID) {
                        return {
                            ...account,
                            planType: action.planType
                        };
                    }

                    return account;
                })
            };
        }

        case GET_ADVISOR_SUCCESS: {
            return {
                ...state,
                ...action.data
            };
        }

        case UPDATE_ADVISOR_SETTINGS_SUCCESS: {
            return {
                ...state,
                meta: { ...state.meta, ...action.data }
            };
        }

        case GET_ACCOUNT_SUCCESS: {
            return {
                ...state,
                currentAccountMeta: action.meta
            };
        }

        case SET_ACCOUNT_LIVE: {
            return {
                ...state,
                currentAccountMeta: {
                    ...state.currentAccountMeta,
                    status: 1,
                    state: 10
                }
            };
        }

        case UPDATE_ACCOUNT_SETTINGS_SUCCESS: {
            return {
                ...state,
                currentAccountMeta: {
                    ...state.currentAccountMeta,
                    company: action.data.companyName,
                    address: action.data.companyAddress,
                    phone: action.data.phone,
                    contactEmail: action.data.contactEmail,
                    authorityName: action.data.authorityName,
                    authorityEmail: action.data.authorityEmail
                }
            };
        }

        case PAYMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                currentAccountMeta: {
                    ...state.currentAccountMeta,
                    paymentsSetup: 1
                },
                accounts: state.accounts.map((account) => {
                    if (account.accountID === action.accountID) {
                        return {
                            ...account,
                            paymentsSetup: 1
                        };
                    }

                    return account;
                })
            };
        }

        case REMOVE_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                notifications: state.notifications.filter((item) => item.id !== action.id)
            };
        }

        default:
            return state;
    }
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextInput, Modal, Services, i18n, tokens } from '@simplybenefits/sb-ui-library';

import { updateAdvisorSettings } from '../../../actions/advisor.js';

class AdvisorSettings extends Component {
    state = {
        firstname: '',
        lastname: '',
        companyName: '',
        email: '',
        language: '',
        showModal: false
    };

    componentDidMount() {
        const { meta } = this.props;

        this.setState({
            firstname: meta.firstname,
            lastname: meta.lastname,
            companyName: meta.company,
            email: meta.email,
            language: meta.language === "fr" ? "Français" : "English"
        });
    }

    _onBlur = async (type) => {
        this.setState({
            [`${ type }Error`]: await Services.validateInput(type, this.state[type])
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value.trim()
        });
    };

    _confirm = async () => {
        const firstnameError = await Services.validateInput('firstname', this.state.firstname);
        const lastnameError = await Services.validateInput('lastname', this.state.lastname);
        const companyNameError = await Services.validateInput('companyName', this.state.companyName);
        const emailError = await Services.validateInput('email', this.state.email);

        this.setState({
            firstnameError,
            lastnameError,
            companyNameError,
            emailError
        });

        if (
            firstnameError ||
            lastnameError ||
            companyNameError ||
            emailError
        ) {
            return false;
        }

        const body = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            company: this.state.companyName,
            email: this.state.email,
            language: this.state.language === "Français" ? "fr" : "en"
        };

        const success = await this.props.dispatch(updateAdvisorSettings(body));

        if (success) {
            this.props.hideModal();
        }

        return null;
    };

    render() {
        const { hideModal, showModal } = this.props;

        return (
            <Modal
                open={showModal}
                onClose={hideModal}
                title={ i18n.t(tokens.labels.account.modifyDetails) }
                primaryButton={{
                    onClick: this._confirm
                }}
                loader={this.props.loader}
            >
                <TextInput
                    label={ i18n.t(tokens.labels.firstname) }
                    value={this.state.firstname}
                    onBlur={() => this._onBlur('firstname')}
                    onChange={(value) => this._handleInputChange('firstname', value)}
                    error={Boolean(this.state.firstnameError)}
                    helperText={this.state.firstnameError}
                    fullWidth
                    autofocus
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this._confirm();
                            e.preventDefault();
                        }
                    }}
                />

                <TextInput
                    label={ i18n.t(tokens.labels.lastname) }
                    value={this.state.lastname}
                    onBlur={() => this._onBlur('lastname')}
                    onChange={(value) => this._handleInputChange('lastname', value)}
                    error={Boolean(this.state.lastnameError)}
                    helperText={this.state.lastnameError}
                    fullWidth
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this._confirm();
                            e.preventDefault();
                        }
                    }}
                />

                <TextInput
                    label={ i18n.t(tokens.labels.account.companyName)}
                    value={this.state.companyName}
                    onBlur={() => this._onBlur('companyName')}
                    onChange={(value) => this._handleInputChange('companyName', value)}
                    error={Boolean(this.state.companyNameError)}
                    helperText={this.state.companyNameError}
                    fullWidth
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this._confirm();
                            e.preventDefault();
                        }
                    }}
                />

                <TextInput
                    label={ i18n.t(tokens.labels.email) }
                    value={this.state.email}
                    onBlur={() => this._onBlur('email')}
                    onChange={(value) => this._handleInputChange('email', value)}
                    error={Boolean(this.state.emailError)}
                    helperText={this.state.emailError}
                    fullWidth
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this._confirm();
                            e.preventDefault();
                        }
                    }}
                />

                <TextInput
                    label={ i18n.t(tokens.labels.signup.language) }
                    value={ this.state.language }
                    onBlur={() => this._onBlur('language')}
                    onChange={(value) => this._handleInputChange('language', value)}
                    select
                    selectOptions={ Services.selectFieldOptions.languageOptionsNotTranslated() }
                    fullWidth
                />
            </Modal>
        );
    }
}

AdvisorSettings.propTypes = {
    meta: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{meta: null, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    meta: state.advisor.meta,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(AdvisorSettings);
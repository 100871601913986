import * as dates from '@simplybenefits/sb-dates';
import * as census from '../xhr/census.js';
import * as types from './types/census.js';
import * as advisorTypes from './types/advisor.js';
import * as planTypes from './types/plan.js';
import * as quotesTypes from './types/quotes.js';
import * as notifier from './notifier.js';
import * as session from '../services/session.js';
import * as loader from './loader.js';
import { i18n, tokens } from '@simplybenefits/sb-ui-library';

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'full') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}

/**
 * Add a user to the census data, need to know if its a advisor making the request or not
 * @param data
 * @param accountID
 * @returns {function(*): Promise}
 */
export function addUser(data, accountID) {
    const isAdvisor = Number(session.getUserType()) === 1;

    return (dispatch) => {
        setLoader(dispatch, true);
        return census.addUser(data, accountID, isAdvisor)
            .then((response) => {
                // if the add user was scheduled the userID will not be set yet
                if (response) {
                    const currentDate = dates.getLocalDisplay(new Date(), 'X');

                    // don't save in local state if it's a future dated change, we don't want them to submit it again and have the changes applied now
                    if (data.effectiveDate <= currentDate) {
                        dispatch({
                            type: types.ADD_CENSUS_USER_SUCCESS,
                            data: {
                                ...data,
                                userID: response.userID
                            }
                        });
                    }
                }

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.employeeAddedSuccessfully)));

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * Update a user to the census data, need to know if its a advisor making the request or not
 * @param data
 * @param userID
 * @param accountID
 * @returns {function(*): Promise}
 */
export function updateUser(data, userID, accountID) {
    const isAdvisor = Number(session.getUserType()) === 1;

    return (dispatch) => {
        setLoader(dispatch, true);
        return census.updateUser(data, userID, isAdvisor)
            .then(() => {
                const currentDate = dates.getLocalDisplay(new Date(), 'X');

                // don't save in local state if it's a future dated change, we don't want them to submit it again and have the changes applied now
                if (data.effectiveDate <= currentDate) {
                    dispatch({
                        type: types.UPDATE_CENSUS_USER_SUCCESS,
                        data: {
                            ...data,
                            accountID,
                            userID
                        }
                    });
                }

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.employeeUpdatedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * Remove a user from the census data
 * @param userID
 * @param accountID
 * @param effectiveDate
 * @returns {function(*): Promise}
 */
export function removeUser(userID, accountID, effectiveDate) {
    const isAdvisor = Number(session.getUserType()) === 1;

    return (dispatch) => {
        setLoader(dispatch, true);
        return census.removeUser(userID, accountID, effectiveDate, isAdvisor)
            .then(() => {
                const currentDate = dates.getLocalDisplay(new Date(), 'X');

                // don't save in local state if it's a future dated change, we don't want them to submit it again and have the changes applied now
                if (effectiveDate <= currentDate) {
                    dispatch({
                        type: types.REMOVE_CENSUS_USER_SUCCESS,
                        userID
                    });
                }

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.userTerminatedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @returns {function(*=): Promise<T | never>}
 */
export function getUsers(accountID, filters) {
    return (dispatch) => {
        setLoader(dispatch, true, false);
        return census.getUsers(accountID, filters)
            .then((users) => {
                dispatch({
                    type: types.GET_USERS_SUCCESS,
                    users
                });

                setLoader(dispatch, false);
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param title
 * @param description
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function addClass(title, description, isRetiree, accountID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true, Boolean(quoteID));
        return census
            .addClass(title, description, isRetiree, accountID, quoteID)
            .then((data) => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.ADD_CLASS_SUCCESS,
                        data
                    });

                    dispatch({
                        type: quotesTypes.ADD_DESIGN_FOR_NEW_CLASS,
                        data
                    });
                } else {
                    dispatch({
                        type: types.ADD_CLASS_SUCCESS,
                        data
                    });

                    dispatch({
                        type: planTypes.ADD_DESIGN_FOR_NEW_CLASS,
                        data
                    });
                }

                setLoader(dispatch, false, Boolean(quoteID));
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.classAddedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, Boolean(quoteID));
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param title
 * @param description
 * @param classID
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function editClass(title, description, isRetiree, classID, accountID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true, Boolean(quoteID));
        return census
            .editClass(title, description, isRetiree, classID, accountID, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.EDIT_CLASS_SUCCESS,
                        data: {
                            title,
                            description,
                            isRetiree,
                            classID
                        }
                    });
                } else {
                    dispatch({
                        type: types.EDIT_CLASS_SUCCESS,
                        data: {
                            title,
                            description,
                            isRetiree,
                            classID
                        }
                    });
                }

                setLoader(dispatch, false, Boolean(quoteID));
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.classSavedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, Boolean(quoteID));
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param title
 * @param description
 * @param address
 * @param bankingDetails
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function addDivision(title, description, address, bankingDetails, accountID, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, Boolean(quoteID));
        return census
            .addDivision(title, description, address, bankingDetails, accountID, quoteID)
            .then((data) => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.ADD_DIVISION_SUCCESS,
                        data: {
                            title: data.title,
                            description: data.description,
                            address: data.address,
                            hasBanking: Boolean(data.bankingDetails),
                            divisionID: data.divisionID
                        }
                    });
                } else {
                    dispatch({
                        type: types.ADD_DIVISION_SUCCESS,
                        data: {
                            title: data.title,
                            description: data.description,
                            address: data.address,
                            hasBanking: Boolean(data.bankingDetails),
                            divisionID: data.divisionID
                        }
                    });
                }

                setLoader(dispatch, false, Boolean(quoteID));
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.divisionAddedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, Boolean(quoteID));
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param title
 * @param description
 * @param address
 * @param bankingDetails
 * @param accountID
 * @param divisionID
 * @param hasBanking
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function editDivision(title, description, address, bankingDetails, accountID, divisionID, hasBanking, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true, Boolean(quoteID));
        return census
            .editDivision(title, description, address, bankingDetails, accountID, divisionID, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.EDIT_DIVISION_SUCCESS,
                        data: {
                            title,
                            description,
                            address,
                            hasBanking: hasBanking || Boolean(bankingDetails),
                            divisionID
                        }
                    });
                } else {
                    dispatch({
                        type: types.EDIT_DIVISION_SUCCESS,
                        data: {
                            title,
                            description,
                            address,
                            hasBanking: hasBanking || Boolean(bankingDetails),
                            divisionID
                        }
                    });
                }

                setLoader(dispatch, false, Boolean(quoteID));
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.divisionSavedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false, Boolean(quoteID));
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @returns {function(*=): Promise<boolean | never>}
 */
export function submitCensus(accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return census
            .submitCensus(accountID)
            .then(() => {
                dispatch({
                    type: advisorTypes.SET_STATE,
                    accountID,
                    state: 1
                });

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @param form
 * @returns {function(*=): Promise<boolean | never>}
 */
export function uploadCensus(form, accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return census.uploadCensus(form, accountID)
            .then((response) => {
                if (response.failedRecords) {
                    setLoader(dispatch, false);
                    return response;
                }

                dispatch({
                    type: types.GET_CENSUS_SUCCESS,
                    users: response
                });

                setLoader(dispatch, false);
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.usersAddedSuccessfully)));
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import Modal from '@mui/material/Modal';
import { ArrowBack, Close as CloseIcon } from '@mui/icons-material';
import { Text, Button, Theme, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as notifier from '../actions/notifier.js';
import store from '../redux/store.js';
import { styled } from '@mui/system';

const StyledModalDiv = styled('div')(({ theme, customStyle }) => ({
    top: '50%',
    left: '50%',
    margin: '0',
    outline: 'none',
    position: 'absolute',
    boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
    borderRadius: '12px',
    transform: 'translate(-50%, -50%)',
    WebkitTransform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('666')]: {
        maxWidth: '75%',
        minWidth: 'unset'
    },
    [theme.breakpoints.up('666')]: {
        maxWidth: '40%',
        minWidth: '648px'
    },
    ...customStyle
}));

const StyledPreviewStyle = styled('div')(({ theme }) => ({
    minHeight: '315px',
    minWidth: '285px',
    backgroundColor: Theme.colors.surface,
    borderRadius: '8px',
    padding: '10px',
    marginLeft: '5px',
    margin: '5px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('666')]: {
        minWidth: 'fit-content'
    }
}));

class FileDropZoneModal extends Component {
    state = {
        showContactModal: false,
        files: [],
        fileName: '',
        showDropzone: true
    };

    _chooseDocument = async (files) => {
        if (files[1] !== null) {
            files = await new Array(files[0]);
        }

        const valid = files[0];

        if (!valid) {
            await this.props.dispatch(notifier.displayError({
                verbiage: i18n.t(tokens.labels.validations.fileCorrupt)
            }));

            return false;
        }

        const duplicate = this.state.files.length > 0 && this.state.files[0].name === files[0].name;

        if (valid && !duplicate) {
            this.setState({
                fileName: files[0].name,
                files: [ ...this.state.files, ...files ]
            });
        } else {
            this.props.dispatch(notifier.displayError({
                verbiage: i18n.t(tokens.labels.validations.fileAlreadySelected)
            }));
        }

        await this.setState({
            showDropzone: this.state.files.length < this.props.maxItems
        });

        return true;
    };

    _fileType = (file) => {
        let fileType = null;

        switch (file.type) {
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || '.xlsx':
                fileType = 'xls';
                break;
            case '.xls':
                fileType = 'xls';
                break;
            case 'text/csv':
                fileType = 'csv';
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                fileType = 'doc';
                break;
            case 'image/jpeg':
                fileType = 'img';
                break;
            case 'image/jpg':
                fileType = 'img';
                break;
            case 'image/png':
                fileType = 'img';
                break;
            default:
                fileType = 'xls';
                break;
        }

        return fileType;
    };

    _fileName = (file) => {
        let filename = null;
        file.name.length > 25 ? filename = `${ file.name.substr(0, 11) }...${ file.name.substr(-11) }` : filename = file.name;
        return filename;
    };

    _removeDocument = async (name) => {
        const fileArray = this.state.files.filter((file) => file.name !== name);

        await this.setState({
            files: fileArray,
            showDropzone: true
        });
    };

    _submitDocument = async (skipClose = null) => {
        if (this.state.files.length > 0) {
            const submit = await this.props.submitFile(this.state.files, this.props.type);
            if (skipClose === true) {
                this.props.hideModal();
            }

            return submit;
        }

        return null;
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            return this._submitDocument();
        }

        return false;
    };

    render() {
        const { showModal, hideModal, title, subText = null, acceptedFiles, testid, onBack, skipClose = null } = this.props;

        const { showDropzone } = this.state;
        const whitelabel = store.getState().whitelabel;
        const styles = {
            goBackIcon: {
                cursor: 'pointer',
                fontSize: '22px',
                marginRight: 1.25,
                display: 'inline',
                marginTop: '5px',
                float: 'left',
                color: Theme.colors.mainTextGrey
            },
            btnWidth: {
                width: 'fit-content',
                marginLeft: 'auto'
            },
            modalBody: {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                borderRadius: '12px',
                padding: '20px',
                backgroundColor: Theme.colors.surface
            },
            closeModalIcon: {
                cursor: 'pointer',
                display: 'inline',
                marginTop: '5px',
                fontSize: '22px',
                float: 'right',
                color: Theme.colors.mainTextGrey
            },
            titleIconWrapper: {
                display: 'block',
                width: '100%',
                paddingBottom: '15px',
                verticalAlign: 'center'
            },
            cardStyle: {
                boxShadow: 'none',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center'
            },
            cardDisabled: {
                backgroundColor: Theme.colors.lightGrey,
                boxShadow: 'none',
                padding: '20px',
                border: `2px dotted ${ Theme.colors.mediumLightGrey }`,
                borderRadius: '8px',
                color: Theme.colors.mediumLightGrey
            },
            previewWrapper: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginBottom: '10px',
                backgroundColor: Theme.colors.lightGrey,
                boxShadow: 'none',
                padding: '5px',
                borderRadius: '8px'
            },
            logo: {
                display: 'block',
                margin: '0 auto 10px',
                maxHeight: '220px',
                maxWidth: '350px',
                paddingBottom: '25px',
                paddingTop: '35px'
            },
            dropZone: {
                backgroundColor: whitelabel.lightColor || Theme.colors.lightBlue, 
                border: `2px dotted ${ whitelabel.primaryColor || Theme.colors.mediumBlue }`, 
                color: whitelabel.primaryColor || Theme.colors.mediumBlue
            }
        };

        return (
            <Modal
                open={showModal}
                onClose={hideModal}
                style={{ overflow: 'auto', 'WebkitOverflowScrolling': 'touch' }}
            >
                <StyledModalDiv
                    onKeyPress={ this.handleKeyPress }
                >
                    <div style={styles.modalBody}>

                        <div style={styles.titleIconWrapper}>
                            {
                                onBack &&
                                <ArrowBack
                                    onClick={ onBack }
                                    sx={ styles.goBackIcon }
                                />
                            }

                            <CloseIcon
                                sx={styles.closeModalIcon}
                                onClick={hideModal}
                            />

                            <Text
                                align="center"
                                bold
                                mediumLarge
                                display="inline-block"
                                margin="0"
                            >
                                { title }
                            </Text>
                        </div>

                        {
                            subText ?
                                <Text
                                    light
                                    small
                                    margin="0px 0px 20px 0px"
                                >
                                    { subText }
                                </Text>
                                : null
                        }

                        {
                            this.state.files.length > 0 &&
                                <React.Fragment>
                                    <div style={styles.previewWrapper} >
                                        {
                                            this.state.files.map((file) => {
                                                const fileType = this._fileType(file);
                                                const fileName = this._fileName(file);
                                                return (
                                                    <StyledPreviewStyle key={file.name} >
                                                        <CloseIcon
                                                            sx={styles.closeModalIcon}
                                                            onClick={() => this._removeDocument(file.name)}
                                                        />

                                                        <img
                                                            alt="File type icon"
                                                            src={require(`../assets/${ fileType }.png`)}
                                                            style={styles.logo}
                                                        />

                                                        <Text
                                                            align="center"
                                                            bold
                                                            mediumLarge
                                                            display="block"
                                                            margin="0"
                                                            customStyle={{ maxWidth: 300 }}
                                                        >
                                                            { fileName }
                                                        </Text>

                                                    </StyledPreviewStyle>
                                                );
                                            })
                                        }
                                    </div>
                                </React.Fragment>
                        }

                        {
                            this.state.showDropzone &&
                                <Dropzone
                                    onDrop={(files) => this._chooseDocument(files)}
                                    accept={acceptedFiles || "image/png, image/jpeg, image/jpg, text/csv, .csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()}
                                                style={ !showDropzone ? { ...styles.cardDisabled, ...styles.dropZone } : { ...styles.cardStyle, ...styles.dropZone }}
                                            >
                                                <input {...getInputProps()} />
                                                <Text testid={testid} color={whitelabel.lightColor ? "darkGrey" : "skyBlue"} margin="20px 0" small align="center">
                                                    { i18n.t(tokens.labels.employees.dragAndDropFilesHere) }
                                                </Text>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                        }

                        {
                            acceptedFiles &&
                                <Text margin="10px 0 0" align="center" tiny>
                                    {acceptedFiles}
                                </Text>
                        }

                        <Button
                            disabled={this.state.files.length === 0}
                            onClick={() => this._submitDocument(skipClose)}
                            color="primary"
                            customStyle={{ ...styles.btnWidth }}
                            marginTop={2.5}
                        >
                            { i18n.t(tokens.buttons.submit) }
                        </Button>
                    </div>
                </StyledModalDiv>
            </Modal>
        );
    }
}

export default (connect()(FileDropZoneModal));
import React, { useState } from 'react';
import Avatar from "react-avatar";
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { Text, Theme, i18n, tokens, localizationMaps } from '@simplybenefits/sb-ui-library';
import store from '../../../redux/store.js';

/**
 * @param meta
 * @param type
 * @param classes
 * @returns {*}
 * @constructor
 */
const NameTag = ({ item, type, edit, remove, writeAccess }) => {
    const [ anchorEl, setAnchorEl ] = useState(null);
    const whitelabel = store.getState().whitelabel;
    let backgroundColor = Theme.colors.mediumLightBlue;
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: 10,
            width: 320,
            height: 100,
            paddingLeft: 20,
            marginRight: 20
        },
        textBlock: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        }
    };

    if (whitelabel.secondaryColor) {
        backgroundColor = whitelabel.secondaryColor;
    } else if (type === 'medium') {
        backgroundColor = Theme.colors.mediumDarkBlue;
    } else if (type === 'dark') {
        backgroundColor = Theme.colors.darkBlue;
    }

    /**
     * 
     * @param {*} action 
     */
    const handleActionClick = (action) => {
        action();
        setAnchorEl(null);
    };


    return (
        <div style={{ backgroundColor: backgroundColor, ...styles.container }}>
            <Avatar
                name={`${ item.firstname } ${ item.lastname }`}
                size="60"
                textSizeRatio={3}
                round={true}
                style={{ fontWeight: 600, marginRight: 20 }}
                color={ whitelabel.primaryColor || Theme.colors.skyBlue }
            />

            <div style={ styles.textBlock }>
                <Text
                    mediumLarge
                    veryBold
                    color={ Theme.colors.surface }
                >
                    { `${ item.firstname } ${ item.lastname }` }
                </Text>

                <Text
                    small
                    bold
                    color={ Theme.colors.surface }
                >
                    { item.isSurvivorBenefitParent ? i18n.t(tokens.labels.employees.survivorBenefit) : localizationMaps.fromEnglish("relationships", item.relationship) }
                </Text>
            </div>
            { (edit || remove) && writeAccess
                ? (<IconButton 
                    aria-label="actionMenu"
                    onClick={ (event) => setAnchorEl(event.currentTarget) }
                    sx={{ padding: "12px",
                        color: Theme.colors.surface }}
                >
                    <MoreVertIcon />
                </IconButton>
                )
                : null
            }

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                disableAutoFocusItem={true}
            >
                {
                    edit && 
                    <MenuItem onClick={() => handleActionClick(edit)}>
                
                        <Text color="inherit" medium display="inline">
                            { i18n.t(tokens.buttons.edit) }
                        </Text>
                    </MenuItem>
                }

                {
                    remove &&
                    <MenuItem onClick={() => handleActionClick(remove)}>
                        <Text color="inherit" medium display="inline">
                            { i18n.t(tokens.buttons.remove) }
                        </Text>
                    </MenuItem>
                }  
            </Menu>
        </div>
    );
};

export default (NameTag);
const environment = process.env.REACT_APP_ENVIRONMENT;
let apiRequestUrl = '';

switch (environment) {
    case 'local':
        apiRequestUrl = 'http://localhost:8000';
        break;
    case 'staging':
        apiRequestUrl = 'https://api-staging.simplybenefits.ca';
        break;
    case 'production':
        apiRequestUrl = 'https://api.simplybenefits.ca';
        break;
    default:
        apiRequestUrl = 'http://localhost:8000';
        break;
}

export default {
    apiRequestUrl
};
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import App from './App.js';
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import store, { history } from './redux/store.js';

import './index.css';

const target = document.querySelector('#root');

render(
    <BrowserRouter>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </BrowserRouter>,
    target
);

serviceWorkerRegistration.register();
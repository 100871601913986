import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import { TextInput, Text, Button, Theme, Services, Loader, Container, Form, Logo, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as session from '../../services/session.js';
import { resetPassword } from '../../actions/admin.js';

class ForgotPassword extends PureComponent {
    state = {
        password: '',
        confirmPassword: '',
        error: '',
        forgotPasswordToken: '',
        visibility: false,
        language: ''
    };

    componentDidMount() {
        // Set the locale to the user's language retrieved from local storage
        const userLanguage = session.getAdminLanguage() || session.getAdvisorLanguage() || "en";
        i18n.setLocale(userLanguage);
        this.setState({ language: userLanguage });

        const forgotPasswordToken = window.location.pathname.split('/')[2];

        if (!forgotPasswordToken) {
            window.location.href = '/login';
            return;
        }

        this.setState({
            forgotPasswordToken
        });
    }

    _toggleVisibility = () => {
        this.setState({
            visibility: !this.state.visibility
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value.trim()
        });
    };

    _submitPasswordReset = async () => {
        const passwordError = Services.validateInput('checkEmpty', this.state.password);
        const confirmPasswordError = Services.validateInput('checkEmpty', this.state.confirmPassword);

        let error = null;

        if (this.state.password !== this.state.confirmPassword) {
            error = 'Please ensure the password match before continuing.';
        }

        this.setState({
            passwordError,
            confirmPasswordError,
            error
        });

        if (error || passwordError || confirmPasswordError) {
            return;
        }

        const success = await this.props.dispatch(resetPassword(this.state.password, this.state.forgotPasswordToken));

        if (success) {
            window.location.href = '/login';
        }
    };

    _onKeyPress = (event) => {
        if (event.key === 'Enter') {
            this._login();
        }
    };

    render() {
        const { whitelabel } = this.props;

        return (
            <Container background={"true"}>
                <Logo whitelabel={whitelabel} />

                <Form customStyle={{
                    width: '450px',
                    paddingTop: 5,
                    paddingBottom: 40,
                    paddingRight: 40,
                    paddingLeft: 40
                }}>
                    <Text
                        mediumLarge
                        bold
                        display="block"
                        align="left"
                        margin="20px 0"
                        customLinkStyle={{ lineHeight: '40px' }}
                    >
                        { i18n.t(tokens.titles.changePassword) }
                    </Text>

                    <TextInput
                        label={ i18n.t(tokens.labels.password) }
                        type="password"
                        onChange={(value) => this._handleInputChange('password', value)}
                        error={Boolean(this.state.passwordError)}
                        helperText={this.state.passwordError}
                        secure={!this.state.visibility}
                        fullWidth
                        autofocus
                        onKeyPress={this._onKeyPress}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{ marginRight: 1 }}>
                                    {
                                        this.state.visibility ?
                                            <VisibilityIcon
                                                style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                                onClick={this._toggleVisibility}
                                            />
                                            :
                                            <VisibilityOffIcon
                                                style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                                onClick={this._toggleVisibility}
                                            />
                                    }
                                </InputAdornment>
                            )
                        }}
                    />

                    <TextInput
                        label={ i18n.t(tokens.labels.changePassword.confirmPassword) }
                        value={this.state.confirmPassword}
                        onChange={(value) => this._handleInputChange('confirmPassword', value)}
                        error={Boolean(this.state.confirmPasswordError)}
                        helperText={this.state.confirmPasswordError}
                        secure={!this.state.visibility}
                        fullWidth
                        onKeyPress={this._onKeyPress}
                        inputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{ marginRight: 1 }}>
                                    {
                                        this.state.visibility ?
                                            <VisibilityIcon
                                                style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                                onClick={this._toggleVisibility}
                                            />
                                            :
                                            <VisibilityOffIcon
                                                style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                                onClick={this._toggleVisibility}
                                            />
                                    }
                                </InputAdornment>
                            )
                        }}
                    />

                    {
                        this.state.error &&
                            <Text
                                small
                                display="block"
                                align="left"
                                color="red"
                                margin="5px 0 0 5px"
                            >
                                { i18n.t(tokens.labels.signup.pleaseConfirmThatYourPasswordsMatch) }
                            </Text>
                    }

                    <Button
                        onClick={this._submitPasswordReset}
                        color="primary"
                        marginTop="30px"
                        fullWidth
                    >
                        { i18n.t(tokens.buttons.submit) }
                    </Button>
                </Form>

                {this.props.loader?.isLoading && <Loader whitelabel={ whitelabel } />}
            </Container>
        );
    }
}

ForgotPassword.propTypes = {
    loading: PropTypes.bool,
    whitelabel: PropTypes.object
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(ForgotPassword);
import * as plan from '../xhr/plan.js';
import * as types from './types/plan.js';
import * as advisorTypes from './types/advisor.js';
import * as quotesTypes from './types/quotes.js';
import * as notifier from './notifier.js';
import * as loader from './loader.js';
import { i18n, tokens } from '@simplybenefits/sb-ui-library';

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'full') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}

/**
 * @param classID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function createPlanDesign(classID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return plan.createDesign(classID, quoteID)
            .then((data) => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.CREATE_NEW_PLAN_DESIGN,
                        classID,
                        quoteID,
                        data,
                        designID: data.designID
                    });
                } else {
                    dispatch({
                        type: types.CREATE_NEW_PLAN_DESIGN,
                        classID,
                        data
                    });
                }

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param classID
 * @param designID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function clonePlanDesign(classID, designID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return plan.cloneDesign(designID, quoteID)
            .then((response) => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.CREATE_NEW_PLAN_DESIGN,
                        data: response,
                        designID: response.designID,
                        classID,
                        quoteID
                    });
                } else {
                    dispatch({
                        type: types.CREATE_NEW_PLAN_DESIGN,
                        classID,
                        data: response
                    });
                }

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param designID
 * @param data
 * @param effectiveDate
 * @param quoteID
 * @returns {function(*): Promise<boolean>}
 */
export function savePlanDesign(designID, data, effectiveDate, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return plan.saveDesign(designID, data, effectiveDate, quoteID)
            .then(() => {
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.planDesignSaved)));
                setLoader(dispatch, false);

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param classID
 * @param data
 * @param effectiveDate
 * @param quoteID
 * @returns {function(*): Promise<boolean>}
 */
export function saveModularBlueprint(classID, data, effectiveDate = null, quoteID = null) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return plan.saveModularBlueprint(classID, data, quoteID, effectiveDate)
            .then(() => {
                dispatch(notifier.displayMessage(i18n.t(tokens.labels.notifications.modularFlexBlueprint)));
                setLoader(dispatch, false);

                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param classID
 * @param designID
 * @param benefit
 * @param field
 * @param value
 * @param quoteID
 * @returns {Function}
 */
export function savePlanDesignField(classID, designID, benefit, field, value, quoteID) {
    return (dispatch) => {
        if (quoteID) {
            dispatch({
                type: quotesTypes.SAVE_PLAN_DESIGN_FIELD,
                classID,
                designID,
                benefit,
                field,
                value,
                quoteID
            });
        } else {
            dispatch({
                type: types.SAVE_PLAN_DESIGN_FIELD,
                classID,
                designID,
                benefit,
                field,
                value
            });
        }
    };
}

/**
 * @param classID
 * @param tier
 * @param tierBenefit
 * @param benefit
 * @param field
 * @param value
 * @param quoteID
 * @returns {(function(*): void)|*}
 */
export function saveModularField(classID, tier, tierBenefit, benefit, field, value, quoteID) {
    return (dispatch) => {
        if (quoteID) {
            dispatch({
                type: quotesTypes.SAVE_MODULAR_FIELD,
                classID,
                tier,
                benefit,
                tierBenefit,
                field,
                value,
                quoteID
            });
        } else {
            dispatch({
                type: types.SAVE_MODULAR_FIELD,
                classID,
                tier,
                benefit,
                tierBenefit,
                field,
                value
            });
        }
    };
}

/**
 * @param classID
 * @param designID
 * @param benefit
 * @param data
 * @param quoteID
 * @returns {Function}
 */
export function savePlanDesignBenefitInitData(classID, designID, benefit, data, quoteID) {
    return (dispatch) => {
        if (quoteID) {
            dispatch({
                type: quotesTypes.SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA,
                classID,
                designID,
                benefit,
                data,
                quoteID
            });
        } else {
            dispatch({
                type: types.SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA,
                classID,
                designID,
                benefit,
                data
            });
        }
    };
}

/**
 * @param classID
 * @param tier
 * @param tierBenefit
 * @param benefit
 * @param data
 * @param quoteID
 * @returns {(function(*): void)|*}
 */
export function saveModularBenefitInitData(classID, tier, tierBenefit, benefit, data, quoteID) {
    return (dispatch) => {
        if (quoteID) {
            dispatch({
                type: quotesTypes.SAVE_MODULAR_BENEFIT_INIT_DATA,
                classID,
                tier,
                tierBenefit,
                benefit,
                data,
                quoteID
            });
        } else {
            dispatch({
                type: types.SAVE_MODULAR_BENEFIT_INIT_DATA,
                classID,
                tier,
                tierBenefit,
                benefit,
                data
            });
        }
    };
}

/**
 * @param classID
 * @param designID
 * @param data
 * @param quoteID
 * @returns {Function}
 */
export function revertToRevision(classID, designID, data, quoteID) {
    return (dispatch) => {
        if (quoteID) {
            dispatch({
                type: quotesTypes.REVERT_FROM_REVISION,
                classID,
                designID,
                data,
                quoteID
            });
        } else {
            dispatch({
                type: types.REVERT_FROM_REVISION,
                classID,
                designID,
                data
            });
        }
    };
}

/**
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function submitPlanDesigns(accountID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return plan.submitPlanDesigns(accountID, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.SET_STATE,
                        state: 1,
                        quoteID
                    });
                } else {
                    dispatch({
                        type: advisorTypes.SET_STATE,
                        state: 2,
                        accountID
                    });
                }

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param quoteID
 * @returns {function(*=): Promise<boolean>}
 */
export function submitModuleBlueprint(accountID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return plan.submitModuleBlueprint(accountID, quoteID)
            .then(() => {
                if (quoteID) {
                    dispatch({
                        type: quotesTypes.SET_STATE,
                        state: 1,
                        quoteID
                    });
                } else {
                    dispatch({
                        type: advisorTypes.SET_STATE,
                        state: 2,
                        accountID
                    });
                }

                setLoader(dispatch, false);
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param designID
 * @param quoteID
 * @returns {function(*=): Promise<T | boolean>}
 */
export function getPlanDesignRevisions(designID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return plan.getPlanDesignRevisions(designID, quoteID)
            .then((revisions) => {
                setLoader(dispatch, false);
                return revisions;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param revisionID
 * @param quoteID
 * @returns {function(*=): Promise<unknown | boolean>}
 */
export function getSinglePlanDesignRevision(revisionID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true);
        return plan.getSinglePlanDesignRevision(revisionID, quoteID)
            .then((revision) => {
                setLoader(dispatch, false);
                return revision;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @returns {function(*): Promise<*>}
 */
export function getLsaItems(accountID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return plan.getLsaItems(accountID)
            .then((items) => {
                setLoader(dispatch, false);
                return items;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param advisorID
 * @returns {function(*): Promise<*>}
 */
export function getSpecialityProductMetaConfigs(advisorID) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return plan.getSpecialityProductMetaConfigs(advisorID)
            .then((response) => {
                setLoader(dispatch, false);
                return response;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param quoteID
 * @returns {function(*): Promise<unknown>}
 */
export function getSpecialityProductRates(accountID, quoteID) {
    return (dispatch) => {
        setLoader(dispatch, true, quoteID);

        return plan.getSpecialityProductRates(accountID, quoteID)
            .then((response) => {
                setLoader(dispatch, false, quoteID);
                return response;
            })
            .catch((error) => {
                setLoader(dispatch, false, quoteID);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @param accountID
 * @param quoteID
 * @param rates
 * @param effectiveDate
 * @returns {function(*): Promise<*>}
 */
export function saveSpecialityProductRates(accountID, quoteID, rates, effectiveDate = null) {
    return (dispatch) => {
        setLoader(dispatch, true, quoteID);

        return plan.saveSpecialityProductRates(accountID, quoteID, rates, effectiveDate)
            .then((response) => {
                dispatch(notifier.displayMessage('Rates saved successfully!'));
                setLoader(dispatch, false, quoteID);
                return response;
            })
            .catch((error) => {
                setLoader(dispatch, false, quoteID);
                dispatch(notifier.displayError(error));
                return false;
            });
    };
}

/**
 * @param accountID
 * @param quoteID
 * @param rates
 * @returns {function(*): Promise<*>}
 */
export function submitSpecialityProductRates(accountID, quoteID, rates) {
    return (dispatch) => {
        setLoader(dispatch, true, quoteID);

        return plan.submitSpecialityProductRates(accountID, quoteID, rates)
            .then(() => {
                setLoader(dispatch, false, quoteID);
                window.location.reload();
            })
            .catch((error) => {
                setLoader(dispatch, false, quoteID);
                dispatch(notifier.displayError(error));
            });
    };
}
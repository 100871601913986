import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tiles, Theme, Text, ContainerControlSite, Content, Title, MessageCard, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as dates from "@simplybenefits/sb-dates";
import ChangePassword from '../../ChangePassword/index.js';
import AccountSettings from '../AccountSettings/AccountSettings.js';

class Dashboard extends PureComponent {
    state = {
        settingsModalOpen: false,
        changePasswordModal: false,
        welcomeMessage: 'Good Morning'
    };

    setWelcomeMessage = () => {
        const today = new Date();
        const curHr = today.getHours();

        if (curHr < 12) {
            this.setState({
                welcomeMessage: i18n.t(tokens.labels.dashboard.goodMorning)
            });
        } else if (curHr < 18) {
            this.setState({
                welcomeMessage: i18n.t(tokens.labels.dashboard.goodAfternoon)
            });
        } else {
            this.setState({
                welcomeMessage: i18n.t(tokens.labels.dashboard.goodEvening)
            });
        }
    };

    componentDidMount() {
        this.setWelcomeMessage();
    }

    componentDidUpdate (prevProps) {
        if (prevProps && this.props.admin && prevProps.admin.language !== this.props.admin.language) {
            this.setWelcomeMessage();
        }
    }

    _toggleChangePasswordModal = () => {
        this.setState({
            changePasswordModal: !this.state.changePasswordModal
        });
    };

    _toggleSettingsModal = () => {
        this.setState({
            settingsModalOpen: !this.state.settingsModalOpen
        });
    };

    _checkIfFutureDate = (date) => {
        const dateForDisplay = dates.getUTC(date, "YYYY/MM/DD");
        const isDateFutureDated = dates.validate(dateForDisplay);

        // this reuses the date validation function to check if the date is in the future
        if (isDateFutureDated === 'Date cannot be in the future.') {
            return true;
        }

        return false;
    };

    render() {
        const { whitelabel } = this.props;

        const tiles = [
            {
                title: i18n.t(tokens.labels.admin.plan),
                description: i18n.t(tokens.labels.admin.viewPlanCoverage),
                link: '/plan',
                testid: 'plan-tile'
            },
            {
                title: i18n.t(tokens.labels.admin.members),
                description: i18n.t(tokens.labels.admin.viewManageMembers),
                link: '/members',
                testid: 'employees-tile'
            },
            {
                title: i18n.t(tokens.labels.admin.reports),
                description: i18n.t(tokens.labels.admin.generateExportReports),
                link: '/reports',
                testid: 'reports-tile'
            },
            {
                title: i18n.t(tokens.titles.invoices),
                description: i18n.t(tokens.labels.admin.viewCurrentAndHistoryInvoice),
                link: '/invoices',
                testid: 'invoices-tile'
            }
        ];

        // if they are the parent admin, show the administrators and settings tile
        if (Number(this.props.admin.isParent) === 1) {
            tiles.push(
                {
                    title: i18n.t(tokens.labels.admin.administrators),
                    description: i18n.t(tokens.labels.admin.viewManageAccountAdministrators),
                    link: '/administrators',
                    testid: 'administrators-tile'
                },
                {
                    title: i18n.t(tokens.labels.admin.settings),
                    description: i18n.t(tokens.labels.admin.viewManageAccountSettings),
                    onClick: () => this._toggleSettingsModal(),
                    testid: 'account-settings-tile'
                }
            );
        }

        return (
            <ContainerControlSite>
                <Content>
                    <Title title={ i18n.t(tokens.titles.dashboard) } />
            
                    {
                        this.props.account.terminationDate !== null && this._checkIfFutureDate(this.props.account.terminationDate) ?
                            <MessageCard
                                title={ i18n.t(tokens.labels.dashboard.accountToBeTerminated) }
                                subtitle={ i18n.t(tokens.labels.dashboard.thisAccountWillBeTerminatedOn, { terminationDate: dates.getUTC(this.props.account.terminationDate, "ll"), lastEffectiveDate: dates.getPastDate(this.props.account.terminationDate, 1, "days", "ll") })}
                                isError={true}
                            />
                            :
                            null
                    }

                    <span>
                        <Text light extraLarge display="inline">{ this.state.welcomeMessage }, </Text>
                        <Text bold extraLarge display="inline" color={ whitelabel.primaryColor || Theme.colors.skyBlue }>{ this.props.admin.firstname }</Text>
                        <Text light extraLarge display="inline">.</Text>
                    </span>

                    <Text light medium margin="5px 0 40px 0">
                        { i18n.t(tokens.labels.admin.adminPersonalizedDashboard) }
                    </Text>

                    <Tiles tiles={tiles} />
                </Content>

                {
                    this.state.settingsModalOpen &&
                        <AccountSettings
                            hideModal={this._toggleSettingsModal}
                        />
                }

                {
                    this.state.changePasswordModal &&
                        <ChangePassword isForgotPassword hideModal={this._toggleChangePasswordModal} />
                }
                <span id="dashboard" />
            </ContainerControlSite>
        );
    }
}

Dashboard.propTypes = {
    admin: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{admin: null, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    admin: state.admin.meta,
    whitelabel: state.whitelabel,
    account: state.admin.account
});

export default (connect(mapStateToProps)(Dashboard));
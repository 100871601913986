import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { TextInput, Services, Text, Modal, Theme, i18n, tokens } from '@simplybenefits/sb-ui-library';

import { changeAdminPassword } from '../../actions/admin.js';
import { changeAdvisorPassword } from '../../actions/advisor.js';
import * as session from '../../services/session.js';
import * as notifier from '../../actions/notifier.js';

class ChangePassword extends PureComponent {
    state = {
        confirmPassword: '',
        existingPassword: '',
        newPassword: '',
        visibility: false,
        notifyText: false,
        notifyTextColor: ''
    };

    _toggleVisibility = () => {
        this.setState({
            visibility: !this.state.visibility
        });
    };

    _onBlur = async (type) => {
        this.setState({
            [`${ type }Error`]: await Services.validateInput(type, this.state[type])
        });
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value.trim()
        });
    };

    _confirm = async () => {
        const newPasswordError = await Services.validateInput('newPassword', this.state.newPassword);
        const confirmPasswordError = await Services.validateInput('confirmPassword', this.state.confirmPassword);

        this.setState({
            newPasswordError,
            confirmPasswordError
        });

        if (newPasswordError || confirmPasswordError) {
            return false;
        }

        if (this.state.newPassword !== this.state.confirmPassword) {
            this.props.dispatch(notifier.displayError({
                verbiage: i18n.t(tokens.labels.changePassword.newPasswordConfirmError)
            }));

            this.setState({
                passwordError: i18n.t(tokens.labels.changePassword.newPasswordConfirmError),
                confirmPasswordError: i18n.t(tokens.labels.changePassword.newPasswordConfirmError)
            });

            return false;
        }

        const isAdvisor = Number(session.getUserType()) === 1;
        let success = null;

        if (isAdvisor) {
            success = await this.props.dispatch(changeAdvisorPassword(this.state.existingPassword, this.state.newPassword));
        } else {
            success = await this.props.dispatch(changeAdminPassword(this.state.existingPassword, this.state.newPassword));
        }

        if (success) {
            this.setState({
                notifyText: false,
                passwordError: false,
                confirmPasswordError: false
            });

            this.props.hideModal();
        }

        return null;
    };

    render() {
        const { hideModal } = this.props;

        return (
            <Modal
                testid="change-password-modal"
                open={true}
                onClose={hideModal}
                title={ i18n.t(tokens.titles.changePassword) }
                primaryButton={{
                    onClick: this._confirm
                }}
            >
                {
                    this.state.notifyText &&
                        <Text
                            align="center"
                            color={this.state.notifyTextColor}
                            small
                            display="inline-block"
                        >
                            {this.state.notifyText}
                        </Text>
                }

                <TextInput
                    label={ i18n.t(tokens.labels.changePassword.existingPassword) }
                    value={this.state.existingPassword}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: 1 }}>
                                {
                                    this.state.visibility ?
                                        <VisibilityIcon
                                            style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                            onClick={this._toggleVisibility}
                                        />
                                        :
                                        <VisibilityOffIcon
                                            style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                            onClick={this._toggleVisibility}
                                        />
                                }
                            </InputAdornment>
                        )
                    }}
                    onBlur={() => this._onBlur('existingPassword')}
                    onChange={(value) => this._handleInputChange('existingPassword', value)}
                    error={Boolean(this.state.existingPasswordError)}
                    helperText={this.state.existingPasswordError}
                    autoComplete="new-password"
                    secure={!this.state.visibility}
                    fullWidth
                    autofocus
                />

                <TextInput
                    label={ i18n.t(tokens.labels.changePassword.newPassword) }
                    value={this.state.newPassword}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: 1 }}>
                                {
                                    this.state.visibility ?
                                        <VisibilityIcon
                                            style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                            onClick={this._toggleVisibility}
                                        />
                                        :
                                        <VisibilityOffIcon
                                            style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                            onClick={this._toggleVisibility}
                                        />
                                }
                            </InputAdornment>
                        )
                    }}
                    onBlur={() => this._onBlur('newPassword')}
                    onChange={(value) => this._handleInputChange('newPassword', value)}
                    error={Boolean(this.state.newPasswordError)}
                    helperText={this.state.newPasswordError}
                    secure={!this.state.visibility}
                    autoComplete="new-password"
                    fullWidth
                />

                <TextInput
                    label={ i18n.t(tokens.labels.changePassword.confirmPassword) }
                    value={this.state.confirmPassword}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: 1 }}>
                                {
                                    this.state.visibility ?
                                        <VisibilityIcon
                                            style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                            onClick={this._toggleVisibility}
                                        />
                                        :
                                        <VisibilityOffIcon
                                            style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
                                            onClick={this._toggleVisibility}
                                        />
                                }
                            </InputAdornment>
                        )
                    }}
                    onBlur={() => this._onBlur('confirmPassword')}
                    onChange={(value) => this._handleInputChange('confirmPassword', value)}
                    error={Boolean(this.state.confirmPasswordError)}
                    helperText={this.state.confirmPasswordError}
                    secure={!this.state.visibility}
                    autoComplete="new-password"
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this._confirm();
                            e.preventDefault();
                        }
                    }}
                    fullWidth
                />

            </Modal>
        );
    }
}

export default connect()(ChangePassword);
import React, { Component } from 'react';
import { Text, Theme, Button, Tooltip, i18n, tokens } from '@simplybenefits/sb-ui-library';
import { helpers } from '@simplybenefits/sb-utils';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { KeyboardArrowUp as KeyboardArrowUpIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';

/**
 *
 * @param {*} param0
 */
class FilterForm extends Component {
    state = {
        expanded: [],
        selectedClasses: [],
        selectedDivisions: [],
        selectedStatuses: [],
        selectedWaived: {
            notWaived: false,
            waived: {
                selected: false,
                dependentOnly: {
                    selected: false,
                    ehcSelected: false,
                    dentalSelected: false,
                    bothSelected: false
                },
                memberAndDependent: {
                    selected: false,
                    ehcSelected: false,
                    dentalSelected: false,
                    bothSelected: false
                }
            }
        },
        hireDateSort: null,
        studyStartDateSort: null
    };

    _handleChange = (panel) => (event, expanding) => {
        let expanded = [ ...this.state.expanded ];
        const index = expanded.indexOf(panel);

        if (expanding && index === -1) {
            expanded.push(panel);
        } else {
            expanded.splice(index, 1);
        }

        this.setState({
            expanded
        });
    };

    _selectFilter = (type, value) => {
        let selectedFilters = [ ...this.state[type] ];
        const index = selectedFilters.indexOf(value);

        if (selectedFilters && index === -1) {
            selectedFilters.push(value);
        } else {
            selectedFilters.splice(index, 1);
        }

        this.setState({
            [type]: selectedFilters
        });
    };

    _selectWaiveFilter = (type) => {
        let selectedWaived = this.state.selectedWaived;

        switch (type) {
            case 'notWaived':
                selectedWaived.notWaived = !selectedWaived.notWaived;

                // If we are turning notwaived on and waived is currently on we need to turn waived off
                if (selectedWaived.notWaived) {
                    Object.keys(selectedWaived.waived.dependentOnly).map((key) => {
                        selectedWaived.waived.dependentOnly[key] = false;
                        return null;
                    });

                    Object.keys(selectedWaived.waived.memberAndDependent).map((key) => {
                        selectedWaived.waived.memberAndDependent[key] = false;
                        return null;
                    });

                    selectedWaived.waived.selected = false;
                }

                break;
            case 'waived':
                Object.keys(selectedWaived.waived.dependentOnly).map((key) => {
                    selectedWaived.waived.dependentOnly[key] = !this.state.selectedWaived.waived.selected;
                    return null;
                });

                Object.keys(selectedWaived.waived.memberAndDependent).map((key) => {
                    selectedWaived.waived.memberAndDependent[key] = !this.state.selectedWaived.waived.selected;
                    return null;
                });

                selectedWaived.waived.selected = !selectedWaived.waived.selected;

                selectedWaived.notWaived = selectedWaived.waived.selected ? false : selectedWaived.notWaived;

                break;
            case 'dependentOnly':
                selectedWaived.waived.dependentOnly.ehcSelected = !selectedWaived.waived.dependentOnly.selected;
                selectedWaived.waived.dependentOnly.dentalSelected = !selectedWaived.waived.dependentOnly.selected;
                selectedWaived.waived.dependentOnly.bothSelected = !selectedWaived.waived.dependentOnly.selected;
                selectedWaived.waived.dependentOnly.selected = !selectedWaived.waived.dependentOnly.selected;

                break;
            case 'dependentOnly.ehcSelected':
                selectedWaived.waived.dependentOnly.ehcSelected = !selectedWaived.waived.dependentOnly.ehcSelected;
                break;
            case 'dependentOnly.dentalSelected':
                selectedWaived.waived.dependentOnly.dentalSelected = !selectedWaived.waived.dependentOnly.dentalSelected;
                break;
            case 'dependentOnly.bothSelected':
                selectedWaived.waived.dependentOnly.bothSelected = !selectedWaived.waived.dependentOnly.bothSelected;
                break;
            case 'memberAndDependent':
                selectedWaived.waived.memberAndDependent.ehcSelected = !selectedWaived.waived.memberAndDependent.selected;
                selectedWaived.waived.memberAndDependent.dentalSelected = !selectedWaived.waived.memberAndDependent.selected;
                selectedWaived.waived.memberAndDependent.bothSelected = !selectedWaived.waived.memberAndDependent.selected;
                selectedWaived.waived.memberAndDependent.selected = !selectedWaived.waived.memberAndDependent.selected;

                break;
            case 'memberAndDependent.ehcSelected':
                selectedWaived.waived.memberAndDependent.ehcSelected = !selectedWaived.waived.memberAndDependent.ehcSelected;
                break;
            case 'memberAndDependent.dentalSelected':
                selectedWaived.waived.memberAndDependent.dentalSelected = !selectedWaived.waived.memberAndDependent.dentalSelected;
                break;
            case 'memberAndDependent.bothSelected':
                selectedWaived.waived.memberAndDependent.bothSelected = !selectedWaived.waived.memberAndDependent.bothSelected;
                break;
            default: break;
        }

        if (![ 'waived', 'notWaived' ].includes(type)) {
            let dependentOnlyValue = true;
            let memberAndDependentValue = true;
            let waivedValue = true;

            Object.keys(selectedWaived.waived.dependentOnly).map((key) => {
                if (key !== 'selected') {
                    dependentOnlyValue = selectedWaived.waived.dependentOnly[key] ? dependentOnlyValue : false;
                    waivedValue = selectedWaived.waived.dependentOnly[key] ? waivedValue : false;
                }

                return null;
            });

            Object.keys(selectedWaived.waived.memberAndDependent).map((key) => {
                if (key !== 'selected') {
                    memberAndDependentValue = selectedWaived.waived.memberAndDependent[key] ? memberAndDependentValue : false;
                    waivedValue = selectedWaived.waived.memberAndDependent[key] ? waivedValue : false;
                }

                return null;
            });

            if (![ 'memberAndDependent', 'dependentOnly' ].includes(type)) {
                selectedWaived.waived.dependentOnly.selected = dependentOnlyValue;
                selectedWaived.waived.memberAndDependent.selected = memberAndDependentValue;
            }

            selectedWaived.waived.selected = waivedValue;
        }

        if (type !== 'notWaived') {
            selectedWaived.notWaived = false;
        }

        this.setState({
            selectedWaived
        });
    };

    _setSort = (type, value) => {
        if (this.state[type] === value) {
            value = null;
        }

        this.setState({
            [type]: value
        });
    };

    _resetFilters = () => {
        this.setState({
            selectedClasses: [],
            selectedStatuses: [],
            selectedWaived: {
                notWaived: false,
                waived: {
                    selected: false,
                    dependentOnly: {
                        selected: false,
                        ehcSelected: false,
                        dentalSelected: false,
                        bothSelected: false
                    },
                    memberAndDependent: {
                        selected: false,
                        ehcSelected: false,
                        dentalSelected: false,
                        bothSelected: false
                    }
                }
            },
            hireDateSort: null,
            studyStartDateSort: null
        });

        this.props.applyFilters({
            classes: [],
            status: [],
            hireDate: null
        });
    };

    _applyFilters = () => {
        // I know hireDateSort isn't a filter but we are packing it in since it's same as filters in the request
        const filters = {
            classes: this.state.selectedClasses,
            divisions: this.state.selectedDivisions,
            status: this.state.selectedStatuses,
            waivedStatus: this.state.selectedWaived,
            hireDate: this.state.hireDateSort,
            studyStartDate: this.state.studyStartDateSort
        };

        this.props.applyFilters(filters);
    };

    render() {
        const { classData, divisions, isStudentGroup } = this.props;
        
        // Placed inside due to render timing issue
        const memberStatusMap = {
            0: i18n.t(tokens.labels.employees.pending),
            1: i18n.t(tokens.labels.employees.active),
            2: i18n.t(tokens.labels.employees.accountDisabled),
            3: i18n.t(tokens.labels.employees.parentalLeave),
            4: i18n.t(tokens.labels.employees.leaveOfAbsence),
            5: i18n.t(tokens.labels.employees.temporaryLayOff),
            6: i18n.t(tokens.labels.employees.disability),
            7: i18n.t(tokens.labels.employees.strike),
            8: i18n.t(tokens.labels.employees.lateApplicant),
            9: i18n.t(tokens.labels.employees.terminated),
            10: i18n.t(tokens.labels.employees.approvedLeave),
            11: i18n.t(tokens.labels.employees.survivorBenefits),
            12: i18n.t(tokens.labels.employees.severance)
        };

        const styles = {
            filterContainer: {
                backgroundColor: Theme.colors.surface,
                boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
                height: 'auto',
                position: 'relative',
                border: '1px solid #E0E0E0'
            },
            filterHeader: {
                padding: '16px 25px',
                borderBottom: '1px solid #EEEEEE'
            },
            filterBody: {
                padding: '0px 25px',
                borderBottom: '1px solid #EEEEEE'
            },
            label: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            buttonsContainer: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '12px'
            }
        };

        return (
            <div style={ styles.filterContainer }>
                <div style={ styles.filterHeader }>
                    <Text
                        align="center"
                        color="inherit"
                        medium
                        fullwidth
                        display="inline"
                        bold
                    >
                        { i18n.t(tokens.labels.employees.filterAndSort) }
                    </Text>
                </div>
                <div style={ styles.filterBody }>
                    <Accordion
                        square
                        onChange={ (e) => this._handleChange(e, 'class') }
                        style={{ boxShadow: 'none', padding: 0 }}
                    >
                        <AccordionSummary
                            style={{ padding: 0, margin: 0, maxHeight: 48, minHeight: 'auto' }}
                            expandIcon={ this.state.expanded.includes('class') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                        >
                            <Text medium>{ i18n.t(tokens.labels.employees.class) }</Text>
                        </AccordionSummary>

                        <AccordionDetails style={{ padding: 0, marginBottom: '24px' }}>
                            <FormGroup style={{ width: '100%' }}>
                                {
                                    helpers.sortObjByTitle(classData).map((classID) => {
                                        const data = classData[classID];

                                        if (data.isModular && data.parentClassID === null) {
                                            return null;
                                        }

                                        return (
                                            <FormControlLabel
                                                key={classID}
                                                classes={{
                                                    label: styles.label
                                                }}
                                                sx={{ fontFamily: Theme.fonts.type.medium }}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={ this.state.selectedClasses.includes(classID) }
                                                        onChange={ () => this._selectFilter('selectedClasses', classID) }
                                                        value={ data.title }
                                                    />
                                                }
                                                label={
                                                    data.description ?
                                                        <Tooltip
                                                            tip={ data.description }
                                                            placement="right"
                                                            arrow
                                                            customStyle={{ fontFamily: Theme.fonts.type.medium }}
                                                        >
                                                            { data.title }
                                                        </Tooltip>
                                                        :
                                                        data.title
                                                }
                                                style={{ maxWidth: '100%' }}
                                            />
                                        );
                                    })
                                }
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div style={ styles.filterBody }>
                    <Accordion
                        square
                        onChange={ (e) => this._handleChange(e, 'class') }
                        style={{ boxShadow: 'none', padding: 0 }}
                    >
                        <AccordionSummary
                            style={{ padding: 0, margin: 0, maxHeight: 48, minHeight: 'auto' }}
                            expandIcon={ this.state.expanded.includes('class') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                        >
                            <Text medium>{ i18n.t(tokens.labels.employees.division) }</Text>
                        </AccordionSummary>

                        <AccordionDetails style={{ padding: 0, marginBottom: '24px' }}>
                            <FormGroup style={{ width: '100%' }}>
                                {
                                    helpers.sortObjByTitle(divisions).map((divisionID) => {
                                        const data = divisions[divisionID];

                                        if (data.isModular && data.parentClassID === null) {
                                            return null;
                                        }

                                        return (
                                            <FormControlLabel
                                                key={divisionID}
                                                classes={{
                                                    label: styles.label
                                                }}
                                                sx={{ fontFamily: Theme.fonts.type.medium }}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={ this.state.selectedDivisions.includes(divisionID) }
                                                        onChange={ () => this._selectFilter('selectedDivisions', divisionID) }
                                                        value={ data.title }
                                                    />
                                                }
                                                label={
                                                    data.description ?
                                                        <Tooltip
                                                            tip={ data.description }
                                                            placement="right"
                                                            arrow
                                                            customStyle={{ fontFamily: Theme.fonts.type.medium }}
                                                        >
                                                            { data.title }
                                                        </Tooltip>
                                                        :
                                                        data.title
                                                }
                                                style={{ maxWidth: '100%' }}
                                            />
                                        );
                                    })
                                }
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div style={ styles.filterBody }>
                    <Accordion
                        square
                        onChange={ (e) => this._handleChange(e, 'class') }
                        style={{ boxShadow: 'none', padding: 0 }}
                    >
                        <AccordionSummary
                            style={{ padding: 0, margin: 0, maxHeight: 48, minHeight: 'auto' }}
                            expandIcon={ this.state.expanded.includes('class') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                        >
                            <Text medium>{ i18n.t(tokens.labels.employees.status) }</Text>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0, marginBottom: '24px' }}>
                            <FormGroup>
                                {
                                    Object.keys(memberStatusMap).map((statusKey) => {
                                        if (isStudentGroup && [ 'Late Applicant', 'Strike', 'Temporary Lay Off', 'Severance' ].includes(memberStatusMap[statusKey])) {
                                            return null;
                                        }

                                        return (
                                            <FormControlLabel
                                                key={statusKey}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={ this.state.selectedStatuses.includes(statusKey) }
                                                        onChange={ () => this._selectFilter('selectedStatuses', statusKey) }
                                                        value={ statusKey }
                                                    />
                                                }
                                                label={ <Text avMedium>{memberStatusMap[statusKey]}</Text> }
                                            />    
                                        );
                                    })
                                }
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div style={ styles.filterBody }>
                    <Accordion
                        square
                        onChange={ (e) => this._handleChange(e, 'waive') }
                        style={{ boxShadow: 'none', padding: 0 }}
                    >
                        <AccordionSummary
                            style={{ padding: 0, margin: 0, maxHeight: 48, minHeight: 'auto' }}
                            expandIcon={ this.state.expanded.includes('waive') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                        >
                            <Text medium>{ i18n.t(tokens.labels.employees.waivedStatus) }</Text>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0, marginBottom: '24px' }}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={ this.state.selectedWaived.notWaived }
                                            onChange={ () => this._selectWaiveFilter('notWaived') }
                                            value={ 'Not Waived' }
                                        />
                                    }
                                    label={<Text avMedium>{ i18n.t(tokens.labels.employees.notWaived) }</Text>}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={ this.state.selectedWaived.waived.selected }
                                            onChange={ () => this._selectWaiveFilter('waived') }
                                            value={ 'Waived' }
                                        />
                                    }
                                    label={<Text avMedium>{ i18n.t(tokens.labels.employees.waived) }</Text>}
                                />

                                <div style={{ marginLeft: 24, display: 'flex', flexDirection: 'column' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={ this.state.selectedWaived.waived.dependentOnly.selected }
                                                onChange={ () => this._selectWaiveFilter('dependentOnly') }
                                                value={ 'dependentOnly' }
                                            />
                                        }
                                        label={<Text avMedium>{ i18n.t(tokens.labels.employees.dependentOnly) }</Text>}
                                    />
                                    <div style={{ marginLeft: 24, display: 'flex', flexDirection: 'column' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={ this.state.selectedWaived.waived.dependentOnly.ehcSelected }
                                                    onChange={ () => this._selectWaiveFilter('dependentOnly.ehcSelected') }
                                                    value={ 'dependentOnly.ehcSelected' }
                                                />
                                            }
                                            label={<Text avMedium>{ i18n.t(tokens.labels.employees.extendedHealthCare)}</Text>}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={ this.state.selectedWaived.waived.dependentOnly.dentalSelected }
                                                    onChange={ () => this._selectWaiveFilter('dependentOnly.dentalSelected') }
                                                    value={ 'dependentOnly.dentalSelected' }
                                                />
                                            }
                                            label={<Text avMedium>{ i18n.t(tokens.titles.dental) }</Text>}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={ this.state.selectedWaived.waived.dependentOnly.bothSelected }
                                                    onChange={ () => this._selectWaiveFilter('dependentOnly.bothSelected') }
                                                    value={ 'dependentOnly.bothSelected' }
                                                />
                                            }
                                            label={<Text avMedium>{ i18n.t(tokens.labels.employees.dentalAndEhc) }</Text>}
                                        />
                                    </div>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={ this.state.selectedWaived.waived.memberAndDependent.selected }
                                                onChange={ () => this._selectWaiveFilter('memberAndDependent') }
                                                value={ 'memberAndDependent' }
                                            />
                                        }
                                        label={<Text avMedium>{ i18n.t(tokens.labels.employees.memberAndDependents) }</Text>}
                                    />

                                    <div style={{ marginLeft: 24, display: 'flex', flexDirection: 'column' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={ this.state.selectedWaived.waived.memberAndDependent.ehcSelected }
                                                    onChange={ () => this._selectWaiveFilter('memberAndDependent.ehcSelected') }
                                                    value={ 'memberAndDependent.ehcSelected' }
                                                />
                                            }
                                            label={<Text avMedium>{ i18n.t(tokens.labels.employees.extendedHealthCare) }</Text>}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={ this.state.selectedWaived.waived.memberAndDependent.dentalSelected }
                                                    onChange={ () => this._selectWaiveFilter('memberAndDependent.dentalSelected') }
                                                    value={ 'memberAndDependent.dentalSelected' }
                                                />
                                            }
                                            label={<Text avMedium>{ i18n.t(tokens.titles.dental) }</Text>}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={ this.state.selectedWaived.waived.memberAndDependent.bothSelected }
                                                    onChange={ () => this._selectWaiveFilter('memberAndDependent.bothSelected') }
                                                    value={ 'memberAndDependent.bothSelected' }
                                                />
                                            }
                                            label={<Text avMedium>{ i18n.t(tokens.labels.employees.dentalAndEhc) }</Text>}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                </div>

                {
                    isStudentGroup ?
                        <div style={ styles.filterBody }>
                            <Accordion
                                square
                                onChange={ (e) => this._handleChange(e, 'studyStartDate') }
                                style={{ boxShadow: 'none', padding: 0 }}
                            >
                                <AccordionSummary
                                    style={{ padding: 0, margin: 0, maxHeight: 48, minHeight: 'auto' }}
                                    expandIcon={ this.state.expanded.includes('studyStartDate') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                >
                                    <Text medium>{ i18n.t(tokens.labels.employees.studyStartDate) }</Text>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0, marginBottom: '24px' }}>
                                    <FormGroup>
                                        {
                                            [
                                                {
                                                    key: 'NEWEST',
                                                    title: i18n.t(tokens.labels.claims.newestFirst)
                                                },
                                                {
                                                    key: 'OLDEST',
                                                    title: i18n.t(tokens.labels.claims.oldestFirst)
                                                }
                                            ].map((sort) => (
                                                <FormControlLabel
                                                    key={sort.key}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={ this.state.studyStartDateSort === sort.key }
                                                            onChange={ () => this._setSort('studyStartDateSort', sort.key) }
                                                            value={ sort.key }
                                                        />
                                                    }
                                                    label={<Text avMedium>{sort.title}</Text>}
                                                />
                                            ))
                                        }
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        :
                        <div style={ styles.filterBody }>
                            <Accordion
                                square
                                onChange={ (e) => this._handleChange(e, 'hireDate') }
                                style={{ boxShadow: 'none', padding: 0 }}
                            >
                                <AccordionSummary
                                    style={{ padding: 0, margin: 0, maxHeight: 48, minHeight: 'auto' }}
                                    expandIcon={ this.state.expanded.includes('hireDate') ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                >
                                    <Text medium>{ i18n.t(tokens.labels.employees.hireDate) }</Text>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0, marginBottom: '24px' }}>
                                    <FormGroup>
                                        {
                                            [
                                                {
                                                    key: 'NEWEST',
                                                    title: i18n.t(tokens.labels.claims.newestFirst)
                                                },
                                                {
                                                    key: 'OLDEST',
                                                    title: i18n.t(tokens.labels.claims.oldestFirst)
                                                }
                                            ].map((sort) => (
                                                <FormControlLabel
                                                    key={sort.key}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={ this.state.hireDateSort === sort.key }
                                                            onChange={ () => this._setSort('hireDateSort', sort.key) }
                                                            value={ sort.key }
                                                        />
                                                    }
                                                    label={<Text avMedium>{sort.title}</Text>}
                                                />
                                            ))
                                        }
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                }

                <div style={ styles.buttonsContainer }>
                    <Button
                        onClick={ this._resetFilters }
                        transparent
                        customStyle={{ fontSize: 14, fontFamily: Theme.fonts.type.heading }}
                    >
                        { i18n.t(tokens.buttons.resetFilters) }
                    </Button>
                    <Button
                        onClick={this._applyFilters}
                        color="primary"
                        customStyle={{ fontSize: 12 }}
                    >
                        { i18n.t(tokens.buttons.applyFilters) }
                    </Button>
                </div>
            </div>
        );
    }
}

export default FilterForm;
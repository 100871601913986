import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { People as PeopleIcon } from '@mui/icons-material';
import Avatar from 'react-avatar';
import { Text, Theme, CardBlock, i18n, tokens, localizationMaps } from '@simplybenefits/sb-ui-library';
import { styled } from '@mui/system';
import * as dates from "@simplybenefits/sb-dates";

const stateMap = {
    0: 'Census',
    1: 'Pending Go Live',
    10: 'Active'
};

const CardBlockBottom = styled('div')(({ theme }) => ({
    display: 'flex',
    height: 'auto',
    minHeight: '20px',
    paddingTop: '5px',
    width: '100%',
    [theme.breakpoints.down('500')]: {
        justifyContent: 'space-between'
    }
}));

const CardBlockLeft = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '100%',
    lineHeight: '30px',
    textAlign: 'left',
    verticalAlign: 'middle',
    width: '70%',
    [theme.breakpoints.down('500')]: {
        width: 'unset'
    }
}));

const CardBlockRight = styled('div')(({ theme }) => ({
    display: 'inline',
    height: '100%',
    lineHeight: '30px',
    textAlign: 'right',
    verticalAlign: 'middle',
    width: '30%',
    [theme.breakpoints.down('500')]: {
        width: '100%'
    }
}));

const CardBlockTop = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${ Theme.colors.lightGrey }`,
    display: 'flex',
    height: 'auto',
    minHeight: '65px',
    width: '100%',
    [theme.breakpoints.down('500')]: {
        flexWrap: 'wrap'
    }
}));

/**
 * 
 * @param {*} props 
 * @returns 
 */
const AccountCard = (props) => {
    const { account, whitelabel } = props;

    const styles = {
        cardBlockAvatar: {
            display: 'inline',
            height: '100%',
            paddingRight: '20px',
            width: '60px'
        },
        cardBlockLeftBody: {
            display: 'inline',
            height: '100%'
        },
        notifyAdminBtn: {
            width: 'fit-content',
            marginTop: 0,
            color: Theme.colors.brandGreen,
            boxShadow: 'none',
            backgroundColor: Theme.colors.clear,
            textAlign: 'right',
            fontSize: '10px',
            paddingLeft: '10px',
            paddingRight: '0',
            '&:hover': {
                backgroundColor: Theme.colors.clear
            }
        }
    };

    return (
        <CardBlock
            onClick={() => {
                window.location.href = `/advisor/account/${ account.accountID }`;
            }}
        >
            <CardBlockTop>
                <CardBlockLeft>
                    <div style={styles.cardBlockAvatar}>
                        <Avatar
                            name={account.company}
                            size="50"
                            textSizeRatio={2.2}
                            round={true}
                            fgColor={whitelabel.name ? whitelabel.primaryColor : Theme.colors.skyBlue}
                            color={whitelabel.name ? whitelabel.lightColor : Theme.colors.lightBlue}
                        />
                    </div>

                    <div style={styles.cardBlockLeftBody}>
                        <Text
                            large
                            display="block"
                        >
                            {account.groupNum ? `${ account.company } (${ account.groupNum })` : account.company}
                        </Text>
                        <Text
                            medium
                            display="inline"
                        >
                            {account.admin}
                        </Text>
                    </div>
                </CardBlockLeft>

                <CardBlockRight
                    style={{
                        color: Number(account.state) === 10 ? whitelabel.primaryColor || Theme.colors.skyBlue : 'black'
                    }}
                >
                    <Text
                        color="inherit"
                        align="right"
                        mediumLarge
                        display="block"
                    >
                        { localizationMaps.fromEnglish("accountStatuses", stateMap[account.state]) }
                    </Text>
                </CardBlockRight>
            </CardBlockTop>

            <CardBlockBottom>
                <CardBlockLeft>
                    <Text
                        color="inherit"
                        medium
                        display="block"
                    >
                        <PeopleIcon/>{account.numUsers}
                    </Text>
                </CardBlockLeft>

                <CardBlockRight>
                    <Text
                        color="inherit"
                        tiny
                        display="inline"
                    >
                        { i18n.t(tokens.labels.advisor.updated) }{' '}{ dates.formatDateForDisplay(account.dateUpdated, 'lll', i18n.locale) }
                    </Text>
                </CardBlockRight>
            </CardBlockBottom>
        </CardBlock>
    );
};

AccountCard.propTypes = {
    loading: PropTypes.bool
};

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.advisor.loading,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(AccountCard);
/**
 * @param token
 */
function setToken(token) {
    if (typeof token === 'string' && token.length > 0) {
        localStorage.setItem('sb-token', token);
    }
}

/**
 * @returns {string | null}
 */
function getToken() {
    return localStorage.getItem('sb-token') || null;
}

/**
 * @returns null
 */
function removeToken() {
    localStorage.removeItem('sb-token');
}

/**
 * @param type
 */
function setUserType(type) {
    localStorage.setItem('sb-ut', type);
}

/**
 * @returns {string | null}
 */
function getUserType() {
    return localStorage.getItem('sb-ut') || null;
}

/**
 * @returns void
 */
function removeUserType() {
    return localStorage.removeItem('sb-ut');
}

/**
 */
function removeWhitelabel() {
    localStorage.removeItem('sb-control-whitelabel');
}

/**
 */
function saveWhitelabel(whitelabel) {
    localStorage.setItem('sb-control-whitelabel', JSON.stringify(whitelabel));
}

/**
 */
function getWhitelabel() {
    const data = localStorage.getItem('sb-control-whitelabel');
    return data ? JSON.parse(data) : null;
}

/**
 */
function saveLsaOptions(options) {
    localStorage.setItem('sb-lsa-options', JSON.stringify(options));
}

/**
 */
function getLsaOptions() {
    const data = localStorage.getItem('sb-lsa-options');
    return data ? JSON.parse(data) : null;
}

/**
 * Sets the language for the admin interface and stores it in the local storage.
 *
 * @param {string} language - The language code to set for the admin interface.
 */
function setAdminLanguage(language) {
    localStorage.setItem('sb-admin-language', language);
}

/**
 * Sets the language for the advisor interface and stores it in the local storage.
 *
 * @param {string} language - The language code to set for the advisor interface.
 */
function setAdvisorLanguage(language) {
    localStorage.setItem('sb-advisor-language', language);
}

/**
 * Retrieves the language preference for the admin from local storage.
 *
 * @returns {string|null} The admin's language preference or null if not set.
 */
function getAdminLanguage() {
    return localStorage.getItem('sb-admin-language');
}

/**
 * Retrieves the language preference for the advisor from local storage.
 *
 * @returns {string|null} The advisor's language preference or null if not set.
 */
function getAdvisorLanguage () {
    return localStorage.getItem('sb-advisor-language');
}


export {
    setToken,
    getToken,
    removeToken,
    setUserType,
    getUserType,
    removeUserType,
    removeWhitelabel,
    saveWhitelabel,
    getWhitelabel,
    saveLsaOptions,
    getLsaOptions,
    setAdminLanguage,
    setAdvisorLanguage,
    getAdminLanguage,
    getAdvisorLanguage
};
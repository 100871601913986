import request from './request.js';
import * as session from '../services/session.js';

/**
 * @returns {Promise<unknown>}
 */
function get(hasAuth) {
    const userType = Number(session.getUserType());

    const options = {
        endpoint: userType === 1 ? `/advisor/whitelabel` : `/admin/whitelabel`,
        method: 'GET'
    };

    if (hasAuth) {
        options.endpoint = `${ options.endpoint }/auth`;
    }

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    get
};
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Text, Modal, DateInput, Services, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as dates from "@simplybenefits/sb-dates";
import { uploadMassSalaryForm, downloadSalaryUpdateData } from '../../actions/admin.js';
import FileDropZoneModal from '../../components/FileDropZoneModal.js';

class MassSalaryUpdate extends PureComponent {
    state = {
        showMassSalaryUpload: false,
        effectiveDate: dates.getLocalDisplay(new Date(), 'YYYY/MM/DD'),
        showEffectiveDateModal: false,
        file: null
    };

    _downloadMassSalaryData = () => {
        this.props.dispatch(downloadSalaryUpdateData());
    };

    _uploadFile = (files) => {
        this.setState({
            file: files[0],
            showEffectiveDateModal: true
        });
    };

    _validateEffectiveDate = async () => {

        let effectiveDateError = await Services.validateInput('effectiveDate', this.state.effectiveDate, i18n.locale);

        const startOfMonth = dates.getStartOfMonth();
        const effectiveDateUTC = dates.getUTC(this.state.effectiveDate);
    
        if (effectiveDateUTC < startOfMonth) {
            effectiveDateError = i18n.t(tokens.labels.employees.backdatedChanges);
        }
    
        await this.setState({
            effectiveDateError
        });
    
        if (effectiveDateError) {
            return false;
        }
    
        return true;

    };

    // eslint-disable-next-line consistent-return
    _completeMassSalaryForm = async () => {
        const form = new FormData();
        form.append('file', this.state.file);
        form.append('effectiveDate', dates.getUTC(this.state.effectiveDate));

        const isEffectiveDateValid = await this._validateEffectiveDate();

        if (!isEffectiveDateValid) {
            return false;
        }

        await this.props.dispatch(uploadMassSalaryForm(form));

        this.setState({
            showMassSalaryUpload: false,
            showEffectiveDateModal: false
        });

        this.props.close();
    };

    _handleInputChange = (type, value) => {
        this.setState({
            [type]: value
        });
    };

    render() {

        return (
            <React.Fragment>
                <Modal
                    open={true}
                    onClose={this.props.close}
                    title={ i18n.t(tokens.titles.massSalaryUpdate) }
                    primaryButton={{
                        label: i18n.t(tokens.labels.employees.uploadChanges),
                        onClick: () => {
                            this.setState({
                                showMassSalaryUpload: true
                            });
                        }
                    }}
                    secondaryButton={{
                        label: i18n.t(tokens.buttons.downloadSalaryData),
                        onClick: this._downloadMassSalaryData
                    }}
                    loader={this.props.loader}
                >
                    <Text medium light>
                        { i18n.t(tokens.labels.employees.performMassSalaryUpdate) }
                    </Text>
                </Modal>

                <FileDropZoneModal
                    title={ i18n.t(tokens.labels.employees.selectMassSalaryFileDownload) }
                    hideModal={() => {
                        this.setState({
                            showMassSalaryUpload: false
                        });
                    }}
                    showModal={ this.state.showMassSalaryUpload }
                    maxItems={1}
                    type="Mass Salary Update"
                    submitFile={this._uploadFile}
                    acceptedFiles="text/csv, .csv"
                />

                <Modal
                    open={this.state.showEffectiveDateModal}
                    onClose={ this.props.close }
                    title={ i18n.t(tokens.titles.setEffectiveDate) }
                    primaryButton={{
                        label: i18n.t(tokens.buttons.submit),
                        onClick: this._completeMassSalaryForm
                    }}
                    loader={this.props.loader}
                >
                    <Text medium>
                        
                    </Text>

                    <DateInput
                        label={ i18n.t(tokens.labels.employees.effectiveDateChange) }
                        value={this.state.effectiveDate}
                        onChange={(e) => this._handleInputChange('effectiveDate', e.target.value)}
                        error={Boolean(this.state.effectiveDateError)}
                        helperText={this.state.effectiveDateError}
                        fullWidth
                        required
                    />

                    <Text medium margin="10px 0 0 0">
                        { i18n.t(tokens.labels.changesAppliedOnEffectiveDate) }
                    </Text>
                </Modal>
            </React.Fragment>
        );
    }
}

export default connect()(MassSalaryUpdate);
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import BookletRow from './BookletRow.js';
import { Modal, Text, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as notifier from '../../../actions/notifier.js';
import { saveAs } from "file-saver";
import loaderGif from "../../../assets/loading.gif";
import { getUserType } from "../../../services/session.js";
import { getAdvisorPdf } from '../../../actions/advisor.js';

class Booklets extends PureComponent {
    state = {
        loading: false
    };

    _downloadBooklet = async (classDetails, employee) => {
        // Always pull autobooklet if available
        if (classDetails.useAutoBooklet) {
            this.setState({
                loading: true
            });

            const fileName = `${ classDetails.title }_${ classDetails.description }_${ i18n.t(tokens.labels.advisor.booklet) }.pdf`;

            await this.props.dispatch(getAdvisorPdf("MemberBooklet", fileName, { userID: employee.userID }));

            this.setState({
                loading: false
            });
        }

        // uploaded booklet
        if (!classDetails.useAutoBooklet && classDetails.hasBooklet) {
            saveAs(`https://d6hvupc5g7lnm.cloudfront.net/member-booklets/${ employee.accountID }/${ employee.classID }.pdf`, `${ classDetails.title }_${ classDetails.description }_${ i18n.t(tokens.labels.advisor.booklet) }`);
        }

        // No booklet available
        if (!classDetails.useAutoBooklet && !classDetails.hasBooklet) {
            this.props.dispatch(notifier.displayError({ verbiage: i18n.t(tokens.labels.advisor.noActiveBooklet) }));
        }
    };

    _renderContent = () => {
        const styles = {
            modalRow: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            },
            gif: {
                maxWidth: '25%',
                marginRight: 40
            }
        };

        return (
            <>
                <BookletRow isHeader={ true }/>

                {
                    Object.keys(this.props.census.classes)
                        .map((classID) => {
                            const classDetails = this.props.census.classes[classID];

                            let employeeInClass = this.props.census.employees.find((employee) => employee.classID === classDetails.classID);

                            // No Employee in the Given Class
                            if (!employeeInClass) {
                                return null;
                            }

                            return (
                                <BookletRow
                                    key={ classDetails.classID }
                                    classDetails={ classDetails }
                                    accountID={ this.props.accountID }
                                    employee={ employeeInClass }
                                    download={ this._downloadBooklet }

                                />
                            );
                        })
                }

                {
                    this.state.loader?.isLoading ?
                        <Modal
                            open={ true }
                            onClose={ this._close }
                            title={ i18n.t(tokens.labels.advisor.bookletDownloading) }
                            loader={this.props.loader}
                        >
                            <div style={ styles.modalRow }>
                                <img style={ styles.gif } src={ this.props.whitelabel.loader || loaderGif } alt="loading..." />

                                <Text
                                    small
                                    margin="10px 0 5px 0"
                                    customStyle={{ width: "65%" }}
                                >
                                    { i18n.t(tokens.labels.advisor.classBookletDownloading) }
                                </Text>
                            </div>
                        </Modal>
                        : 
                        null
                }
            </>
        );
    };

    render() {
        const isAdvisor = Number(getUserType()) === 1;

        if (isAdvisor) {
            return this._renderContent();
        }

        return (
            <Container>
                <Content>
                    { this._renderContent() }
                </Content>
            </Container>
        );
    }
}

Booklets.propTypes = {
    account: PropTypes.object
};

/**
 * @param state
 * @returns {{selectedBill: null, admin: null, history: Array, currentBill: null}}
 */
const mapStateToProps = (state) => ({
    census: state.census,
    state: state,
    loader: state.loader,
    whitelabel: state.whitelabel
});

export default connect(mapStateToProps)(Booklets);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Text,
    Tiles,
    Modal,
    MessageCard,
    DateInput,
    Services,
    Loader,
    MasterApplication,
    PlanDesign,
    CommissionsForm,
    AssignPlans,
    ReviewRates,
    Census,
    ContainerControlSite,
    Content,
    Title,
    LsaCategoriesModal,
    i18n,
    SpecialityProductRates,
    ClassesDivisions,
    tokens,
    Files
} from '@simplybenefits/sb-ui-library';
import * as dates from '@simplybenefits/sb-dates';

import {
    getAccount,
    makeAccountLive,
    sendMasterAppLink,
    sendReviewRequest,
    submitCommissions,
    saveCommissions,
    getCommissions,
    getMasterApplicationData,
    updateMasterApp,
    completeMasterApp,
    assignPlans,
    rejectPricing,
    getAdvisorPdf
} from '../../../actions/advisor.js';

import {
    submitPlanDesigns,
    submitModuleBlueprint,
    createPlanDesign,
    savePlanDesign,
    clonePlanDesign,
    savePlanDesignBenefitInitData,
    savePlanDesignField,
    getPlanDesignRevisions,
    getSinglePlanDesignRevision,
    revertToRevision,
    saveModularBlueprint,
    saveModularBenefitInitData,
    saveModularField,
    getLsaItems,
    getSpecialityProductMetaConfigs,
    getSpecialityProductRates,
    saveSpecialityProductRates
} from '../../../actions/plan';

import {
    submitCensus, uploadCensus, addUser, updateUser, removeUser, addDivision,
    editDivision, editClass, addClass
} from '../../../actions/census.js';

import { createFolder, downloadFile, listFiles, removeFile, uploadFile } from "../../../actions/files.js";

import PlanOverview from '../../Admin/Plan/Overview.js';
import Reports from '../../Reports/index.js';
import AdminManagement from '../../Admin/Management/index.js';
import AccountSettingsModal from '../../Advisor/Accounts/Settings/index.js';
import * as events from '../../../services/events';
import Invoices from '../../Invoices/index.js';
import Amendments from '../Amendments/index.js';
import { getUserType } from '../../../services/session.js';
import * as notifier from '../../../actions/notifier.js';
import Booklets from '../Booklets/index.js';

class Account extends Component {
    state = {
        selectedSection: null,
        showAccountSettingsModal: false,
        showGoLiveDialog: false,
        showSendForReviewDialog: false,
        showLsaCategories: false,
        effDate: dates.getUTC()
    };

    componentDidMount() {
        this.props.dispatch(getAccount(this.props.match.params.accountID));
        events.record('view_account');

        // set the default effDate to be the start of the next month
        const now = new Date();
        const effDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);

        if (window.location.hash && window.location.hash !== '#dashboard') {
            this.setState({
                effDate: Services.dates.formatIncomingDate(Math.floor(effDate.getTime() / 1000)),
                selectedSection: window.location.hash.replace('#', '')
            });
        } else {
            this.setState({
                effDate: Services.dates.formatIncomingDate(Math.floor(effDate.getTime() / 1000))
            });
        }
    }

    _selectSection = (section) => {
        this.setState({
            selectedSection: section
        });
    };

    _checkIfFutureDate = (date) => {
        const dateForDisplay = dates.getUTC(date, "YYYY/MM/DD");
        const isDateFutureDated = dates.validate(dateForDisplay);

        // this reuses the date validation function to check if the date is in the future
        if (isDateFutureDated === 'Date cannot be in the future.') {
            return true;
        }

        return false;
    };

    _getEndpoint = () => {
        let endpoint = 'pending';

        if (this.props.currentAccountMeta.status === 1) {
            endpoint = 'active';
        } else if (this.props.currentAccountMeta.status === 2) {
            endpoint = 'lost';
        }

        return endpoint;
    };

    hideModal = () => {
        this.setState({
            showAccountSettingsModal: false,
            showGoLiveDialog: false
        });
    };

    _toggleModal = (type) => {
        this.setState({
            [type]: !this.state.type
        });
    };

    _toggleGoLiveDialog = () => {
        this.setState({
            showGoLiveDialog: !this.state.showGoLiveDialog
        });
    };

    _toggleSendForReviewDialog = () => {
        this.setState({
            showSendForReviewDialog: !this.state.showSendForReviewDialog
        });
    };

    _submitForReview = () => {
        this._toggleSendForReviewDialog();
        this.props.dispatch(sendReviewRequest(this.props.match.params.accountID));
    };

    _setEffectiveDate = (e) => {
        this.setState({
            effDate: e.target.value
        });
    };

    _goLive = async () => {
        // validate the effective date is correct
        const now = new Date();
        const tempDate = new Date(now.getFullYear(), now.getMonth(), 1);
        const firstOfThisMonth = Services.dates.formatIncomingDate(Math.floor(tempDate.getTime() / 1000));
        let error = false;

        // Effective date can be first of current month
        if (firstOfThisMonth !== this.state.effDate) {
            error = await Services.validateInput('effDate', this.state.effDate, i18n.locale);
        }

        if (error) {
            this.setState({
                effectiveDateError: error
            });

            return false;
        }

        const temp = this.state.effDate.split('/');
        const day = temp[2];

        if (day !== '01') {
            error = i18n.t(tokens.labels.quotes.effectiveDateEndMonth);
        }

        this.setState({
            effectiveDateError: error
        });

        if (error) {
            return false;
        }

        this._toggleGoLiveDialog();
        this.props.dispatch(makeAccountLive(this.props.match.params.accountID, Services.dates.formatOutgoingDate(this.state.effDate)));
        return null;
    };

    _getTiles = () => {
        const tileOptions = {
            accountDetails: {
                title: i18n.t(tokens.titles.accountDetails),
                description: i18n.t(tokens.labels.dashboard.viewAndManageAccountDetails),
                onClick: () => this._toggleModal('showAccountSettingsModal'),
                testid: 'settings-tile',
                completed: true
            },
            planOverview: {
                title: i18n.t(tokens.labels.admin.planOverview),
                description: i18n.t(tokens.labels.advisor.viewTheAccountPlanDetails),
                onClick: () => this._selectSection('advisor-plan-overview'),
                testid: 'advisor-plan-overview',
                completed: true
            },
            planDesign: {
                title: i18n.t(tokens.titles.planDesign),
                description: i18n.t(tokens.labels.advisor.viewCustomizePlanDesigns),
                onClick: () => this._selectSection('advisor-plan-design'),
                testid: 'advisor-plan-design',
                completed: true
            },
            masterApplication: {
                title: i18n.t(tokens.titles.masterApplication),
                description: i18n.t(tokens.labels.advisor.completeSubmitMasterApplication),
                onClick: () => this._selectSection('advisor-master-application'),
                testid: 'master-application-tile',
                completed: true
            },
            administrators: {
                title: i18n.t(tokens.labels.admin.administrators),
                description: i18n.t(tokens.labels.advisor.viewManageAccountAdmins),
                onClick: () => this._selectSection('advisor-account-administrators'),
                testid: 'administrators-tile',
                completed: true
            },
            census: {
                title: i18n.t(tokens.titles.census),
                description: i18n.t(tokens.labels.advisor.inputManageEmployeeInformation),
                step: 5,
                onClick: () => this._selectSection('advisor-account-census'),
                testid: 'census-tile',
                completed: Number(this.props.currentAccountMeta.state) >= 1
            },
            goLive: {
                title: i18n.t(tokens.titles.goLive),
                description: i18n.t(tokens.labels.advisor.setAccountActiveStartBenefitsPlan),
                step: 6,
                disabled: Number(this.props.currentAccountMeta.state) < 1,
                completed: Number(this.props.currentAccountMeta.state) >= 2,
                onClick: () => this._toggleGoLiveDialog(),
                testid: 'go-live-tile'
            },
            amendments: {
                title: i18n.t(tokens.titles.amendments),
                description: i18n.t(tokens.labels.advisor.viewHistoryOfGroupAmendments),
                onClick: () => this._selectSection('amendments'),
                testid: 'advisor-account-amendments'
            },
            specialityProductRates: {
                title: i18n.t(tokens.titles.specialityProductRates),
                description: i18n.t(tokens.labels.specialityProductRatesTileDesc),
                onClick: () => this._selectSection('advisor-speciality-product-rates'),
                testid: 'advisor-speciality-product-rates',
                completed: true
            },
            reports: {
                title: i18n.t(tokens.labels.admin.reports),
                description: i18n.t(tokens.labels.advisor.generateExportReportsAnalytics),
                onClick: () => this._selectSection('advisor-account-reports'),
                testid: 'advisor-account-reports'
            },
            invoices: {
                title: i18n.t(tokens.titles.invoices),
                description: i18n.t(tokens.labels.advisor.viewAccountInvoices),
                onClick: () => this._selectSection('advisor-account-invoices'),
                testid: 'advisor-account-inovices'
            },
            pricing: {
                title: i18n.t(tokens.titles.pricing),
                description: i18n.t(tokens.labels.advisor.viewPlanPricing),
                onClick: () => this._selectSection('advisor-pricing'),
                testid: 'advisor-pricing'
            },
            commissions: {
                title: i18n.t(tokens.titles.commissions),
                description: i18n.t(tokens.labels.advisor.viewSetCommissionsForAccount),
                onClick: () => this._selectSection('commissions'),
                testid: 'commissions'
            },
            classBooklets: {
                title: i18n.t(tokens.titles.classBooklets),
                description: i18n.t(tokens.labels.advisor.reviewClassBooklets),
                onClick: () => this._selectSection('account-class-booklets'),
                testid: 'member-booklets-tile',
                icon: 'Resources'
            },
            lsaCategories: {
                title: i18n.t(tokens.labels.spendingAccounts.lsaCategories),
                description: i18n.t(tokens.labels.advisor.viewCoveredLSACategories),
                onClick: () => this.setState({ showLsaCategories: true })
            },
            classesDivisions: {
                title: i18n.t(tokens.titles.classesAndDivisions),
                description: i18n.t(tokens.labels.advisor.manageClassesAndDivisions),
                onClick: () => this._selectSection('classes-divisions'),
                testid: 'classes-divisions'
            },
            accountHistory: {
                title: i18n.t(tokens.titles.accountHistory),
                description: i18n.t(tokens.labels.tiles.accountHistory),
                onClick: () => this._selectSection('account-history'),
                testid: 'account-history-tile',
                icon: 'Account History'
            }
        };

        let tiles = null;

        if (this.props.currentAccountMeta.status === 0) {
            if (this.props.currentAccountMeta.isPrivateLabel) {
                // for Pending Private Label Accounts (just after Quote)
                tiles = [
                    tileOptions.accountDetails,
                    tileOptions.administrators,
                    tileOptions.planDesign,
                    tileOptions.specialityProductRates,
                    tileOptions.census,
                    tileOptions.goLive
                ];
            } else {
                // for Pending Standard Accounts (just after Quote)
                tiles = [
                    tileOptions.accountDetails,
                    tileOptions.planOverview,
                    tileOptions.masterApplication,
                    tileOptions.administrators,
                    tileOptions.census,
                    tileOptions.goLive
                ];
            }
        } else if (this.props.currentAccountMeta.isPrivateLabel) {
            // for live private label accounts
            tiles = [
                tileOptions.accountDetails,
                tileOptions.administrators,
                tileOptions.planDesign,
                tileOptions.planOverview,
                tileOptions.specialityProductRates,
                tileOptions.census,
                tileOptions.classesDivisions,
                tileOptions.invoices,
                tileOptions.reports,
                tileOptions.accountHistory
            ];
        } else {
            tiles = [
                tileOptions.accountDetails,
                tileOptions.amendments,
                tileOptions.census,
                tileOptions.administrators,
                tileOptions.planOverview,
                tileOptions.specialityProductRates,
                tileOptions.reports,
                tileOptions.invoices,
                tileOptions.masterApplication,
                tileOptions.pricing,
                tileOptions.commissions,
                tileOptions.classBooklets,
                tileOptions.accountHistory
            ];

            if (this.props.currentAccountMeta.hasSpendingAccounts) {
                tiles.push(tileOptions.lsaCategories);
            }
        }

        return tiles;
    };

    _buildBreadcrumbs = (endpoint) => {
        let title = i18n.t(tokens.titles.pendingAccounts);

        if (this.props.currentAccountMeta.status === 1) {
            title = i18n.t(tokens.titles.activeAccounts);
        } else if (this.props.currentAccountMeta.status === 2 || this.props.currentAccountMeta.status === 9) {
            title = i18n.t(tokens.titles.lostOpportunities);
        }

        if (!this.state.selectedSection) {
            return [
                {
                    title: i18n.t(tokens.titles.dashboard),
                    link: `/advisor/dashboard`
                },
                {
                    title,
                    link: `/advisor/accounts/${ endpoint }`
                }
            ];
        }

        return [
            {
                title: i18n.t(tokens.titles.dashboard),
                link: `/advisor/dashboard`
            },
            {
                title,
                link: `/advisor/accounts/${ endpoint }`
            },
            {
                title: this.props.currentAccountMeta.company,
                onClick: () => this._selectSection(null)
            }
        ];
    };

    _getSubHeader = () => {
        if (this.state.selectedSection === 'advisor-plan-design') {
            return i18n.t(tokens.titles.planDesign);
        } else if (this.state.selectedSection === 'advisor-plan-overview') {
            return i18n.t(tokens.labels.admin.planOverview);
        } else if (this.state.selectedSection === 'advisor-account-census') {
            return i18n.t(tokens.titles.census);
        } else if (this.state.selectedSection === 'advisor-account-settings') {
            return i18n.t(tokens.titles.settings);
        } else if (this.state.selectedSection === 'advisor-account-administrators') {
            return i18n.t(tokens.labels.admin.administrators);
        } else if (this.state.selectedSection === 'advisor-account-history') {
            return i18n.t(tokens.titles.accountHistory);
        } else if (this.state.selectedSection === 'advisor-account-reports') {
            return i18n.t(tokens.labels.admin.reports);
        } else if (this.state.selectedSection === 'advisor-master-application') {
            return i18n.t(tokens.titles.masterApplication);
        } else if (this.state.selectedSection === 'advisor-pricing') {
            return i18n.t(tokens.titles.pricing);
        } else if (this.state.selectedSection === 'advisor-assign-plans') {
            return i18n.t(tokens.titles.planAssign);
        } else if (this.state.selectedSection === 'commissions') {
            return i18n.t(tokens.titles.commissions);
        } else if (this.state.selectedSection === 'advisor-account-invoices') {
            return i18n.t(tokens.titles.invoices);
        } else if (this.state.selectedSection === 'request-approval') {
            return i18n.t(tokens.titles.requestApproval);
        } else if (this.state.selectedSection === 'amendments') {
            return i18n.t(tokens.titles.amendments);
        } else if (this.state.selectedSection === 'account-class-booklets') {
            return i18n.t(tokens.titles.classBooklets);
        } else if (this.state.selectedSection === 'advisor-speciality-product-rates') {
            return i18n.t(tokens.titles.specialityProductRates);
        } else if (this.state.selectedSection === 'classes-divisions') {
            return i18n.t(tokens.titles.classesAndDivisions);
        }

        return this.props.currentAccountMeta.company;
    };

    _setSelectedSection = (section) => {
        this.setState({
            selectedSection: section
        });
    };

    render() {
        const { whitelabel, currentAccountMeta } = this.props;
        const disableEditHireDate = currentAccountMeta && currentAccountMeta.status === 1;

        if (!this.props.currentAccountMeta) {
            return <Loader whitelabel={ whitelabel } />;
        }

        const endpoint = this._getEndpoint();
        const subHeader = this._getSubHeader();
        const breadcrumbs = this._buildBreadcrumbs(endpoint);
        const tiles = this._getTiles();
        const accountID = this.props.match.params.accountID;
        const isAdvisor = Number(getUserType()) === 1;

        return (
            <ContainerControlSite id="account-container">
                <Content>
                    <Title
                        title={subHeader}
                        subheader={subHeader}
                        breadcrumbs={breadcrumbs}
                    />

                    {
                        !this.state.selectedSection &&
                            <React.Fragment>
                                {
                                    this.props.currentAccountMeta.state === 6 &&
                                        <MessageCard
                                            title={ i18n.t(tokens.labels.quotes.accountUnderReview) }
                                            subtitle={ i18n.t(tokens.labels.quotes.accountUnderReviewMemo) }
                                        />
                                }

                                {
                                    this.props.currentAccountMeta.terminationDate !== null && this._checkIfFutureDate(this.props.currentAccountMeta.terminationDate) ?
                                        <MessageCard
                                            title={ i18n.t(tokens.labels.dashboard.accountToBeTerminated)}
                                            subtitle={ i18n.t(tokens.labels.dashboard.thisAccountWillBeTerminatedOn, { terminationDate: dates.getUTC(this.props.currentAccountMeta.terminationDate, "ll", i18n.locale), lastEffectiveDate: dates.getPastDate(this.props.currentAccountMeta.terminationDate, 1, "days", "ll", i18n.locale) })}
                                            isError={true}
                                        />
                                        :
                                        null
                                }

                                <Tiles tiles={tiles} stepped={ this.props.currentAccountMeta.status !== 1 } />
                            </React.Fragment>
                    }

                    {
                        this.state.selectedSection === 'advisor-plan-overview' &&
                            <PlanOverview isAdvisor={ isAdvisor } />
                    }

                    {
                        this.state.selectedSection === 'advisor-plan-design' &&
                            <PlanDesign
                                isMasterSite={ false }
                                setSelectedSection={this._setSelectedSection}
                                step={ this.props.currentAccountMeta.state }
                                currentAccountMeta={ this.props.currentAccountMeta }
                                isPrivateLabel={ this.props.currentAccountMeta.isPrivateLabel }
                                classData={ this.props.classData }
                                designs={ this.props.designs }
                                modularBlueprints={ this.props.modularBlueprints }
                                createPlanDesign={ (...params) => this.props.dispatch(createPlanDesign(...params)) }
                                savePlanDesign={ (...params) => this.props.dispatch(savePlanDesign(...params)) }
                                clonePlanDesign={ (...params) => this.props.dispatch(clonePlanDesign(...params)) }
                                savePlanDesignBenefitInitData={ (...params) => this.props.dispatch(savePlanDesignBenefitInitData(...params)) }
                                savePlanDesignField={ (...params) => this.props.dispatch(savePlanDesignField(...params)) }
                                getPlanDesignRevisions={ (...params) => this.props.dispatch(getPlanDesignRevisions(...params)) }
                                getSinglePlanDesignRevision={ (...params) => this.props.dispatch(getSinglePlanDesignRevision(...params)) }
                                revertToRevision={ (...params) => this.props.dispatch(revertToRevision(...params)) }
                                saveModularBenefitInitData={ (...params) => this.props.dispatch(saveModularBenefitInitData(...params)) }
                                saveModularField={ (...params) => this.props.dispatch(saveModularField(...params)) }
                                saveModularBlueprint={ (...params) => this.props.dispatch(saveModularBlueprint(...params)) }
                                submitPlanDesigns={ (...params) => this.props.dispatch(submitPlanDesigns(...params)) }
                                submitModuleBlueprint={ (...params) => this.props.dispatch(submitModuleBlueprint(...params)) }
                                getSpecialityProductMetaConfigs={ () => this.props.dispatch(getSpecialityProductMetaConfigs(this.props.currentAccountMeta.advisorID))}
                                specialityProductMetaConfigs={ this.props.specialityProductMetaConfigs }
                                loading={ this.props.loading }
                                whitelabel={ whitelabel }
                                language={ this.props.advisorMeta.language || 'en' }
                                isPrivateLabelAdvisor={ Boolean(this.props.advisorMeta.privateLabelID) }
                            />
                    }

                    {
                        this.state.selectedSection === 'commissions' &&
                            <CommissionsForm
                                isMasterSite={ false }
                                currentAccountMeta={ this.props.currentAccountMeta }
                                setSelectedSection={this._setSelectedSection}
                                submitCommissions={ (...params) => this.props.dispatch(submitCommissions(...params)) }
                                saveCommissions={ (...params) => this.props.dispatch(saveCommissions(...params)) }
                                getCommissions={ (...params) => this.props.dispatch(getCommissions(...params)) }
                            />
                    }

                    {
                        this.state.selectedSection === 'advisor-account-census' &&
                            <Census
                                setSelectedSection={this._setSelectedSection}
                                accountID={accountID}
                                currentAccountMeta={ this.props.currentAccountMeta }
                                classData={ this.props.classData }
                                divisions={ this.props.divisions }
                                census={ this.props.census }
                                displayError={ (...params) => this.props.dispatch(notifier.displayError(...params)) }
                                uploadCensus={ (...params) => this.props.dispatch(uploadCensus(...params)) }
                                submitCensus={ (...params) => this.props.dispatch(submitCensus(...params)) }
                                addUser={ (...params) => this.props.dispatch(addUser(...params)) }
                                updateUser={ (...params) => this.props.dispatch(updateUser(...params)) }
                                removeUser={ (...params) => this.props.dispatch(removeUser(...params)) }
                                addDivision={ (...params) => this.props.dispatch(addDivision(...params)) }
                                editDivision={ (...params) => this.props.dispatch(editDivision(...params)) }
                                editClass={ (...params) => this.props.dispatch(editClass(...params)) }
                                addClass={ (...params) => this.props.dispatch(addClass(...params)) }
                                isAdvisor={ isAdvisor }
                                isMasterSite={ false }
                                disableEditHireDate={ disableEditHireDate }
                            />
                    }

                    {
                        this.state.selectedSection === 'classes-divisions' &&
                            <ClassesDivisions
                                currentAccountMeta={this.props.currentAccountMeta}
                                step={this.props.currentAccountMeta.state}
                                addDivision={(...params) => this.props.dispatch(addDivision(...params))}
                                editDivision={(...params) => this.props.dispatch(editDivision(...params))}
                                editClass={(...params) => this.props.dispatch(editClass(...params))}
                                addClass={(...params) => this.props.dispatch(addClass(...params))}
                                classData={this.props.classData}
                                divisions={this.props.divisions}
                                isQuote={false}
                                isMasterSite={false}
                                loading={this.props.loading}
                            />
                    }

                    {
                        this.state.selectedSection === 'advisor-account-administrators' &&
                            <AdminManagement
                                isAdvisor={ isAdvisor }
                                accountID={accountID}
                                setSelectedSection={this._setSelectedSection}
                            />
                    }

                    {
                        this.state.selectedSection === 'advisor-account-reports' &&
                            <Reports
                                isAdvisor={ isAdvisor }
                                accountID={accountID}
                                currentAccountMeta={this.props.currentAccountMeta}
                                setSelectedSection={this._setSelectedSection}
                            />
                    }

                    {
                        this.state.selectedSection === 'account-history' &&
                            <Files
                                type="accounts"
                                id={ this.props.currentAccountMeta.accountID }
                                parentFolder="Account History"
                                dispatch={ this.props.dispatch }
                                listFiles={ listFiles }
                                createFolder={ createFolder }
                                uploadFile={ uploadFile }
                                downloadFile={ downloadFile }
                                removeFile={ removeFile }
                            />
                    }

                    {
                        this.state.selectedSection === 'advisor-master-application' &&
                            <MasterApplication
                                whitelabel={ this.props.whitelabel }
                                setSelectedSection={this._setSelectedSection}
                                advisorMeta={ this.props.advisorMeta }
                                isMasterSite={ false }
                                currentAccountMeta={ this.props.currentAccountMeta }
                                isReadOnly={ this.props.currentAccountMeta.status !== 3 } // if its not in quoting or in progress (status = 3)
                                getMasterApplicationData={ (...params) => this.props.dispatch(getMasterApplicationData(...params)) }
                                updateMasterApp={ (...params) => this.props.dispatch(updateMasterApp(...params)) }
                                completeMasterApp={ (...params) => this.props.dispatch(completeMasterApp(...params)) }
                            />
                    }

                    {
                        this.state.selectedSection === 'account-class-booklets' &&
                            <Booklets accountID={accountID} />
                    }

                    {
                        this.state.selectedSection === 'amendments' &&
                            <Amendments accountID={accountID} />
                    }

                    {
                        this.state.selectedSection === 'request-approval' &&
                            <MasterApplication
                                whitelabel={ this.props.whitelabel }
                                setSelectedSection={this._setSelectedSection}
                                advisorMeta={ this.props.advisorMeta }
                                isMasterSite={ false }
                                currentAccountMeta={ this.props.currentAccountMeta }
                                getMasterApplicationData={ (...params) => this.props.dispatch(getMasterApplicationData(...params)) }
                                updateMasterApp={ (...params) => this.props.dispatch(updateMasterApp(...params)) }
                                completeMasterApp={ (...params) => this.props.dispatch(completeMasterApp(...params)) }
                                sendRequestApprovalLink={ (...params) => this.props.dispatch(sendMasterAppLink(...params)) }
                            />
                    }

                    {
                        this.state.selectedSection === 'advisor-assign-plans' &&
                            <AssignPlans
                                whitelabel={ this.props.whitelabel }
                                classData={ this.props.classData }
                                designs={ this.props.designs }
                                setSelectedSection={this._setSelectedSection}
                                advisorMeta={ this.props.advisorMeta }
                                isMasterSite={ false }
                                currentAccountMeta={ this.props.currentAccountMeta }
                                assignPlans={ (...params) => this.props.dispatch(assignPlans(...params)) }
                                rejectPricing={ (...params) => this.props.dispatch(rejectPricing(...params)) }
                            />
                    }

                    {
                        this.state.selectedSection === 'advisor-pricing' &&
                            <ReviewRates
                                whitelabel={ this.props.whitelabel }
                                classData={ this.props.classData }
                                designs={ this.props.designs }
                                setSelectedSection={this._setSelectedSection}
                                advisorMeta={ this.props.advisorMeta }
                                isMasterSite={ false }
                                currentAccountMeta={ this.props.currentAccountMeta }
                                downloadPricingPdf={ (...params) => this.props.dispatch(getAdvisorPdf(...params)) }
                            />
                    }

                    {
                        this.state.selectedSection === 'advisor-speciality-product-rates' &&
                            <SpecialityProductRates
                                isMasterSite={ false }
                                isAccountLive={ true }
                                specialityProductMetaConfigs={ this.props.specialityProductMetaConfigs }
                                getSpecialityProductRates={ () => this.props.dispatch(getSpecialityProductRates(this.props.currentAccountMeta.accountID, null))}
                                saveSpecialityProductRates={ (...params) => this.props.dispatch(saveSpecialityProductRates(this.props.currentAccountMeta.accountID, null, ...params))}
                                currentAccountMeta={ this.props.currentAccountMeta }
                                loading={ this.props.loading }
                                whitelabel={ this.props.whitelabel }
                                downloadRatesPdf={ (...params) => this.props.dispatch(getAdvisorPdf(...params))}
                            />
                    }

                    {
                        this.state.showAccountSettingsModal &&
                            <AccountSettingsModal
                                hideModal={this.hideModal}
                                showModal={this.state.showAccountSettingsModal}
                                isAdvisor={isAdvisor}
                            />
                    }

                    {
                        this.state.selectedSection === 'advisor-account-invoices' &&
                            <Invoices
                                accountID={accountID}
                            />
                    }
                </Content>

                {
                    this.state.showLsaCategories &&
                        <LsaCategoriesModal
                            open={ this.state.showLsaCategories }
                            getItems={ () => this.props.dispatch(getLsaItems(accountID)) }
                            close={ () => this.setState({ showLsaCategories: false }) }
                            whitelabel={ whitelabel || {} }
                        />
                }

                <Modal
                    open={this.state.showGoLiveDialog}
                    onClose={this._toggleGoLiveDialog}
                    title={ i18n.t(tokens.titles.setEffectiveDate) }
                    primaryButton={{
                        label: this._confirm,
                        onClick: this._goLive
                    }}
                    loader={this.props.loader}
                >
                    <Text medium>
                        { i18n.t(tokens.labels.quotes.accountLiveDate) }
                    </Text>

                    <DateInput
                        label={i18n.t(tokens.labels.effectiveDate)}
                        value={this.state.effDate}
                        onBlur={this._setEffectiveDate}
                        error={Boolean(this.state.effectiveDateError)}
                        helperText={this.state.effectiveDateError}
                        fullWidth
                        required
                    />

                    <Text medium margin="10px 0 0 0">
                        { i18n.t(tokens.labels.quotes.accountLiveSubmit) }
                    </Text>
                </Modal>

                <Modal
                    open={this.state.showSendForReviewDialog}
                    onClose={this._toggleSendForReviewDialog}
                    title={ i18n.t(tokens.titles.areYouSure) }
                    primaryButton={{
                        label: i18n.t(tokens.buttons.requestReview),
                        onClick: this._submitForReview
                    }}
                    loader={this.props.loader}
                >
                    <Text medium>
                        { i18n.t(tokens.labels.quotes.accountLiveReview) }
                    </Text>
                </Modal>
                <span id="accounts-single" />
            </ContainerControlSite>
        );
    }
}

Account.propTypes = {
    currentAccountMeta: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{currentAccountMeta: null, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    currentAccountMeta: state.advisor.currentAccountMeta,
    advisorMeta: state.advisor.meta,
    designs: state.plan.designs,
    modularBlueprints: state.plan.modularBlueprints,
    classData: state.census.classes,
    whitelabel: state.whitelabel,
    census: state.census.employees,
    divisions: state.census.divisions,
    specialityProductMetaConfigs: state.plan.specialityProductMetaConfigs
});

export default (connect(mapStateToProps)(Account));
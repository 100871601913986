import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Loader, MasterAppForm, ContainerControlSite, Logo, Content, Theme, i18n, tokens } from '@simplybenefits/sb-ui-library';
import { getMasterApplicationDataReadOnly, submitMasterApp, getAdvisorPdfForQuote, validateBankingDetails } from '../../../actions/advisor.js';
import * as notifier from '../../../actions/notifier.js';

class MasterApplicationAuthorityView extends Component {
    state = {
        masterAppData: null,
        quoteID: null
    };

    async componentDidMount() {
        const id = this.props.match.params.id;
        const uniqueKey = this.props.match.params.uniqueKey;
        const isQuote = this.props.match.params.isQuote !== "false";

        const accountID = isQuote ? null : id;
        const quoteID = isQuote ? id : null;

        const data = await this.props.dispatch(getMasterApplicationDataReadOnly(accountID, uniqueKey, quoteID));
        
        const storedLocale = localStorage.getItem("locale") || i18n.locale;
        i18n.setLocale(storedLocale);

        this.setState({
            masterAppData: data,
            quoteID,
            locale: storedLocale 
        });
    }

    _downloadPlanDesign = async () => {
        const id = this.props.match.params.id;
        const uniqueKey = this.props.match.params.uniqueKey;
        const isQuote = this.props.match.params.isQuote !== "false";
        const accountID = isQuote ? null : id;
        const quoteID = isQuote ? id : null;
        const language = i18n.locale;

        await this.props.dispatch(getAdvisorPdfForQuote("Plan", i18n.t(tokens.labels.planDesignFileName), { accountID, uniqueKey, quoteID, forQuote: true, language }));
    };

    _downloadPlanPricing = async () => {
        const quoteID = this.props.match.params.id;
        const language = i18n.locale;

        await this.props.dispatch(getAdvisorPdfForQuote("PlanPricing", i18n.t(tokens.labels.planPricingFileName), { quoteID, language }));
    };

    _setUserLocale = () => {
        i18n.toggleLocale();
        localStorage.setItem('locale', i18n.locale);
        this.setState({ locale: i18n.locale === 'en' ? 'fr' : 'en' });
    };

    render() {
        const { whitelabel } = this.props;
        const styles = {
            downloadBtnContainer: {
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
                marginBottom: 10
            }
        };

        if (!this.state.masterAppData || this.props.loading) {
            return <Loader/>;
        }

        return (
            <ContainerControlSite id="content" background={Theme.colors.surface}>
                <Logo whitelabel={whitelabel} />

                <Content>
                    <div style={ styles.downloadBtnContainer }>
                        <Button transparent onClick={() => this._downloadPlanDesign()}>
                            {i18n.t(tokens.buttons.downloadPlanDesign) }
                        </Button>

                        {
                            !this.state.masterAppData.accountMeta.isPrivateLabel &&
                                <Button transparent onClick={() => this._downloadPlanPricing()}>
                                    {i18n.t(tokens.buttons.downloadPlanPricing) }
                                </Button>
                        }
                    </div>

                    <Button onClick={() => this._setUserLocale() } customStyle={{ }}>
                        { i18n.locale === "en" ? "Français" : "English" }
                    </Button>
                    
                    <MasterAppForm
                        accountID={ this.props.match.params.accountID }
                        currentAccountMeta={ this.state.masterAppData.accountMeta }
                        data={ this.state.masterAppData.formData }
                        plan={ this.state.masterAppData.plan }
                        isAuthorityView={ true }
                        whitelabel={ whitelabel }
                        submitMasterApp={ (...params) => this.props.dispatch(submitMasterApp(...params, this.state.quoteID)) }
                        displayError={ (...params) => this.props.dispatch(notifier.displayError(...params)) }
                        validateBankingDetails={ (...params) => this.props.dispatch(validateBankingDetails(...params)) }
                        bankingDetailsVerifying={ this.props.bankingDetailsVerifying }
                    />
                </Content>
            </ContainerControlSite>
        );
    }
}

MasterApplicationAuthorityView.propTypes = {
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    bankingDetailsVerifying: state.advisor.bankingDetailsVerifying,
    loader: state.loader,
    whitelabel: state.whitelabel
});

export default (connect(mapStateToProps)(MasterApplicationAuthorityView));
import request from './request.js';

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param path
 * @returns {Promise<unknown>}
 */
function listFiles(type, id, parentFolder, path) {
    const options = {
        endpoint: `/files/${ type }/${ id }/directory?parentFolder=${ encodeURIComponent(parentFolder) }`,
        method: 'GET'
    };

    if (path) {
        options.endpoint += `&path=${ encodeURIComponent(path) }`;
    }

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param folderName
 * @param path
 * @returns {Promise<unknown>}
 */
function createFolder(type, id, parentFolder, folderName, path = null) {
    const options = {
        endpoint: `/files/${ type }/${ id }/folder`,
        method: 'POST',
        body: {
            name: folderName,
            parentFolder,
            path
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param file
 * @param path
 * @returns {Promise<unknown>}
 */
function uploadFile(type, id, parentFolder, file, path, skipNotification = false) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('parentFolder', parentFolder);
    formData.append('skipNotification', skipNotification);

    if (path) {
        formData.append('path', path);
    }

    const options = {
        endpoint: `/files/${ type }/${ id }/file`,
        method: 'POST',
        noJson: true,
        body: formData
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param path
 * @returns {Promise<unknown>}
 */
function downloadFile(type, id, parentFolder, path) {
    const options = {
        endpoint: `/files/${ type }/${ id }?parentFolder=${ encodeURIComponent(parentFolder) }&path=${ encodeURIComponent(path) }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param type
 * @param id
 * @param parentFolder
 * @param path
 * @returns {Promise<unknown>}
 */
function removeFile(type, id, parentFolder, path) {
    const options = {
        endpoint: `/files/${ type }/${ id }`,
        method: 'DELETE',
        body: {
            parentFolder,
            path
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    listFiles,
    createFolder,
    uploadFile,
    downloadFile,
    removeFile
};
import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/system';
import Divider from "@mui/material/Divider";
import * as dates from '@simplybenefits/sb-dates';
import { downloadChangeRequestPdf } from '../../../actions/changeRequests';

import {
    Theme,
    Text,
    Button,
    i18n,
    tokens,
    localizationMaps
} from '@simplybenefits/sb-ui-library';

const ChangeRequestRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    height: 60,
    paddingLeft: 20,
    cursor: 'pointer',
    '&:hover': {
        filter: 'brightness(97%)'
    }
});

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const RequestedChangesTable = ({
    requestedChanges,
    loadMoreRequestedChanges,
    dispatch,
    adminMeta,
    employees,
    divisions

}) => {
    const styles = {
        headerContainer: {
            height: 75,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 20
        },
        firstBlock: {
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            width: '20%',
            paddingLeft: 15
        },
        centerBlock: {
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            width: '12%',
            justifyContent: 'flex-start'
        },
        lastBlock: {
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: i18n.locale === 'fr' ? '30%' : '20%'
        }
    };

    /**
     * 
     * @param {*} status 
     * @returns 
     */
    const returnStatusText = (status) => {
        if (status.toLowerCase() === "pending admin review") {
            return localizationMaps.fromEnglish('changeRequestStatuses', "Needs Review");
        }

        const lowerStatus = status.toLowerCase();

        if ([ 'incomplete', 'suspended', 'pending agent review', 'pending member review' ].includes(lowerStatus)) {
            return i18n.t(tokens.labels.employees.pending);
        }

        return localizationMaps.fromEnglish('changeRequestStatuses', status);
    };

    /**
     * 
     * @param {*} status 
     */
    const returnStatusColor = (status) => {
        switch (status) {
            case status.toLowerCase().includes('pending'):
                return "#FCE83A";

            case "Approved":
                return Theme.colors.brandGreen;

            case "Declined":
                return Theme.colors.red;
        
            default: 
                return "#FCE83A";
        }
    };

    /**
     * 
     * @param {*} userID 
     * @returns 
     */
    const _writeAccess = (userID) => {
        const divisionID = employees.find((employee) => employee.userID === userID)?.divisionID;
        return divisions[divisionID].access === 'Write' || adminMeta.isParent;
    };

    /**
     * 
     * @param {*} changeRequestID 
     * @param {*} status 
     */
    const _handleDisplay = async (changeRequestData) => {
        const { changeRequestID, status, userID } = changeRequestData;
        const writeAccess = _writeAccess(userID);

        if ([ 'approved', 'declined' ].includes(status.toLowerCase())) {
            const url = await dispatch(downloadChangeRequestPdf(changeRequestID));

            if (typeof url === 'string') {
                window.open(url, '_blank');
            }

            return true;
        }

        // If admin only has read access for the given division only show approved/declined pdfs
        if (!writeAccess) {
            return false;
        }

        window.location.href = `/memberChanges/${ changeRequestID }`;
        return true;
    };

    return (
        <>
            {
                requestedChanges.length ?
                    <>
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                border: `1px solid ${ Theme.colors.lightGrey }`
                            }}
                        >
                            <div style={ styles.headerContainer }>
                                <div style={ styles.firstBlock }>
                                    <Text bold medium>{ i18n.t(tokens.labels.changeRequests.memberName) }</Text>
                                </div>
                                <div style={ styles.centerBlock }>
                                    <Text bold medium>{ i18n.t(tokens.labels.changeRequests.identifier) }</Text>
                                </div>
                                <div style={ styles.firstBlock }>
                                    <Text bold medium>{ i18n.t(tokens.labels.changeRequests.requestedChange) }</Text>
                                </div>

                                <div style={ styles.centerBlock }>
                                    <Text bold medium>{ i18n.t(tokens.labels.changeRequests.dateSubmitted) }</Text>
                                </div>


                                <div style={ styles.centerBlock }>
                                    <Text bold medium>{ i18n.t(tokens.labels.admin.effectiveDate) }</Text>
                                </div>

                                <div style={ styles.lastBlock }>
                                    <Text bold medium align="right">{i18n.t(tokens.titles.status) }</Text>
                                </div>
                            </div>

                            <Divider/>

                            {
                                requestedChanges.map((changeRequestData, i) => (
                                    <div
                                        key={ changeRequestData.changeRequestID }
                                    >
                                        <ChangeRequestRow 
                                            onClick ={ () => _handleDisplay(changeRequestData)}
                                        >
                                            <div style={ styles.firstBlock }>
                                                <Text medium bold>{ changeRequestData.memberName }</Text>
                                            </div>
                                            <div style={ styles.centerBlock }>
                                                <Text medium>{ changeRequestData.changeRequestID }</Text>
                                            </div>
                                            <div style={ styles.firstBlock }>
                                                <Text medium bold>{ localizationMaps.fromEnglish('changeRequestTypes', changeRequestData.type) }</Text>
                                            </div>
                                            <div style={ styles.centerBlock }>
                                                <Text medium>{ dates.formatDateForDisplay(changeRequestData.submittedDate, 'MMM DD, YYYY', i18n.locale) }</Text>
                                            </div>
                                            <div style={{ ...styles.centerBlock, ...(!changeRequestData.effectiveDate ? { paddingLeft: 50 } : {}) }}>
                                                <Text medium>{ changeRequestData.effectiveDate ? dates.formatDateForDisplay(changeRequestData.effectiveDate, 'MMM DD, YYYY', i18n.locale) : "-" }</Text>
                                            </div>
                                            <div style={{ ...styles.lastBlock, flexDirection: "row", justifyContent: 'flex-start' }}>
                                                <Text 
                                                    customStyle={{
                                                        fontSize: 20,
                                                        marginRight: 10,
                                                        color: returnStatusColor(changeRequestData.status)
                                                    }}
                                                >•</Text>
                                                <Text medium align="right">{ returnStatusText(changeRequestData.status) }</Text>
                                            </div>
                                        </ChangeRequestRow>
                                        { requestedChanges.length - 1 !== i ? <Divider/> : null }
                                    </div>
                                ))
                            }
                        </div>
                        {
                            requestedChanges.length >= 50
                                ? (
                                    <div 
                                        style={{ 
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <Button
                                            color={"primary"}
                                            customStyle={{
                                                height: 50,
                                                marginRight: 1.25,
                                                marginTop: 1.25,
                                                textTransform: "capitalize",
                                                fontSize: 16,
                                                minWidth: 100
                                            }}
                                            onClick={ loadMoreRequestedChanges }
                                            id="loadmorerequestedchnages"
                                        >
                                            { i18n.t(tokens.buttons.next) }
                                        </Button>
                                    </div>
                                )
                                : null
                        }
                    </>
                    :
                    <Text medium light align="left">{ i18n.t(tokens.labels.changeRequests.noChangeRequestsToDisplay) }</Text>
            }
        </>
    );
};

/**
 * 
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    adminMeta: state.admin.meta,
    divisions: state.census.divisions,
    employees: state.census.employees
});

export default connect(mapStateToProps)(RequestedChangesTable);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text, Tiles, ContainerControlSite, Title, Content, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as events from '../../../services/events';
import { saveAs } from "file-saver";

class AdvisorResources extends Component {
    componentDidMount() {
        events.record('view_resources');
    }

    _downloadPDF = (item) => {
        saveAs(`https://d6hvupc5g7lnm.cloudfront.net/files/${ item }`, item);
    };

    render() {
        const tiles = [
            {
                title: i18n.t(tokens.labels.resources.spendingAccountProposalTemplate),
                description: i18n.t(tokens.labels.resources.insertClientDetails),
                onClick: () => this._downloadPDF('HSA_LSA_Proposal_Template.docx'),
                icon: 'Resources'
            }
        ];

        return (
            <ContainerControlSite>
                <Content>
                    <Title title={i18n.t(tokens.titles.resources)} />
                    <Tiles tiles={ tiles }>
                        {!tiles.length && 
                                <Text
                                    small
                                    margin="10px 0 5px 0"
                                    customStyle={{ maxWidth: 600 }}
                                >
                                    {i18n.t(tokens.labels.resources.whenFormsAndDocumentsAreAvailable)}
                                </Text>
                        }
                    </Tiles>
                </Content>
                <span id="resources" />
            </ContainerControlSite>
        );
    }
}

/**
 * @param state
 * @returns {loading: boolean}
 */
const mapStateToProps = (state) => ({
    loader: state.loader
});

export default (connect(mapStateToProps)(AdvisorResources));
import * as types from './types/notifier.js';
import * as session from '../services/session.js';
import { i18n, tokens } from '@simplybenefits/sb-ui-library';

/**
 * @param verbiage
 * @param timeout
 * @returns {Function}
 */
export function displayMessage(verbiage, timeout = 3500) {
    return (dispatch) => {
        setTimeout(() => {
            dispatch({
                type: types.HIDE_NOTIFICATION
            });
        }, timeout);

        dispatch({
            type: types.SHOW_NOTIFICATION,
            showing: true,
            notifierType: 'message',
            verbiage
        });

        const content = document.getElementById("content");

        if (content) {
            document.getElementById("content").scroll(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    };
}

/**
 *
 * @param {*} error
 */
export function displayError(error) {
    return (dispatch) => {
        if (error.code === 'INVALID_TOKEN') {
            session.removeToken();
            window.location.href = '/login?type=invalid-token';
            return;
        }

        if (error.code === 'FAILED_WHITELABEL_VALIDATION') {
            session.removeToken();
            window.location.href = '/login?type=failed-whitelabel';
            return;
        }

        setTimeout(() => {
            dispatch({
                type: types.HIDE_NOTIFICATION
            });
        }, 10000);

        dispatch({
            type: types.SHOW_NOTIFICATION,
            showing: true,
            notifierType: 'error',
            verbiage: error.verbiage || i18n.t(tokens.labels.notifications.somethingWentWrong)
        });

        const content = document.getElementById("content");

        if (content) {
            document.getElementById("content").scroll(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    };
}

/**
 *
 * @param {*} verbiage
 */
export function displayBrowserMessage(verbiage, link, timeout) {
    return (dispatch) => {
        if (timeout) {
            setTimeout(() => {
                dispatch({
                    type: types.HIDE_NOTIFICATION
                });
            }, timeout);
        }

        dispatch({
            type: types.SHOW_NOTIFICATION,
            showing: true,
            notifierType: 'browser',
            link,
            verbiage
        });

        const content = document.getElementById("content");

        if (content) {
            document.getElementById("content").scroll(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    };
}

/**
 *
 */
export function hideNotification() {
    return (dispatch) => dispatch({
        type: types.HIDE_NOTIFICATION
    });
}

// /**
//  */
// export function updateLastUpdatedTime() {
//     return (dispatch) => dispatch({
//         type: types.UPDATE_LAST_UPDATED_TIMESTAMP
//     });
// }
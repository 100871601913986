import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Button, Loader, ContainerControlSite, Content, Title, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as dates from '@simplybenefits/sb-dates';
import { getUserType } from '../../../services/session.js';
import { getAsoReport, getInvoices } from '../../../actions/invoices.js';
import AsoHistory from './AsoHistory.js';
import AsoReport from './AsoReport.js';
import { getAdminPdf } from '../../../actions/admin.js';

class AsoReports extends PureComponent {
    state = {
        selectedAsoReport: null
    };

    componentDidMount() {
        if (!this.props.history) {
            this.props.dispatch(getInvoices()); // if we have no ASO History then we need to grab it (it's bundled in the GetInvoices call)
        }
    }

    _goToHistory = () => {
        this.setState({
            selectedAsoReport: null
        });
    };

    _selectReport = async (report) => {
        const selectedAsoReport = await this.props.dispatch(getAsoReport(report.asoReportID));

        this.setState({
            selectedAsoReport
        });
    };

    _downloadAsoReport = (asoReportID, clientName, timeStamp, period, groupNum, accountID) => {
        const fileName = `${ i18n.t(tokens.labels.asoFileName, { date: dates.getLocalDisplay(this.props.date, 'MMMM_YYYY', i18n.locale) }) }`;
        this.props.dispatch(getAdminPdf("AsoSummary", fileName, { asoReportID, clientName, timeStamp, groupNum, period, accountID }));
    };

    render() {
        const { whitelabel } = this.props;
        const isAdvisor = Number(getUserType()) === 1;
        const styles = {
            titleBlock: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center'
            }
        };

        if (!this.props.history) {
            return <Loader whitelabel={ whitelabel } />;
        }

        return (
            <ContainerControlSite>
                <Content>
                    <div style={ styles.titleBlock }>
                        <Title title={i18n.t(tokens.labels.admin.asoReporting)} customStyle={{ flex: 1 }} />

                        {
                            this.state.selectedAsoReport &&
                                <>
                                    {
                                        Boolean(isAdvisor || this.props.admin.isParent) &&
                                                <Button
                                                    id="download-monthly-summary"
                                                    color="white"
                                                    noHover
                                                    customStyle={{
                                                        height: 50,
                                                        width: 280,
                                                        marginRight: 1.25,
                                                        textTransform: "capitalize",
                                                        fontSize: 16
                                                    }}
                                                    onClick={() => this._downloadAsoReport(this.state.selectedAsoReport.asoReportID, this.props.clientName, this.state.selectedAsoReport.timestamp, this.state.selectedAsoReport.summary.period, this.props.account.groupNum, this.props.account.accountID)}
                                                >
                                                    {i18n.t(tokens.labels.admin.downloadAccountSummary)}
                                                </Button>
                                    }

                                    <Button
                                        id="view-history"
                                        color="primary"
                                        customStyle={{
                                            height: 50,
                                            width: 180,
                                            textTransform: "capitalize",
                                            fontSize: 16
                                        }}
                                        onClick={ this._goToHistory }
                                    >
                                        {i18n.t(tokens.labels.admin.viewHistory)}
                                    </Button>
                                </>
                        }
                    </div>

                    {
                        !this.state.selectedAsoReport ?
                            <AsoHistory
                                history={ this.props.history }
                                selectReport={ this._selectReport }
                            />
                            :
                            <AsoReport
                                asoReport={ this.state.selectedAsoReport }
                                whitelabel={ whitelabel }
                            />
                    }
                </Content>

                {this.props.loading && <Loader whitelabel={ whitelabel } />}

                <span id="aso-reports" />
            </ContainerControlSite>
        );
    }
}

AsoReports.propTypes = {
    loading: PropTypes.bool,
    history: PropTypes.array,
    currentInvoice: PropTypes.object,
    selectedInvoice: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{selectedBill: null, admin: null, history: Array, loading: boolean, currentBill: null}}
 */
const mapStateToProps = (state) => ({
    loading: state.invoices.loading,
    history: state.invoices.asoReports.history,
    admin: state.admin.meta,
    clientName: state.admin.meta.company,
    whitelabel: state.whitelabel,
    account: state.admin.account
});

export default (connect(mapStateToProps)(AsoReports));
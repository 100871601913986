import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect, Link } from "react-router-dom";
import Avatar from "react-avatar";
import * as session from './services/session.js';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Badge from "@mui/material/Badge";
import { 
    Notifications as NotificationsIcon, 
    Settings as GearIcon, 
    Menu as MenuIcon,
    ArrowDropDownSharp
} from '@mui/icons-material';
import { Theme, Loader, LsaCategoriesModal } from '@simplybenefits/sb-ui-library';

import store from './redux/store.js';
import { logout, getLsaItems } from './actions/admin.js';
import Sidebar from './components/Sidebar.js';
import MetaMenu from './components/MetaMenu.js';
import AdvisorSettingsModal from './containers/Advisor/Settings/index.js';
import AdminSettingsModal from './containers/Admin/Settings/index.js';
import ChangePassword from './containers/ChangePassword/index.js';
import AccountSettingsModal from './containers/Advisor/Accounts/Settings/index.js';
import BankingDetailsModal from './containers/Banking/index.js';
import ClassAndDivisionDetailsModal from './containers/Admin/ClassAndDivisionDetails/index.js';

import { styled } from '@mui/system';
import logoFullColorMedium from './assets/logo-full-color-medium.png';
import waterMarkLogo from './assets/logo-watermark-color.png';

const PrivateRouteContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Theme.colors.surface,
    [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start'
    },
    height: '100vh',
    overflow: 'hidden'
}));

const DrawerIcon = styled(IconButton)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
        display: 'block',
        position: 'absolute',
        top: 10,
        left: 5,
        zIndex: 1000
    }
}));

const SidebarContainer = styled('div')(({ theme }) => ({
    flex: 0.15,
    [theme.breakpoints.up(2000)]: {
        flex: 0.15
    },
    [theme.breakpoints.down(1900)]: {
        flex: 0.20
    },
    [theme.breakpoints.down(1400)]: {
        flex: 0.35
    },
    [theme.breakpoints.down(1070)]: {
        flex: 0.45
    },
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}));

const LogoContainer = styled('div')(({ theme }) => ({
    flex: 0.15,
    [theme.breakpoints.up(2000)]: {
        flex: 0.15
    },
    [theme.breakpoints.down(1900)]: {
        flex: 0.20
    },
    [theme.breakpoints.down(1400)]: {
        flex: 0.35
    },
    [theme.breakpoints.down(1070)]: {
        flex: 0.45
    },
    [theme.breakpoints.down(960)]: {
        display: 'none'
    }
}));

/**
 * 
 * @param {*} props 
 * @returns 
 */
const WaterMarkImage = (props) => {
    const WaterMarkedImage = styled('img')(({ theme }) => ({
        position: 'fixed',
        bottom: 0,
        right: 15,
        zIndex: 0,
        [theme.breakpoints.up(2000)]: {
            width: '18%'
        },
        [theme.breakpoints.down(2000)]: {
            width: '18%'
        },
        [theme.breakpoints.down(1400)]: {
            width: '20%'
        },
        [theme.breakpoints.down(1070)]: {
            width: '25%'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        maxWidth: 500,
        opacity: 0.4
    }));

    const WhiteLabelledMarkedImage = styled('img')(({ theme }) => ({
        position: 'fixed',
        bottom: 15,
        right: 25,
        zIndex: 0,
        [theme.breakpoints.up(2000)]: {
            width: '15%',
            bottom: 40,
            right: 50
        },
        [theme.breakpoints.down(2000)]: {
            width: '17%'
        },
        [theme.breakpoints.down(1400)]: {
            width: '18%'
        },
        [theme.breakpoints.down(1070)]: {
            width: '22%'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        maxWidth: 500,
        opacity: 0.4
    }));
        

    return (
        <>
            { props.whitelabel.watermarkColor ?
                <WhiteLabelledMarkedImage
                    alt={props.alt}
                    src={ props.whitelabel.watermarkColor }
                >{props.children}</WhiteLabelledMarkedImage>
                :
                <WaterMarkedImage
                    alt={props.alt}
                    src={ props.src }
                >{props.children}</WaterMarkedImage>
            }

        </>
    );
};


class PrivateRoute extends Component {
    state = {
        loading: true,
        isAuthenticated: false,
        isAdvisorRoute: false,
        mobileOpen: false,
        anchorEl: null,
        showSettingsModal: false,
        showPasswordModal: false,
        showAccountSettingsModal: false,
        showAdvisorSettingsModal: false,
        showAdminSettingsModal: false,
        showBankingDetailsModal: false,
        showClassAndDivisionDetailsModal: false,
        showLsaItemsModal: false
    };

    async componentDidMount() {
        const isAuthenticated = await this._isUserAuthenticated();

        this.setState({
            isAuthenticated,
            loading: false
        });
    }

    /**
     * Check if the account is already authenticated and get the account data
     * @returns {Promise<boolean>}
     * @private
     */
    _isUserAuthenticated = async () => {
        const isAdvisorRoute = window.location.href.indexOf('advisor') > -1;
        const token = await session.getToken();
        const userType = await session.getUserType();

        if (isAdvisorRoute) {
            this.setState({
                isAdvisorRoute: true
            });
        }

        if (!token) {
            if (isAdvisorRoute) {
                window.location.href = '/advisor/login';
            } else {
                window.location.href = '/admin/login';
            }

            return false;
        }

        const isAdvisor = Number(userType) === 1;

        // if its a advisor route and the user is not a advisor
        if (isAdvisorRoute && !isAdvisor) {
            return false;
        }

        // if its not a advisor route but the user is a advisor
        if (!isAdvisorRoute && isAdvisor) {
            return false;
        }

        return true;
    };

    hideModal = () => {
        this.setState({
            showPasswordModal: false,
            showAccountSettingsModal: false,
            showAdvisorSettingsModal: false,
            showAdminSettingsModal: false,
            showBankingDetailsModal: false,
            showClassAndDivisionDetailsModal: false,
            showLsaItemsModal: false
        });
    };

    _toggleModal = (type) => {
        this.setState({
            anchorEl: null,
            [type]: !this.state.type
        });
    };

    _handleMenuClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    _handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    _logout = () => {
        this.props.dispatch(logout());
    };

    handleDrawerToggle = () => {
        this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
    };

    render() {
        const { component: Comp, ...rest } = this.props;
        const whitelabel = store.getState().whitelabel;

        const styles = {
            header: {
                display: 'flex',
                flex: 1,
                width: '100%',
                flexDirection: 'row',
                minHeight: 70,
                alignItems: 'center',
                borderBottom: `1px solid ${ Theme.colors.lightGrey }`
            },
            body: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                backgroundColor: Theme.colors.backgroundGrey,
                width: '100%'
            },
            breadcrumbsContainer: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1
            },
            headerIconContainer: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginRight: 40
            },
            headerIcon: {
                color: Theme.colors.mainTextGrey,
                cursor: 'pointer',
                marginLeft: 20,
                fontSize: 25
            },
            headerIconStale: {
                color: Theme.colors.mainTextGrey,
                cursor: 'pointer',
                marginLeft: 0,
                fontSize: 22
            },
            drawer: {
                height: '100vh',
                position: 'relative',
                width: '100%',
                backgroundColor: Theme.colors.surface
            },
            content: {
                flex: 1,
                height: '100vh',
                overflow: 'auto',
                position: 'relative',
                paddingTop: 25
            },
            
            logo: {
                width: 'auto',
                height: 40,
                marginLeft: 40
            }
        };

        if (!this.props.advisorMeta && !this.props.adminMeta) {
            return null;
        }

        const isAdvisor = Number(session.getUserType()) === 1;
        const email = isAdvisor ? this.props.advisorMeta.email : this.props.adminMeta.email;
        const name = isAdvisor ?
            `${ this.props.advisorMeta.firstname } ${ this.props.advisorMeta.lastname }`
            :
            `${ this.props.adminMeta.firstname } ${ this.props.adminMeta.lastname }`;

        const notifications = isAdvisor ? this.props.advisorNotifications : this.props.adminNotifications;

        if (this.state.loading) {
            return (
                <Loader/>
            );
        }

        return (
            <Route
                {...rest}
                render={(props) => (
                    this.state.isAuthenticated ?
                        (
                            <PrivateRouteContainer>
                                <div style={ styles.header }>
                                    <DrawerIcon
                                        aria-label="Open drawer"
                                        onClick={this.handleDrawerToggle}
                                    >
                                        <MenuIcon />
                                    </DrawerIcon>

                                    <LogoContainer>
                                        <Link
                                            to={isAdvisor ? `/advisor/dashboard` : `/`}
                                            style={styles.titleLink}
                                        >
                                            <img
                                                alt={ `${ whitelabel.name || 'Simply Benefits' } Logo` }
                                                src={ whitelabel.logo || logoFullColorMedium }
                                                style={styles.logo}
                                            />
                                        </Link>
                                    </LogoContainer>

                                    <div style={ styles.breadcrumbsContainer } />

                                    <div style={ styles.headerIconContainer }>
                                        <Link
                                            to={`/notifications`}
                                            style={ styles.notificationBadge }
                                        >
                                            <Badge
                                                color="secondary"
                                                badgeContent={ notifications ? notifications.length : 0 }
                                                invisible={ !notifications || notifications.length === 0 }
                                                sx={{ "& .MuiBadge-badge": { fontSize: 12, fontWeight: 900 } }}
                                            >
                                                <NotificationsIcon style={styles.headerIcon} />
                                            </Badge>
                                        </Link>

                                        <GearIcon
                                            style={ { ...styles.headerIcon, marginLeft: 10 } }
                                            onClick={() => this._toggleModal(isAdvisor ? 'showAdvisorSettingsModal' : 'showAdminSettingsModal')}
                                        />

                                        <Avatar
                                            name={name}
                                            size="40"
                                            textSizeRatio={2.5}
                                            round={true}
                                            color={whitelabel.primaryColor || Theme.colors.skyBlue}
                                            onClick={this._handleMenuClick}
                                            style={styles.headerIcon}
                                        />

                                        <ArrowDropDownSharp style={styles.headerIconStale} onClick={this._handleMenuClick} />

                                        <MetaMenu
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this._handleCloseMenu}
                                            isAdvisor={isAdvisor}
                                            name={name}
                                            email={email}
                                            toggleModal={this._toggleModal}
                                            handleCloseMenu={this._handleCloseMenu}
                                            logout={this._logout}
                                            isParent={ isAdvisor ? false : this.props.adminMeta.isParent }
                                            hasSpendingAccounts={ isAdvisor ? false : this.props.adminMeta.hasSpendingAccounts }
                                        />
                                    </div>
                                </div>

                                <div style={ styles.body }>
                                    <SidebarContainer>
                                        <Drawer
                                            variant="temporary"
                                            anchor={'left'}
                                            open={this.state.mobileOpen}
                                            onClose={this.handleDrawerToggle}
                                        >
                                            <Sidebar closeSideBar={this.handleDrawerToggle} />
                                        </Drawer>
                                        <Drawer
                                            // classes={{ paper: classes.drawer }}
                                            variant="permanent"
                                            open
                                            sx={ { [`& .MuiDrawer-paper`]: { height: '100vh', position: 'relative', backgroundColor: Theme.colors.surface, ...styles.drawer } }}
                                        >
                                            <Sidebar />
                                        </Drawer>
                                    </SidebarContainer>

                                    <div style={styles.content}>
                                        <Comp {...props} />

                                        <WaterMarkImage
                                            alt="Watermark Logo"
                                            src={ waterMarkLogo }
                                            whitelabel={ whitelabel }
                                        />
                                    </div>
                                </div>

                                {
                                    isAdvisor ?
                                        <AdvisorSettingsModal
                                            hideModal={this.hideModal}
                                            showModal={this.state.showAdvisorSettingsModal} /*  This is set in ui-library - TODO: remove from ui-library*/
                                        />
                                        :
                                        <AdminSettingsModal
                                            hideModal={this.hideModal}
                                            showModal={this.state.showAdminSettingsModal} /*  This is set in ui-library - TODO: remove from ui-library*/
                                        />
                                }

                                {
                                    this.state.showPasswordModal &&
                                    <ChangePassword
                                        hideModal={this.hideModal}
                                        isAdvisor={isAdvisor}
                                    />
                                }

                                {
                                    this.state.showAccountSettingsModal &&
                                    <AccountSettingsModal
                                        hideModal={this.hideModal}
                                        isAdvisor={isAdvisor}
                                    />
                                }

                                {
                                    this.state.showBankingDetailsModal &&
                                    <BankingDetailsModal
                                        hideModal={this.hideModal}
                                        isAdvisor={isAdvisor}
                                    />
                                }

                                {
                                    this.state.showPasswordModal &&
                                        <ChangePassword
                                            hideModal={this.hideModal}
                                            isAdvisor={isAdvisor}
                                        />
                                }

                                {
                                    this.state.showAccountSettingsModal &&
                                        <AccountSettingsModal
                                            hideModal={this.hideModal}
                                            isAdvisor={isAdvisor}
                                        />
                                }

                                {
                                    this.state.showBankingDetailsModal && 
                                        <BankingDetailsModal
                                            hideModal={this.hideModal}
                                            isAdvisor={isAdvisor}
                                        />
                                }

                                {
                                    (!isAdvisor && this.state.showClassAndDivisionDetailsModal) &&
                                        <ClassAndDivisionDetailsModal
                                            showModal={ this.state.showClassAndDivisionDetailsModal }
                                            hideModal={ this.hideModal }
                                        />
                                }

                                {
                                    this.state.showLsaItemsModal &&
                                        <LsaCategoriesModal
                                            open={ this.state.showLsaItemsModal }
                                            getItems={ () => this.props.dispatch(getLsaItems()) }
                                            close={ this.hideModal }
                                            whitelabel={ whitelabel || {} }
                                        />
                                }

                                {
                                    (this.props.loader?.isLoading && this.props.loader.type === 'full') &&
                                        <Loader/>
                                }
                            </PrivateRouteContainer>
                        )
                        :
                        (
                            <Redirect
                                to={ {
                                    pathname: this.state.isAdvisorRoute ? '/advisor/login' : '/admin/login',
                                    state: { from: props.location }
                                } }
                            />
                        )
                )}
            />
        );
    }
}

PrivateRoute.propTypes = {
    advisorMeta: PropTypes.object,
    adminMeta: PropTypes.object
};

/**
 * @param state
 * @returns {{advisorMeta: null, adminMeta: null}}
 */
const mapStateToProps = (state) => ({
    advisorMeta: state.advisor.meta,
    adminMeta: state.admin.meta,
    advisorNotifications: state.advisor.notifications,
    adminNotifications: state.admin.notifications,
    loader: state.loader
});

export default (connect(mapStateToProps)(PrivateRoute));
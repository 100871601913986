import initialState from '../initialState.js';
import {
    SAVE_WHITELABEL
} from '../../actions/types/whitelabel';

export default (state = initialState.whitelabel, action) => {
    switch (action.type) {
        case SAVE_WHITELABEL: {
            return {
                ...state,
                ...action.data
            };
        }

        default:
            return state;
    }
};
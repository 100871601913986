import request from './request.js';

/**
 * @param body
 * @returns {Promise<any>}
 */
function createQuote(body) {
    const options = {
        endpoint: '/advisor/quote',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param quoteID
 * @param body
 * @returns {Promise<unknown>}
 */
function editQuote(quoteID, body) {
    const options = {
        endpoint: `/advisor/quote/${ quoteID }`,
        method: 'PUT',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function convertQuote(quoteID) {
    const options = {
        endpoint: `/advisor/quote/${ quoteID }/convert`,
        method: 'POST'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function getQuote(quoteID) {
    const options = {
        endpoint: `/advisor/quote/${ quoteID }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param planType
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function savePlanType(planType, quoteID) {
    const options = {
        method: 'PUT',
        endpoint: `/advisor/quote/${ quoteID }/plan/type`,
        body: {
            planType
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param classID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function removeClass(classID, quoteID) {
    const options = {
        endpoint: `/advisor/quote/${ quoteID }/class/${ classID }`,
        method: 'DELETE'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param divisionID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function removeDivision(divisionID, quoteID) {
    const options = {
        endpoint: `/advisor/quote/${ quoteID }/division/${ divisionID }`,
        method: 'DELETE'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    createQuote,
    editQuote,
    getQuote,
    savePlanType,
    removeClass,
    removeDivision,
    convertQuote
};
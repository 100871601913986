import React from 'react';
import PropTypes from 'prop-types';
import * as dates from '@simplybenefits/sb-dates';
import { helpers } from '@simplybenefits/sb-utils';
import { Theme, i18n, tokens, localizationMaps, Text } from "@simplybenefits/sb-ui-library";
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import TableContainer from '@mui/material/TableContainer/TableContainer';

/**
 * @param value
 * @param field
 * @private
 */
function _getValue(value, field) {
    if (field === localizationMaps.fromEnglish("journey", 'Shares')) {
        return `${ Number(value).toFixed(2) }%`;
    }

    if (field === localizationMaps.fromEnglish("journey", 'Salary') || field === localizationMaps.fromEnglish("journey", 'Hourly Wage')) {
        return helpers.formatNumber(value, 2, true);
    }

    if (field === localizationMaps.fromEnglish("journey", 'Birth Date') || field === localizationMaps.fromEnglish("journey", 'Hire Date')) {
        return dates.getStartOfDay(value, 'll', i18n.locale);
    }

    return value;
}

/**
 * @param report
 * @returns {*}
 * @constructor
 */
const CompletedChangesTable = ({ records }) => {
    const styles = {
        tableHeading: {
            whiteSpace: "nowrap",
            fontSize: Theme.fonts.size.medium,
            fontFamily: Theme.fonts.type.heading,
            backgroundColor: Theme.colors.surface,
            paddingLeft: "2vw",
            color: Theme.colors.mainTextGrey,
            height: '62px',
            border: 'none'
        },
        tableCell: {
            height: '62px',
            fontFamily: Theme.fonts.type.light,
            paddingLeft: "2vw",
            color: Theme.colors.mainTextGrey,
            backgroundColor: Theme.colors.surface,
            borderTop: `1px solid ${ Theme.colors.lightGrey }`,
            borderBottom: 'none' 

        },
        tableContainer: {
            maxHeight: '620px', 
            boxShadow: `0 0 3px 0 rgba(151, 151, 151, 0.5)`,
            overflowY: 'auto'
        }
    };

    if (!records || records.length === 0) {
        return (
            <Text light small >
                { i18n.t(tokens.labels.admin.noCompletedChanges) }
            </Text>
        );
    }
    
    return (
        <TableContainer style={ styles.tableContainer }>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={ styles.tableHeading }>
                            { i18n.t(tokens.labels.type) }
                        </TableCell>

                        <TableCell style={{ ...styles.tableHeading }}>
                            { i18n.t(tokens.labels.admin.member) }
                        </TableCell>

                        <TableCell style={{ ...styles.tableHeading }}>
                            { i18n.t(tokens.labels.admin.field) }
                        </TableCell>

                        <TableCell style={{ ...styles.tableHeading }}>
                            { i18n.t(tokens.labels.admin.oldValue) }
                        </TableCell>

                        <TableCell style={{ ...styles.tableHeading }}>
                            { i18n.t(tokens.labels.admin.newValue) }
                        </TableCell>

                        <TableCell style={{ ...styles.tableHeading }}>
                            { i18n.t(tokens.labels.admin.changedBy) }
                        </TableCell>

                        <TableCell style={{ ...styles.tableHeading, paddingRight: '1vw' }}>
                            { i18n.t(tokens.labels.effectiveDate) }
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        records.map((item, index) => (
                            <TableRow key={item.occurred + index}>
                                <TableCell style={{ ...styles.tableCell, fontFamily: Theme.fonts.type.medium, width: '15%' }}>
                                    { item.type }
                                </TableCell>

                                <TableCell style={{ ...styles.tableCell, width: '20%' }}>
                                    { item.user }
                                </TableCell>

                                <TableCell style={{ ...styles.tableCell, width: '10%' }}>
                                    { item.field || 'N/A' }
                                </TableCell>

                                <TableCell style={{ ...styles.tableCell, width: '15%' }}>
                                    { item.oldValue ? _getValue(item.oldValue, item.field) : 'N/A' }
                                </TableCell>

                                <TableCell style={{ ...styles.tableCell, width: '15%' }}>
                                    { item.newValue ? _getValue(item.newValue, item.field) : 'N/A' }
                                </TableCell>

                                <TableCell style={{ ...styles.tableCell, width: '15%' }}>
                                    { item.changedBy }
                                </TableCell>

                                <TableCell style={{ ...styles.tableCell, width: '10%' }}>
                                    { dates.getUTC(item.effectiveDate, 'll', i18n.locale) }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

CompletedChangesTable.propTypes = {
    records: PropTypes.array
};

export default (CompletedChangesTable);
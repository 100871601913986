import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    Loader,
    Text,
    Title,
    ContainerControlSite,
    Content,
    i18n,
    tokens,
    Bubble
} from '@simplybenefits/sb-ui-library';
import PendingChangesTable from './PendingChangesTable.js';
import CompletedChangesTable from './CompletedChangesTable.js';
import RequestedChangesTable from './RequestedChangesTable.js';
import { getPendingChanges, removePendingChange, getChangelog } from '../../../actions/admin.js';
import { getChangeRequests } from '../../../actions/changeRequests.js';
import * as notifier from '../../../actions/notifier.js';

/**
 * Component for managing member changes with different types of change tables.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} props.dispatch - Redux dispatch function
 * @param {boolean} props.loader - Loading state from Redux store
 * @returns {JSX.Element} MemberChanges component
 */
const MemberChanges = ({ dispatch, loader }) => {
    const [ tableType, setTableType ] = useState('requestedChanges');
    const [ requestedChanges, setRequestedChanges ] = useState([]);
    const [ cursor, setCursor ] = useState(0);
    const [ pendingChanges, setPendingChanges ] = useState([]);
    const [ completedChanges, setCompletedChanges ] = useState([]);
    const [ changeToDelete, setChangeToDelete ] = useState(null);

    /**
     * Fetches change requests from the server.
     *
     * @private
     * @returns {Promise<void>}
     */
    const fetchChangeRequests = async () => {
        const result = await dispatch(getChangeRequests(cursor));
        const items = cursor > 0 ? [ ...requestedChanges, ...result.items ] : result.items;
        setRequestedChanges(items);
        setCursor(result.cursor);
    };

    useEffect(() => {

        /**
         * Fetches all types of change table items on component mount.
         *
         * @private
         * @returns {void}
         */
        const getAllChangeTableItems = async () => {
            const pending = await dispatch(getPendingChanges());
            const completed = await dispatch(getChangelog());
            await fetchChangeRequests();
            setPendingChanges(pending || []);
            setCompletedChanges(completed || []);
        };

        const queryString = window.location.search;
        if (queryString.includes('success')) {
            dispatch(notifier.displayMessage(i18n.t(tokens.labels.changeRequests.changeRequestSuccessfullySent)));
        }

        getAllChangeTableItems();
    }, []);

    /**
     * Handles pending change removal.
     *
     * @param {string} changeID - ID of the change to remove
     * @returns {Promise<void>}
     */
    const _removePendingChangeHandler = async (changeID) => {
        if (changeID) {
            setChangeToDelete(changeID);
            return;
        }
        
        const success = await dispatch(removePendingChange(changeToDelete));
        if (success) {
            const pending = await dispatch(getPendingChanges());
            setPendingChanges(pending || []);
            setChangeToDelete(null);
        }
    };

    /**
     * Handles table type change.
     *
     * @param {string} type - Type of table to switch to
     * @param {string} event - Event triggering the change
     * @returns {void}
     */
    const _handleInputChange = (type, event) => {
        if (type === 'tableType') {
            setTableType(event);
        }
    };

    if (!pendingChanges) {
        return <Loader />;
    }

    return (
        <ContainerControlSite>
            <Content>
                <Title title={i18n.t(tokens.titles.viewMemberChanges)} />
                <Text mediumLarge bold>{i18n.t(tokens.labels.admin.changeTypes)}</Text>

                <div style={{ display: 'flex', flexDirection: 'row', gap: '25px' }}>
                    <Bubble
                        label={i18n.t(tokens.labels.amendments.requestedChanges)}
                        clickable
                        isSelected={tableType === 'requestedChanges'}
                        onClick={() => _handleInputChange('tableType', 'requestedChanges')}
                    />

                    <Bubble
                        label={i18n.t(tokens.labels.admin.pendingChanges)}
                        clickable
                        isSelected={tableType === 'pendingChanges'}
                        onClick={() => _handleInputChange('tableType', 'pendingChanges')}
                    />

                    <Bubble
                        label={i18n.t(tokens.labels.admin.completedChanges)}
                        clickable
                        isSelected={tableType === 'completedChanges'}
                        onClick={() => _handleInputChange('tableType', 'completedChanges')}
                    />
                </div>

                {tableType === 'requestedChanges' && (
                    <RequestedChangesTable 
                        loadMoreRequestedChanges={fetchChangeRequests}
                        requestedChanges={requestedChanges} 
                    />
                )}

                {tableType === 'pendingChanges' && (
                    <PendingChangesTable records={pendingChanges} removeChange={_removePendingChangeHandler} />
                )}

                {tableType === 'completedChanges' && (
                    <CompletedChangesTable records={completedChanges} />
                )}
            </Content>

            <Modal
                open={changeToDelete !== null}
                onClose={() => setChangeToDelete(null)}
                title={i18n.t(tokens.titles.areYouSure)}
                primaryButton={{
                    label: i18n.t(tokens.buttons.removeChange),
                    onClick: () => _removePendingChangeHandler(false)
                }}
                loader={ loader }
            >
                <Text medium margin="10px 0 0 0">
                    {i18n.t(tokens.labels.admin.scheduledChangeWillNotTakePlace)}
                </Text>
            </Modal>
        </ContainerControlSite>
    );
};

/**
 * Maps Redux state to component props.
 *
 * @param {Object} state - Redux state
 * @returns {Object} Props for the connected component
 */
const mapStateToProps = (state) => ({
    loader: state.loader
});

export default connect(mapStateToProps)(MemberChanges);
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { Text, Theme, Icons, i18n, tokens } from '@simplybenefits/sb-ui-library';
import Avatar from "react-avatar";
import * as session from '../services/session.js';
import store from '../redux/store.js';
import Icon from '@mui/material/Icon';

/**
 * 
 * @param {*} props 
 * @returns custom link component to avoid repeating styles
 */
const LinkStyled = (props) => (
    <Link style={{ 
        textDecoration: 'none',
        marginTop: "2.5px",
        marginBottom: "2.5px", 
        display: "flex", 
        justifyContent: "center", 
        cursor: 'pointer', 
        backgroundColor: props.selected && Theme.colors.backgroundGrey
    }} 
    to={ props.to } 
    onClick={ props.onClick }>
        <ListItem button sx={{ 
            py: "12px",
            px: "16px",
            minWidth: 250,
            "&:hover": {
                backgroundColor: props.selected && Theme.colors.lightHoverGrey
            }
        }}>
            { props.children }
        </ListItem>
    </Link>
);

/**
 * 
 * @param {*} props 
 * @returns 
 */
const Sidebar = (props) => {
    const [ state, setState ] = useState({
        selectedScreen: 'dashboard',
        supportModal: false
    });

    let selectedScreen = '';
    const href = window.location.href;

    const isAdvisor = props.isAdvisorTest || Number(session.getUserType()) === 1;
    const isParentAdmin = Boolean(props?.adminMeta?.isParent);

    useEffect(() => {
        if (href.includes('employees')) {
            selectedScreen = 'employees';
        } else if (href.includes('invoice')) {
            selectedScreen = 'invoices';
        } else if (href.includes('aso-reporting')) {
            selectedScreen = 'aso-reporting';
        } else if (href.includes('usage')) {
            selectedScreen = 'usage';
        } else if (href.includes('pla')) {
            selectedScreen = 'plan-coverage';
        } else if (href.includes('administrators')) {
            selectedScreen = 'administrators';
        } else if (href.includes('resource')) {
            selectedScreen = 'resources';
        } else if (href.includes('report')) {
            selectedScreen = 'reports';
        } else if (href.includes('comm')) {
            selectedScreen = 'commissions';
        } else if (href.includes('quotes')) {
            selectedScreen = 'quotes';
        } else if ((href.includes('pending') || href.includes('change')) && !isAdvisor) {
            selectedScreen = 'employees';
        } else if (href.includes('pending')) {
            selectedScreen = 'pending-accounts';
        } else if (href.includes('active')) {
            selectedScreen = 'active-accounts';
        } else if (href.includes('files') && (isAdvisor || isParentAdmin)) {
            selectedScreen = 'files';
        } else {
            selectedScreen = 'dashboard';
        }
    
        setState({
            ...state,
            selectedScreen
        });

    }, [ href ]);

    /**
     * 
     * @param {\} selectedScreen 
     */
    const _selectItem = (screen) => {
        setState({
            ...state,
            selectedScreen: screen
        });

        if (props.closeSideBar) {
            props.closeSideBar();
        }
    };

    const whitelabel = store.getState().whitelabel;

    if (!props.advisorMeta && !props.adminMeta) {
        return null;
    }
    
    const email = isAdvisor ? props.advisorMeta.email : props.adminMeta.email;
    const name = isAdvisor ?
        `${ props.advisorMeta.firstname } ${ props.advisorMeta.lastname }`
        :
        `${ props.adminMeta.firstname } ${ props.adminMeta.lastname }`;

    const styles = {
        sidebar: {
            backgroundColor: Theme.colors.surface,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
        },
        sidebarHeader: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: "100%",
            minHeight: 250,
            padding: 10
        },
        sidebarHeaderWatermark: {
            alignSelf: 'flex-end',
            position: 'absolute',
            zIndex: 9,
            bottom: 0,
            right: 0,
            width: 180,
            opacity: 0.4
        },
        sidebarBody: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: Theme.colors.surface,
            flex: 1,
            padding: '10px 30px',
            overflowY: 'auto'
        },
        listItem: {
            margin: '5px 0px',
            cursor: 'pointer'
        },
        selected: {
            backgroundColor: Theme.colors.backgroundGrey
        },
        icon: {
            color: Theme.colors.mainTextGrey
        }
    };
        

    return (
        <div style={ styles.sidebar }>
            <div style={ styles.sidebarHeader }>
                <Avatar
                    name={name}
                    size="100"
                    textSizeRatio={3}
                    round={true}
                    color={whitelabel.primaryColor || Theme.colors.skyBlue}
                    style={{ marginBottom: 20 }}
                />

                <Text medium>{ i18n.t(tokens.labels.dashboard.welcome) },</Text>
                <Text large bold>{ name }</Text>
                <Text medium>{ email }</Text>
            </div>

            <Divider variant="middle" style={{ margin: 21 }}/>

            <div style={ styles.sidebarBody }>
                <List style={{ display: "flex", flexDirection: "column" }}>
                    <LinkStyled
                        to={isAdvisor ? '/advisor' : '/'}
                        onClick={() => _selectItem('dashboard')}
                        selected={ state.selectedScreen === 'dashboard' }
                    >
                        <ListItemIcon>
                            <Icon style={ styles.icon }>
                                { Icons.Dashboard }
                            </Icon>
                        </ListItemIcon>
                        <Text avMedium>{ i18n.t(tokens.titles.dashboard) }</Text>
                    </LinkStyled>

                    {
                        isAdvisor ?
                            <React.Fragment>
                                <LinkStyled
                                    to={'/advisor/quotes'}
                                    onClick={() => _selectItem('quotes')}
                                    selected={ state.selectedScreen === 'quotes' }
                                >
                                    <ListItemIcon>
                                        <Icon style={ styles.icon }>
                                            { Icons.Quotes }
                                        </Icon>
                                    </ListItemIcon>
                                    <Text avMedium>{ i18n.t(tokens.titles.quotes) }</Text>
                                </LinkStyled>

                                <LinkStyled
                                    to={'/advisor/accounts/pending'}
                                    onClick={() => _selectItem('pending-accounts')}
                                    selected={ state.selectedScreen === 'pending-accounts' }
                                >
                                    <ListItemIcon>
                                        <Icon style={ styles.icon }>
                                            { Icons['Pending Accounts'] }
                                        </Icon>
                                    </ListItemIcon>
                                    <Text avMedium>{ i18n.t(tokens.titles.pendingAccounts) }</Text>
                                </LinkStyled>

                                <LinkStyled
                                    to={'/advisor/accounts/active'}
                                    onClick={() => _selectItem('active-accounts')}
                                    selected={ state.selectedScreen === 'active-accounts' }
                                >
                                    <ListItemIcon>
                                        <Icon style={ styles.icon }>
                                            { Icons['Active Accounts'] }
                                        </Icon>
                                    </ListItemIcon>
                                    <Text avMedium>{ i18n.t(tokens.titles.activeAccounts) }</Text>
                                </LinkStyled>

                                <LinkStyled
                                    to={'/advisor/commissions'}
                                    onClick={() => _selectItem('commissions')}
                                    selected={ state.selectedScreen === 'commissions' }
                                >
                                    <ListItemIcon>
                                        <Icon style={ styles.icon }>
                                            { Icons.Commissions }
                                        </Icon>
                                    </ListItemIcon>
                                    <Text avMedium>{ i18n.t(tokens.titles.commissions) }</Text>
                                </LinkStyled>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <LinkStyled
                                    to={'/members'}
                                    onClick={() => _selectItem('employees')}
                                    selected={ state.selectedScreen === 'employees' }
                                >
                                    <ListItemIcon>
                                        <Icon style={ styles.icon }>
                                            { Icons.Employees }
                                        </Icon>
                                    </ListItemIcon>
                                    <Text avMedium>{ i18n.t(tokens.labels.admin.members) }</Text>
                                </LinkStyled>

                                <LinkStyled
                                    to={'/invoices'}
                                    onClick={() => _selectItem('invoices')}
                                    selected={ state.selectedScreen === 'invoices' }

                                >
                                    <ListItemIcon>
                                        <Icon style={ styles.icon }>
                                            { Icons.Invoices }
                                        </Icon>
                                    </ListItemIcon>
                                    <Text avMedium>{ i18n.t(tokens.titles.invoices) }</Text>
                                </LinkStyled>

                                {
                                    (props.account.asoType === 'Budgeted' && (props.account.isAsoDental === 1 || props.account.isAsoHealth === 1)) &&
                                        <LinkStyled
                                            to={'/aso'}
                                            onClick={() => _selectItem('aso-reporting')}
                                            selected={ state.selectedScreen === 'aso-reporting' }

                                        >
                                            <ListItemIcon>
                                                <Icon style={ styles.icon }>
                                                    { Icons['ASO Reporting'] }
                                                </Icon>
                                            </ListItemIcon>
                                            <Text avMedium>{ i18n.t(tokens.labels.admin.asoReporting) }</Text>
                                        </LinkStyled>
                                }

                                <LinkStyled
                                    to={'/reports'}
                                    onClick={() => _selectItem('reports')}
                                    selected={ state.selectedScreen === 'reports' }
                                >
                                    <ListItemIcon>
                                        <Icon style={ styles.icon }>
                                            { Icons.Reports }
                                        </Icon>
                                    </ListItemIcon>
                                    <Text avMedium>{ i18n.t(tokens.labels.admin.reports) }</Text>
                                </LinkStyled>

                                <LinkStyled
                                    to={'/plan'}
                                    onClick={() => _selectItem('plan-coverage')}
                                    selected={ state.selectedScreen === 'plan-coverage' }
                                >
                                    <ListItemIcon>
                                        <Icon style={ styles.icon }>
                                            { Icons['Plan Overview'] }
                                        </Icon>
                                    </ListItemIcon>
                                    <Text avMedium>{ i18n.t(tokens.labels.admin.planCoverage) }</Text>
                                </LinkStyled>

                                {
                                    Boolean(props.adminMeta.isParent) &&
                                        <LinkStyled
                                            to={'/administrators'}
                                            onClick={() => _selectItem('administrators')}
                                            selected={ state.selectedScreen === 'administrators' }
                                        >
                                            <ListItemIcon>
                                                <Icon style={ styles.icon }>
                                                    { Icons.Administrators }
                                                </Icon>
                                            </ListItemIcon>
                                            <Text avMedium>{ i18n.t(tokens.labels.admin.administrators) }</Text>
                                        </LinkStyled>
                                }
                            </React.Fragment>
                    }

                    {
                        (isAdvisor || Boolean(props.adminMeta.isParent)) &&
                            <LinkStyled
                                to={isAdvisor ? '/advisor/files' : '/files'}
                                onClick={() => _selectItem('files')}
                                selected={ state.selectedScreen === 'files' }

                            >
                                <ListItemIcon>
                                    <Icon style={ styles.icon }>
                                        { Icons.Files }
                                    </Icon>
                                </ListItemIcon>
                                <Text avMedium>{ i18n.t(tokens.titles.files) }</Text>
                            </LinkStyled>
                    }

                    <Divider variant="middle" style={{ margin: 20 }}/>

                    <LinkStyled
                        to={isAdvisor ? '/advisor/resources' : '/resources'}
                        onClick={() => _selectItem('resources')}
                        selected={ state.selectedScreen === 'resources' }

                    >
                        <ListItemIcon>
                            <Icon style={ styles.icon }>
                                { Icons.Resources }
                            </Icon>
                        </ListItemIcon>
                        <Text avMedium>{ i18n.t(tokens.titles.resources) }</Text>
                    </LinkStyled>
                </List>
            </div>
        </div>
    );
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    advisorMeta: state.advisor.meta,
    adminMeta: state.admin.meta,
    account: state.admin.account
});

export default connect(mapStateToProps)(Sidebar);
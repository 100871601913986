export const BANKING_DETAILS_VERIFYING = 'advisor/BANKING_DETAILS_VERIFYING';
export const GET_ADVISOR_SUCCESS = 'advisor/GET_ADVISOR_SUCCESS';
export const UPDATE_ADVISOR_SETTINGS_SUCCESS = 'advisor/UPDATE_ADVISOR_SETTINGS_SUCCESS';
export const CREATE_QUOTE_SUCCESS = 'advisor/CREATE_QUOTE_SUCCESS';
export const GET_ACCOUNT_SUCCESS = 'advisor/GET_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS = 'advisor/UPDATE_ACCOUNT_SETTINGS_SUCCESS';
export const SET_ACCOUNT_LIVE = 'advisor/SET_ACCOUNT_LIVE';
export const PAYMENT_DETAILS_SUCCESS = 'advisor/PAYMENT_DETAILS_SUCCESS';
export const REMOVE_NOTIFICATION_SUCCESS = 'advisor/REMOVE_NOTIFICATION_SUCCESS';
export const SET_STATE = 'advisor/SET_STATE';
export const SET_PLAN_TYPE = 'advisor/SET_PLAN_TYPE';
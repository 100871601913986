import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import * as dates from '@simplybenefits/sb-dates';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody";
import Grid from "@mui/material/Grid";
import { DatePicker, Text, TextInput, Theme, Title, ContainerControlSite, Content, i18n, tokens, localizationMaps, Services } from "@simplybenefits/sb-ui-library";
import { helpers } from '@simplybenefits/sb-utils';
import { getCommissionsHistory } from '../../../actions/advisor.js';
import store from '../../../redux/store.js';
import { styled } from '@mui/system';

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main
}));

class Commissions extends Component {
    state = {
        records: [],
        totalPaid: 0,
        totalPending: 0,
        activeAccounts: [ i18n.t(tokens.dropdowns.commission.all) ],
        filters: {
            dateRange: {
                startDate: moment().startOf('year'),
                endDate: moment()
            },
            account: i18n.t(tokens.dropdowns.commission.all),
            status: i18n.t(tokens.dropdowns.commission.all)
        }
    };

    async componentDidMount() {
        const data = await this.props.dispatch(getCommissionsHistory({
            dateRange: {
                startDate: null,
                endDate: null
            },
            account: 'All',
            status: 'All'
        }));

        const activeAccounts = [];

        this.props.accounts.map((account) => {
            if (account.status === 1) {
                activeAccounts.push(account.company);
            }

            return null;
        });

        // sort the active accounts alphabetically
        activeAccounts.sort();
        activeAccounts.unshift(i18n.t(tokens.dropdowns.commission.all));

        if (data) {
            this.setState({
                records: data.records || [],
                totalPaid: data.totalPaid || 0,
                totalPending: data.totalPending || 0,
                activeAccounts
            });
        }
    }

    _getHistory = async () => {
        // Translate filters to English before sending request
        const data = await this.props.dispatch(getCommissionsHistory({
            ...this.state.filters,
            status: localizationMaps.toEnglish('commissionStatus', this.state.filters.status),
            account: localizationMaps.toEnglish('commissionStatus', this.state.filters.account)
        }));

        if (data) {
            this.setState({
                records: data.records || [],
                totalPaid: data.totalPaid || 0,
                totalPending: data.totalPending || 0
            });
        }
    };

    /**
     * @param x
     * @returns {string}
     */
    _numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    _setFilter = async (type, value) => {
        await this.setState({
            filters: {
                ...this.state.filters,
                [type]: value
            }
        });

        this._getHistory();
    };

    /**
     * Used in the datepicker to set local state
     * @param startDate
     * @param endDate
     */
    _submitDate = (dateRange) => {
        this._setFilter('dateRange', {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        });
    };

    render() {
        const whitelabel = store.getState().whitelabel;
        const styles = {
            contentBlock: {
                backgroundColor: Theme.colors.surface,
                boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
                display: 'block',
                margin: 'auto auto 30px auto',
                padding: '30px',
                position: 'relative',
                zIndex: Theme.zIndex.container,
                marginTop: "30px"
            },
            titleBlock: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center'
            },
            scrollbar: {
                '&::WebkitScrollbar': {
                    height: '15px',
                    width: '15px'
                },
                '&::WebkitScrollbarThumb': {
                    backgroundColor: Theme.colors.surface,
                    borderRadius: '10px',
                    boxShadow: 'inset 0 0 2px rgba(0, 0, 0, .5)'
                },
                '&::WebkitScrollbarTrack': {
                    backgroundColor: Theme.colors.backgroundGrey,
                    borderRadius: '10px',
                    boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.2)'
                }
            },
            tableHeading: {
                fontFamily: Theme.fonts.type.medium,
                paddingTop: "8.25px",
                paddingBottom: "8.25px",
                paddingRight: "2vw",
                paddingLeft: "2vw"
            },
            tableCell: {
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingRight: "2vw",
                paddingLeft: "2vw"
            },
            totalBlock: {
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                backgroundColor: Theme.colors.surface,
                boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
                padding: '30px',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center'
            }
        };

        return (
            <ContainerControlSite>
                <Content>
                    <Title title={ i18n.t(tokens.titles.commissions) } />

                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                        <div style={{ ...styles.totalBlock, marginRight: 5 }}>
                            <Text mediumLarge customStyle={{ flex: 1 }}>{ i18n.t(tokens.labels.advisor.pendingCommissions) }</Text>
                            <Text extraLarge bold color={ whitelabel.primaryColor || Theme.colors.skyBlue }>{`$${ this._numberWithCommas(this.state.totalPending.toFixed(2)) }`}</Text>
                        </div>

                        <div style={{ ...styles.totalBlock, marginLeft: 5 }}>
                            <Text mediumLarge customStyle={{ flex: 1 }}>{ i18n.t(tokens.labels.advisor.paidCommissions) }</Text>
                            <Text extraLarge bold color={ Theme.colors.brandGreen }>{`$${ this._numberWithCommas(this.state.totalPaid.toFixed(2)) }`}</Text>
                        </div>
                    </div>

                    <div style={{ ...styles.contentBlock, marginTop: 10 }}>
                        <Text mediumLarge bold>{ i18n.t(tokens.labels.advisor.filters) }</Text>

                        <div style={ styles.titleBlock }>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={12} sm={6} lg={3} xl={4}>
                                    <TextInput
                                        label={ i18n.t(tokens.labels.advisor.accounts) }
                                        variant="outlined"
                                        value={ this.state.filters.account || i18n.t(tokens.dropdowns.commission.all) }
                                        onChange={(e) => this._setFilter('account', e.target.value)}
                                        select
                                        selectOptions={ this.state.activeAccounts }
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} xl={4} sx={{ marginTop: "16px" }}>
                                    <DatePicker
                                        startDate={this.state.filters.dateRange.startDate}
                                        endDate={this.state.filters.dateRange.endDate}
                                        submitDate={this._submitDate}
                                        noSaveButton={true}
                                        customStyle={{ width: "100%" }}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} sm={6} lg={3} xl={4}>
                                    <TextInput
                                        label={ i18n.t(tokens.labels.advisor.status) }
                                        variant="outlined"
                                        value={ this.state.filters.status || i18n.t(tokens.dropdowns.commission.all) }
                                        onChange={(e) => this._setFilter('status', e.target.value)}
                                        select
                                        selectOptions={ Services.selectFieldOptions.commissionStatusOptions() }
                                        fullWidth
                                    />
                                </Grid>


                            </Grid>
                        </div>


                        {
                            this.state.records.length === 0 ?
                                <Text mediumLarge margin="20px 0 0" align="center">{ i18n.t(tokens.labels.advisor.noCommissionRecords) }</Text>
                                :
                                <div style={{ ...styles.scrollbar, overflow: "auto", maxHeight: 1200, marginTop: 20 }}>
                                    <Table>
                                        <StyledTableHead>
                                            <TableRow sx={{ maxHeight: "40px" }}>
                                                <TableCell sx={styles.tableHeading}>
                                                    <Text small bold color={ Theme.colors.surface }>{ i18n.t(tokens.labels.advisor.account) }</Text>
                                                </TableCell>

                                                <TableCell sx={styles.tableHeading}>
                                                    <Text small bold color={ Theme.colors.surface }>{ i18n.t(tokens.labels.advisor.amount) }</Text>
                                                </TableCell>

                                                <TableCell sx={styles.tableHeading}>
                                                    <Text small bold color={ Theme.colors.surface }>{ i18n.t(tokens.labels.advisor.status) }</Text>
                                                </TableCell>

                                                <TableCell sx={styles.tableHeading}>
                                                    <Text small bold color={ Theme.colors.surface }>{ i18n.t(tokens.labels.advisor.paidDate) }</Text>
                                                </TableCell>

                                                <TableCell sx={styles.tableHeading}>
                                                    <Text small bold color={ Theme.colors.surface }>{ i18n.t(tokens.labels.advisor.dateAdded) }</Text>
                                                </TableCell>
                                            </TableRow>
                                        </StyledTableHead>

                                        <TableBody>
                                            {
                                                this.state.records.map((item, index) => {
                                                    let backgroundObject = { border: "none" };
                                                    if (index % 2 !== 0) {
                                                        backgroundObject = { backgroundColor: Theme.colors.backgroundGrey, border: "none" };
                                                    }

                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                                                <Text small>{ item.company }</Text>
                                                            </TableCell>

                                                            <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                                                <Text small>{ `$ ${ this._numberWithCommas(item.total.toFixed(2)) }` }</Text>
                                                            </TableCell>

                                                            <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                                                <Text small>{ localizationMaps.fromEnglish("commissionStatus", helpers.capitalizeFirstLetter(item.status)) }</Text>
                                                            </TableCell>

                                                            <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                                                <Text small>{ item.paidDate ? dates.getStartOfDay(item.paidDate, 'll', i18n.locale) : '-' }</Text>
                                                            </TableCell>

                                                            <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                                                <Text small>{ dates.getStartOfDay(item.dateAdded, 'll', i18n.locale) }</Text>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                        }
                    </div>
                </Content>

                <span id="commissions" />
            </ContainerControlSite>
        );
    }
}

/**
 * @param state
 * @returns {{loading: *}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    accounts: state.advisor.accounts
});

export default (connect(mapStateToProps)(Commissions));
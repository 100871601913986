import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Loader, ContainerControlSite } from '@simplybenefits/sb-ui-library';
import Gauge from './Gauge.js';
import Bar from './Bar.js';
import store from '../../../redux/store.js';

class Metrics extends PureComponent {

    render() {
        const whitelabel = store.getState().whitelabel;
        const styles = {
            wrapper: {
                margin: '20px 10px 0 10px',
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '500px',
                maxWidth: '564px'
            },
            fullWidth: {
                maxWidth: '1150px'
            },
            dataBlock: {
                flex: 1,
                height: '400px',
                display: 'flex',
                padding: "30px",
                flexWrap: 'wrap',
                boxShadow: '0 1px 2px rgba(0, 0, 0, .15)',
                justifyContent: 'center',
                backgroundColor: '#FFFFFF'
            },
            dataRow: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap'
            }
        };

        return (
            <ContainerControlSite>
                <div style={styles.dataRow} >
                    <div style={styles.wrapper}>
                        <div style={styles.dataBlock} >
                            <Text large>App Utilization</Text>
                            <Gauge id={1} setInterval startValue={50} />
                        </div>
                    </div>

                    <div style={styles.wrapper}>
                        <div style={styles.dataBlock} >
                            <Text large>Active Users (Weekly)</Text>
                            <Gauge id={2} startValue={82} />
                        </div>
                    </div>
                </div>

                <div style={styles.dataRow} >
                    <div style={{ ...styles.wrapper, ...styles.fullWidth }}>
                        <div style={styles.dataBlock} >
                            <Text large>Claims</Text>
                            <Bar id={3} />
                        </div>
                    </div>
                </div>

                {this.props.loading && <Loader whitelabel={ whitelabel } />}

                <span id="metrics-screen" />
            </ContainerControlSite>
        );
    }
}

Metrics.propTypes = {
    loading: PropTypes.bool
};

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.advisor.loading
});

export default (connect(mapStateToProps)(Metrics));
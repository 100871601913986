import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Button, Loader, Logo, ContainerControlSite, i18n, tokens } from '@simplybenefits/sb-ui-library';
import { logout } from '../../actions/admin.js';

import store from '../../redux/store.js';


class InternalError extends Component {
    state = {};

    _navigateTo = (link) => {
        window.location.href = link;
    };

    _logout = () => {
        this.props.dispatch(logout());
    };

    render() {
        const whitelabel = store.getState().whitelabel;
        const styles = {
            buttonWrapper: {
                margin: '50px auto 50px auto',
                maxWidth: '550px'
            },
            buttonHover: {
                '&:hover': { backgroundColor: 'lightgrey' } 
            },
            subButtons: {
                display: "flex", 
                justifyContent: "center",
                marginTop: "5px"
            }
        };

        return (
            <ContainerControlSite>
                <Logo whitelabel={whitelabel} />

                <Text
                    largest
                    bold
                    display="block"
                    align="center"
                    margin="20px 0"
                    customLinkStyle={{ lineHeight: '40px' }}
                >
                    { i18n.t(tokens.labels.internalError)}
                </Text>

                <div style={styles.buttonWrapper}>
                    <Button
                        fullWidth
                        onClick={() => this._navigateTo('/')}
                        color="primary"
                    >
                        { i18n.t(tokens.buttons.navigateToHome) }
                    </Button>
                    <div style={ styles.subButtons }>
                        <Button
                            onClick={() => this._navigateTo('/my-account')}
                            transparent
                            customStyle={styles.buttonHover}
                        >
                            { i18n.t(tokens.buttons.navigateToMyAccount) }
                        </Button>

                        <Button
                            onClick={() => this._navigateTo('/support')}
                            transparent
                            customStyle={styles.buttonHover}
                        >
                            { i18n.t(tokens.buttons.navigateToSupport) }
                        </Button>

                        <Button
                            onClick={this._logout}
                            transparent
                            customStyle={styles.buttonHover}
                        >
                            { i18n.t(tokens.buttons.logout) }
                        </Button>
                    </div>
                    {
                        this.props.loading &&
                        <Loader whitelabel={ whitelabel } />
                    }
                </div>
            </ContainerControlSite>
        );
    }
}

InternalError.propTypes = {
    loading: PropTypes.bool
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.admin.loading
});

export default (connect(mapStateToProps)(InternalError));
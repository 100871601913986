import request from './request';

/**
 * @param body
 * @returns {Promise<any>}
 */
function createChangeRequest(body) {
    const options = {
        endpoint: '/changeRequests',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function submitChangeRequest(changeRequestID, body) {
    const options = {
        endpoint: `/changeRequests/${ changeRequestID }/submit`,
        method: 'PUT',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function deleteChangeRequest(changeRequestUUID) {
    const options = {
        endpoint: `/changeRequests/${ changeRequestUUID }`,
        method: 'DELETE'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param changeRequestID
 * @param changeRequest
 * @returns {Promise<unknown>}
 */
function approveChangeRequest(changeRequestID, changeRequest) {
    const options = {
        endpoint: `/changeRequests/${ changeRequestID }/approve`,
        method: 'POST',
        body: { changeRequest }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param changeRequestID
 * @param changeRequest
 * @returns {Promise<unknown>}
 */
function declineChangeRequest(changeRequestID, changeRequest) {
    const options = {
        endpoint: `/changeRequests/${ changeRequestID }/decline`,
        method: 'POST',
        body: { changeRequest }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param changeRequestID
 * @param id
 * @param reviewerID
 * @returns {Promise<unknown>}
 */
function getChangeRequestDataReadOnly(changeRequestID, id, reviewerID) {
    const queryParams = new URLSearchParams({ reviewerID });

    const options = {
        endpoint: `/changeRequests/${ changeRequestID }/${ id }?${ queryParams.toString() }`,
        method: 'GET',
        skipAuth: true
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param cursor
 * @param filters
 * @returns {Promise<unknown>}
 */
function getAllChangeRequests(cursor) {
    const queryParams = new URLSearchParams({
        cursor
    });

    const options = {
        endpoint: `/changeRequests?${ queryParams.toString() }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * 
 * @param {*} changeRequestID 
 */
function downloadChangeRequestPdf(changeRequestID) {
    const options = {
        endpoint: `/changeRequests/${ changeRequestID }/downloadPdf`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param changeRequestID
 * @returns {Promise<unknown>}
 */
function resendChangeRequestLink(changeRequestID) {
    const options = {
        endpoint: `/changeRequests/${ changeRequestID }/sendLink`,
        method: 'POST'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param changeRequestID
 * @returns {Promise<unknown>}
 */
function getFile(changeRequestID) {
    const options = {
        endpoint: `/changeRequests/${ changeRequestID }/file`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}


export {
    createChangeRequest,
    submitChangeRequest,
    deleteChangeRequest,
    approveChangeRequest,
    declineChangeRequest,
    getChangeRequestDataReadOnly,
    getAllChangeRequests,
    downloadChangeRequestPdf,
    resendChangeRequestLink,
    getFile
};
import initialState from '../initialState.js';
import {
    GET_INVOICES_SUCCESS,
    GET_INVOICE_SUCCESS,
    CLEAR_SELECTED_INVOICE,
    GET_CURRENT_INVOICE_SUCCESS
} from '../../actions/types/invoices';


export default (state = initialState.invoices, action) => {
    switch (action.type) {
        case GET_INVOICES_SUCCESS: {
            return {
                ...state,
                current: action.invoices.currentInvoice,
                history: action.invoices.history,
                divisions: action.invoices.divisions,
                asoReports: {
                    ...state.asoReports,
                    history: action.invoices.asoReports.history,
                    balances: action.invoices.asoReports.balances
                }
            };
        }

        case GET_CURRENT_INVOICE_SUCCESS: {
            return {
                ...state,
                current: action.invoice.currentInvoice
            };
        }

        case GET_INVOICE_SUCCESS: {
            return {
                ...state,
                selected: action.invoice
            };
        }

        case CLEAR_SELECTED_INVOICE: {
            return {
                ...state,
                selected: null
            };
        }

        default:
            return state;
    }
};
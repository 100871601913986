import request from './request.js';
import { getUserType } from '../services/session.js';

/**
 * @returns {Promise<any>}
 */
function getInvoices(accountID) {
    const isAdvisor = Number(getUserType()) === 1;
    const endpoint = isAdvisor ? `/advisor/account/${ accountID }/invoices` : `/invoices`;
    const options = {
        method: 'GET',
        endpoint
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param invoiceNum
 * @param accountID
 * @returns {Promise<unknown>}
 */
function getInvoice(invoiceNum, accountID) {
    const isAdvisor = Number(getUserType()) === 1;
    const endpoint = isAdvisor ? `/advisor/account/${ accountID }/invoices/${ invoiceNum }` : `/invoices/${ invoiceNum }`;

    const options = {
        method: 'GET',
        endpoint
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param invoiceNum
 * @param accountID
 * @returns {Promise<unknown>}
 */
function getCurrentInvoice(accountID) {
    const isAdvisor = Number(getUserType()) === 1;
    const endpoint = isAdvisor ? `/advisor/account/${ accountID }/invoices/current` : `/invoices/current`;

    const options = {
        method: 'GET',
        endpoint
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param asoReportID
 * @returns {Promise<unknown>}
 */
function getAsoReport(asoReportID) {
    const endpoint = `/invoices/asoReport/${ asoReportID }`;
    const options = {
        method: 'GET',
        endpoint
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    getInvoice,
    getInvoices,
    getAsoReport,
    getCurrentInvoice
};
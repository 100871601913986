import React from 'react';
import * as dates from '@simplybenefits/sb-dates';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import PropTypes from 'prop-types';
import { globalStyles } from '../../../theme/index.js';
import { Theme, i18n, tokens, localizationMaps } from "@simplybenefits/sb-ui-library";
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';

/**
 * @param x
 * @returns {string}
 */
function _numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * @param value
 * @param field
 * @private
 */
function _getValue(value, field) {
    if (field === localizationMaps.fromEnglish("journey", 'Shares')) {
        return `${ Number(value).toFixed(2) }%`;
    }

    if (field === localizationMaps.fromEnglish("journey", 'Salary') || field === localizationMaps.fromEnglish("journey", 'Hourly Wage')) {
        return `$${ _numberWithCommas(Number(value).toFixed(2)) }`;
    }

    if (field === localizationMaps.fromEnglish("journey", 'Birth Date') || field === localizationMaps.fromEnglish("journey", 'Hire Date')) {
        return dates.getStartOfDay(value, 'll', i18n.locale);
    }

    return value;
}

/**
 * @param report
 * @param classes
 * @returns {*}
 * @constructor
 */
const JourneyTable = ({ journey, meta }) => {
    const theme = useTheme();
    const styles = {
        table: {
            ...globalStyles.table,
            border: "2px solid #F1F1F1",
            backgroundColor: '#FFF'
        },
        scrollbar: {
            '&::webkitScrollbar': {
                height: '15px',
                width: '15px'
            },
            '&::webkitScrollbarThumb': {
                backgroundColor: Theme.colors.surface,
                borderRadius: '10px',
                boxShadow: 'inset 0 0 2px rgba(0, 0, 0, .5)'
            },
            '&::webkitScrollbarTrack': {
                backgroundColor: Theme.colors.backgroundGrey,
                borderRadius: '10px',
                boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.2)'
            }
        },
        tableHeading: {
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingRight: "2vw",
            paddingLeft: "2vw",
            fontFamily: Theme.fonts.type.heading,
            color: Theme.colors.surface,
            whiteSpace: "nowrap",
            border: "none"
            
        },
        tableCell: {
            paddingTop: "10px",
            paddingBottom: "10px",
            fontFamily: Theme.fonts.type.light,
            whiteSpace: "nowrap",
            paddingRight: "2vw",
            paddingLeft: "2vw",
            color: Theme.colors.mainTextGrey
        },
        container: {
            flex: 1,
            border: "2px solid #F1F1F1",
            borderRadius: 5,
            backgroundColor: '#FFF',
            padding: "20px"
        }
    };

    return (
        <div style={ styles.container }>
            <div style={{ ...styles.scrollbar, overflow: "auto", maxHeight: 600 }}>
                <Table>
                    <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                        <TableRow>
                            <TableCell sx={styles.tableHeading}>
                                { i18n.t(tokens.labels.type) }
                            </TableCell>

                            <TableCell sx={styles.tableHeading}>
                                { i18n.t(tokens.labels.admin.user) }
                            </TableCell>

                            <TableCell sx={styles.tableHeading}>
                                { i18n.t(tokens.labels.admin.field) }
                            </TableCell>

                            <TableCell sx={styles.tableHeading}>
                                { i18n.t(tokens.labels.admin.oldValue) }
                            </TableCell>

                            <TableCell sx={styles.tableHeading}>
                                { i18n.t(tokens.labels.admin.newValue) }
                            </TableCell>

                            <TableCell sx={styles.tableHeading}>
                                { i18n.t(tokens.labels.admin.changedBy) }
                            </TableCell>

                            <TableCell sx={styles.tableHeading}>
                                { i18n.t(tokens.labels.admin.dateOfChange) }
                            </TableCell>

                            <TableCell sx={styles.tableHeading}>
                                { i18n.t(tokens.labels.effectiveDate) }
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            journey.map((item, index) => {
                                let backgroundObject = { border: "none" };
                                if (index % 2 !== 0) {
                                    backgroundObject = { backgroundColor: Theme.colors.backgroundGrey, border: "none" };
                                }

                                return (
                                    <TableRow key={item.occurred + index}>
                                        <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                            { item.type }
                                        </TableCell>

                                        <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                            { item.entityLabel || `${ meta.firstname } ${ meta.lastname }` }
                                        </TableCell>

                                        <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                            { item.field || 'N/A' }
                                        </TableCell>

                                        <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                            { item.oldValue ? _getValue(item.oldValue, item.field) : 'N/A' }
                                        </TableCell>

                                        <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                            { item.newValue ? _getValue(item.newValue, item.field) : 'N/A' }
                                        </TableCell>

                                        <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                            { item.changedBy }
                                        </TableCell>

                                        <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                            { dates.getLocalDisplay(item.occurred, 'lll', i18n.locale) }
                                        </TableCell>

                                        <TableCell sx={{ ...styles.tableCell, ...backgroundObject }}>
                                            { dates.getLocalDisplay(item.effectiveDate, 'll', i18n.locale) }
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

JourneyTable.propTypes = {
    report: PropTypes.array
};

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.admin.loading
});

export default (connect(mapStateToProps)(JourneyTable));
import React from 'react';
import * as dates from '@simplybenefits/sb-dates';
import { Button, Text, Theme, i18n, tokens } from '@simplybenefits/sb-ui-library';
import Avatar from "react-avatar";

import store from '../../../redux/store.js';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        border: "2px solid #F1F1F1",
        borderRadius: 5,
        backgroundColor: '#FFF',
        marginLeft: 10,
        width: 350
    },
    block: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: "5px 10px 10px 10px",
        justifyContent: 'center'
    },
    journeyItemRow: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    journeyList: {
        flex: 1,
        paddingBottom: 20
    },
    left: {
        display: 'flex',
        flexDirection: 'column'
    },
    middle: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingLeft: 10
    },
    divider: {
        borderBottom: `1px solid ${ Theme.colors.borderGrey }`,
        width: '90%',
        margin: '20px auto 0 auto'
    }
};

/**
 * @param item
 * @param avatar
 * @param member
 * @param classes
 * @param showDivider
 * @returns {*}
 * @constructor
 */
const JourneyItem = ({ item, avatar, meta, color, showDivider = true }) => (
    <div style={ styles.block }>
        <Text
            superTiny
            color={ Theme.colors.mainTextGrey }
            align="right"
        >
            { dates.fromNow(item.occurred, i18n.locale) }
        </Text>

        <div style={ styles.journeyItemRow }>
            <div style={ styles.left }>
                <Avatar
                    name={avatar}
                    initials={avatar}
                    size="60"
                    textSizeRatio={3.5}
                    round={true}
                    style={{ fontWeight: 600 }}
                    color={ color }
                />
            </div>

            <div style={ styles.middle }>
                <Text
                    small
                    bold
                    color={ Theme.colors.mainTextGrey }
                >
                    { item.type }
                </Text>

                <Text
                    superTiny
                    color={ Theme.colors.mainTextGrey }
                >
                    { item.entityLabel || `${ meta.firstname } ${ meta.lastname }` }
                </Text>
            </div>
        </div>

        {
            showDivider &&
                <div style={ styles.divider } />
        }
    </div>
);

/**
 * @param date
 * @returns {*}
 * @private
 */
const _getAvatar = (date) => dates.getLocalDisplay(date, 'MMM DD', i18n.locale);

/**
 * @param index
 * @private
 */
const _getAvatarColor = (index) => {
    const whitelabel = store.getState().whitelabel;

    if (index % 2 === 0) {
        return whitelabel.lightColor || Theme.colors.mediumLightBlue;
    }

    if (index % 3 === 0) {
        return whitelabel.secondaryColor || Theme.colors.mediumBlue;
    }

    return whitelabel.primaryColor || Theme.colors.skyBlue;
};

/**
 * @param journey
 * @param meta
 * @param onViewAll
 * @param viewAllJourney
 * @param classes
 * @returns {*}
 * @constructor
 */
const JourneyBlock = ({ journey, meta, onViewAll, viewAllJourney }) => (
    <div style={ styles.container }>
        <Text
            large
            veryBold
            color={ Theme.colors.mainTextGrey }
            margin={ "20px 0 20px 20px" }
        >
            { i18n.t(tokens.titles.latestChanges) }
        </Text>

        <div style={ styles.journeyList}>
            {
                journey.length === 0 ?
                    <Text
                        small
                        bold
                        color={ Theme.colors.mainTextGrey }
                        margin="0 0 0 20px"
                    >
                        { i18n.t(tokens.labels.employees.memberNoChangesApplied) }
                    </Text>
                    :
                    journey.map((item, i) => {
                        if (i > 4) {
                            return null;
                        }

                        const avatar = _getAvatar(item.occurred);

                        return (
                            <JourneyItem
                                key={ i + item.occurred }
                                item={ item }
                                avatar={ avatar }
                                meta={ meta }
                                color={_getAvatarColor(i + 1)}
                            />
                        );
                    })
            }
        </div>

        {
            journey.length > 0 &&
                <Button
                    color="primary"
                    fullWidth
                    customStyle={{ borderRadius: 0 }}
                    onClick={ onViewAll }
                >
                    {
                        viewAllJourney ? i18n.t(tokens.labels.employees.hideAll) : i18n.t(tokens.labels.employees.viewAll)
                    }
                </Button>
        }
    </div>
);

export default (JourneyBlock);
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader, Quote, ContainerTitle, Container, Content, i18n, tokens } from '@simplybenefits/sb-ui-library';
import * as notifier from '../../../actions/notifier.js';
import config from '../../../config.js';
import * as session from '../../../services/session.js';

import {
    submitPlanDesigns, submitModuleBlueprint, createPlanDesign, savePlanDesign, clonePlanDesign, savePlanDesignBenefitInitData,
    savePlanDesignField, getPlanDesignRevisions, getSinglePlanDesignRevision, revertToRevision, saveModularBlueprint,
    saveModularBenefitInitData, saveModularField, getSpecialityProductRates, saveSpecialityProductRates, submitSpecialityProductRates
} from '../../../actions/plan';

import {
    uploadAccountHistory, submitCommissions, saveCommissions, getCommissions, getMasterApplicationData,
    updateMasterApp, completeMasterApp, sendReviewRequest, assignPlans, rejectPricing,
    getAdvisorPdf, sendMasterAppLink,
    validateBankingDetails
} from '../../../actions/advisor';

import { getQuote, savePlanType, removeClass, removeDivision, editQuote, convertQuote } from '../../../actions/quotes.js';
import { addClass, editClass, addDivision, editDivision } from '../../../actions/census.js';

class QuoteContainer extends PureComponent {
    state = {
        selectedSection: null
    };

    componentDidMount() {
        this.props.dispatch(getQuote(this.props.match.params.quoteID));
    }

    _setSelectedSection = (section) => {
        this.setState({
            selectedSection: section
        });
    };

    _downloadCarrierPackage = async () => {
        const token = await session.getToken();
        const url = `${ config.apiRequestUrl }/advisor/quote/carrierPackage/${ this.props.quote.quoteID }/${ token }`;
        window.open(encodeURI(url));
        return true;
    };

    _buildBreadcrumbs = () => {
        if (!this.state.selectedSection) {
            return [
                {
                    title: i18n.t(tokens.titles.dashboard),
                    link: `/advisor/dashboard`
                },
                {
                    title: i18n.t(tokens.titles.quotes),
                    link: `/advisor/quotes`
                }
            ];
        }

        return [
            {
                title: i18n.t(tokens.titles.dashboard),
                link: `/advisor/dashboard`
            },
            {
                title: i18n.t(tokens.titles.quotes),
                link: `/advisor/quotes`
            },
            {
                title: this.props.quote.meta.company,
                onClick: () => this._setSelectedSection(null)
            }
        ];
    };

    _getSubHeader = () => {
        let subHeader = null;

        if (this.state.selectedSection === 'plan-design') {
            subHeader = i18n.t(tokens.titles.planDesign);
        } else if (this.state.selectedSection === 'classes-divisions') {
            subHeader = i18n.t(tokens.titles.classesAndDivisions);
        } else if (this.state.selectedSection === 'settings') {
            subHeader = i18n.t(tokens.titles.settings);
        } else if (this.state.selectedSection === 'administrators') {
            subHeader = i18n.t(tokens.labels.admin.administrators);
        } else if (this.state.selectedSection === 'account-history') {
            subHeader = i18n.t(tokens.titles.accountHistory);
        } else if (this.state.selectedSection === 'master-application') {
            subHeader = i18n.t(tokens.titles.masterApplication);
        } else if (this.state.selectedSection === 'assign-plans') {
            subHeader = i18n.t(tokens.titles.planAssign);
        } else if (this.state.selectedSection === 'commissions') {
            subHeader = i18n.t(tokens.titles.commissions);
        } else if (this.state.selectedSection === 'pricing') {
            subHeader = i18n.t(tokens.titles.pricing);
        } else if (this.state.selectedSection === 'speciality-product-rates') {
            subHeader = i18n.t(tokens.titles.specialityProductRates);
        } else {
            subHeader = this.props.quote.meta.company;
        }

        return subHeader;
    };

    render() {
        const { quote, whitelabel } = this.props;

        if (!quote) {
            return <Loader/>;
        }

        const subHeader = this._getSubHeader();
        const breadcrumbs = this._buildBreadcrumbs();

        return (
            <Container>
                <Content>
                    <ContainerTitle
                        title={subHeader}
                        subheader={subHeader}
                        breadcrumbs={breadcrumbs}
                        whitelabel={whitelabel}
                    />

                    <Quote
                        loading={ this.props.loading }
                        language={ this.props.advisor.meta.language }
                        selectedSection={ this.state.selectedSection }
                        setSelectedSection={ this._setSelectedSection }
                        quote={ quote }
                        currentAccountMeta={ quote.meta }
                        isMasterSite={ false }
                        whitelabel={ whitelabel }
                        advisorMeta={ this.props.advisor.meta }

                        // notifier handler
                        displayError={ (...params) => this.props.dispatch(notifier.displayError(...params)) }

                        // we need the speciality product configs for setting the plan designs
                        specialityProductMetaConfigs={ this.props.specialityProductMetaConfigs }
                        getSpecialityProductRates={ () => this.props.dispatch(getSpecialityProductRates(null, quote.quoteID))}
                        saveSpecialityProductRates={ (...params) => this.props.dispatch(saveSpecialityProductRates(null, quote.quoteID, ...params))}
                        submitSpecialityProductRates={ (...params) => this.props.dispatch(submitSpecialityProductRates(null, quote.quoteID, ...params))}

                        editQuote={ (...params) => this.props.dispatch(editQuote(quote.quoteID, ...params)) }

                        // classes and divisions handler
                        addClass={ (...params) => this.props.dispatch(addClass(...params, quote.quoteID)) }
                        editClass={ (...params) => this.props.dispatch(editClass(...params, quote.quoteID)) }
                        removeClass={ (...params) => this.props.dispatch(removeClass(...params, quote.quoteID)) }
                        addDivision={ (...params) => this.props.dispatch(addDivision(...params, quote.quoteID)) }
                        editDivision={ (...params) => this.props.dispatch(editDivision(...params, quote.quoteID)) }
                        removeDivision={ (...params) => this.props.dispatch(removeDivision(...params, quote.quoteID)) }
                        validateBankingDetails={ (...params) => this.props.dispatch(validateBankingDetails(...params)) }
                        bankingDetailsVerifying={ this.props.bankingDetailsVerifying }

                        // account history handler
                        uploadAccountHistory={ (...params) => this.props.dispatch(uploadAccountHistory(...params, quote.quoteID)) }
                        downloadCarrierPackage={ () => this._downloadCarrierPackage() }

                        // plan design routes
                        savePlanType={ (...params) => this.props.dispatch(savePlanType(...params, quote.quoteID)) }
                        createPlanDesign={ (...params) => this.props.dispatch(createPlanDesign(...params, quote.quoteID)) }
                        savePlanDesign={ (...params) => this.props.dispatch(savePlanDesign(...params, quote.quoteID)) }
                        clonePlanDesign={ (...params) => this.props.dispatch(clonePlanDesign(...params, quote.quoteID)) }
                        savePlanDesignBenefitInitData={ (...params) => this.props.dispatch(savePlanDesignBenefitInitData(...params, quote.quoteID)) }
                        savePlanDesignField={ (...params) => this.props.dispatch(savePlanDesignField(...params, quote.quoteID)) }
                        getPlanDesignRevisions={ (...params) => this.props.dispatch(getPlanDesignRevisions(...params, quote.quoteID)) }
                        getSinglePlanDesignRevision={ (...params) => this.props.dispatch(getSinglePlanDesignRevision(...params, quote.quoteID)) }
                        revertToRevision={ (...params) => this.props.dispatch(revertToRevision(...params, quote.quoteID)) }
                        saveModularBenefitInitData={ (...params) => this.props.dispatch(saveModularBenefitInitData(...params, quote.quoteID)) }
                        saveModularField={ (...params) => this.props.dispatch(saveModularField(...params, quote.quoteID)) }
                        saveModularBlueprint={ (...params) => this.props.dispatch(saveModularBlueprint(...params, quote.quoteID)) }
                        submitPlanDesigns={ (...params) => this.props.dispatch(submitPlanDesigns(...params, quote.quoteID)) }
                        submitModuleBlueprint={ (...params) => this.props.dispatch(submitModuleBlueprint(...params, quote.quoteID)) }

                        // commissions
                        submitCommissions={ (...params) => this.props.dispatch(submitCommissions(...params, quote.quoteID)) }
                        saveCommissions={ (...params) => this.props.dispatch(saveCommissions(...params, quote.quoteID)) }
                        getCommissions={ (...params) => this.props.dispatch(getCommissions(...params, quote.quoteID)) }

                        // master application
                        getMasterApplicationData={ (...params) => this.props.dispatch(getMasterApplicationData(...params, quote.quoteID)) }
                        updateMasterApp={ (...params) => this.props.dispatch(updateMasterApp(...params, quote.quoteID)) }
                        completeMasterApp={ (...params) => this.props.dispatch(completeMasterApp(...params, quote.quoteID)) }

                        // send for underwriting
                        sendReviewRequest={ (...params) => this.props.dispatch(sendReviewRequest(...params, quote.quoteID)) }

                        // assign plans
                        assignPlans={ (...params) => this.props.dispatch(assignPlans(...params, quote.quoteID)) }
                        rejectPricing={ (...params) => this.props.dispatch(rejectPricing(...params, quote.quoteID)) }

                        // finish setup -> send review to signing auth
                        sendMasterAppLink={ (...params) => this.props.dispatch(sendMasterAppLink(...params, quote.quoteID)) }

                        convertQuote={ (...params) => this.props.dispatch(convertQuote(...params, quote.quoteID)) }

                        downloadSpecialityProductRatesPdf={ (...params) => this.props.dispatch(getAdvisorPdf(...params))}
                    />
                </Content>

                <span id="quote-single" />
            </Container>
        );
    }
}

QuoteContainer.propTypes = {
    quote: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{quote: null, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loading: state.loader,
    bankingDetailsVerifying: state.advisor.bankingDetailsVerifying,
    quote: state.quotes.currentQuote,
    whitelabel: state.whitelabel,
    advisor: state.advisor,
    specialityProductMetaConfigs: state.plan.specialityProductMetaConfigs,
    titleBlock: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    }
});

export default connect(mapStateToProps)(QuoteContainer);
import * as whitelabel from '../xhr/whitelabel.js';
import * as types from './types/whitelabel.js';
import * as notifier from './notifier.js';
import * as session from '../services/session.js';
import * as loader from './loader.js';

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'full') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}

/**
 * @returns {Function}
 */
export function getLocalStorageWhitelabel() {
    return async (dispatch) => {
        const data = await session.getWhitelabel();

        if (!data) {
            return null;
        }

        dispatch({
            type: types.SAVE_WHITELABEL,
            data
        });

        return data;
    };
}

/**
 * @returns {function(*=): Promise<unknown>}
 */
export function getWhiteLabel(hasAuth) {
    return (dispatch) => {
        setLoader(dispatch, true);

        return whitelabel
            .get(hasAuth)
            .then(async (data) => {
                if (!data || Object.keys(data).length === 0) {
                    if (hasAuth) {
                        await session.removeWhitelabel();
                    }

                    setLoader(dispatch, false);
                    return null;
                }

                // save the whitelabel in local storage for if they logout
                await session.saveWhitelabel(data);

                dispatch({
                    type: types.SAVE_WHITELABEL,
                    data
                });

                setLoader(dispatch, false);

                return data;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}
import request from './request.js';

/**
 * @returns {Promise<any>}
 */
function get() {
    const options = {
        endpoint: '/advisor',
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param username
 * @param password
 * @returns {Promise<any>}
 */
function login(username, password) {
    const options = {
        endpoint: '/advisor/login',
        method: 'POST',
        skipAuth: true,
        body: {
            username,
            password
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function sendForgotPasswordEmail(body) {
    const options = {
        endpoint: '/advisor/sendForgotPasswordEmail',
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @returns {Promise<any>}
 */
function updateAdvisorSettings(body) {
    const options = {
        endpoint: `/advisor`,
        method: 'PUT',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @returns {Promise<any>}
 */
function getAccount(accountID) {
    const options = {
        endpoint: `/advisor/account/${ accountID }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @param accountID
 * @returns {Promise<any>}
 */
function updateAccountSettings(body, accountID) {
    const options = {
        endpoint: `/advisor/account/${ accountID }/settings`,
        method: 'PUT',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param body
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function uploadAccountHistory(body, accountID, quoteID) {
    body.append('quoteID', quoteID || null);

    const options = {
        endpoint: `/advisor/${ accountID }/accountHistory/upload`,
        method: 'POST',
        noJson: true,
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param password
 * @returns {Promise<any>}
 */
function changePassword(existing, password) {
    const options = {
        endpoint: `/advisor/password`,
        method: 'PUT',
        body: {
            existing,
            password
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param effDate
 * @returns {Promise<unknown>}
 */
function makeAccountLive(accountID, effDate) {
    const options = {
        endpoint: `/advisor/account/${ accountID }/goLive`,
        method: 'POST',
        body: {
            effDate
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function sendReviewRequest(accountID, quoteID) {
    const options = {
        endpoint: `/advisor/account/${ accountID }/reviewRequest`,
        method: 'POST',
        body: {
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function getMasterApplicationData(accountID, quoteID = false) {
    const options = {
        endpoint: quoteID ? `/advisor/${ accountID }/masterApplication?quoteID=${ quoteID }` : `/advisor/${ accountID }/masterApplication`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param key
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function getMasterApplicationDataReadOnly(accountID, key, quoteID) {
    const options = {
        endpoint: quoteID ? `/application/master/${ accountID }/${ key }?quoteID=${ quoteID }` : `/application/master/${ accountID }/${ key }`,
        method: 'GET',
        skipAuth: true
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function sendMasterAppLink(accountID, quoteID) {
    const options = {
        endpoint: `/advisor/${ accountID }/masterApplication/sendLink`,
        method: 'POST',
        body: {
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @returns {Promise<any>}
 */
function setMasterAppBack(accountID) {
    const options = {
        endpoint: `/advisor/${ accountID }/masterApplication/setBack`,
        method: 'POST'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}


/**
 * @param accountID
 * @param body
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function updateMasterApp(accountID, body, quoteID) {
    const options = {
        endpoint: `/advisor/${ accountID }/masterApplication`,
        method: 'PUT',
        body: {
            ...body,
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param body
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function submitMasterApp(accountID, body, quoteID) {
    const options = {
        endpoint: `/application/master/${ accountID }`,
        method: 'POST',
        body: {
            ...body,
            quoteID: quoteID || null
        },
        skipAuth: true
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * The advisor is done with the master app so lets complete and move to the next step
 * @param accountID
 * @param quoteID
 * @returns {Promise<any>}
 */
function completeMasterApp(accountID, quoteID) {
    const options = {
        endpoint: `/advisor/${ accountID }/masterApplication/complete`,
        method: 'POST',
        body: {
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param body
 * @returns {Promise<any>}
 */
function saveBankingDetails(accountID, body) {
    const options = {
        endpoint: `/advisor/${ accountID }/banking`,
        method: 'POST',
        body
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param id
 */
function removeNotification(id) {
    const options = {
        endpoint: `/advisor/notifications/${ id }`,
        method: 'DELETE'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param reason
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function rejectPricing(accountID, reason, quoteID) {
    const options = {
        endpoint: `/advisor/${ accountID }/pricing/reject`,
        method: 'POST',
        body: {
            reason,
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param assignments
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function assignPlans(accountID, assignments, quoteID) {
    const options = {
        endpoint: `/advisor/${ accountID }/class/assignPlans`,
        method: 'POST',
        body: {
            assignments,
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param commissions
 * @param quoteID
 * @param effectiveDate
 * @returns {Promise<unknown>}
 */
function saveCommissions(accountID, commissions, quoteID, effectiveDate) {
    const options = {
        endpoint: `/advisor/${ accountID }/commissions`,
        method: 'POST',
        body: {
            ...commissions,
            quoteID: quoteID || null,
            effectiveDate: effectiveDate || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param commissions
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function submitCommission(accountID, commissions, quoteID) {
    const options = {
        endpoint: `/advisor/${ accountID }/commissions/submit`,
        method: 'POST',
        body: {
            ...commissions,
            quoteID: quoteID || null
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param quoteID
 * @returns {Promise<unknown>}
 */
function getCommissions(accountID, quoteID) {
    let endpoint = `/advisor/${ accountID }/commissions`;

    if (quoteID) {
        endpoint = `/advisor/${ accountID }/commissions?quoteID=${ quoteID }`;
    }

    const options = {
        endpoint,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param filters
 * @returns {Promise<>}
 */
function getCommissionsHistory(filters) {
    const options = {
        endpoint: `/advisor/commissions/history`,
        method: 'POST',
        body: {
            filters
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @returns {Promise<unknown>}
 */
function getAccountAmendments(accountID) {
    const options = {
        endpoint: `/advisor/accounts/${ accountID }/amendments`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 */
function createAmendment(accountID) {
    const options = {
        endpoint: `/advisor/accounts/${ accountID }/amendments`,
        method: 'POST',
        body: {}
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param accountID
 * @param amendmentID
 * @returns {Promise<unknown>}
 */
function getAmendment(accountID, amendmentID) {
    const options = {
        endpoint: `/advisor/accounts/${ accountID }/amendments/${ amendmentID }`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @returns {Promise<unknown>}
 */
function getAmendmentAuthorityView(accountID, uniqueKey, amendmentID) {
    const options = {
        endpoint: `/amendments/authority/accounts/${ accountID }/amendments/${ amendmentID }/${ uniqueKey }`,
        method: 'GET',
        noAuth: true
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param signage
 * @param accountID
 * @param amendmentID
 * @returns {Promise<unknown>}
 */
function completeAmendmentApproval(signage, accountID, amendmentID) {
    const options = {
        endpoint: `/amendments/authority/accounts/${ accountID }/amendments/${ amendmentID }/approve`,
        method: 'POST',
        noAuth: true,
        body: {
            signage
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 * @param advisorID
 */
function getAccountsUnderAmendment(advisorID) {
    const options = {
        endpoint: `/advisor/${ advisorID }/accountsUnderAmendment`,
        method: 'GET'
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

/**
 *
 * @param institutionNum
 * @param transitNum
 * @returns {Promise<unknown>}
 */
function validateBankingDetails(institutionNum, transitNum) {
    const options = {
        endpoint: '/advisor/branchDetails/validate',
        method: 'POST',
        body: {
            institutionNum,
            transitNum
        }
    };

    return new Promise((resolve, reject) => {
        request(options)
            .then(resolve)
            .catch(reject);
    });
}

export {
    get,
    login,
    sendForgotPasswordEmail,
    getAccount,
    updateAdvisorSettings,
    updateAccountSettings,
    uploadAccountHistory,
    changePassword,
    makeAccountLive,
    sendReviewRequest,
    getMasterApplicationData,
    getMasterApplicationDataReadOnly,
    updateMasterApp,
    sendMasterAppLink,
    submitMasterApp,
    saveBankingDetails,
    removeNotification,
    completeMasterApp,
    rejectPricing,
    assignPlans,
    saveCommissions,
    submitCommission,
    getCommissions,
    getCommissionsHistory,
    setMasterAppBack,
    getAccountAmendments,
    createAmendment,
    getAmendment,
    getAmendmentAuthorityView,
    completeAmendmentApproval,
    getAccountsUnderAmendment,
    validateBankingDetails
};
/**
 * 
 * @param {*} x
 */
export const numberWithCommas = (x) => Number(x).toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * 
 * @param {*} string 
 */
export const snakeCapitalCase = (string) => string.replace(/\W+/g, " ").split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase().charAt(0)
        .toUpperCase() + word.slice(1))
    .join('_');

/**
 * 
 * @param {*} number 
 * @returns 
 */
export const formatNumber = (number) => `$${ (number || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }`;
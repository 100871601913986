import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Services, DivisionListItem } from '@simplybenefits/sb-ui-library';
import { helpers } from '@simplybenefits/sb-utils';
import DivisionBreakdown from './DivisionBreakdown.js';
import { connect } from "react-redux";

class AsoReport extends Component {
    state = {
        value: 0,
        selectedDivisionID: helpers.sortObjByTitle(this.props.divisions)[0],
        divisionIDs: helpers.sortObjByTitle(this.props.divisions),
        selectedItem: this.props.divisions[helpers.sortObjByTitle(this.props.divisions)[0]].title,
        currentDivision: this.props.asoReport.divisions[helpers.sortObjByTitle(this.props.divisions)[0]]
    };

    /**
     * Only re-render when we change the selected division
     * @param nextProps
     * @param nextState
     * @returns {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return this.state.currentDivision !== nextState.currentDivision;
    }

    _selectDivision = (selectedItem, divisionID) => {
        this.setState({
            selectedItem,
            currentDivision: !divisionID ? null : this.props.asoReport.divisions[divisionID]
        });
    };

    render() {
        const { whitelabel } = this.props;

        if (!this.props.asoReport) {
            return null;
        }

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2} md={3}>
                        {
                            this.state.divisionIDs.map((divisionID, i) => {
                                const data = this.props.asoReport.divisions[divisionID];

                                // if the division does not exist in the ASO report then return nothing for that division
                                if (!data) {
                                    return null;
                                }

                                data.title = this.props.divisions[divisionID].title;

                                return (
                                    <DivisionListItem
                                        id={i === 0 ? 'invoices-division' : `invoices-division-${ i }`}
                                        key={Services.concatTokens(divisionID, this.props.divisions[divisionID].title)}
                                        data={data}
                                        onSelect={ () => this._selectDivision(data.title, divisionID) }
                                        selected={ this.state.selectedItem === data.title }
                                    />
                                );
                            })
                        }
                    </Grid>

                    <Grid item xs={12} sm={10} md={9}>
                        <DivisionBreakdown
                            data={ this.state.currentDivision }
                            account={ this.props.account }
                            divisionTitle={ this.state.selectedItem }
                            clientName={ this.props.clientName }
                            timeStamp={ this.props.asoReport.timestamp }
                            period={this.props.asoReport.summary.period}
                            whitelabel={ whitelabel }
                            asoReportID={ this.props.asoReport.asoReportID }
                            selectedDivisionID={ this.state.selectedDivisionID }
                        />
                    </Grid>
                </Grid>

                <span id="aso-report-overview" />
            </div>
        );
    }
}

AsoReport.propTypes = {
    report: PropTypes.object
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    loading: state.admin.loading,
    clientName: state.admin.meta.company,
    divisions: state.census.divisions,
    account: state.admin.account
});

export default (connect(mapStateToProps)(AsoReport));
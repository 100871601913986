import * as reports from '../xhr/reports.js';
import * as session from '../services/session.js';
import * as types from './types/reports.js';
import * as notifier from './notifier.js';
import * as loader from './loader.js';
import * as events from '../services/events.js';

/**
 * @param dispatch
 * @param isLoading
 * @param type
 */
function setLoader(dispatch, isLoading, type = 'full') {
    dispatch(loader.setLoader({
        isLoading,
        type
    }));
}

/**
 * @param type
 * @param filters
 * @param accountID
 * @returns {function(*=): Promise<boolean | never>}
 */
export function generateReport(type, filters, accountID = false) {
    const isAdvisor = Number(session.getUserType()) === 1;

    return (dispatch) => {
        setLoader(dispatch, true);
        return reports
            .generate(type, filters, accountID, isAdvisor)
            .then((data) => {
                dispatch({
                    type: types.GENERATE_REPORT_SUCCESS,
                    reportTitle: type,
                    data
                });

                setLoader(dispatch, false);
                events.record('generate_report');
                return true;
            })
            .catch((error) => {
                setLoader(dispatch, false);
                dispatch(notifier.displayError(error));
            });
    };
}

/**
 * @returns {Function}
 */
export function clearReport() {
    return (dispatch) => (
        dispatch({
            type: types.CLEAR_REPORT
        })
    );
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as addressTools from '@simplybenefits/sb-address';
import FormControl from '@mui/material/FormControl';
import { TextInput, PhoneInput, Services, Modal, BubbleSelectBlock, Text, i18n, tokens, localizationMaps } from '@simplybenefits/sb-ui-library';

import * as notifier from '../../../../actions/notifier.js';
import { updateAccountSettings } from '../../../../actions/advisor.js';

class AccountSettings extends Component {
    state = {
        companyName: '',
        suite: '',
        firstLine: '',
        city: '',
        province: '',
        postalCode: '',
        country: 'Canada',
        phone: '',
        contactEmail: '',
        authorityName: '',
        authorityEmail: '',
        asoType: '',
        isAsoDental: '',
        isAsoHealth: '',
        showModal: false
    };

    async componentDidMount() {
        const { currentAccountMeta } = this.props;
        let address = '';

        if (currentAccountMeta.address) {
            address = addressTools.parseAddress(currentAccountMeta.address);
        }

        if (currentAccountMeta) {
            await this.setState({
                companyName: currentAccountMeta.company,
                suite: address.suite,
                firstLine: address.firstLine,
                city: address.city,
                province: localizationMaps.fromEnglish("provinces", address.province),
                postalCode: address.postalCode,
                phone: currentAccountMeta.phone,
                contactEmail: currentAccountMeta.contactEmail,
                authorityName: currentAccountMeta.authorityName,
                authorityEmail: currentAccountMeta.authorityEmail,
                asoType: currentAccountMeta.asoType,
                isAsoDental: currentAccountMeta.isAsoDental,
                isAsoHealth: currentAccountMeta.isAsoHealth
            });
        }

        document.addEventListener("keydown", this._handleKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyPress, false);
    }

    _onBlur = async (type) => {
        this.setState({
            [`${ type }Error`]: await Services.validateInput(type, this.state[type])
        });
    };

    _onAddressBlur = async (type) => {
        if (type !== 'suite') {
            await this.setState({
                [`${ type }Error`]: await Services.validateInput(type, this.state[type])
            });
        }

        const { firstLine, city, province, postalCode } = this.state;

        if (firstLine && city && province && postalCode) {
            await addressTools.generateAddress(this.state);
        }

        return null;
    };

    _handleInputChange = (type, event) => {
        this.setState({
            [type]: event.target.value
        });
    };

    _handleAddressChange = (type, event) => {
        this.setState({
            [type]: event.target.value.replace(/,/g, '')
        });
    };

    _confirm = async () => {
        const companyNameError = await Services.validateInput('companyName', this.state.companyName);
        const phoneError = await Services.validateInput('phone', this.state.phone);
        const contactEmailError = await Services.validateInput('contactEmail', this.state.contactEmail);
        const authorityError = await Services.validateInput('authority', this.state.authorityName);
        const authorityEmailError = await Services.validateInput('authorityEmail', this.state.authorityEmail);

        const firstLineError = await Services.validateInput('firstLine', this.state.firstLine);
        const cityError = await Services.validateInput('city', this.state.city);
        const provinceError = await Services.validateInput('province', this.state.province);
        const postalCodeError = await Services.validateInput('postalCode', this.state.postalCode);

        this.setState({
            companyNameError,
            phoneError,
            contactEmailError,
            authorityError,
            authorityEmailError,

            firstLineError,
            cityError,
            provinceError,
            postalCodeError
        });

        if (
            companyNameError ||
            phoneError ||
            contactEmailError ||
            authorityError ||
            authorityEmailError ||

            firstLineError ||
            cityError ||
            provinceError ||
            postalCodeError
        ) {
            this.props.dispatch(notifier.displayError({ verbiage: 'Please confirm that your have filled out all the details.' }));

            return false;
        }

        const {
            suite,
            firstLine,
            city,
            province,
            postalCode,
            country,

            companyName,
            contactEmail,
            phone,
            authorityName,
            authorityEmail
        } = this.state;

        const generatedAddress = addressTools.generateAddress({
            suite,
            firstLine,
            city,
            province: localizationMaps.fromEnglish("provinces", province),
            postalCode,
            country
        });

        const body = {
            companyName: companyName.trim(),
            companyAddress: generatedAddress.trim(),
            phone: phone.trim(),
            contactEmail: contactEmail.trim(),
            authorityName: authorityName.trim(),
            authorityEmail: authorityEmail.trim()
        };

        const success = await this.props.dispatch(updateAccountSettings(body, this.props.currentAccountMeta.accountID));

        if (success) {
            this.props.hideModal();
            document.removeEventListener("keydown", this._handleKeyPress, false);
        }

        return null;
    };

    _handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            return this._confirm();
        }

        return false;
    };

    render() {
        const {
            hideModal,
            currentAccountMeta
        } = this.props;

        const {
            suite,
            firstLine,
            city,
            province,
            postalCode
        } = this.state;

        const styles = {
            row: {
                display: 'flex',
                flexDirection: 'row'
            },
            divider: {
                width: 40
            }
        };

        return (
            <Modal
                open={true}
                onClose={hideModal}
                title={ i18n.t(tokens.titles.modifyAccountDetails) }
                primaryButton={{
                    onClick: this._confirm
                }}
                loader={this.props.loader}
            >
                <TextInput
                    label={ i18n.t(tokens.labels.account.companyName) }
                    value={ this.state.companyName }
                    onBlur={ () => this._onBlur('companyName') }
                    onChange={ (value) => this._handleInputChange('companyName', value) }
                    error={ Boolean(this.state.companyNameError) }
                    helperText={ this.state.companyNameError }
                    disabled={ Boolean(currentAccountMeta.groupNum) }
                    fullWidth
                    autofocus
                />

                <TextInput
                    label={ i18n.t(tokens.labels.streetAddress) }
                    autocomplete="shipping street-address"
                    value={ firstLine }
                    onBlur={ () => this._onAddressBlur('firstLine') }
                    onChange={ (value) => this._handleAddressChange('firstLine', value) }
                    error={ Boolean(this.state.firstLineError) }
                    helperText={ this.state.firstLineError }
                    fullWidth
                />

                <div style={ styles.row }>
                    <TextInput
                        label={ i18n.t(tokens.labels.account.suiteUnit) }
                        value={ suite }
                        onBlur={ () => this._onAddressBlur('suite') }
                        onChange={ (value) => this._handleAddressChange('suite', value) }
                        fullWidth
                    />

                    <div style={ styles.divider } />

                    <TextInput
                        label={ i18n.t(tokens.labels.account.cityTown) }
                        autocomplete="shipping locality"
                        value={ city }
                        onBlur={ () => this._onAddressBlur('city') }
                        onChange={ (value) => this._handleAddressChange('city', value) }
                        error={ Boolean(this.state.cityError) }
                        helperText={ this.state.cityError }
                        fullWidth
                    />
                </div>


                <div style={ styles.row }>
                    <TextInput
                        label={ i18n.t(tokens.labels.province) }
                        autocomplete="shipping region"
                        value={ province }
                        onBlur={ () => this._onAddressBlur('province') }
                        onChange={ (value) => this._handleInputChange('province', value) }
                        select
                        selectOptions={ Services.selectFieldOptions.provinceOptions() }
                        fullWidth
                    />

                    <div style={ styles.divider } />

                    <TextInput
                        label={ i18n.t(tokens.labels.postalCode) }
                        autocomplete="shipping postal-code"
                        value={ postalCode }
                        onBlur={ () => this._onAddressBlur('postalCode') }
                        onChange={ (value) => this._handleAddressChange('postalCode', value) }
                        error={ Boolean(this.state.postalCodeError) }
                        helperText={ this.state.postalCodeError }
                        fullWidth
                    />
                </div>

                <PhoneInput
                    label={ i18n.t(tokens.labels.account.companyContactNumber) }
                    value={ this.state.phone }
                    onBlur={ () => this._onBlur('phone') }
                    onChange={ (value) => this._handleInputChange('phone', value) }
                    error={ Boolean(this.state.phoneError) }
                    helperText={ this.state.phoneError }
                    fullWidth
                />

                <TextInput
                    label={ i18n.t(tokens.labels.account.companyEmail) }
                    value={ this.state.contactEmail }
                    onBlur={ () => this._onBlur('contactEmail') }
                    onChange={ (value) => this._handleInputChange('contactEmail', value) }
                    error={ Boolean(this.state.contactEmailError) }
                    helperText={ this.state.contactEmailError }
                    fullWidth
                />

                <TextInput
                    label={ i18n.t(tokens.labels.account.signingAuthorityName) }
                    value={ this.state.authorityName }
                    onBlur={ () => this._onBlur('authorityName') }
                    onChange={ (value) => this._handleInputChange('authorityName', value) }
                    error={ Boolean(this.state.authorityError) }
                    helperText={ this.state.authorityError }
                    fullWidth
                />

                <TextInput 
                    label={ i18n.t(tokens.labels.account.signingAuthorityEmail) }
                    value={ this.state.authorityEmail }
                    onBlur={ () => this._onBlur('authorityEmail') }
                    onChange={ (value) => this._handleInputChange('authorityEmail', value) }
                    error={ Boolean(this.state.authorityEmailError) }
                    helperText={ this.state.authorityEmailError }
                    fullWidth
                />

                {
                    (!this.props.currentAccountMeta.isPrivateLabel && this.state.asoType !== null) &&
                    <>
                        <Text
                            medium
                            light
                            margin="10px 0 10px 0"
                        >
                            { i18n.t(tokens.labels.account.asoSettingsContact) }
                        </Text>

                        <FormControl component="fieldset">
                            <Text
                                small
                                bold
                                margin="10px 0 0 0"
                            >
                                { i18n.t(tokens.labels.account.asoType) }
                            </Text>

                            <BubbleSelectBlock
                                first={{
                                    title: i18n.t(tokens.labels.account.budgeted),
                                    selected: this.state.asoType === 'Budgeted',
                                    field: 'Budgeted'
                                }}
                                second={{
                                    title: i18n.t(tokens.labels.account.inArrears),
                                    selected: this.state.asoType === 'Arrears',
                                    field: 'Arrears'
                                }}
                                disabled={ true }
                            />
                        </FormControl>

                        <FormControl component="fieldset">
                            <Text
                                medium
                                bold
                                margin="10px 0 10px 0"
                            >
                                { i18n.t(tokens.labels.account.asoDental) }
                            </Text>

                            <BubbleSelectBlock
                                first={{
                                    title: i18n.t(tokens.buttons.yes),
                                    selected: Boolean(this.state.isAsoDental) === true,
                                    field: 'isAsoDentalYes'
                                }}
                                second={{
                                    title: i18n.t(tokens.buttons.no),
                                    selected: Boolean(this.state.isAsoDental) === false,
                                    field: 'isAsoDentalNo'
                                }}
                                disabled={ true }
                            />
                        </FormControl>

                        <FormControl component="fieldset">
                            <Text
                                medium
                                bold
                                margin="10px 0 10px 0"
                            >
                                { i18n.t(tokens.labels.account.asoHealth) }
                            </Text>

                            <BubbleSelectBlock
                                first={{
                                    title: i18n.t(tokens.buttons.yes),
                                    selected: Boolean(this.state.isAsoHealth) === true,
                                    field: 'isAsoHealthYes'
                                }}
                                second={{
                                    title: i18n.t(tokens.buttons.no),
                                    selected: Boolean(this.state.isAsoHealth) === false,
                                    field: 'isAsoHealthNo'
                                }}
                                disabled={ true }
                            />
                        </FormControl>
                    </>
                }
                <span id="account-settings" />
            </Modal>
        );
    }
}

AccountSettings.propTypes = {
    currentAccountMeta: PropTypes.object,
    whitelabel: PropTypes.object
};

/**
 * @param state
 * @returns {{meta: null, loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader,
    currentAccountMeta: state.advisor.currentAccountMeta,
    whitelabel: state.whitelabel
});

export default (connect(mapStateToProps)(AccountSettings));
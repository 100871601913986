import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import { Title, Content, MessageCard, i18n, tokens, ChangeRequestForm, Theme, Container, Button } from '@simplybenefits/sb-ui-library';
import { getChangeRequestDataReadOnly, approveChangeRequest, declineChangeRequest, getChangeRequestFile } from '../../actions/changeRequests.js';
import { listFiles, createFolder, uploadFile, downloadFile, removeFile } from '../../actions/files.js';

/**
 *
 * @param dispatch
 * @param match
 * @param loader
 * @param isPrivateView
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeRequestDetailsView = ({ dispatch, match, loader, isPrivateView = true }) => {
    const [ changesRequestData, setChangeRequestData ] = useState(null);
    const [ changeRequestID, setChangeRequestID ] = useState(null);
    const [ locale, setLocale ] = useState(i18n.locale);
    const topRef = useRef(null);

    useEffect(() => {

        /**
         *
         * @returns {Promise<void>}
         */
        const fetchData = async () => {
            const id = match.params?.id;
            const reviewerID = match.params?.reviewerID;
            const changeRequestIDFromURL = match?.params?.changeRequestID;

            const changeRequest = isPrivateView
                ? await dispatch(getChangeRequestDataReadOnly(changeRequestIDFromURL, id, reviewerID))
                : await dispatch(getChangeRequestFile(changeRequestIDFromURL));

            if (changeRequest) {
                setChangeRequestID(match.params?.changeRequestID);
                setChangeRequestData(changeRequest);
            }

            return true;
        };

        fetchData();
    }, []);

    /**
     * 
     * @param {*} link 
     */
    const _navigateTo = (link) => {
        window.location.href = link;
    };

    /**
     * 
     */
    const _toggleUserLocale = () => {
        i18n.toggleLocale();
        setLocale(i18n.locale);
    };

    /**
     * 
     * @param {*} submitData Data returned from the change request form
     * @param {*} submitType Approve or Decline
     * @returns 
     */
    const submit = async (submitData, submitType) => {
        let updatedChangeRequestFile = null;
    
        if (submitType === 'Approve') {
            updatedChangeRequestFile = await dispatch(approveChangeRequest(changeRequestID, submitData));
        } else if (submitType === 'Decline') {
            updatedChangeRequestFile = await dispatch(declineChangeRequest(changeRequestID, submitData));
        }

        if (updatedChangeRequestFile) {
            setChangeRequestData((prevState) => ({
                ...prevState,
                ...updatedChangeRequestFile
            }));

            topRef.current.scrollIntoView({ behavior: 'smooth' });
            return true;
        }

        return false;
    };

    return (
        <Container ref={ topRef }>
            <Content>
                {
                    !isPrivateView
                        ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <>
                                    <ArrowBack
                                        onClick={() => _navigateTo("/memberChanges")}
                                        sx={{
                                            cursor: 'pointer',
                                            fontSize: '60px',
                                            marginRight: 1.25,
                                            paddingBottom: '10px',
                                            color: Theme.colors.darkGrey
                                        }}
                                    />
                                    <Title title={i18n.t(tokens.titles.changeRequest)} />
                                </>
                            </div>
                        )
                        :
                        <Button 
                            onClick={() => _toggleUserLocale() }
                            customStyle={{ margin: '10px 0 -10px 0' }}
                        >
                            { locale === 'en' ? 'Français' : 'English' }
                        </Button>

                }

                <MessageCard
                    title={ i18n.t(tokens.labels.changeRequests.reviewChangeRequest) }
                    subtitle={ i18n.t(tokens.labels.changeRequests.reviewChangeRequestBodyAdmin) }
                />

                {
                    changesRequestData ?
                        <ChangeRequestForm
                            accessType={'administrator'}
                            data={ changesRequestData }
                            type="users"
                            id={ changesRequestData.userID }
                            dispatch={ dispatch }
                            loader={ loader }
                            listFiles={ listFiles }
                            createFolder={ createFolder }
                            uploadFile={ uploadFile }
                            downloadFile={ downloadFile }
                            removeFile={ removeFile }
                            approveChangeRequest={ (changeRequest) => submit(changeRequest, 'Approve') }
                            declineChangeRequest={ (changeRequest) => submit(changeRequest, 'Decline') }
                        />
                        : null
                }
            </Content>
        </Container>
    );
};

/**
 * @param state
 * @returns {{loading: boolean}}
 */
const mapStateToProps = (state) => ({
    loader: state.loader
});

export default (connect(mapStateToProps)(ChangeRequestDetailsView));
import initialState from '../initialState.js';
import {
    GET_QUOTE_SUCCESS,
    GET_QUOTES,
    SET_PLAN_TYPE,
    CREATE_NEW_PLAN_DESIGN,
    SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA,
    SAVE_PLAN_DESIGN_FIELD,
    REVERT_FROM_REVISION,
    SAVE_MODULAR_BENEFIT_INIT_DATA,
    SAVE_MODULAR_FIELD,
    SET_STATE,
    ADD_CLASS_SUCCESS,
    EDIT_CLASS_SUCCESS,
    ADD_DIVISION_SUCCESS,
    EDIT_DIVISION_SUCCESS,
    ADD_DESIGN_FOR_NEW_CLASS,
    REMOVE_CLASS_SUCCESS,
    REMOVE_DIVISION_SUCCESS
} from '../../actions/types/quotes';

export default (state = initialState.quotes, action) => {
    switch (action.type) {
        case GET_QUOTES: {
            return {
                ...state,
                all: action.quotes
            };
        }

        case GET_QUOTE_SUCCESS: {
            return {
                ...state,
                currentQuote: action.data
            };
        }

        case SET_PLAN_TYPE: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    meta: {
                        ...state.currentQuote.meta,
                        planType: action.planType
                    }
                }
            };
        }

        case CREATE_NEW_PLAN_DESIGN: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    planDesigns: {
                        ...state.currentQuote.planDesigns,
                        [action.classID]: {
                            ...state.currentQuote.planDesigns[action.classID],
                            [action.designID]: action.data
                        }
                    }
                }
            };
        }

        case SAVE_PLAN_DESIGN_BENEFIT_INIT_DATA: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    planDesigns: {
                        ...state.currentQuote.planDesigns,
                        [action.classID]: {
                            ...state.currentQuote.planDesigns[action.classID],
                            [action.designID]: {
                                ...state.currentQuote.planDesigns[action.classID][action.designID],
                                [action.benefit]: {
                                    ...action.data
                                }
                            }
                        }
                    }
                }
            };
        }

        case SAVE_PLAN_DESIGN_FIELD: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    planDesigns: {
                        ...state.currentQuote.planDesigns,
                        [action.classID]: {
                            ...state.currentQuote.planDesigns[action.classID],
                            [action.designID]: {
                                ...state.currentQuote.planDesigns[action.classID][action.designID],
                                [action.benefit]: {
                                    ...state.currentQuote.planDesigns[action.classID][action.designID][action.benefit],
                                    [action.field]: action.value
                                }
                            }
                        }
                    }
                }
            };
        }

        case REVERT_FROM_REVISION: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    planDesigns: {
                        ...state.currentQuote.planDesigns,
                        [action.classID]: {
                            ...state.currentQuote.planDesigns[action.classID],
                            [action.designID]: {
                                ...state.currentQuote.planDesigns[action.classID][action.designID],
                                ...action.data
                            }
                        }
                    }
                }
            };
        }

        case SAVE_MODULAR_BENEFIT_INIT_DATA: {
            if (!action.tier) {
                return {
                    ...state,
                    currentQuote: {
                        ...state.currentQuote,
                        modularBlueprints: {
                            ...state.currentQuote.modularBlueprints,
                            [action.classID]: {
                                ...state.currentQuote.modularBlueprints[action.classID],
                                [action.benefit]: {
                                    ...action.data
                                }
                            }
                        }
                    }
                };
            }

            if (action.tierBenefit === 'dental') {
                return {
                    ...state,
                    currentQuote: {
                        ...state.currentQuote,
                        modularBlueprints: {
                            ...state.currentQuote.modularBlueprints,
                            [action.classID]: {
                                ...state.currentQuote.modularBlueprints[action.classID],
                                dental: {
                                    ...state.currentQuote.modularBlueprints[action.classID].dental,
                                    [action.tier]: {
                                        ...action.data
                                    }
                                }
                            }
                        }
                    }
                };
            }

            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    modularBlueprints: {
                        ...state.currentQuote.modularBlueprints,
                        [action.classID]: {
                            ...state.currentQuote.modularBlueprints[action.classID],
                            health: {
                                ...state.currentQuote.modularBlueprints[action.classID].health,
                                [action.tier]: {
                                    ...state.currentQuote.modularBlueprints[action.classID].health[action.tier],
                                    [action.benefit]: {
                                        ...action.data
                                    }
                                }
                            }
                        }
                    }
                }
            };
        }

        case SAVE_MODULAR_FIELD: {
            if (!action.tier) {
                return {
                    ...state,
                    currentQuote: {
                        ...state.currentQuote,
                        modularBlueprints: {
                            ...state.currentQuote.modularBlueprints,
                            [action.classID]: {
                                ...state.currentQuote.modularBlueprints[action.classID],
                                [action.benefit]: {
                                    ...state.currentQuote.modularBlueprints[action.classID][action.benefit],
                                    [action.field]: action.value
                                }
                            }
                        }
                    }
                };
            }

            if (action.tierBenefit === 'dental') {
                return {
                    ...state,
                    currentQuote: {
                        ...state.currentQuote,
                        modularBlueprints: {
                            ...state.currentQuote.modularBlueprints,
                            [action.classID]: {
                                ...state.currentQuote.modularBlueprints[action.classID],
                                dental: {
                                    ...state.currentQuote.modularBlueprints[action.classID].dental,
                                    [action.tier]: {
                                        ...state.currentQuote.modularBlueprints[action.classID].dental[action.tier],
                                        [action.field]: action.value
                                    }
                                }
                            }
                        }
                    }
                };
            }

            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    modularBlueprints: {
                        ...state.currentQuote.modularBlueprints,
                        [action.classID]: {
                            ...state.currentQuote.modularBlueprints[action.classID],
                            health: {
                                ...state.currentQuote.modularBlueprints[action.classID].health,
                                [action.tier]: {
                                    ...state.currentQuote.modularBlueprints[action.classID].health[action.tier],
                                    [action.benefit]: {
                                        ...state.currentQuote.modularBlueprints[action.classID].health[action.tier][action.benefit],
                                        [action.field]: action.value
                                    }
                                }
                            }
                        }
                    }
                }
            };
        }

        case SET_STATE: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    meta: {
                        ...state.currentQuote.meta,
                        state: action.state
                    }
                },
                all: state.all.map((quote) => {
                    if (quote.quoteID === action.quoteID) {
                        return {
                            ...quote,
                            meta: {
                                ...quote.meta,
                                state: action.state
                            }
                        };
                    }

                    return quote;
                })
            };
        }

        case ADD_CLASS_SUCCESS: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    classes: {
                        ...state.currentQuote.classes,
                        [action.data.classID]: action.data
                    }
                }
            };
        }

        case ADD_DESIGN_FOR_NEW_CLASS: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    planDesigns: {
                        ...state.currentQuote.planDesigns,
                        [action.data.classID]: {}
                    }
                }
            };
        }

        case EDIT_CLASS_SUCCESS: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    classes: {
                        ...state.currentQuote.classes,
                        [action.data.classID]: {
                            ...state.currentQuote.classes[action.data.classID],
                            ...action.data
                        }
                    }
                }
            };
        }

        case REMOVE_CLASS_SUCCESS: {
            const classes = { ...state.currentQuote.classes };
            delete classes[action.data.classID];

            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    classes: {
                        ...classes
                    }
                }
            };
        }

        case REMOVE_DIVISION_SUCCESS: {
            const divisions = { ...state.currentQuote.divisions };
            delete divisions[action.data.divisionID];

            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    divisions: {
                        ...divisions
                    }
                }
            };
        }

        case ADD_DIVISION_SUCCESS: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    divisions: {
                        ...state.currentQuote.divisions,
                        [action.data.divisionID]: action.data
                    }
                }
            };
        }

        case EDIT_DIVISION_SUCCESS: {
            return {
                ...state,
                currentQuote: {
                    ...state.currentQuote,
                    divisions: {
                        ...state.currentQuote.divisions,
                        [action.data.divisionID]: {
                            ...state.currentQuote.divisions[action.data.divisionID],
                            ...action.data
                        }
                    }
                }
            };
        }

        default:
            return state;
    }
};
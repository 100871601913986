import initialState from '../initialState.js';
import {
    BANKING_DETAILS_VERIFYING,
    GET_ADMIN_ACCOUNT_SUCCESS,
    CREATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_SETTINGS_SUCCESS,
    UPDATE_ADMIN_ACCOUNT_SETTINGS_SUCCESS,
    REMOVE_ADMIN_SUCCESS,
    PAYMENT_DETAILS_SUCCESS,
    REMOVE_NOTIFICATION_SUCCESS,
    GET_CHANGELOG,
    GET_PENDING_CHANGES
} from '../../actions/types/admin';

export default (state = initialState.admin, action) => {
    switch (action.type) {
        case BANKING_DETAILS_VERIFYING: {
            return {
                ...state,
                bankingDetailsVerifying: action.state
            };
        }

        case GET_ADMIN_ACCOUNT_SUCCESS: {
            return {
                ...state,
                meta: action.meta,
                administrators: action.administrators,
                notifications: action.notifications,
                account: action.account,
                changelog: action.changelog
            };
        }

        case CREATE_ADMIN_SUCCESS: {
            action.data.divisions = action.data.selectedDivisions;
            delete action.data.selectedDivisions;

            return {
                ...state,
                administrators: {
                    ...state.administrators,
                    [action.data.adminID]: action.data
                }
            };
        }

        case UPDATE_ADMIN_SUCCESS: {
            action.data.divisions = action.data.selectedDivisions;
            delete action.data.selectedDivisions;

            return {
                ...state,
                administrators: {
                    ...state.administrators,
                    [action.data.adminID]: {
                        ...state.administrators[action.data.adminID],
                        ...action.data
                    }
                }
            };
        }

        case UPDATE_ADMIN_ACCOUNT_SETTINGS_SUCCESS: {
            return {
                ...state,
                account: {
                    ...state.account,
                    company: action.data.company,
                    address: action.data.address,
                    phone: action.data.phone,
                    contactEmail: action.data.contactEmail,
                    authorityName: action.data.authorityName,
                    authorityEmail: action.data.authorityEmail,
                    planDefaultType: action.data.planDefaultType,
                    status: action.data.status
                }
            };
        }

        case UPDATE_ADMIN_SETTINGS_SUCCESS: {
            return {
                ...state,
                meta: { ...state.meta, ...action.data }
            };
        }

        case REMOVE_ADMIN_SUCCESS: {
            const copy = state.administrators;
            delete copy[action.adminID];
            return {
                ...state,
                administrators: copy
            };
        }

        case PAYMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                meta: {
                    ...state.meta,
                    paymentsSetup: 1
                }
            };
        }

        case REMOVE_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                notifications: state.notifications.filter((item) => item.id !== action.id)
            };
        }

        case GET_CHANGELOG: {
            return {
                ...state,
                changelog: action.changelog
            };
        }

        case GET_PENDING_CHANGES: {
            return {
                ...state,
                pendingChanges: action.pendingChanges
            };
        }

        default:
            return state;
    }
};
export const LOGOUT = 'admin/LOGOUT';
export const BANKING_DETAILS_VERIFYING = 'admin/BANKING_DETAILS_VERIFYING';
export const GET_ADMIN_ACCOUNT_SUCCESS = 'admin/GET_ADMIN_ACCOUNT_SUCCESS';

export const CREATE_ADMIN_SUCCESS = 'admin/CREATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_SUCCESS = 'admin/UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_SETTINGS_SUCCESS = 'admin/UPDATE_ADMIN_SETTINGS_SUCCESS';
export const UPDATE_ADMIN_ACCOUNT_SETTINGS_SUCCESS = 'admin/UPDATE_ADMIN_ACCOUNT_SETTINGS_SUCCESS';
export const REMOVE_ADMIN_SUCCESS = 'admin/REMOVE_ADMIN_SUCCESS';
export const PAYMENT_DETAILS_SUCCESS = 'admin/PAYMENT_DETAILS_SUCCESS';
export const REMOVE_NOTIFICATION_SUCCESS = 'admin/REMOVE_NOTIFICATION_SUCCESS';
export const GET_CHANGELOG = 'admin/GET_CHANGELOG';
export const GET_PENDING_CHANGES = 'admin/GET_PENDING_CHANGES';
export const GET_CENSUS_SUCCESS = 'census/GET_CENSUS_SUCCESS';
export const ADD_CENSUS_USER_SUCCESS = 'census/ADD_CENSUS_USER_SUCCESS';
export const REMOVE_CENSUS_USER_SUCCESS = 'census/REMOVE_CENSUS_USER_SUCCESS';
export const GET_USERS_SUCCESS = 'census/GET_USERS_SUCCESS';
export const GET_CLASSES_SUCCESS = 'census/GET_CLASSES_SUCCESS';
export const GET_DIVISIONS_SUCCESS = 'census/GET_DIVISIONS_SUCCESS';
export const ADD_CLASS_SUCCESS = 'census/ADD_CLASS_SUCCESS';
export const EDIT_CLASS_SUCCESS = 'census/EDIT_CLASS_SUCCESS';
export const ADD_DIVISION_SUCCESS = 'census/ADD_DIVISION_SUCCESS';
export const EDIT_DIVISION_SUCCESS = 'census/EDIT_DIVISION_SUCCESS';
export const UPDATE_CENSUS_USER_SUCCESS = 'census/UPDATE_CENSUS_USER_SUCCESS';